// src/components/Modal.tsx
import { useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Drawer, Typography, Divider } from '@mui/material'

import useDiscriminator from 'src/hooks/useDiscriminator'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, openModal, setModalData } from 'src/slices/modal'

import AircraftSelectorModal from '../AircraftSelectorModal'

import CreateTime from './CreateTimeV2'

interface AircraftTimeModalProps {
  discriminator?: string
  onSuccess?: (aircraftUsageLogId: string) => void
  onClose?: () => void
}

const AircraftTimeModal: React.FC<AircraftTimeModalProps> = ({
  discriminator,
  onSuccess,
  onClose,
}) => {
  const dispatch = useDispatch()

  const open = useSelector(
    (state) => state.modal.modals?.aircraftTimeModal?.isOpen
  )

  const metadata =
    useSelector((state) => state.modal.modals?.aircraftTimeModal?.data) ?? {}
  const show = useDiscriminator(open, discriminator, metadata?.discriminator)

  useEffect(() => {
    if (show && !metadata?.aircraftId) {
      console.warn('opened modal without aircraftId')
      dispatch(
        openModal({
          name: 'aircraftSelectorModal',
          data: {
            discriminator: 'timeModalAircraftDisambiguator',
          },
        })
      )
      dispatch(
        setModalData({ name: 'aircraftTimeModal', data: { loading: false } })
      )
    }
  }, [show])

  const handleClose = () => {
    onClose?.()
    dispatch(closeModal({ name: 'aircraftTimeModal' }))
  }

  return (
    <>
      <Drawer
        open={show && !!metadata?.aircraftId}
        onClose={handleClose}
        anchor="right"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disablePortal
        sx={{ zIndex: 1300 }}
        PaperProps={{
          className: 'min-w-[60%]',
        }}
      >
        <div className="flex items-center justify-between p-2">
          <Typography variant="h6">Update Aircraft Times</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <CreateTime
          disablePick={metadata?.disablePick}
          onSuccess={onSuccess}
          aircraftUsageLogId={metadata?.aircraftUsageLogId}
          aircraftUsageAsOf={metadata?.aircraftUsageAsOf}
        />
      </Drawer>
      <AircraftSelectorModal
        discriminator="timeModalAircraftDisambiguator"
        onSuccess={(id) =>
          dispatch(
            setModalData({
              name: 'aircraftTimeModal',
              data: { aircraftId: id },
            })
          )
        }
        onBail={() => dispatch(closeModal({ name: 'aircraftTimeModal' }))}
      />
    </>
  )
}

export default AircraftTimeModal
