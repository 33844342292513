import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import { Chip, Divider, Skeleton, Typography } from '@mui/material'
import { useMutation, useQuery } from '@redwoodjs/web'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import WorkflowAircraftDetailsDrawer from 'src/components/WorkflowAircraftDetailsDrawer'
import WorkflowHeader from 'src/components/WorkflowHeader'
import useComplianceLedgerRefresh from 'src/hooks/requests/useComplianceLedgerRefresh'
import { useDispatch } from 'src/hooks/useDispatch'
import { useInlineFieldState } from 'src/hooks/useInlineFieldState'
import useModal from 'src/hooks/useModal'
import useUnresolvedComments from 'src/hooks/useUnresolvedComments'
import Sentry from 'src/lib/sentry'
import {
  GET_WORK_ORDER_ATTACHMENTS,
  UPDATE_WORK_ORDER_STATUS,
} from 'src/pages/WorkOrderCompliancePageV2/queries'
import { openModal } from 'src/slices/modal'
import { formatDateForUpdatedAt } from 'src/utils/helpers'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import Button from 'src/components/MUI/Button'
import { WorkflowAttachmentType } from 'src/components/workflow/WorkflowAttachmentsSection'
import WorkflowAttachmentsSection from 'src/components/workflow/WorkflowAttachmentsSection'
import InlinePopoverField from 'src/components/InlinePopoverField'
import { AttachFile } from '@mui/icons-material'

interface WorkOrderHeaderDetails {
  internalWorkOrder: {
    id: string
    createdAt: string
    updatedAt: string
    aircraft: {
      id: string
      tailNumber: string
    }
  }
}

interface WorkOrderReviewHeaderProps {
  id: string
  data: WorkOrderHeaderDetails
  loading: boolean
}

export const WorkOrderReviewHeader: React.FC<WorkOrderReviewHeaderProps> = ({
  id,
  data,
  loading,
}) => {
  const dispatch = useDispatch()
  const { isSubmitting, hasError, isSettled } = useInlineFieldState()
  const [savedStatus, setSavedStatus] = useState(false)
  const prevIsSubmittingRef = useRef(isSubmitting)

  const [updateWorkOrderStatus] = useMutation(UPDATE_WORK_ORDER_STATUS)
  const {
    data: attachmentsData,
    loading: attachmentsLoading,
    refetch: refetchAttachments,
  } = useQuery(GET_WORK_ORDER_ATTACHMENTS, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })

  const { mutate: complianceLedgerRefresh } = useComplianceLedgerRefresh()

  const { handleOpen: openAircraftDetailsDrawer } = useModal(
    'workflowAircraftDetailsDrawer'
  )

  const {
    hasUnresolvedComments,
    unresolvedCommentThreads,
    isLoading: commentsLoading,
    error: commentsError,
  } = useUnresolvedComments()
  const [unresolvedCommentsModalOpen, setUnresolvedCommentsModalOpen] =
    React.useState(false)

  const [completeWorkOrder, { loading: completionLoading }] = useMutation(gql`
    mutation CompleteWorkOrder(
      $id: String!
      $input: completeInternalWorkOrderInput
    ) {
      completeInternalWorkOrder(id: $id, input: $input) {
        id
        status
        invoice {
          id
        }
        complianceLedger {
          id
        }
      }
    }
  `)

  useEffect(() => {
    if (prevIsSubmittingRef.current && !isSubmitting) {
      setSavedStatus(true)
      const timer = setTimeout(() => setSavedStatus(false), 1000)
      return () => clearTimeout(timer)
    }
    prevIsSubmittingRef.current = isSubmitting
  }, [isSubmitting])

  const appliedUsageLog = useMemo(() => {
    return data?.internalWorkOrder?.aircraftUsageLog
  }, [data?.internalWorkOrder?.aircraftUsageLog])

  return (
    <>
      <WorkflowHeader
        loading={loading}
        showAppliedTimes
        appliedUsageLog={appliedUsageLog}
        onUsageLogClick={() => {
          openAircraftDetailsDrawer({
            highlightAppliedTimes: true,
          })
        }}
        title={data?.internalWorkOrder?.workOrderNumber}
        createdAt={data?.internalWorkOrder?.createdAt}
        tailNumber={data?.internalWorkOrder?.aircraft?.tailNumber}
        slots={{
          statusChip: loading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <Chip
              className="px-1"
              size="small"
              variant="filled"
              label={
                data?.internalWorkOrder?.status === 'IN_REVIEW'
                  ? 'In Review'
                  : 'In Progress'
              }
              color="warning"
            />
          ),
          topRightFields: (
            <>
              <div>
                {hasError ? (
                  <div className="flex items-center gap-1">
                    <ErrorOutlineOutlinedIcon color="error" />
                    <Typography variant="caption">SAVING FAILED</Typography>
                  </div>
                ) : savedStatus ? (
                  <div className="flex items-center gap-1">
                    <CheckCircleOutlinedIcon color="primary" />
                    <Typography variant="caption">SAVED!</Typography>
                  </div>
                ) : (
                  <div className="flex items-center gap-1">
                    <HistoryOutlinedIcon color="primary" />
                    <Typography variant="caption">
                      {formatDateForUpdatedAt(
                        data?.internalWorkOrder?.updatedAt
                      )}
                    </Typography>
                  </div>
                )}
              </div>

              <Button
                variant="outlined"
                color="base"
                onClick={openAircraftDetailsDrawer}
                disabled={loading}
              >
                AIRCRAFT DETAILS
              </Button>
              <Button
                color="base"
                variant="outlined"
                disabled={loading}
                onClick={() => {
                  updateWorkOrderStatus({
                    variables: {
                      id,
                      status: 'IN_PROGRESS',
                    },
                    onError: (error) => {
                      toast.error('Failed to update work order status')
                      Sentry.captureException(error)
                    },
                  })
                }}
              >
                BACK
              </Button>
              <Button
                disabled={!isSettled || completionLoading || loading}
                locked={
                  !useHasPermission(
                    Permissions.workOrder.complete,
                    data?.internalWorkOrder?.aircraft?.id
                  )
                }
                lockedTooltip="You do not have permission"
                onClick={async () => {
                  if (commentsError) {
                    Sentry.captureException(commentsError)
                    toast.error(
                      'There was an error submitting the work order.  Please try again.',
                      { duration: 10000 }
                    )
                    return
                  }

                  if (hasUnresolvedComments) {
                    setUnresolvedCommentsModalOpen(true)
                    return
                  }

                  completeWorkOrder({
                    variables: {
                      id,
                    },

                    onCompleted: (data) => {
                      const ledgerId =
                        data?.completeInternalWorkOrder?.complianceLedger?.id
                      complianceLedgerRefresh(
                        { id: ledgerId },
                        {
                          onCompleted: () =>
                            console.log('Maintenance Items Refreshed'),
                        }
                      )
                      dispatch(openModal({ name: 'workOrderCompletedModal' }))
                    },
                  })
                }}
              >
                COMPLETE
              </Button>
            </>
          ),
          inlineFields: (
            <>
              <Divider orientation="vertical" flexItem variant="middle" />
              <InlinePopoverField
                loading={attachmentsLoading}
                label="Attachments"
                value={`(${
                  attachmentsData?.internalWorkOrder?.attachedFiles?.length || 0
                })`}
                icon={AttachFile}
                name="attachments"
                triggerOn={{ enter: false, blur: false, onClose: false }}
              >
                {({ handleClose }) => {
                  const attachables = useMemo(
                    () => [
                      {
                        id: attachmentsData?.internalWorkOrder?.id,
                        type: 'InternalWorkOrder' as const,
                      },
                    ],
                    [attachmentsData?.internalWorkOrder?.id]
                  )

                  const attachments: WorkflowAttachmentType[] = useMemo(() => {
                    return [
                      ...(attachmentsData?.internalWorkOrder?.attachedFiles?.map(
                        (file) =>
                          file?.file
                            ? {
                                ...file.file,
                                category: 'InternalWorkOrder',
                              }
                            : null
                      ) ?? []),
                    ].filter(Boolean) as WorkflowAttachmentType[]
                  }, [attachmentsData?.internalWorkOrder?.attachedFiles])

                  return (
                    <div className="max-w-lg py-2">
                      <WorkflowAttachmentsSection
                        data={attachments}
                        attachables={attachables}
                        refetchAttachments={() => {
                          refetchAttachments({
                            id,
                          })
                          handleClose()
                        }}
                        variant={'default'}
                      />
                    </div>
                  )
                }}
              </InlinePopoverField>
            </>
          ),
        }}
      />

      <WorkflowAircraftDetailsDrawer
        aircraftId={data?.internalWorkOrder?.aircraft?.id}
        aircraftUsageLogId={data?.internalWorkOrder?.aircraftUsageLogId}
      />
    </>
  )
}

export default WorkOrderReviewHeader
