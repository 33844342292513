import { useState } from 'react'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Modal, Paper, TextField, Typography } from '@mui/material'

import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import { DELETE_ATTACHED_FILES } from 'src/components/FileRemoveButton'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal } from 'src/slices/modal'

import Button from '../MUI/Button'
import { ButtonProps } from '../MUI/Button'
import IconButton from '../MUI/IconButton'

type ConfirmationModalProps = {
  title: string
  discriminator?: string
  message?: string | React.ReactNode
  richMessage?: string
  confirmText: string
  denyText: string
  confirmNote?: string
  collectNotes?: boolean
  isNotesRequired?: boolean
  notesFieldLabel?: string
  placeholder?: string
  confirmButtonProps?: ButtonProps
  beforeRender?: () => Promise<boolean>
  onConfirm?: (note?: string) => void
  /** Optionally define what happens when the user clicks the outlined/secondary button */
  onDeny?: () => void
  /** Optionally define what happens when the user clicks backdrop, the close icon, or hits "escape" */
  onBail?: () => void
}

const modalName = 'confirmationModal'

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  discriminator,
  message,
  richMessage,
  confirmText,
  denyText,
  confirmNote,
  collectNotes = false,
  isNotesRequired = false,
  notesFieldLabel,
  placeholder = '',
  confirmButtonProps = {},
  beforeRender,
  onConfirm,
  onDeny,
  onBail,
}) => {
  if (collectNotes && !notesFieldLabel) {
    throw new Error(
      'ConfirmationModal: If collectNotes is true, you must provide a notesFieldLabel'
    )
  }
  const dispatch = useDispatch()
  const [note, setNote] = useState('')

  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  const requestedDiscriminator = useSelector(
    (state) => state.modal.modals?.[modalName]?.data?.discriminator
  )

  const requestedMessage = useSelector(
    (state) => state.modal.modals?.[modalName]?.data?.message
  )

  const requestedVariables = useSelector(
    (state) => state.modal.modals?.[modalName]?.data?.variables
  )

  const [renderConditionMet, setRenderConditionMet] = useState(false)

  const discriminatorMatch =
    typeof discriminator === 'undefined' &&
    typeof requestedDiscriminator === 'undefined'
      ? true
      : discriminator === requestedDiscriminator

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
    setNote('')
  }

  const [deleteAttachedFiles] = useMutation(DELETE_ATTACHED_FILES)

  const removeFileOnConfirm = async () => {
    await deleteAttachedFiles({
      variables: requestedVariables,
      onError: (error) => {
        console.error('Error removing file:', error)
      },
      onCompleted: (data) => {
        toast.success(`${data.deleteAttachedFiles.originalFilename} removed`)
      },
    })
  }

  const handleConfirm = async () => {
    if (discriminator === 'removeFile') {
      await removeFileOnConfirm()
    }
    onConfirm?.(note)
    handleClose()
  }

  const handleDeny = () => {
    onDeny?.()
    handleClose()
  }

  const handleBail = () => {
    onBail?.()
    handleClose()
  }

  React.useEffect(() => {
    if (open && discriminatorMatch && !renderConditionMet) {
      if (beforeRender) {
        beforeRender()
          .then((result) => {
            if (result === false) {
              dispatch(closeModal({ name: modalName }))
              // NOTE: The ID is required to avoid duplicate toasts,
              // for some reason the component gets re-rendered and the toast is triggered again
              toast.error('Fix the form errors before proceeding.', {
                id: 'confirmationModalError',
              })
            } else {
              setRenderConditionMet(true)
            }
          })
          .catch(() => {
            setRenderConditionMet(false)
          })
      } else {
        setRenderConditionMet(true)
      }
    } else {
      setRenderConditionMet(false)
    }
  }, [open, beforeRender, discriminatorMatch])

  return (
    <Modal
      open={renderConditionMet}
      onClose={handleBail}
      aria-labelledby="confirmationModal-title"
      aria-describedby="confirmationModal-description"
      disablePortal
    >
      <Paper className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-xl">
        <div className="p-3 pb-2">
          <div className="mb-6 flex gap-1">
            <div className="flex flex-col justify-between gap-1">
              <Typography
                variant="h6"
                component="h3"
                id="confirmationModal-title"
              >
                {title}
              </Typography>
              {
                // TODO: Add more functionality to richMessage
              }
              {richMessage && (
                <Typography
                  variant="body1"
                  id="confirmationModal-description"
                  className="text-sys-textLowEmpathy"
                >
                  {richMessage.split('\n').map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Typography>
              )}
              <Typography
                variant="subtitle2"
                id="confirmationModal-description"
                className="text-sys-textLowEmpathy"
              >
                {requestedMessage ?? message}
              </Typography>
            </div>

            <IconButton onClick={handleBail}>
              <CloseRoundedIcon />
            </IconButton>
          </div>
          {collectNotes && (
            <TextField
              className="mb-2"
              label={
                <span>
                  {notesFieldLabel}
                  {!isNotesRequired && <strong> (optional)</strong>}
                </span>
              }
              placeholder={placeholder}
              multiline
              fullWidth
              rows={4}
              autoFocus={isNotesRequired}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          )}
          <div className="flex flex-col gap-1">
            <div className="flex flex-col gap-0.25">
              <Button
                {...confirmButtonProps}
                disabled={isNotesRequired && collectNotes && !note}
                onClick={handleConfirm}
                fullWidth
              >
                {confirmText}
              </Button>
              {confirmNote && (
                <Typography className="text-center" variant="caption">
                  {confirmNote}
                </Typography>
              )}
            </div>
            <Button
              onClick={handleDeny}
              fullWidth
              variant="outlined"
              color="base"
            >
              {denyText}
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  )
}

export default ConfirmationModal
