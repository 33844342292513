import { Chip, Typography, Skeleton } from '@mui/material'
import React from 'react'
import AircraftIcon from '@mui/icons-material/AirplanemodeActiveRounded'
import InlinePopoverField from './InlinePopoverField'
import { formatDateForDisplay } from '@wingwork/common/src/helpers'
import type { AircraftUsageLog, User } from 'types/graphql'
import { abbreviateFullName } from 'src/utils/stringFormatters'
import PrimSecTextCombo from './common/PrimSecTextCombo'
import Button from './MUI/Button'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

interface WorkflowHeaderProps {
  title: string
  createdAt?: string | Date
  createdBy?: User
  updatedAt?: string | Date
  tailNumber?: string
  showAppliedTimes?: boolean
  appliedUsageLog?: AircraftUsageLog
  onUsageLogClick?: () => void
  slots?: {
    inlineFields?: React.ReactNode
    topRightFields?: React.ReactNode
    statusChip?: React.ReactNode
  }
  loading?: boolean
}

const WorkflowHeader: React.FC<WorkflowHeaderProps> = ({
  title,
  createdAt,
  createdBy,
  updatedAt,
  tailNumber,
  loading = false,
  showAppliedTimes,
  appliedUsageLog,
  onUsageLogClick,
  slots = {},
}) => {
  const { inlineFields, topRightFields, statusChip } = slots

  return (
    <div className="flex flex-col gap-1 p-4 pb-2">
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              {loading ? (
                <Skeleton variant="text" width={100} />
              ) : (
                <Typography variant="h5" component="h1">
                  {title}
                </Typography>
              )}
              {statusChip}
            </div>
          </div>
          {showAppliedTimes && (
            <AppliedTimesSlot
              loading={loading}
              appliedUsageLog={appliedUsageLog}
              onUsageLogClick={onUsageLogClick}
            />
          )}
        </div>
        <div className="flex items-center gap-2">
          {updatedAt && (
            <Typography variant="subtitle1">
              updated at {formatDateForDisplay(updatedAt)}
            </Typography>
          )}
          {topRightFields}
        </div>
      </div>
      <Typography variant="body1" component="p">
        {loading ? (
          <Skeleton variant="text" width={200} />
        ) : (
          <>
            Created on {formatDateForDisplay(createdAt)}
            {createdBy && <> by {abbreviateFullName(createdBy)}</>}
          </>
        )}
      </Typography>
      <div className="flex flex-wrap items-center gap-2">
        <InlinePopoverField
          loading={loading}
          label="AC Tail"
          value={tailNumber}
          icon={AircraftIcon}
        />
        {inlineFields}
      </div>
    </div>
  )
}

const AppliedTimesSlot = ({
  appliedUsageLog,
  onUsageLogClick,
  loading,
}: {
  appliedUsageLog?: AircraftUsageLog
  onUsageLogClick?: () => void
  loading?: boolean
}) => {
  const airframeUsageLog = appliedUsageLog?.ComponentUsageLog?.find(
    (log) => log.component?.name === 'Airframe'
  )
  if (loading) {
    return <Skeleton variant="text" width={100} />
  }
  return (
    <div>
      {appliedUsageLog ? (
        <div>
          <Button
            variant="text"
            size="small"
            tooltip="Click to see applied times for all other components"
            className="p-0"
            endIcon={<HelpOutlineIcon />}
            onClick={onUsageLogClick}
          >
            Airframe Applied Times
          </Button>
          <div className="flex gap-2">
            <PrimSecTextCombo
              primaryText={airframeUsageLog?.totalTimeSinceNew ?? '-'}
              secondaryText="Hours"
              variant="inverted"
            />
            <PrimSecTextCombo
              primaryText={airframeUsageLog?.cycleSinceNew ?? '-'}
              secondaryText="Landings"
              variant="inverted"
            />
          </div>
        </div>
      ) : (
        <Chip
          label="APPLY TIMES FIRST"
          variant="outlined"
          size="small"
          color="error"
          className="mt-1"
        />
      )}
    </div>
  )
}

export default WorkflowHeader
