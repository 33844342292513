import { useCallback, useEffect, useMemo } from 'react'

import { useDispatch } from 'react-redux'

import { openModal, closeModal, removeClosedModals } from 'src/slices/modal'
import type { ModalName } from 'src/slices/modal'

import { useSelector } from './useSelector'

const useModal = (name: ModalName, discriminator?: string) => {
  const dispatch = useDispatch()
  const modalKey = useMemo(
    () => (discriminator ? `${name}_${discriminator}` : name),
    [name, discriminator]
  )

  const modalState = useSelector((state) => {
    return state.modal.modals?.[modalKey]
  })

  const { isOpen, data } = useMemo(() => {
    return modalState ?? { isOpen: false, data: {} }
  }, [modalState])

  const openingOrder = useSelector((state) => state.modal.openingOrder)

  const zIndex = useMemo(() => {
    const index = openingOrder.findIndex(
      (modal) => modal.name === name && modal.discriminator === discriminator
    )
    return index >= 0 ? 1000 + index : -1 // Return -1 if the modal is not found
  }, [openingOrder, name, discriminator])

  const handleOpen = useCallback(
    (data?: object | React.SyntheticEvent) => {
      if (data && (data as React.SyntheticEvent).currentTarget) {
        data = undefined // Ignore the event object
      }
      dispatch(openModal({ name, data: { discriminator, ...data } }))
    },
    [name, discriminator, dispatch]
  )

  const handleClose = useCallback(() => {
    dispatch(closeModal({ name, discriminator }))
  }, [name, discriminator, dispatch])

  useEffect(() => {
    if (!isOpen) {
      const timeoutId = setTimeout(() => {
        dispatch(removeClosedModals())
      }, 300) // Delay to match the closing animation duration

      return () => clearTimeout(timeoutId)
    }
  }, [isOpen, dispatch])

  return { handleOpen, handleClose, isOpen, data, zIndex }
}

export default useModal
