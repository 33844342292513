import React, { useMemo } from 'react'
import WorkflowHeader from 'src/components/WorkflowHeader'
import { Chip, Divider, Skeleton } from '@mui/material'
import { openModal } from 'src/slices/modal'
import { useDispatch } from 'src/hooks/useDispatch'
import Button from 'src/components/MUI/Button'
import {
  GET_WORK_ORDER_ATTACHMENTS,
  GET_WORK_ORDER_BASE_FIELDS,
} from 'src/pages/WorkOrderCompliancePageV2/queries'
import useQuery from 'src/hooks/useQuery'
import { useMutation } from '@redwoodjs/web'
import { useOrgName } from 'src/hooks/useOrgName'
import { toast } from 'react-hot-toast'
import { navigate, routes } from '@redwoodjs/router'
import { CREATE_INVOICE } from '../queries'
import useUserId from 'src/hooks/useUserId'
import { statusMap } from 'src/pages/PastCompliancePage/PastComplianceListDisplay/PastComplianceTable'
import { ComplianceStatus } from 'types/graphql'
import { t } from 'i18next'
import {
  getComplianceLedgerStatusText,
  isUserRequestedAsNextSigner,
} from 'src/components/ViewLogbookEntryModal/helper'
import WorkflowAircraftDetailsDrawer from 'src/components/WorkflowAircraftDetailsDrawer'
import useModal from 'src/hooks/useModal'
import WorkflowAttachmentsSection, {
  WorkflowAttachmentType,
} from 'src/components/workflow/WorkflowAttachmentsSection'
import InlinePopoverField from 'src/components/InlinePopoverField'
import { AttachFile } from '@mui/icons-material'

interface WorkOrderCompletedHeaderProps {
  id: string
  createdLogbook: boolean
  ledgerStatus: ComplianceStatus
}

export const WorkOrderCompletedHeader: React.FC<
  WorkOrderCompletedHeaderProps
> = ({ id, createdLogbook, ledgerStatus }) => {
  const { data, loading: workOrderLoading } = useQuery(
    GET_WORK_ORDER_BASE_FIELDS,
    {
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }
  )
  const {
    data: attachmentsData,
    loading: attachmentsLoading,
    refetch: refetchAttachments,
  } = useQuery(GET_WORK_ORDER_ATTACHMENTS, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
  const [createInvoice] = useMutation(CREATE_INVOICE)
  const dispatch = useDispatch()
  const orgName = useOrgName()

  const { id: currentUserId, hasLoaded: currentUserIdHasLoaded } = useUserId()
  const loading = useMemo(() => {
    return workOrderLoading || !currentUserIdHasLoaded
  }, [workOrderLoading, currentUserIdHasLoaded])

  const { handleOpen: openAircraftDetailsDrawer } = useModal(
    'workflowAircraftDetailsDrawer'
  )

  const userRequestedAsNextSigner = isUserRequestedAsNextSigner(
    currentUserId,
    data?.internalWorkOrder?.complianceLedger
  )

  const appliedUsageLog = useMemo(() => {
    return data?.internalWorkOrder?.aircraftUsageLog
  }, [data?.internalWorkOrder?.aircraftUsageLog])

  return (
    <>
      <WorkflowHeader
        loading={loading}
        showAppliedTimes
        appliedUsageLog={appliedUsageLog}
        onUsageLogClick={() => {
          openAircraftDetailsDrawer({
            highlightAppliedTimes: true,
          })
        }}
        title={data?.internalWorkOrder?.workOrderNumber}
        createdAt={data?.internalWorkOrder?.createdAt}
        tailNumber={data?.internalWorkOrder?.aircraft?.tailNumber}
        slots={{
          statusChip: loading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <Chip
              className="px-1"
              size="small"
              variant="filled"
              label={getComplianceLedgerStatusText(
                ledgerStatus,
                currentUserId,
                data?.internalWorkOrder?.complianceLedger
              )}
              color={statusMap[ledgerStatus]?.color ?? 'success'}
              icon={statusMap[ledgerStatus]?.icon}
            />
          ),
          topRightFields: (
            <>
              {data?.internalWorkOrder?.invoice?.id ? (
                <Button
                  variant="outlined"
                  color="base"
                  disabled={loading}
                  onClick={() => {
                    navigate(
                      routes.invoice({
                        orgName,
                        id: data?.internalWorkOrder?.invoice?.id,
                      })
                    )
                  }}
                >
                  VIEW INVOICE
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="base"
                  disabled={loading}
                  onClick={() => {
                    createInvoice({
                      variables: {
                        input: {
                          orgSlug: orgName,
                          internalWorkOrderId: id,
                        },
                      },
                      onCompleted: (data) => {
                        toast.success('Invoice created')
                        navigate(
                          routes.invoice({
                            orgName,
                            id: data.createInvoice.id,
                          })
                        )
                      },
                    })
                  }}
                >
                  GENERATE INVOICE
                </Button>
              )}
              {createdLogbook ? (
                <Button
                  variant={
                    ledgerStatus === 'SIGN' && userRequestedAsNextSigner
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    dispatch(openModal({ name: 'viewLogbookEntryModal' }))
                  }}
                >
                  {ledgerStatus === 'SIGN' && userRequestedAsNextSigner
                    ? 'SIGN LOGBOOK ENTRY'
                    : 'VIEW LOGBOOK ENTRY'}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    dispatch(
                      openModal({
                        name: 'createLogbookEntryModal',
                      })
                    )
                  }}
                >
                  PUBLISH LOGBOOK ENTRY
                </Button>
              )}
            </>
          ),
          inlineFields: (
            <>
              <Divider orientation="vertical" flexItem variant="middle" />
              <InlinePopoverField
                loading={attachmentsLoading}
                label="Attachments"
                value={`(${
                  attachmentsData?.internalWorkOrder?.attachedFiles?.length || 0
                })`}
                icon={AttachFile}
                name="attachments"
                triggerOn={{ enter: false, blur: false, onClose: false }}
              >
                {({ handleClose }) => {
                  const attachables = useMemo(
                    () => [
                      {
                        id: attachmentsData?.internalWorkOrder?.id,
                        type: 'InternalWorkOrder' as const,
                      },
                    ],
                    [attachmentsData?.internalWorkOrder?.id]
                  )

                  const attachments: WorkflowAttachmentType[] = useMemo(() => {
                    return [
                      ...(attachmentsData?.internalWorkOrder?.attachedFiles?.map(
                        (file) =>
                          file?.file
                            ? {
                                ...file.file,
                                category: 'InternalWorkOrder',
                              }
                            : null
                      ) ?? []),
                    ].filter(Boolean) as WorkflowAttachmentType[]
                  }, [attachmentsData?.internalWorkOrder?.attachedFiles])

                  return (
                    <div className="max-w-lg py-2">
                      <WorkflowAttachmentsSection
                        data={attachments}
                        attachables={attachables}
                        refetchAttachments={() => {
                          refetchAttachments({
                            id,
                          })
                          handleClose()
                        }}
                        variant={'default'}
                      />
                    </div>
                  )
                }}
              </InlinePopoverField>
            </>
          ),
        }}
      />
      <WorkflowAircraftDetailsDrawer
        aircraftId={data?.internalWorkOrder?.aircraft?.id}
        aircraftUsageLogId={data?.internalWorkOrder?.aircraftUsageLogId}
      />
    </>
  )
}

export default WorkOrderCompletedHeader
