import Button from 'src/components/MUI/Button'
import WorkflowTaskDetailDrawer from 'src/components/WorkflowTaskDetailDrawer/WorkflowTaskDetailDrawer'
import useQuery from 'src/hooks/useQuery'
import {
  GET_SINGLE_COMPLIANCE_DRAWER_DETAILS,
  PERFORM_SINGLE_COMPLIANCE,
} from './queries'
import { useMutation } from '@redwoodjs/web'
import { useForm } from 'react-hook-form'
import WorkflowItemDetailsSection from 'src/components/WorkflowTaskDetailDrawer/sections/Details'
import useModal from 'src/hooks/useModal'
import { CreatePartsTransactionInput } from 'types/graphql'
import AttachmentsSection from './ComplianceDrawerSections/AttachmentsSection'
import PartsTransactionSection from './ComplianceDrawerSections/PartsTransactionSection'
import PartsTransactionDrawer from './PartsTransactionDrawer'
import AircraftTimeModal from 'src/components/AircraftTimeModal/AircraftTimeModal'
import useUploadFile from 'src/hooks/requests/useUploadFile'
import { useEffect } from 'react'
import WorkPerformedDrawer from 'src/components/workflow/WorkPerformed/WorkPerformedDrawer'
import WorkPerformedSection from 'src/components/workflow/WorkPerformed/WorkPerformedSection'
import { toast } from 'react-hot-toast'
import SingleComplianceDetailesSection from '../workflow/SingleComplianceDetailsSection'

type FormValues = {
  attachments: File[]
  partsTransactionFiles: {
    added: File[]
    removed: File[]
  }
  task: {
    maintenanceItemId: string
    workHours: number
    notes: string
    description: string
    workedById: string
  }
  partsTransaction: CreatePartsTransactionInput
  ledger: {
    aircraftId?: string
    aircraftUsageLogId?: string
    description?: string
    statementId?: string
    requestedWorkerId?: string
    requestedInspectorId?: string
  }
}

const getDefaultValues = (maintenanceItemId: string) => ({
  task: {
    maintenanceItemId,
    workHours: null,
    notes: '',
    description: '',
    workedById: '',
  },
  partsTransaction: null,
  attachments: [],
  partsTransactionFiles: {
    added: [],
    removed: [],
  },
  ledger: {
    aircraftUsageLogId: null,
  },
})

type SingleComplianceFormProps = {
  id: string
  isOpen: boolean
  onCompleted?: () => void
}

const SingleComplianceForm: React.FC<SingleComplianceFormProps> = ({
  id,
  isOpen,
  onCompleted,
}) => {
  const { handleOpen: openApplyTimesDrawer } = useModal('aircraftTimeModal')
  const uploadFile = useUploadFile()
  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(id),
  })

  useEffect(() => {
    if (!id && isOpen) {
      console.error('No maintenanceItemId provided when drawer opened')
    }
  }, [isOpen, id])

  // Updated useEffect to use the same default values function
  useEffect(() => {
    methods.reset(getDefaultValues(id))
  }, [isOpen, id, methods])

  const partsTransaction = methods.watch('partsTransaction')
  const usageLogId = methods.watch('ledger.aircraftUsageLogId')

  const workedById = methods.watch('task.workedById')
  const workHours = methods.watch('task.workHours')
  const notes = methods.watch('task.notes')
  const description = methods.watch('task.description')

  const [performSingleCompliance] = useMutation(PERFORM_SINGLE_COMPLIANCE)

  const onSubmit = async (values: FormValues) => {
    if (!values.ledger.aircraftUsageLogId) {
      toast.error('Please apply times before performing compliance')
      return
    }

    try {
      await performSingleCompliance({
        variables: {
          doSign: false,
          input: {
            task: {
              maintenanceItemId: id,
              description: values.task.description,
              notes: values.task.notes,
              workedById: values.task?.workedById
                ? values.task.workedById
                : null,
              workHours: values.task?.workHours ? values.task.workHours : null,
            },
            ledger: {
              aircraftId: data.maintenanceItem.aircraftId,
              aircraftUsageLogId: values.ledger.aircraftUsageLogId,
            },
            partsTransaction: values.partsTransaction,
          },
        },
        onCompleted: async (data) => {
          const ledger = data.performSingleCompliance
          console.log('ledger', ledger)
          if (
            values.attachments.length > 0 ||
            values.partsTransactionFiles?.added?.length ||
            values.partsTransactionFiles?.removed?.length
          ) {
            // Upload all attachments concurrently
            await Promise.all([
              ...values.attachments.map((file) =>
                uploadFile(file, [
                  {
                    id: ledger.complianceActivity[0].id,
                    type: 'ComplianceActivity',
                  },
                ])
              ),
              ...values.partsTransactionFiles.added.map((file) =>
                uploadFile(file, [
                  {
                    id: ledger.complianceActivity[0].partsTransaction.id,
                    type: 'AddedPartTransaction',
                  },
                ])
              ),
              ...values.partsTransactionFiles.removed.map((file) =>
                uploadFile(file, [
                  {
                    id: ledger.complianceActivity[0].partsTransaction.id,
                    type: 'RemovedPartTransaction',
                  },
                ])
              ),
            ])
          }
        },
      })

      onCompleted?.()
    } catch (error) {
      console.error('Error performing compliance:', error)
    }
  }

  const { data, loading } = useQuery(GET_SINGLE_COMPLIANCE_DRAWER_DETAILS, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
  return (
    <div className="flex flex-col gap-2">
      <WorkflowItemDetailsSection
        task={data?.complianceActivity}
        loading={loading}
      />
      <WorkflowItemDetailsSection
        task={{ maintenanceItem: data?.maintenanceItem }}
        loading={loading}
      />
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        id="singleComplianceDrawerForm"
      >
        <SingleComplianceDetailesSection
          aircraftId={data?.maintenanceItem.aircraftId}
          aircraftUsageLogId={usageLogId}
          maintenanceItem={data?.maintenanceItem}
        />
        <WorkPerformedSection
          workedById={workedById}
          workHours={workHours}
          notes={notes}
          description={description}
        />
        <PartsTransactionSection
          disableRequestPart
          maintenanceItemId={id}
          partsTransaction={partsTransaction}
          onDelete={() => {
            methods.setValue('partsTransaction', null)
          }}
        />
        <AttachmentsSection
          onChange={(files) => {
            console.log('bang')
            methods.setValue('attachments', files)
          }}
        />
      </form>
      <PartsTransactionDrawer
        maintenanceItemId={id}
        partsTransaction={partsTransaction}
        onSubmit={({ input, files }) => {
          methods.setValue('partsTransaction', input)
          methods.setValue('partsTransactionFiles', files)
        }}
      />
      <AircraftTimeModal
        discriminator="singleComplianceForm"
        onSuccess={(id) => {
          methods.setValue('ledger.aircraftUsageLogId', id)
          toast.success('Applied times to compliance')
        }}
      />

      <WorkPerformedDrawer
        onSubmit={(data) => {
          console.log('submit', data)
          methods.setValue('task.workedById', data.workedById)
          methods.setValue('task.workHours', data.workHours)
          methods.setValue('task.notes', data.notes)
          methods.setValue('task.description', data.description)
        }}
        initialValues={{
          workedById,
          workHours,
          notes,
          description,
        }}
      />
    </div>
  )
}

export default SingleComplianceForm
