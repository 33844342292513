import { useCallback, useMemo } from 'react'
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { IconButton, Typography, Tooltip, Chip } from '@mui/material'
import { PurchaseOrderItem } from 'types/graphql'
import { useFormContext } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'
import { useDispatch } from 'src/hooks/useDispatch'
import { openModal } from 'src/slices/modal'
import { abbreviateFullName } from 'src/utils/stringFormatters'
import { getDefaultMRTOptions } from 'src/utils/getDefaultMRTOptions'
import Button from 'src/components/MUI/Button'
import TextField from 'src/components/MUI/TextField'
import SelectDropdown from 'src/components/MUI/SelectDropdown'
import DollarCentsTextField from 'src/components/DollarCentsTextField'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants'
import { REMOVE_ITEM_FROM_PURCHASE_ORDER } from './queries'

interface PartLineItemsTableProps {
  partLineItems: PurchaseOrderItem[]
}

const PartLineItemsTable: React.FC<PartLineItemsTableProps> = ({
  partLineItems,
}) => {
  const dispatch = useDispatch()
  const formMethods = useFormContext()
  const { control } = formMethods
  const canAddItems = useHasPermission(Permissions.purchaseOrder.addItems)

  const [removeItemFromPurchaseOrder] = useMutation(
    REMOVE_ITEM_FROM_PURCHASE_ORDER,
    {
      onCompleted: () => {
        toast.success('Part Line Item removed from Purchase Order')
      },
      update: (cache, { data: { deletePurchaseOrderItem: task } }) => {
        cache.evict({
          id: cache.identify(task),
        })
      },
    }
  )

  const removeItem = useCallback(
    (id) => {
      removeItemFromPurchaseOrder({ variables: { id } })
      formMethods.setValue('parts', (prev) => {
        const parts = { ...prev }
        delete parts[id]
        return parts
      })
    },
    [formMethods, removeItemFromPurchaseOrder]
  )

  const columns = useMemo<MRT_ColumnDef<PurchaseOrderItem>[]>(
    () => [
      {
        accessorKey: 'partNumber',
        header: 'Part Number',
        size: 300,
        Cell: ({ row }) => (
          <TextField
            size="small"
            name={`parts.${row.original.id}.partNumber`}
            label=""
            fullWidth
            multiline
            maxRows={2}
          />
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 600,
        Cell: ({ row }) => (
          <TextField
            name={`parts.${row.original.id}.description`}
            label=""
            fullWidth
            multiline
            size="small"
            maxRows={2}
          />
        ),
      },
      {
        accessorKey: 'condition',
        header: 'Condition',
        size: 200,
        Cell: ({ row }) => (
          <SelectDropdown
            name={`parts.${row.original.id}.condition`}
            label=""
            fullWidth
            options={[
              { value: 'NEW', label: 'New' },
              { value: 'OVERHAULED', label: 'Overhauled' },
              { value: 'REPAIRED', label: 'Repaired' },
              { value: 'SERVICEABLE', label: 'Serviceable' },
              { value: 'INSPECTED', label: 'Inspected' },
            ]}
          />
        ),
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        size: 200,
        Cell: ({ row }) => (
          <TextField
            name={`parts.${row.original.id}.quantity`}
            size="small"
            type="number"
            inputProps={{ step: '1', min: 0 }}
          />
        ),
      },
      {
        accessorKey: 'cost',
        header: 'Cost',
        size: 200,
        Cell: ({ row }) => (
          <DollarCentsTextField
            name={`parts.${row.original.id}.cost`}
            control={control}
            label=""
          />
        ),
      },
      {
        accessorKey: 'requestedBy',
        header: 'Requested By',
        size: 200,
        Cell: ({ row }) => abbreviateFullName(row.original.requestedBy),
      },
      {
        id: 'actions',
        header: '',
        size: 75,
        Cell: ({ row }) => (
          <Tooltip title="Delete">
            <IconButton onClick={() => removeItem(row.original.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ),
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
    ],
    [control, removeItem]
  )

  const table = useMaterialReactTable({
    ...getDefaultMRTOptions(),
    columns,
    data: partLineItems,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    layoutMode: 'grid-no-grow',
    state: {
      columnPinning: {
        right: ['actions'],
      },
    },
  })

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Typography variant="h6">Parts</Typography>
        <div className="flex gap-2">
          <Button
            variant="outlined"
            color="base"
            onClick={() =>
              dispatch(openModal({ name: 'lowStockPickerDrawer' }))
            }
            locked={!canAddItems}
            lockedTooltip="You do not have permission"
          >
            LOW STOCK
          </Button>
          <Button
            variant="outlined"
            color="base"
            startIcon={<AddIcon />}
            onClick={() =>
              dispatch(openModal({ name: 'addPartLineItemModal' }))
            }
            locked={!canAddItems}
            lockedTooltip="You do not have permission"
          >
            NEW PART
          </Button>
        </div>
      </div>
      <MaterialReactTable table={table} />
    </div>
  )
}

export default PartLineItemsTable
