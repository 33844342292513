import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { get, isEqual } from 'lodash'
import * as Yup from 'yup'
import { capitalizeFirstLetter, formatDateForDisplayInUtc } from '../helpers'
import { UnscheduledTypeMapConsts } from '../constants'
dayjs.extend(utc)

import {
  MaintenanceItem,
  ComponentUsageLog,
  AircraftUsageLog,
  MaintenanceCadenceType,
  MaintenanceNextDue,
} from '../graphql'
import {
  getNextDueValueAsStringArray,
  nextDueValueSchema,
  getCadenceAsStringArray,
  cadenceValueSchema,
  calculateNextDueValue,
  getRemainingValueFromNextDueValue,
  getRemainingValueAsStringArray,
  toleranceSchemaV1,
  NextDueValue,
  CadenceValue,
  UNIT_MAP,
} from '../jsonObjects'

export const CADENCE_TYPE_TO_LABEL = {
  FLYING_HOURS: 'H',
  CYCLES: 'C',
  LANDINGS: 'L',
  CALENDAR_MONTHS: 'M',
  CALENDAR_DAYS: 'D',
}

export const CADENCE_VALUE_TO_LABEL = {
  flying_hours: 'Flying Hours',
  cycles: 'Cycles',
  landings: 'Landings',
  months: 'Months',
  days: 'Days',
}

export const CADENCE_VALUE_TO_CADENCE_TYPE = {
  flying_hours: 'FLYING_HOURS',
  cycles: 'CYCLES',
  landings: 'LANDINGS',
  months: 'CALENDAR_MONTHS',
  days: 'CALENDAR_DAYS',
}

export type LastComplianceValues = {
  flying_hours: number | undefined
  date: Date | undefined
  cycles: number | undefined
  landings: number | undefined
}

export const getCadenceValueFieldSchema = (errorId: string) => {
  const cadenceValueFieldSchema = Yup.string()
    .optional()
    .test(errorId, 'Enter Valid Number', (value) => {
      if (value === undefined || value === null) return true
      if (!value) return false
      return !isNaN(parseFloat(value)) && parseFloat(value) > 0
    })
  return cadenceValueFieldSchema
}

export const optionalValidNumberSchema = Yup.string()
  .nullable()
  .test('is-not-empty-and-number', 'Enter Valid Number', (value) => {
    if (!value) return true
    return !isNaN(parseFloat(value)) && parseFloat(value) >= 0
  })

export const getUpdatedCadenceType = (cadenceValue): MaintenanceCadenceType => {
  const valueCpy = JSON.parse(JSON.stringify(cadenceValue))
  delete valueCpy['tolerance']
  const cadenceValueKeys = Object.keys(valueCpy)
  if (cadenceValueKeys.includes('flying_hours')) {
    return 'FLYING_HOURS'
  } else if (cadenceValueKeys.includes('cycles')) {
    return 'CYCLES'
  } else if (cadenceValueKeys.includes('landings')) {
    return 'LANDINGS'
  } else if (cadenceValueKeys.includes('months')) {
    return 'CALENDAR_MONTHS'
  } else {
    return 'CALENDAR_DAYS'
  }
}

export const getNextDueCadenceType = (
  nextDueVal: Partial<MaintenanceNextDue>
): MaintenanceCadenceType => {
  // Create a new object excluding properties with '' or 0 values
  const cleanedNextDueVal = Object.fromEntries(
    Object.entries(nextDueVal ?? {}).filter(([, value]) => !!value)
  )

  const nextDueValueKeys = Object.keys(cleanedNextDueVal)
  if (nextDueValueKeys.includes('flying_hours')) {
    return 'FLYING_HOURS'
  } else if (nextDueValueKeys.includes('cycles')) {
    return 'CYCLES'
  } else if (nextDueValueKeys.includes('landings')) {
    return 'LANDINGS'
  } else if (nextDueValueKeys.includes('date')) {
    return 'CALENDAR_MONTHS'
  } else {
    return 'CALENDAR_DAYS'
  }
}

export const getToleranceValue = (mtxItem: MaintenanceItem): string => {
  const mtxCadenceType = mtxItem.cadenceType
  const cadenceValue = cadenceValueSchema.cast(
    get(mtxItem, ['cadenceValue'], {})
  )

  //It makes sense to return 0 if tolerance is not set??
  switch (mtxCadenceType) {
    case 'FLYING_HOURS':
      return cadenceValue?.tolerance?.flying_hours?.toString() || '0'
    case 'CYCLES':
      return cadenceValue?.tolerance?.cycles?.toString() || '0'
    case 'LANDINGS':
      return cadenceValue?.tolerance?.landings?.toString() || '0'
    case 'CALENDAR_MONTHS':
      return cadenceValue?.tolerance?.months?.toString() || '0'
  }
}

export const getLastComplianceHours = (
  maintenanceItem: Partial<MaintenanceItem>,
  componentUsageLog: ComponentUsageLog | undefined
) => {
  const importedDataCompliance = get(maintenanceItem, [
    'importedDataCompliance',
  ])
  const value = parseFloat(
    componentUsageLog?.totalTimeSinceNew ??
      get(importedDataCompliance, ['logHours'])
  )
  return !isNaN(value) ? value : undefined
}

export const getLastComplianceCycles = (
  maintenanceItem: Partial<MaintenanceItem>,
  componentUsageLog: ComponentUsageLog | undefined
) => {
  const importedDataCompliance = get(maintenanceItem, [
    'importedDataCompliance',
  ])
  const value = parseFloat(
    get(componentUsageLog, ['cycleSinceNew']) ??
      get(importedDataCompliance, ['logCycles'])
  )
  return !isNaN(value) ? value : undefined
}

export const getLastComplianceLandings = (
  maintenanceItem: Partial<MaintenanceItem>,
  componentUsageLog: ComponentUsageLog | undefined
) => {
  const importedDataCompliance = get(maintenanceItem, [
    'importedDataCompliance',
  ])
  const value = parseFloat(
    get(componentUsageLog, ['cycleSinceNew']) ??
      get(importedDataCompliance, ['logLandings'])
  )
  return !isNaN(value) ? value : undefined
}

export const getLastComplianceDate = (
  maintenanceItem: Partial<MaintenanceItem>
): Date | null => {
  const dateValue =
    get(maintenanceItem, ['lastComplianceDate']) ??
    get(maintenanceItem, ['importedDataCompliance', 'logDate'])
  if (dateValue) {
    return dayjs.utc(dateValue).toDate()
  } else {
    return null
  }
}

/**
 * Get the date, hours, cycles, and/or landings of the last compliance for this task.  Can come from a lastComplianceStamp or imported data  (importedDataCompliance)
 * Same as ./nextDueStatus.ts:getLastComplianceValues except that it takes in the last usage log for the relevant component instead of assuming it can be found on the maintenance item object
 * @param maintenanceItem
 * @param componentUsageLog
 * @returns
 */
export const getAbsoluteLastComplianceValues = (
  maintenanceItem: Partial<MaintenanceItem>,
  componentUsageLog: ComponentUsageLog | undefined
): LastComplianceValues => {
  const lastComplianceHours = getLastComplianceHours(
    maintenanceItem,
    componentUsageLog
  )
  const lastComplianceDate = getLastComplianceDate(maintenanceItem)
  const lastComplianceCycles = getLastComplianceCycles(
    maintenanceItem,
    componentUsageLog
  )
  const lastComplianceLandings = getLastComplianceLandings(
    maintenanceItem,
    componentUsageLog
  )
  return {
    flying_hours: lastComplianceHours,
    date: lastComplianceDate,
    cycles: lastComplianceCycles,
    landings: lastComplianceLandings,
  }
}

export const isTrackedByAirframe = (maintenanceItem: MaintenanceItem) => {
  return maintenanceItem.trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
}

/**
 * If it exists, get the component usage log for the given maintenance item via the lastComplianceStamp property
 * @param maintenanceItem
 * @returns
 */
export const getComponentUsageLogFromMaintenanceItem = (
  maintenanceItem: Partial<MaintenanceItem>
) => {
  const lastComplianceStamp = get(maintenanceItem, ['lastComplianceStamp'])
  const mtxItemTrackedByComponentId =
    maintenanceItem.trackedByComponent?.id ??
    maintenanceItem.trackedByComponentId
  return lastComplianceStamp?.ComponentUsageLog.find(
    (log) => log.component.id === mtxItemTrackedByComponentId
  )
}

/**
 * Get an array of strings that can be used to display the last compliance values for a maintenance item
 * @param maintenanceItem
 * @returns
 */
export const getLastComplianceTimeString = (
  maintenanceItem: MaintenanceItem
): string[] => {
  const componentUsageLog =
    getComponentUsageLogFromMaintenanceItem(maintenanceItem)
  const lastComplianceValues = getAbsoluteLastComplianceValues(
    maintenanceItem,
    componentUsageLog
  )

  return convertLastComplianceValuesToString(
    lastComplianceValues,
    isTrackedByAirframe(maintenanceItem)
  )
}

/**
 * Given an object with date, hours, cycles, and/or landings, convert it to an array of strings that can be used to display the last compliance values for a maintenance item
 * @param lastComplianceValues
 * @param isItemTrackedByAirframe
 * @returns
 */
export const convertLastComplianceValuesToString = (
  lastComplianceValues: LastComplianceValues,
  isItemTrackedByAirframe: boolean
): string[] => {
  const value: string[] = []
  if (lastComplianceValues.date) {
    value.push(formatDateForDisplayInUtc(lastComplianceValues.date))
  }
  if (isFinite(lastComplianceValues.flying_hours)) {
    value.push(`${lastComplianceValues.flying_hours.toFixed(1)} Hours`)
  }
  if (isItemTrackedByAirframe) {
    if (isFinite(lastComplianceValues.landings)) {
      value.push(`${lastComplianceValues.landings} Landings`)
    }
  } else {
    if (isFinite(lastComplianceValues.cycles)) {
      value.push(`${lastComplianceValues.cycles} Cycles`)
    }
  }
  return value
}

export const getIntervalCadenceValues = (
  maintenanceItem: MaintenanceItem
): string[] => {
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)
  return Object.keys(cadenceValue).filter((key) => key !== 'tolerance')
}

export const getIntervals = (
  maintenanceItem: Partial<Pick<MaintenanceItem, 'cadenceValue'>>
): string[] => {
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem?.cadenceValue)
  return getCadenceAsStringArray(cadenceValue)
}

export const getTolerances = (maintenanceItem: MaintenanceItem): string[] => {
  const cadenceValue = maintenanceItem.cadenceValue
  const inputTolerance =
    cadenceValue &&
    typeof cadenceValue === 'object' &&
    'tolerance' in cadenceValue
      ? cadenceValue.tolerance
      : undefined
  const toleranceValues = toleranceSchemaV1.cast(inputTolerance)
  return getCadenceAsStringArray(toleranceValues)
}

export const getIntervalString = (maintenanceItem: MaintenanceItem): string => {
  const intervals = getIntervals(maintenanceItem)
  return intervals.length > 0 ? intervals.join(' or ') : ''
}

export function getNextDueString(
  maintenanceItem: MaintenanceNextDue,
  discardOverride?: boolean
): string[]
export function getNextDueString(
  maintenanceItem: MaintenanceItem,
  discardOverride?: boolean
): string[]
export function getNextDueString(
  maintenanceItemOrNextDue: MaintenanceItem | MaintenanceNextDue,
  discardOverride = false
): string[] {
  if (
    'maintenanceNextDue' in maintenanceItemOrNextDue &&
    !maintenanceItemOrNextDue?.maintenanceNextDue?.length
  ) {
    return []
  }

  const maintenanceNextDue =
    'maintenanceNextDue' in maintenanceItemOrNextDue
      ? maintenanceItemOrNextDue.maintenanceNextDue[0]
      : maintenanceItemOrNextDue

  const nextDueValue = nextDueValueSchema.cast(
    !discardOverride && isOverrideProvided(maintenanceNextDue)
      ? maintenanceNextDue?.nextDueOverride
      : maintenanceNextDue?.nextDueValue
  )
  return getNextDueValueAsStringArray(nextDueValue)
}

export function isEmptyNextDueValue(nextDueValue: unknown): boolean {
  return !Object.entries(nextDueValue ?? {}).some(([key, value]) => {
    if (['note', 'allow_tolerance'].includes(key)) return false
    return !!value
  })
}

export function isOverrideProvided(
  maintenanceNextDue: Partial<MaintenanceNextDue>
): boolean
export function isOverrideProvided(maintenanceItem: {
  maintenanceNextDue: Partial<MaintenanceNextDue>[]
}): boolean
export function isOverrideProvided(
  maintenanceItemOrNextDue:
    | { maintenanceNextDue: Partial<MaintenanceNextDue>[] }
    | Partial<MaintenanceNextDue>
): boolean {
  let mtxNextDue: Partial<MaintenanceNextDue>
  if ('maintenanceNextDue' in maintenanceItemOrNextDue) {
    mtxNextDue = maintenanceItemOrNextDue?.maintenanceNextDue[0]
  } else {
    mtxNextDue = maintenanceItemOrNextDue
  }
  const nextDueOverride = mtxNextDue?.nextDueOverride ?? {}

  if (typeof nextDueOverride === 'object' && nextDueOverride !== null) {
    return !isEmptyNextDueValue(nextDueOverride)
  }

  return false
}

export const getMaxNextDueString = (
  maintenanceItem: MaintenanceItem
): string[] => {
  if (maintenanceItem.maintenanceNextDue.length === 0) return []
  const hasOverride = isOverrideProvided(maintenanceItem)
  // If override is provided, return the override value without applying any tolerance
  if (hasOverride) {
    const allowTolerance = get(maintenanceItem, [
      'maintenanceNextDue',
      0,
      'nextDueOverride',
      'allow_tolerance',
    ])
    if (!allowTolerance) {
      return getNextDueValueAsStringArray(
        nextDueValueSchema.cast(
          maintenanceItem.maintenanceNextDue[0]?.nextDueOverride
        )
      )
    }
  }

  const nextDueValue = hasOverride
    ? nextDueValueSchema.cast(
        maintenanceItem.maintenanceNextDue[0]?.nextDueOverride
      )
    : nextDueValueSchema.cast(
        maintenanceItem.maintenanceNextDue[0]?.nextDueValue
      )

  const result: string[] = []
  const fields = ['date', 'flying_hours', 'cycles', 'landings']
  const toleranceValues = cadenceValueSchema.cast(
    maintenanceItem.cadenceValue
  )?.tolerance
  fields.forEach((field) => {
    if (nextDueValue[field]) {
      if (field === 'date') {
        if (toleranceValues?.['months']) {
          result.push(
            formatDateForDisplayInUtc(
              dayjs
                .utc(nextDueValue[field])
                .add(toleranceValues['months'], 'months')
            )
          )
        } else if (toleranceValues?.['days']) {
          result.push(
            formatDateForDisplayInUtc(
              dayjs
                .utc(nextDueValue[field])
                .add(toleranceValues['days'], 'days')
            )
          )
        } else {
          result.push(formatDateForDisplayInUtc(nextDueValue[field]))
        }
      } else {
        if (toleranceValues?.[field]) {
          result.push(
            `${nextDueValue[field] + toleranceValues[field]} ${UNIT_MAP[field]}`
          )
        } else {
          result.push(`${nextDueValue[field]} ${UNIT_MAP[field]}`)
        }
      }
    }
  })
  return result
}

export const getNextDueAdjustmentString = (
  maintenanceNextDue: MaintenanceNextDue
): string[] => {
  if (!maintenanceNextDue) return []
  if (!isOverrideProvided(maintenanceNextDue)) return []
  const nextDueOverride = nextDueValueSchema.cast(
    maintenanceNextDue?.nextDueOverride
  )
  const nextDueValue = nextDueValueSchema.cast(maintenanceNextDue?.nextDueValue)

  const result = []
  const fields = ['date', 'flying_hours', 'cycles', 'landings']
  fields.forEach((field) => {
    if (nextDueValue[field] !== nextDueOverride[field]) {
      if (field === 'date') {
        const diff = dayjs
          .utc(nextDueOverride[field])
          .diff(dayjs.utc(nextDueValue[field]), 'day', true)
        const diffRoundedUp = Math.sign(diff) * Math.ceil(Math.abs(diff))
        const diffWithSign =
          diffRoundedUp > 0 ? `+${diffRoundedUp}` : diffRoundedUp
        result.push(`${diffWithSign} D`)
      } else {
        const diff = nextDueOverride[field] - nextDueValue[field]
        const diffWithSign = diff > 0 ? `+${diff}` : diff
        result.push(`${diffWithSign} ${UNIT_MAP[field]}`)
      }
    }
  })
  return result
}

export const getRemainingValueString = (
  maintenanceItem: MaintenanceItem,
  latestAircraftUsageLog: AircraftUsageLog
): string[] => {
  const trackedByComponentId =
    maintenanceItem.trackedByComponent.id ??
    maintenanceItem.trackedByComponentId ??
    ''
  const componentUsageLog = latestAircraftUsageLog?.ComponentUsageLog.find(
    (log) => log.component.id === trackedByComponentId
  )
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)
  if (maintenanceItem.nextDueStatus === 'NOT_DUE') return []
  const remainingValue = getRemainingValueFromNextDueValue(
    maintenanceItem.maintenanceNextDue[0],
    cadenceValue,
    componentUsageLog
  )
  return getRemainingValueAsStringArray(remainingValue)
}

export const getCalculatedNextDueString = (
  maintenanceItem: MaintenanceItem,
  latestAircraftUsageLog: AircraftUsageLog
): string[] => {
  const trackedByComponentId =
    maintenanceItem.trackedByComponent.id ??
    maintenanceItem.trackedByComponentId ??
    ''
  const componentUsageLog = latestAircraftUsageLog?.ComponentUsageLog.find(
    (log) => log.component.id === trackedByComponentId
  )
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)
  const nextDueValue = calculateNextDueValue(
    maintenanceItem.cadenceType,
    cadenceValue,
    componentUsageLog,
    new Date(maintenanceItem.lastComplianceDate),
    false
  )
  return getNextDueValueAsStringArray(nextDueValue)
}

export const getCalculatedNextDueValuesFromLastCompliance = (
  maintenanceItem: MaintenanceItem,
  overrideCadenceValue?: CadenceValue
): NextDueValue => {
  const complianceUsageLog = maintenanceItem.lastComplianceStamp
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)
  const trackedByComponentId =
    maintenanceItem.trackedByComponent.id ??
    maintenanceItem.trackedByComponentId ??
    ''
  const componentUsageLog = complianceUsageLog?.ComponentUsageLog.find(
    (log) => log.componentId === trackedByComponentId
  )
  const lastComplianceUsageLog = {
    usageAsOf: getLastComplianceDate(maintenanceItem),
    totalTimeSinceNew: getLastComplianceHours(
      maintenanceItem,
      componentUsageLog
    ),
    cycleSinceNew: isTrackedByAirframe(maintenanceItem)
      ? getLastComplianceLandings(maintenanceItem, componentUsageLog)
      : getLastComplianceCycles(maintenanceItem, componentUsageLog),
  }
  const nextDueValue = calculateNextDueValue(
    maintenanceItem.cadenceType,
    overrideCadenceValue || cadenceValue,
    lastComplianceUsageLog,
    getLastComplianceDate(maintenanceItem),
    false
  )
  return nextDueValue
}

export const getCalculatedNextDueObject = (
  maintenanceItem: MaintenanceItem,
  latestAircraftUsageLog: AircraftUsageLog,
  overrideCadenceValue?: CadenceValue
): NextDueValue => {
  const trackedByComponentId =
    maintenanceItem.trackedByComponent.id ??
    maintenanceItem.trackedByComponentId ??
    ''
  const componentUsageLog = latestAircraftUsageLog?.ComponentUsageLog.find(
    (log) => log.component.id === trackedByComponentId
  )
  const cadenceValue = cadenceValueSchema.cast(maintenanceItem.cadenceValue)
  const nextDueValue = calculateNextDueValue(
    maintenanceItem.cadenceType,
    overrideCadenceValue || cadenceValue,
    componentUsageLog,
    getLastComplianceDate(maintenanceItem),
    false
  )
  return nextDueValue
}

export const getMaintenanceItemTypeDisplay = (
  maintenanceItem: MaintenanceItem
) => {
  const componentName = capitalizeFirstLetter(
    maintenanceItem.trackedByComponent?.name || ''
  )
  if (
    maintenanceItem.maintenanceType === 'UNSCHEDULED' &&
    maintenanceItem.adSbType
  ) {
    return `${componentName} ${
      UnscheduledTypeMapConsts[maintenanceItem.adSbType]
    }`
  }
  return `${componentName} ${capitalizeFirstLetter(
    maintenanceItem.maintenanceType
  )}`
}

export default function cleanDateForComparison(date: unknown) {
  try {
    let dateString = ''
    if (typeof date === 'string') {
      dateString = new Date(date).toISOString()
    } else {
      dateString = (date as Date).toISOString()
    }
    return dateString.replace(/T.*/, '')
  } catch (error) {
    console.error(`Error cleaning date: ${date}`)
    console.error(error)
    throw error
  }
}

export const cleanNextDueValueForComparison = (
  inputNextDueValue: NextDueValue
) => {
  const nextDueValue: Record<string, unknown> = {
    ...inputNextDueValue,
  }
  delete nextDueValue.note
  delete nextDueValue.allow_tolerance
  nextDueValue.landings = nextDueValue.landings
    ? Number(nextDueValue.landings).toFixed(2)
    : undefined
  nextDueValue.flying_hours = nextDueValue.flying_hours
    ? Number(nextDueValue.flying_hours).toFixed(2)
    : undefined
  nextDueValue.cycles = nextDueValue.cycles
    ? Number(nextDueValue.cycles).toFixed(2)
    : undefined
  if (nextDueValue.date) {
    nextDueValue.date = cleanDateForComparison(nextDueValue.date)
  }
  return JSON.parse(JSON.stringify(nextDueValue))
}

export const nextDueValuesAreEqual = (
  nextDueValue1: object,
  nextDueValue2: object
) => {
  return isEqual(
    cleanNextDueValueForComparison(nextDueValue1),
    cleanNextDueValueForComparison(nextDueValue2)
  )
}
