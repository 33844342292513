import { useEffect, useMemo, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import {
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { InternalWorkOrder } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import { useDispatch } from 'src/hooks/useDispatch'
import useHasPermission from 'src/hooks/useHasPermission'
import { useOrgName } from 'src/hooks/useOrgName'
import useQuery from 'src/hooks/useQuery'
import { ADD_MAINTENANCE_ITEMS_TO_WORK_ORDER } from 'src/pages/CreateWorkOrderPage/queries'
import { openModal } from 'src/slices/modal'

import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Button from '../MUI/Button'

import { CREATE_WORK_ORDER, GET_WORK_ORDERS_FOR_SELECTOR } from './queries'
import useMaintenanceItemTable, {
  TableVariant,
} from 'src/hooks/useMaintenanceItemTable'

type AddToWorkOrderButtonProps = {
  tableVariant: TableVariant
  onCompleted?: ({
    workOrder,
    created,
  }: {
    workOrder: InternalWorkOrder
    created: boolean
  }) => void
  selected?: string[]
  anchorEl?: any
  onClose?: () => void
  renderButton?: boolean
  disabled?: boolean
}

const AddToWorkOrderButton: React.FC<AddToWorkOrderButtonProps> = ({
  tableVariant,
  onCompleted,
  selected: selectedProp,
  anchorEl: externalAnchorEl,
  onClose: externalHandleClose,
  renderButton = true,
  disabled = false,
}) => {
  const client = useApolloClient()
  const dispatch = useDispatch()
  const orgName = useOrgName()

  const [showOrderPicker, setShowOrderPicker] = useState(false)
  const [anchorEl, setAnchorEl] = useState(externalAnchorEl)

  const [addToWorkOrder] = useMutation(ADD_MAINTENANCE_ITEMS_TO_WORK_ORDER)
  const [createWorkOrder] = useMutation(CREATE_WORK_ORDER)
  const { data } = useQuery(GET_WORK_ORDERS_FOR_SELECTOR, {
    variables: {
      orgSlug: orgName,
    },
  })
  const { internalWorkOrders } = data ?? {}

  useEffect(() => {
    setAnchorEl(externalAnchorEl)
  }, [externalAnchorEl])

  const { selectedItemIds, selectedInProgressItems, unselectAll } =
    useMaintenanceItemTable(tableVariant)

  const selected = selectedProp
    ? typeof selectedProp === 'string'
      ? [selectedProp]
      : selectedProp
    : selectedItemIds

  const allMaintenanceItems = useMemo(
    () =>
      selected.map((id) =>
        client.readFragment({
          id: client.cache.identify({
            __typename: 'MaintenanceItem',
            id,
          }),
          fragment: gql`
            fragment aircraftOnMaintenanceItemFragment on MaintenanceItem {
              aircraftId
            }
          `,
        })
      ),
    [selected, client]
  )

  const aircraftIds = useMemo(
    () =>
      Array.from(new Set(allMaintenanceItems.map((item) => item.aircraftId))),
    [allMaintenanceItems]
  )

  const canCreate = useMemo(() => {
    if (aircraftIds.length === 1) {
      return useHasPermission(Permissions.workOrder.create, aircraftIds[0])
    }
    return false
  }, [aircraftIds])
  const canAddToWorkOrder = useMemo(() => {
    if (aircraftIds.length === 1) {
      return useHasPermission(Permissions.workOrder.addTasks, aircraftIds[0])
    }
    return false
  }, [aircraftIds])

  const handleButtonClick = (event) => {
    if (anchorEl) {
      handleClose()
      return
    }

    setAnchorEl(event.currentTarget)
  }

  const handleWorkOrderItems = (id?: string) => {
    // Remove inProgress tasks from selected items
    const filteredSelected = selected.filter(
      (itemId) => !selectedInProgressItems[itemId]
    )

    if (id) {
      addToWorkOrder({
        variables: {
          workOrderId: id,
          ids: filteredSelected,
        },
        onCompleted: (data) => {
          onCompleted?.({
            workOrder: data?.addMaintenanceItemsToInternalWorkOrder,
            created: false,
          })
          unselectAll()
        },
        onError: (error) => {
          toast.error(error.message)
        },
      })
    } else {
      createWorkOrder({
        variables: {
          aircraftId: aircraftIds[0],
          maintenanceItemIds: filteredSelected,
          orgSlug: orgName,
        },
        onCompleted: (data) => {
          onCompleted?.({
            workOrder: data?.createInternalWorkOrder,
            created: true,
          })
          unselectAll()
        },
        onError: (error) => {
          toast.error(error.message)
        },
      })
    }
    handleClose()
  }

  const handleItemClick = (id?: string) => {
    if (Object.keys(selectedInProgressItems).length > 0) {
      const modalToOpen = id ? `inProgressWO${id}` : 'inProgressNewWorkOrder'
      dispatch(
        openModal({
          name: 'confirmationModal',
          data: { discriminator: modalToOpen },
        })
      )
    } else {
      handleWorkOrderItems(id)
    }
  }

  const handleClose = () => {
    if (!anchorEl) {
      return
    }

    setAnchorEl(null)
    setShowOrderPicker(false)
    externalHandleClose?.()
  }

  const selectedItemsString = Object.values(selectedInProgressItems)
    .map((item) => `${item.ataCode} ${item.manufactureCode}: ${item.title}`)
    .join('\n')

  const isThereNewTasks =
    selectedItemIds.length !== Object.keys(selectedInProgressItems).length

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {renderButton && (
          <Button
            disabled={
              selected.length === 0 || aircraftIds.length > 1 || disabled
            }
            disabledTooltip={
              aircraftIds.length > 1
                ? 'All selected items must belong to a single aircraft'
                : 'Select some items to add to a Work Order'
            }
            endIcon={
              <ArrowDropDownIcon
                className={`h-2 w-2 transition-transform duration-300 ${
                  anchorEl && 'rotate-180'
                }`}
              />
            }
            onClick={handleButtonClick}
            size="large"
            variant="outlined"
            color="primary"
          >
            CREATE WORK ORDER
          </Button>
        )}
        <Popper
          open={Boolean(anchorEl)}
          modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
          anchorEl={anchorEl}
          placement="bottom-end"
          className="z-[1100]"
        >
          <Paper elevation={3}>
            {!showOrderPicker && (
              <div>
                <MenuList>
                  <MenuItem
                    onClick={() => setShowOrderPicker(true)}
                    disabled={!canAddToWorkOrder}
                  >
                    Add to existing Work Order
                    <ChevronRightRoundedIcon />
                  </MenuItem>
                  <Tooltip
                    enterDelay={100}
                    title={
                      canCreate
                        ? ''
                        : 'You do not have permissions to perform this action.'
                    }
                  >
                    <div>
                      <MenuItem
                        onClick={() => handleItemClick()}
                        disabled={!canCreate}
                      >
                        <span className="flex items-center gap-2">
                          Create new Work Order
                          {!canCreate && <LockPersonIcon fontSize="small" />}
                        </span>
                      </MenuItem>
                    </div>
                  </Tooltip>
                </MenuList>
              </div>
            )}
            {showOrderPicker && (
              <div>
                <MenuList>
                  <MenuItem onClick={() => setShowOrderPicker(false)}>
                    <ChevronRightRoundedIcon className="rotate-180" />
                    Work Orders
                  </MenuItem>
                  {internalWorkOrders?.map((order) => (
                    <div key={order.id}>
                      <Tooltip
                        enterDelay={100}
                        title={
                          order.aircraft.id !== aircraftIds[0]
                            ? 'Maintenance items must belong to the same aircraft as the Work Order'
                            : ''
                        }
                      >
                        <MenuItem
                          onClick={() => handleItemClick(order.id)}
                          disabled={order.aircraft.id !== aircraftIds[0]}
                        >
                          {order.aircraft.tailNumber} -{' '}
                          {order.status === 'DRAFT'
                            ? 'Draft'
                            : [order.vendor, order.shop].filter(Boolean).join('/')}{' '}
                          ({order.workItems.length} tasks)
                        </MenuItem>
                      </Tooltip>
                      </div>
                  ))}
                </MenuList>
              </div>
            )}
          </Paper>
        </Popper>
        <ConfirmationModal
          title="Continue to new Work Order"
          discriminator="inProgressNewWorkOrder"
          richMessage={
            isThereNewTasks
              ? `The following in-progress tasks will not be added to the work order:\n\n\n${selectedItemsString}`
              : `All selected tasks are in progress, no new tasks will be added to the work order.`
          }
          confirmText="Continue to Work Order"
          denyText="Cancel"
          confirmNote={
            isThereNewTasks ? 'Continue without the in-progress tasks.' : ''
          }
          onConfirm={handleWorkOrderItems}
        />
        {internalWorkOrders?.map((order) => (
          <>
            <ConfirmationModal
              title="Continue to Work Order"
              discriminator={`inProgressWO${order.id}`}
              richMessage={
                isThereNewTasks
                  ? `The following in-progress tasks will not be added to the work order:\n${selectedItemsString}`
                  : `All selected tasks are in progress, no new tasks will be added to the work order.`
              }
              confirmText={
                isThereNewTasks
                  ? 'Continue to Work Order'
                  : 'Go back to Due List'
              }
              denyText="Cancel"
              confirmNote={
                isThereNewTasks ? 'Continue without the in-progress tasks.' : ''
              }
              onConfirm={() =>
                isThereNewTasks && handleWorkOrderItems(order.id)
              }
            />
          </>
        ))}
      </div>
    </ClickAwayListener>
  )
}

export default AddToWorkOrderButton
