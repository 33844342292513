import React, { useEffect, useState } from 'react'

import {
  DatePicker as MUIDatePicker,
  DatePickerProps as MUIDatePickerProps,
} from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { get } from 'lodash'

import { useFormContext } from '@redwoodjs/forms'

interface DatePickerProps<TDate> extends MUIDatePickerProps<TDate> {
  name: string
  label: string
  required?: boolean
  error?: boolean
  helperText?: string
}

const DatePicker: React.FC<DatePickerProps<Date>> = ({
  name,
  label,
  required = false,
  slotProps = { textField: {} },
  ...otherProps
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const valStr = watch(name)
  const value: Dayjs = React.useMemo(
    () => (valStr ? dayjs(valStr) : null),
    [valStr]
  )

  const fieldError = get(errors, name.split('.'))?.message as string | undefined
  const [dateError, setDateError] = useState<string | undefined>(undefined)
  const { onChange, ...rest } = register(name, { required })

  // TODO: Check if this local override is doing anything, does not look like
  // this is getting invoked
  const onChangeHandler = (date: Dayjs | null) => {
    onChange({
      target: { name, value: date?.isValid() ? date?.toISOString() : null },
    })
  }

  const handleDateError = (err: string) => {
    if (err === 'invalidDate') {
      setDateError('Invalid Date')
    } else {
      setDateError('')
    }
  }

  useEffect(() => {
    // allows for clearing the value
    if (!value) {
      onChangeHandler(value)
    }
  }, [value])

  return (
    <MUIDatePicker
      {...rest}
      onChange={onChangeHandler}
      label={label}
      onError={handleDateError}
      value={value}
      slotProps={{
        ...slotProps,
        field: {
          clearable: true,
        },
        textField: {
          ...slotProps.textField,
          error: Boolean(dateError) || Boolean(fieldError),
          helperText: fieldError || dateError,
          required: required,
          size: 'small',
        },
      }}
      {...otherProps}
    />
  )
}

export default DatePicker
