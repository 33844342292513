//import { MaintenanceCadenceType } from 'types/graphql'
import { array, object, string, number, InferType } from 'yup'

export const miscellaneousExpensesSchema = array().of(
  object().shape({
    label: string().required('Type is required'),
    value: number().typeError('Cost is required').required('Cost is required'),
  })
)

export type MiscellaneousExpenses = InferType<
  typeof miscellaneousExpensesSchema
>
