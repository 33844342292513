import { ThemeProvider } from '@mui/material/styles'
import { StyledEngineProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Uploady from '@rpldy/uploady'
import { Provider } from 'react-redux'

import { RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import Sentry from 'src/lib/sentry'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'
import CustomApolloLink from './CustomApolloLink'
import { InMemoryCache } from '@apollo/client'
import store from './store'
import theme from './theme'
import './i18n/i18n'

import 'src/lib/logger'
import 'src/lib/datadogRum'

import './index.css'
import '@liveblocks/react-ui/styles.css'
import { parseStringFromObject } from '@wingwork/common/src/helpers'

const ID_WARNING_BLACKLIST = new Set([
  'AircraftUsageParameter',
  'UserTenantMembership',
  'Mutation',
  'PageInfo',
  'MaintenanceItemPage',
  'MaintenanceItemStats',
  'ComplianceLedgerStats',
  'ProductsPage',
  'OnboardingAircraftDetails',
  'ComplianceActivityPage',
  'AircraftTemplateMatchResult',
  'EntityMatchResult',
  'SuccessfulEntityMatchResult', // Using keyFields instead
  'UnmatchedTemplateEntity', // Using keyFields instead
  'UnmatchedSourceEntity', // Using keyFields instead

  // permit types
  'PermitUser',
  'PermitUserRole',
  'PermitAircraftRole',
  'PermitAircraftRolesResponse',
  'PermitUpdateRoleResponse',
  'RolePermissionsInfoResponse',
])
const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    if (!object.__typename) {
      console.warn('Missing __typename for object:', object)
      return null // Store without normalization
    }

    switch (object.__typename) {
      case 'TenantFeature': {
        if (!object.orgSlug || !object.featureName) {
          console.warn('TenantFeature missing orgSlug or featureName:', object)
          return null
        }
        return `TenantFeature:${object.orgSlug}:${object.featureName}`
      }

      default: {
        if (!object.id && !ID_WARNING_BLACKLIST.has(object.__typename)) {
          console.warn(`Missing ID for ${object.__typename}:`, object)
        }
        return object.id ? `${object.__typename}:${object.id}` : null
      }
    }
  },
  typePolicies: {
    Query: {
      fields: {
        complianceActivitiesPaginated: {
          keyArgs: ['filters'],

          merge(existing, incoming, { readField }) {
            if (!existing) {
              return incoming
            }
            if (!incoming) {
              return existing
            }
            if (!existing.edges || !incoming.edges) {
              return incoming
            }

            // Create a Map to track unique edges by ID
            const uniqueEdges = new Map()

            existing.edges.forEach((edge) => {
              uniqueEdges.set(readField('id', edge), edge)
            })

            incoming.edges.forEach((edge) => {
              uniqueEdges.set(readField('id', edge), edge)
            })

            const mergedEdges = Array.from(uniqueEdges.values())

            return {
              ...existing,
              edges: mergedEdges,
            }
          },
        },
        internalWorkItemsPaginated: {
          keyArgs: ['$id'],
          read(existing, { canRead }) {
            return existing ? existing.filter(canRead) : []
          },
          merge(existing = [], incoming, { toReference }) {
            if (!incoming) return existing

            // Create a Map to track unique items by reference
            const uniqueItems = new Map()

            // Add existing items
            existing.forEach((item) => {
              const ref = toReference(item)
              if (ref) uniqueItems.set(ref.__ref, ref)
            })

            // Add incoming items
            incoming.forEach((item) => {
              const ref = toReference(item)
              if (ref) uniqueItems.set(ref.__ref, ref)
            })

            // Return array of unique items
            return Array.from(uniqueItems.values())
          },
        },
        aircraftComponentsPaginated: {
          // Include queryName in keyArgs to ensure proper separation between major assemblies and components
          keyArgs: ['$filters', '$sortField', '$sortOrder', 'queryName'],
          read(existing, { canRead }) {
            if (!existing)
              return {
                edges: [],
                pageInfo: { totalCount: 0, hasNextPage: false, cursor: '' },
              }

            // Filter edges that can be read
            const filteredEdges = existing.edges
              ? existing.edges.filter(canRead)
              : []

            return {
              ...existing,
              edges: filteredEdges,
            }
          },
          merge(existing, incoming, { toReference }) {
            if (!incoming)
              return (
                existing || {
                  edges: [],
                  pageInfo: { totalCount: 0, hasNextPage: false, cursor: '' },
                }
              )

            // Initialize existing if it doesn't exist
            const existingEdges = existing?.edges || []

            // Create a Map to track unique items by reference
            const uniqueItems = new Map()

            // Add existing items
            existingEdges.forEach((item) => {
              const ref = toReference(item)
              if (ref) uniqueItems.set(ref.__ref, item)
            })

            // Add incoming items
            incoming.edges.forEach((item) => {
              const ref = toReference(item)
              if (ref) uniqueItems.set(ref.__ref, item)
            })

            // Return merged result with updated edges and pageInfo from incoming
            return {
              ...incoming,
              edges: Array.from(uniqueItems.values()),
            }
          },
        },
      },
    },
    SuccessfulEntityMatchResult: {
      keyFields: ['sourceItem', ['id'], 'templateItem', ['id']],
    },
    UnmatchedTemplateEntity: {
      keyFields: ['templateItem', ['id']],
    },
    UnmatchedSourceEntity: {
      keyFields: ['sourceItem', ['id']],
    },
  },
})

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <RedwoodApolloProvider
            useAuth={useAuth}
            graphQLClientConfig={{ link: CustomApolloLink, cache }}
          >
            <Uploady>
              <Provider store={store}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Routes />
                    </LocalizationProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </Provider>
            </Uploady>
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
