import React, { useCallback } from 'react'
import { FormControlLabel, Checkbox, CircularProgress } from '@mui/material'
import { useMutation } from '@redwoodjs/web'
import { updateMaintenanceItemEndOfMonthAdjustmentQuery } from 'src/pages/BulkCompliancePage/queries'
import { calculateNextDueValue, cadenceValueSchema } from '@wingwork/common/src/jsonObjects'
import { useApolloClient } from '@apollo/client'
import coreAircraftUsageLogFragment from 'src/fragments/AircraftUsageLog'
import { toast } from 'react-hot-toast'

const RECALCULATE_NEXT_DUE_VALUES = gql`
  mutation RecalculateNextDueValues($ids: [String!]!) {
    recalculateNextDueValues(ids: $ids) {
      id
      nextDue {
        id
        nextDueType
        nextDueValue
        nextDueOverride
      }
    }
  }
`

const RECALCULATE_INTERNAL_WORK_ITEM_NEXT_DUE_VALUES = gql`
  mutation RecalculateInternalWorkItemNextDueValues($ids: [String!]!) {
    recalculateInternalWorkItemNextDueValues(ids: $ids) {
      id
      nextDue {
        id
        nextDueType
        nextDueValue
        nextDueOverride
      }
    }
  }
`

interface EndOfMonthAdjustmentCheckboxProps {
  maintenanceItemId: string
  maintenanceItem: {
    id: string
    isEndOfMonthAdjustment?: boolean
    cadenceType: string
    cadenceValue: any
    trackedByComponent: {
      id: string
    }
  }
  aircraftUsageLogId?: string
  onNextDueUpdated?: (newNextDueValue: any) => void
  className?: string
  activityId?: string // For compliance activities
  workItemId?: string // For internal work items
}

const EndOfMonthAdjustmentCheckbox = ({
  maintenanceItemId,
  maintenanceItem,
  aircraftUsageLogId,
  onNextDueUpdated,
  className,
  activityId,
  workItemId,
}: EndOfMonthAdjustmentCheckboxProps) => {
  const client = useApolloClient()
  const [updateMaintenanceItemEndOfMonthAdjustment] = useMutation(
    updateMaintenanceItemEndOfMonthAdjustmentQuery
  )
  const [recalculateNextDueValues] = useMutation(RECALCULATE_NEXT_DUE_VALUES)
  const [recalculateInternalWorkItemNextDueValues] = useMutation(RECALCULATE_INTERNAL_WORK_ITEM_NEXT_DUE_VALUES)
  const [isSaving, setIsSaving] = React.useState(false)

  const handleChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setIsSaving(true)
    try {
      // Update the maintenance item's isEndOfMonthAdjustment field
      await updateMaintenanceItemEndOfMonthAdjustment({
        variables: {
          id: maintenanceItemId,
          endOfMonthAdjustment: isChecked,
        },
      })

      // If we have a usage log ID and an onNextDueUpdated callback, recalculate the next due value
      if (aircraftUsageLogId) {
        // Determine if we should use server-side recalculation
        const shouldUseServerRecalculation = activityId || workItemId
        if (shouldUseServerRecalculation) {
          try {
            let data = {}
            if (activityId) {
              // For compliance activities
              const result = await recalculateNextDueValues({
                variables: {
                  ids: [activityId],
                },
              })
              data = result.data?.recalculateNextDueValues || []
            } else if (workItemId) {
              // For internal work items
              const result = await recalculateInternalWorkItemNextDueValues({
                variables: {
                  ids: [workItemId],
                },
              })
              data = result.data?.recalculateInternalWorkItemNextDueValues || []
            }
            if (data?.length > 0) {
              const updatedActivity = data[0]
              const newNextDueValue = updatedActivity.nextDue?.nextDueValue || {}
              // Call the callback with the updated next due value
              if (onNextDueUpdated) {
                onNextDueUpdated(newNextDueValue)
              }
              toast.success('Next due values recalculated successfully')
              setIsSaving(false)
              return
            }
          } catch (recalcError) {
            console.error('Failed to recalculate next due values:', recalcError)
            // Fall back to client-side calculation
          }
        }

        // Client-side calculation (fallback or when no server recalculation is available)
        const usageLog = client.readFragment({
          id: client.cache.identify({
            __typename: 'AircraftUsageLog',
            id: aircraftUsageLogId,
          }),
          fragment: coreAircraftUsageLogFragment,
        })

        const componentUsageLog = usageLog?.ComponentUsageLog?.find(
          (log) => log.component.id === maintenanceItem.trackedByComponent.id
        )

        if (componentUsageLog) {
          const calculatedNextDueValue = calculateNextDueValue(
            maintenanceItem.cadenceType,
            cadenceValueSchema.cast(maintenanceItem.cadenceValue),
            componentUsageLog,
            componentUsageLog.usageAsOf,
            isChecked
          )

          // Call the callback with the updated next due value
          if (onNextDueUpdated) {
            onNextDueUpdated(calculatedNextDueValue)
          }
        }
      }
      // Show success toast
      toast.success('End of month adjustment updated successfully')
    } catch (error) {
      console.error('Failed to update end of month adjustment:', error)
      toast.error('Failed to update end of month adjustment')
    } finally {
      setIsSaving(false)
    }
  }, [
    maintenanceItemId,
    aircraftUsageLogId,
    maintenanceItem,
    onNextDueUpdated,
    activityId,
    workItemId,
  ])

  return (
    <FormControlLabel
      control={
        <div className="relative">
          <Checkbox
            name="endOfMonthAdjustment"
            size="small"
            className="text-sm"
            checked={maintenanceItem?.isEndOfMonthAdjustment || false}
            onChange={handleChange}
            disabled={isSaving}
          />
          {isSaving && (
            <CircularProgress
              size={16}
              className="absolute right-0 bottom-0 mr-1 mb-1"
            />
          )}
        </div>
      }
      label="End of Month"
      className={`text-sm ${className || ''}`}
    />
  )
}

export default EndOfMonthAdjustmentCheckbox