import React, { useMemo } from 'react'

import Drawer from '../MUI/Drawer/Drawer'
import DrawerHeader from '../MUI/Drawer/DrawerHeader'
import { closeModal } from 'src/slices/modal'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Typography } from '@mui/material'
import PrimSecTextCombo from '../common/PrimSecTextCombo'
import useGetAircraftById from 'src/hooks/requests/useGetAircraftById'
import { sortAircraftComponents } from 'src/utils/helpers'
import { ComponentUsageLog } from 'types/graphql'
import { RootState } from 'src/store'
import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'
import { useApolloClient } from '@apollo/client'
import dayjs from 'dayjs'

const modalName = 'workflowAircraftDetailsDrawer'

interface WorkflowAircraftDetailsDrawerProps {
  aircraftId: string
  aircraftUsageLogId?: string
}
const WorkflowAircraftDetailsDrawer = ({
  aircraftId,
  aircraftUsageLogId,
}: WorkflowAircraftDetailsDrawerProps) => {
  const client = useApolloClient()
  const { data: aircraftData } = useGetAircraftById(aircraftId, {
    skip: !aircraftId,
  })
  const aircraft = useMemo(() => aircraftData?.aircraft ?? {}, [aircraftData])

  const aircraftUsageLog = useMemo(
    () => aircraft?.AircraftUsageLog?.[0],
    [aircraft]
  )

  const airframeLog: ComponentUsageLog = useMemo(
    () =>
      aircraftUsageLog?.ComponentUsageLog?.find(
        (log: ComponentUsageLog) => log.component?.name === 'Airframe'
      ),
    [aircraftUsageLog]
  )

  const appliedUsageLog = useMemo(
    () =>
      client.readFragment({
        id: `AircraftUsageLog:${aircraftUsageLogId}`,
        fragment: CORE_AIRCRAFT_USAGE_LOG_FRAGMENT,
      }),
    [aircraftUsageLogId]
  )

  const appliedAirframeLog = useMemo(
    () =>
      appliedUsageLog?.ComponentUsageLog?.find(
        (log: ComponentUsageLog) => log.component?.name === 'Airframe'
      ),
    [appliedUsageLog]
  )

  const [, ...otherComponents] = useMemo(
    () => sortAircraftComponents(aircraft?.AircraftComponent),
    [aircraft?.AircraftComponent]
  )

  const dispatch = useDispatch()
  const open =
    useSelector(
      (state: RootState) => state.modal.modals?.[modalName]?.isOpen
    ) ?? false
  const highlightAppliedTimes =
    useSelector(
      (state: RootState) =>
        state.modal.modals?.[modalName]?.data?.highlightAppliedTimes
    ) ?? false
  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <DrawerHeader title="Aircraft Details" onClose={handleClose} />
      <div className="flex min-w-[600px] flex-col gap-2 p-3">
        <div>
          <Typography variant="subtitle2">GENERAL</Typography>
          <div className="grid grid-cols-3 gap-2">
            <PrimSecTextCombo
              primaryText={aircraft?.model}
              secondaryText="Model"
              variant="inverted"
            />
            <PrimSecTextCombo
              primaryText={aircraft?.serialNumber}
              secondaryText="Serial Number"
              variant="inverted"
            />
            <PrimSecTextCombo
              primaryText={aircraft?.tailNumber}
              secondaryText="Registration Number"
              variant="inverted"
            />
            <PrimSecTextCombo
              primaryText={airframeLog?.cycleSinceNew}
              secondaryText="A/C Landings"
              variant="inverted"
            />
            <PrimSecTextCombo
              primaryText={airframeLog?.totalTimeSinceNew}
              secondaryText="A/C Hours"
              variant="inverted"
            />
          </div>
        </div>
        <Divider />
        <div>
          <div className="grid grid-cols-4 gap-2">
            <div></div>
            <div></div>
            <div className={`${highlightAppliedTimes ? 'p-0.5' : ''}`}>
              <Typography variant="subtitle2">CURRENT TIMES</Typography>
              <Typography variant="caption">
                {dayjs(airframeLog?.usageAsOf).format('MM/DD/YYYY')}
              </Typography>
            </div>
            <div className="flex">
              <div
                className={`${
                  highlightAppliedTimes
                    ? 'border border-2 border-solid border-green-500 p-0.5'
                    : ''
                }`}
              >
                <Typography variant="subtitle2">APPLIED TIMES</Typography>
                <Typography variant="caption">
                  {dayjs(appliedAirframeLog?.usageAsOf).format('MM/DD/YYYY')}
                </Typography>
              </div>
            </div>
          </div>

          <Typography variant="caption">AIRFRAME</Typography>
          <div className="grid grid-cols-4 gap-2">
            <PrimSecTextCombo
              primaryText={aircraft.model}
              secondaryText="Model"
              variant="inverted"
            />
            <PrimSecTextCombo
              primaryText={aircraft.serialNumber}
              secondaryText="Serial Number"
              variant="inverted"
            />
            <div className="flex gap-2">
              <PrimSecTextCombo
                primaryText={airframeLog?.totalTimeSinceNew}
                secondaryText="Hours"
                variant="inverted"
              />
              <PrimSecTextCombo
                primaryText={airframeLog?.cycleSinceNew}
                secondaryText="Landings"
                variant="inverted"
              />
            </div>
            <div className="flex gap-2">
              <PrimSecTextCombo
                primaryText={appliedAirframeLog?.totalTimeSinceNew ?? '-'}
                secondaryText="Hours"
                variant="inverted"
              />
              <PrimSecTextCombo
                primaryText={appliedAirframeLog?.cycleSinceNew ?? '-'}
                secondaryText="Landings"
                variant="inverted"
              />
            </div>
          </div>
          {otherComponents.map((component) => {
            const componentLog = aircraftUsageLog?.ComponentUsageLog?.find(
              (l) => l.component?.name === component.name
            )
            const appliedComponentLog =
              appliedUsageLog?.ComponentUsageLog?.find(
                (l) => l.component?.name === component.name
              )
            return (
              <div key={component.id} className="mt-3">
                <Typography variant="caption">
                  {component.name.toUpperCase()}
                </Typography>
                <div className="grid grid-cols-4 gap-2">
                  <PrimSecTextCombo
                    primaryText={component?.partNumber}
                    secondaryText="Model"
                    variant="inverted"
                  />
                  <PrimSecTextCombo
                    primaryText={component?.serialNumber}
                    secondaryText="Serial Number"
                    variant="inverted"
                  />
                  <div className="flex gap-2">
                    <PrimSecTextCombo
                      primaryText={componentLog?.totalTimeSinceNew}
                      secondaryText="Hours"
                      variant="inverted"
                    />
                    <PrimSecTextCombo
                      primaryText={componentLog?.cycleSinceNew}
                      secondaryText="Cycles"
                      variant="inverted"
                    />
                  </div>
                  <div className="flex gap-2">
                    <PrimSecTextCombo
                      primaryText={
                        appliedComponentLog?.totalTimeSinceNew ?? '-'
                      }
                      secondaryText="Hours"
                      variant="inverted"
                    />
                    <PrimSecTextCombo
                      primaryText={appliedComponentLog?.cycleSinceNew ?? '-'}
                      secondaryText="Cycles"
                      variant="inverted"
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <Divider />
      </div>
    </Drawer>
  )
}

export default WorkflowAircraftDetailsDrawer
