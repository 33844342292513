import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import Sentry from 'src/lib/sentry'

const CREATE_PURCHASE_ORDER_PDF = gql`
  mutation CreatePurchaseOrderPdf($pdfData: PurchaseOrderPdfDataInput!) {
    createPurchaseOrderPdf(pdfData: $pdfData)
  }
`

interface CreatePurchaseOrderPDFResult {
  pdfUrl: string
}

const useCreatePurchaseOrderPdf = () => {
  const [createPurchaseOrderPdf] = useMutation(CREATE_PURCHASE_ORDER_PDF)

  const handleCreatePurchaseOrderPDF = async (
    pdfData: any,
    options?: any
  ): Promise<CreatePurchaseOrderPDFResult> => {
    try {
      const pdfResponse = await createPurchaseOrderPdf({
        variables: {
          pdfData,
        },
        ...options,
      })
      return { pdfUrl: pdfResponse.data.createPurchaseOrderPdf }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
      toast.error('Error creating purchase order PDF')
    }
  }
  return {
    createPurchaseOrderPdf: handleCreatePurchaseOrderPDF,
  }
}

export default useCreatePurchaseOrderPdf
