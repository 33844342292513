import React, { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { ApolloError } from '@apollo/client'
import AddTaskIcon from '@mui/icons-material/AddTask'
import PopoverTaskPicker from 'src/components/PopoverTaskPicker/PopoverTaskPicker'
import { LoadingButton } from '@mui/lab'
import { Button, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import {
  ComplianceActivity,
  InternalWorkItem,
  MaintenanceItem,
} from 'types/graphql'
import Switch from 'src/components/MUI/Switch'
import { Form, useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import Checkbox from 'src/components/MUI/Checkbox'
import DatePicker from 'src/components/MUI/DatePicker'
import CustomRadioGroup from 'src/components/MUI/RadioGroup'
import SelectDropdown from 'src/components/MUI/SelectDropdown'
import TextField from 'src/components/MUI/TextField'
import TagModalForm from 'src/components/TagModal/TagModalForm'
import useAddTagsToMtxItem from 'src/hooks/requests/useAddTagsToMtxItem'
import { useOrgName } from 'src/hooks/useOrgName'
import { capitalizeFirstLetter } from 'src/utils/helpers'
import {
  CREATE_DISCREPANCY_ITEM_TRANSACTION,
  DELETE_DISCREPANCY_ITEM,
  GET_TAGS,
  UPDATE_DISCREPANCY_ITEM_TRANSACTION,
} from 'src/components/Discrepanies/consts'
import PromptForPinDialog from 'src/components/common/PromptForPinDialog'
import useUserId from 'src/hooks/useUserId'
import Loading from 'src/components/Loading'
import {
  deferredOptions,
  melCategoryOptions,
  schema,
  taskTypeOptions,
  getMassagedMtxItemInput,
  melCatToDaysMap,
} from './consts'
import DrawerActions from '../MUI/Drawer/DrawerActions'
import DrawerHeader from '../MUI/Drawer/DrawerHeader'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from 'src/slices/modal'
import TrackedByComponentsSelector from '../TrackedByComponentsSelector/TrackedByComponentsSelector'

interface WorkflowAddDiscrepancyDrawerProps {
  onCreateSuccess: (
    createMtxItemData: MaintenanceItem,
    attachedTask?: ComplianceActivity | InternalWorkItem,
    isStandaloneDiscrepancy?: boolean
  ) => void
  isWorkOrder: boolean
  aircraftId: string
  workPackageTasks: ComplianceActivity[] | InternalWorkItem[]
  workPackageId: string
  aircraftUsageLogId: string
  selectedDiscrepancyToUpdate: MaintenanceItem
  setSelectedDiscrepancyToUpdate: (discrepancy: MaintenanceItem | null) => void
}

const modalName = 'workflowAddDiscrepancyDrawer'

const WorkflowAddDiscrepancyDrawer: React.FC<
  WorkflowAddDiscrepancyDrawerProps
> = ({
  onCreateSuccess,
  isWorkOrder,
  aircraftId,
  workPackageTasks,
  workPackageId,
  aircraftUsageLogId,
  selectedDiscrepancyToUpdate,
  setSelectedDiscrepancyToUpdate,
}) => {
  const dispatch = useDispatch()
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false
  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }

  const [attachedTask, setAttachedTask] = React.useState<
    ComplianceActivity | InternalWorkItem | null
  >(null)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [isCreateOrUpdateLoading, setIsCreateOrUpdateLoading] =
    React.useState(false)
  const [submissionType, setSubmissionType] = React.useState<
    'create' | 'update' | 'delete'
  >()

  const orgName = useOrgName()
  const { id: currentUserId, hasLoaded: currentUserIdHasLoaded } = useUserId()
  const { data: tags } = useQuery(GET_TAGS)
  const [createDiscrepancyItemTransaction] = useMutation(
    CREATE_DISCREPANCY_ITEM_TRANSACTION
  )
  const [updateDiscrepancyItemTransaction] = useMutation(
    UPDATE_DISCREPANCY_ITEM_TRANSACTION
  )
  const [deleteDiscrepancyItem] = useMutation(DELETE_DISCREPANCY_ITEM)
  const { addTagsToMtxItem } = useAddTagsToMtxItem()

  const defaultValues = {
    discrepancyDeferred: 'no',
    ataCode: '',
    adSbType: 'MEL',
    nextDueCategory: '',
    nextDueDate: '',
    isStandaloneDiscrepancy: false,
    nextDueHours: '',
    nextDueLandings: '',
    statusClosed: !isWorkOrder, // Carry-over from when compliance and work order discrepancy components were
    tags: [],
    title: '',
    description: '',
    notes: '',
    enteredBy: '',
    pin: '',
    trackedByComponent: '',
  }

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const { watch, setValue, reset, handleSubmit } = formMethods
  const adSbType = watch('adSbType')
  const discrepancyDeferred = watch('discrepancyDeferred')
  const category = watch('nextDueCategory')
  const isStandaloneDiscrepancy = watch('isStandaloneDiscrepancy')
  const trackedByComponent = watch('trackedByComponent')

  const isUpdateMode = selectedDiscrepancyToUpdate ? true : false
  const allMaintenanceItemIds = workPackageTasks.map((task) => {
    return task.maintenanceItem.id
  })

  useEffect(() => {
    setValue(
      'trackedByComponent',
      attachedTask?.maintenanceItem.trackedByComponent
    )
    setValue('ataCode', attachedTask?.maintenanceItem.ataCode ?? '')
  }, [attachedTask])

  useEffect(() => {
    // set form values if selected task is present (update mode)
    if (isUpdateMode) {
      // debugger
      const notes: any = selectedDiscrepancyToUpdate?.notes
      let nextDueInfo = {}
      if (selectedDiscrepancyToUpdate?.discrepancyStatus === 'DEFERRED') {
        const nextDueValue: any =
          selectedDiscrepancyToUpdate?.maintenanceNextDue[0]?.nextDueValue ?? {}
        nextDueInfo = {
          nextDueCategory: nextDueValue?.category ?? '',
          nextDueDate: nextDueValue?.date ?? '',
          nextDueHours: nextDueValue?.hours ?? '',
          nextDueLandings: nextDueValue?.landings ?? '',
        }
      }
      // Find the maintenanceItemId that the selected discrepancy is attached to
      const targetWorkPackageItem = workPackageTasks.find(
        (task) =>
          task.id ===
          (isWorkOrder
            ? selectedDiscrepancyToUpdate.discrepancyWorkItemId
            : selectedDiscrepancyToUpdate.discrepancyCompActivityId)
      )
      setAttachedTask(targetWorkPackageItem)
      reset({
        ...selectedDiscrepancyToUpdate,
        statusClosed:
          selectedDiscrepancyToUpdate.discrepancyStatus === 'CLOSED',
        discrepancyDeferred:
          selectedDiscrepancyToUpdate.discrepancyStatus === 'DEFERRED'
            ? 'yes'
            : 'no',
        notes: notes ?? '',
        ...nextDueInfo,
      })
    }
  }, [selectedDiscrepancyToUpdate])

  useEffect(() => {
    // set next due date based on category
    if (category) {
      const daysToAdd = melCatToDaysMap[category]
      const nextDate = dayjs().add(daysToAdd, 'day')
      setValue('nextDueDate', nextDate.toISOString())
    }
  }, [category])

  useEffect(() => {
    // reset values based on adSbType
    if (adSbType === 'DISCREPANCY') {
      setValue('discrepancyDeferred', 'no')
      setValue('nextDueCategory', '')
      setValue('nextDueDate', '')
      setValue('nextDueHours', '')
      setValue('nextDueLandings', '')
    }
    if (adSbType === 'WATCH_LIST') {
      setValue('nextDueCategory', '')
    }
  }, [adSbType])

  useEffect(() => {
    if (isStandaloneDiscrepancy) {
      setValue('discrepancyDeferred', 'no')
      // Reset these values immediately since we know it's standalone
      setAttachedTask(null)
      setValue('trackedByComponent', null)
      setValue('ataCode', '')
      setValue('nextDueDate', dayjs().toISOString())
      setValue('nextDueCategory', 'catA')
      setValue('nextDueHours', '')
      setValue('nextDueLandings', '')
    }
  }, [isStandaloneDiscrepancy])

  useEffect(() => {
    if (discrepancyDeferred === 'yes') {
      setValue('statusClosed', false)
    } else if (discrepancyDeferred === 'no') {
      if (!isStandaloneDiscrepancy) {
        setValue('nextDueDate', '')
        setValue('nextDueCategory', '')
        setValue('nextDueHours', '')
        setValue('nextDueLandings', '')
      }
    }
  }, [discrepancyDeferred, isStandaloneDiscrepancy])

  const resetFormAndClose = () => {
    setIsDeleteLoading(false)
    setIsCreateOrUpdateLoading(false)
    reset(defaultValues)
    setAttachedTask(null)
    setSelectedDiscrepancyToUpdate(null)
    handleClose()
  }

  const somethingWentWrong = (message?: string) => {
    setIsDeleteLoading(false)
    setIsCreateOrUpdateLoading(false)
    toast.error(message ?? `Something went wrong. Please try again later.`, {
      duration: 3000,
    })
  }

  const handleRequestError = (error: ApolloError) => {
    if (error.message === 'PIN_DOES_NOT_MATCH') {
      somethingWentWrong('PIN does not match.  Please try again.')
    } else {
      somethingWentWrong()
    }
  }

  const triggerSubmitChain = async (userPin: string) => {
    const values = formMethods.getValues()
    setIsCreateOrUpdateLoading(true)
    const mtxItemInput = getMassagedMtxItemInput(
      workPackageId,
      isUpdateMode,
      isWorkOrder,
      aircraftId,
      values,
      attachedTask,
      aircraftUsageLogId,
      orgName,
      currentUserId,
      userPin
    )

    // This is standard function to add tags to mtx item
    // this is executed at the end of all the mutations
    // because of the nature of it's low priority,
    // there is no rollback mechanism for it.
    const addTags = (mtxId) => {
      addTagsToMtxItem(values.tags, mtxId, true)
        .then(() => {
          toast.success(`Discrepancy added successfully`, {
            duration: 2000,
          })
          resetFormAndClose()
        })
        .catch(() => {
          somethingWentWrong(
            'Something went wrong while adding tags to discrepancy. However, discrepancy was added successfully.'
          )
          resetFormAndClose()
        })
    }

    // If it is update mode, then we need to update the mtx item and mtx next due
    if (isUpdateMode) {
      updateDiscrepancyItemTransaction({
        variables: {
          id: selectedDiscrepancyToUpdate.id,
          input: mtxItemInput,
          isDeferred: values.discrepancyDeferred === 'yes',
        },
        onCompleted: async (mtxData) => {
          addTags(mtxData.updateMaintenanceItemTransaction.id)
        },
        onError: handleRequestError,
      })
    } else {
      // If it is create mode, then we need to create the mtx item and mtx next due
      createDiscrepancyItemTransaction({
        variables: { input: mtxItemInput },
        onCompleted: async (mtxData) => {
          addTags(mtxData.createMaintenanceItemTransaction.id)
          onCreateSuccess(
            mtxData.createMaintenanceItemTransaction,
            attachedTask,
            isStandaloneDiscrepancy
          )
        },
        onError: handleRequestError,
      })
    }
  }

  const handleDelete = async (userPin: string) => {
    setIsDeleteLoading(true)
    deleteDiscrepancyItem({
      variables: {
        id: selectedDiscrepancyToUpdate.id,
        input: {
          verifyPin: true,
          verifyUserId: currentUserId,
          verifyUserPin: userPin,
        },
      },
      onCompleted: () => {
        resetFormAndClose()
      },
      onError: handleRequestError,
    })
  }

  const handlePinEntry = async (userPin: string) => {
    if (submissionType === 'delete') {
      handleDelete(userPin)
    } else {
      triggerSubmitChain(userPin)
    }
    setSubmissionType(null)
  }

  const areMutationsLoading = isCreateOrUpdateLoading || isDeleteLoading

  return (
    <>
      <Drawer anchor="right" open={open} onClose={resetFormAndClose}>
        <DrawerHeader
          title={`${isUpdateMode ? 'Update' : 'Add'} Discrepancy`}
          onClose={resetFormAndClose}
        />
        <Form
          formMethods={formMethods}
          className="relative h-full overflow-auto"
        >
          <div>
            <div className="flex items-center justify-between gap-2 p-2">
              <Typography variant="body1">Standalone</Typography>
              <Switch
                name="isStandaloneDiscrepancy"
                switchProps={{ size: 'small', disabled: isUpdateMode }}
                disabledTooltip="You cannot make a standalone discrepancy in update mode."
              />
            </div>
            <Divider />
            <div className="bg-[#ffffff] p-2 pb-12">
              <div>
                <div className="mt-2 flex flex-col gap-2">
                  <PopoverTaskPicker
                    trigger={
                      <Button
                        variant="outlined"
                        disabled={isUpdateMode || isStandaloneDiscrepancy}
                      >
                        {attachedTask?.maintenanceItem.title
                          ? `${attachedTask.maintenanceItem.ataCode} ${attachedTask.maintenanceItem.manufactureCode} - ${attachedTask.maintenanceItem.title}`
                          : isStandaloneDiscrepancy
                          ? 'This is a standalone discrepancy...'
                          : 'Click to select task to attach this discrepancy to...'}
                      </Button>
                    }
                    filterByIds={allMaintenanceItemIds}
                    aircraftId={aircraftId}
                    onSelect={(maintenanceItemId) => {
                      // setAttachedMaintenanceItemId(maintenanceItemId)
                      setAttachedTask(
                        workPackageTasks.find(
                          (task) =>
                            task.maintenanceItem.id === maintenanceItemId
                        )
                      )
                    }}
                  />
                  <div className="grid grid-cols-2 gap-2">
                    <TrackedByComponentsSelector
                      name="trackedByComponent"
                      aircraftId={aircraftId}
                      disabled={!isStandaloneDiscrepancy}
                    />
                    <TextField
                      placeholder="ATA Code"
                      size="small"
                      name="ataCode"
                      disabled={!isStandaloneDiscrepancy}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <SelectDropdown
                      required
                      name="adSbType"
                      label="Type"
                      options={taskTypeOptions}
                      fullWidth
                    />
                    <TagModalForm
                      control={formMethods.control}
                      options={tags?.tags ?? []}
                    />
                  </div>
                  <TextField
                    placeholder="Defect"
                    required
                    size="small"
                    name="title"
                  />
                  <TextField
                    placeholder="Corrective Action"
                    name="description"
                    multiline
                    fullWidth
                    rows={4}
                  />
                </div>
                <div className="mt-2 flex flex-col gap-2">
                  <Typography variant="subtitle1" fontWeight={'bold'}>
                    Report Information
                  </Typography>
                  {!isStandaloneDiscrepancy && (
                    <div className="grid grid-cols-2 gap-2">
                      <CustomRadioGroup
                        name="discrepancyDeferred"
                        label="Is deferred?"
                        required
                        options={deferredOptions}
                        disabled={adSbType === 'DISCREPANCY'}
                        row
                      />

                      <Checkbox
                        name="statusClosed"
                        label="Mark as closed"
                        checkboxProps={{
                          defaultChecked: true,
                          tabIndex: -1,
                        }}
                      />
                    </div>
                  )}
                  {discrepancyDeferred === 'yes' && (
                    <div className="flex flex-col gap-2">
                      <Typography variant="subtitle2">Next Due</Typography>
                      {(adSbType === 'MEL' || adSbType === 'NEF') && (
                        <SelectDropdown
                          name="nextDueCategory"
                          label="Category"
                          options={melCategoryOptions}
                          fullWidth
                          value=""
                        />
                      )}
                      <DatePicker
                        className="col-span-2"
                        name={'nextDueDate'}
                        label="Date"
                        disabled={category && category !== 'catA'}
                        disablePast
                        closeOnSelect
                        slotProps={{
                          textField: { size: 'small', fullWidth: true },
                        }}
                      />

                      <TextField
                        placeholder="Hours"
                        size="small"
                        name="nextDueHours"
                        type="number"
                        fullWidth
                      />
                      <TextField
                        placeholder="Landings"
                        size="small"
                        name="nextDueLandings"
                        type="number"
                        fullWidth
                      />
                    </div>
                  )}

                  <div className="flex flex-col gap-2">
                    <Typography variant="subtitle2">Additional</Typography>
                    <TextField
                      placeholder="Notes"
                      name="notes"
                      multiline
                      fullWidth
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <DrawerActions
          className="gap-1"
          leftActions={
            <Button
              variant="text"
              onClick={() => {
                if (isUpdateMode) {
                  resetFormAndClose()
                } else {
                  reset(defaultValues)
                }
              }}
            >
              {isUpdateMode ? 'Close' : 'Reset'}
            </Button>
          }
          rightActions={
            <div>
              {isUpdateMode && (
                <Button
                  variant="text"
                  disabled={areMutationsLoading}
                  color="error"
                  onClick={() => setSubmissionType('delete')}
                  style={{ marginRight: '8px' }}
                >
                  Delete
                </Button>
              )}
              <Tooltip
                title={
                  !isStandaloneDiscrepancy && !trackedByComponent
                    ? 'Please attach discrepancy to a task first'
                    : ''
                }
              >
                <span>
                  <LoadingButton
                    loading={isCreateOrUpdateLoading}
                    startIcon={<AddTaskIcon />}
                    loadingPosition="start"
                    disabled={
                      areMutationsLoading ||
                      // if not a standalone discrepancy AND haven't attached discrepancy to a task
                      (!isStandaloneDiscrepancy && !trackedByComponent)
                    }
                    variant="contained"
                    onClick={() =>
                      handleSubmit(() => {
                        setSubmissionType(isUpdateMode ? 'update' : 'create')
                      })()
                    }
                  >
                    {isUpdateMode ? 'Update Discrepancy' : 'Add Discrepancy'}
                  </LoadingButton>
                </span>
              </Tooltip>
            </div>
          }
        />
      </Drawer>
      <PromptForPinDialog
        open={!!submissionType}
        onClose={() => setSubmissionType(null)}
        onSubmit={handlePinEntry}
      />
    </>
  )
}

export default WorkflowAddDiscrepancyDrawer
