import { type MRT_RowData, type MRT_TableOptions } from 'material-react-table'
import cx from './cx'

// reusable function that should output our default options for a table @ wingwork
// naturally, you can override any of these options in your specific instance

// see official documentation here for more information about reusable table options
// https://www.material-react-table.com/docs/guides/best-practices#re-usable-default-options

export const getTableTheme = () => ({
  // custom paper container styles to match our design
  muiTablePaperProps: {
    className: 'rounded-lg',
  },

  // custom table head row styles to match our design
  muiTableHeadRowProps: {
    className: 'bg-gray-50',
  },
})

/**
 * Configures a table to grow without overflowing
 * talk to @dowhilegeek about this if you need help
 * but try putting the table into a flex container with all regular page elements in it, and give it the classes:
 *
 * <div className="flex h-[calc(100vh-64px)] flex-col overflow-auto">
 *   ...whatever other stuff, searchbar, action buttons, etc...
 *   <MaterialReactTable table={table} />
 * </div>
 *
 * this will give the table a max-height of 100% of the page minus the app bar/breadcrumbs height
 */
export const getTableScrollConfig = () => ({
  muiTableContainerProps: {
    className: 'h-full',
  },
  muiTablePaperProps: {
    className: 'flex flex-grow flex-col overflow-hidden mx-3 my-2',
  },
  enableStickyHeader: true,
})

export const getTableConfig = <TData extends MRT_RowData>({
  enableTheme = true,
  enableScrollConfig = false,
}: {
  enableTheme?: boolean
  enableScrollConfig?: boolean
} = {}): Partial<MRT_TableOptions<TData>> => {
  const themeConfig = enableTheme ? getTableTheme() : {}
  const scrollConfig = enableScrollConfig ? getTableScrollConfig() : {}

  return {
    // disable column actions and sorting
    enableColumnActions: false,
    enableSorting: false,

    // remove default toolbar, doesnt match our design
    enableTopToolbar: false,

    ...themeConfig,
    ...scrollConfig,
    muiTablePaperProps: {
      className: cx(
        themeConfig?.muiTablePaperProps?.className,
        scrollConfig?.muiTablePaperProps?.className
      ),
    },
  }
}

// Alias for backward compatibility
// TODO: remove this alias after we move over to new naming convention
export const getDefaultMRTOptions = getTableConfig
