import { useLazyQuery, useApolloClient, gql } from '@apollo/client'
import { useMemo } from 'react'
import { FileUploadButtonProps } from 'src/components/FileUploadButton'
import WorkflowAttachmentsSection from 'src/components/workflow/WorkflowAttachmentsSection'
import {
  GET_COMPLIANCE_ACTIVITY_ATTACHMENTS,
  ATTACHMENTS_FRAGMENT,
} from '../../queries'

const AttachmentsSection = ({
  complianceActivityId,
  flashSaved,
  flashError,
  variant,
}: {
  complianceActivityId: string
  variant?: 'default' | 'review' | 'readonly'
  flashSaved?: (text?: string) => void
  flashError?: (text?: string) => void
}) => {
  const client = useApolloClient()
  const [loadAttachments] = useLazyQuery(GET_COMPLIANCE_ACTIVITY_ATTACHMENTS, {
    variables: { id: complianceActivityId },
    notifyOnNetworkStatusChange: false,
  })

  const cachedData = client.cache.readFragment({
    id: `ComplianceActivity:${complianceActivityId}`,
    fragmentName: 'CachedAttachments',
    fragment: gql`
      ${ATTACHMENTS_FRAGMENT}
      fragment CachedAttachments on ComplianceActivity {
        attachedFiles {
          id
          ...Attachments
        }
      }
    `,
  })

  const attachables: FileUploadButtonProps['attachables'] = [
    { id: complianceActivityId, type: 'ComplianceActivity' },
  ]

  const attachments = useMemo(
    () =>
      cachedData?.attachedFiles?.map((attachment) => ({
        ...attachment?.file,
        category: 'ComplianceActivity',
      })) ?? [],
    [cachedData]
  )

  return (
    <WorkflowAttachmentsSection
      data={attachments}
      attachables={attachables}
      refetchAttachments={loadAttachments}
      flashSaved={flashSaved}
      flashError={flashError}
      variant={variant}
    />
  )
}

export default AttachmentsSection
