import { CreateAircraftComponentInput, AircraftComponent } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import Sentry from 'src/lib/sentry'

const CREATE_AIRCRAFT_COMPONENT_MUTATION = gql`
  mutation createAircraftComponent($input: CreateAircraftComponentInput!) {
    createAircraftComponent(input: $input) {
      id
      isPrimary
      isIntegral
      partNumber
      serialNumber
      monthsSinceNew
      monthsSinceOverhaul
      monthsSinceRepair
      hoursSinceNew
      hoursSinceOverhaul
      hoursSinceRepair
      cyclesSinceNew
      cyclesSinceOverhaul
      cyclesSinceRepair
    }
  }
`

function useCreateAircraftComponent(options = {}) {
  return useMutation<AircraftComponent, CreateAircraftComponentInput>(
    CREATE_AIRCRAFT_COMPONENT_MUTATION,
    {
      onError: (error) => {
        toast.error('Something went wrong.')
        Sentry.captureException(error)
      },
      ...options,
    }
  )
}

export default useCreateAircraftComponent
