import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Modal, Paper, Typography } from '@mui/material'
import { PurchaseOrderItem } from 'types/graphql'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import {
  CREATE_AND_ADD_SERVICE_TO_PURCHASE_ORDER,
  GET_PURCHASE_ORDER,
} from 'src/pages/PurchaseOrderPage/queries'
import { closeModal } from 'src/slices/modal'
import {
  dollarsToCents,
  isDecimalWithTwoOrLessPlacesNullable,
} from 'src/utils/helpers'

import DollarCentsTextField from './DollarCentsTextField'
import Button from './MUI/Button'
import IconButton from './MUI/IconButton'
import TextField from './MUI/TextField'
import Drawer from './MUI/Drawer/Drawer'
import DrawerHeader from './MUI/Drawer/DrawerHeader'
import DrawerActions from './MUI/Drawer/DrawerActions'

type AddServiceLineItemModalProps = {
  purchaseOrderId?: string
  onRequest?: ({ description, quantity, partNumber }) => void
  onComplete?: (item: PurchaseOrderItem) => void
  /** Optionally define what happens when the user clicks backdrop, the close icon, or hits "escape" */
  onBail?: () => void
}

const modalName = 'addServiceLineItemModal'

const schema = yup.object().shape({
  ataCode: yup.string().nullable(),
  cost: yup
    .number()
    .typeError('Cost is a number')
    .min(0, 'Must be positive')
    .test(
      'valid-decimal',
      'No more than 2 decimal points',
      isDecimalWithTwoOrLessPlacesNullable
    )
    .nullable(),
  description: yup.string(),
})

const AddServiceLineItemModal: React.FC<AddServiceLineItemModalProps> = ({
  purchaseOrderId,
  onComplete,
  onRequest,
  onBail,
}) => {
  const [createAndAddServiceToPurchaseOrder] = useMutation(
    CREATE_AND_ADD_SERVICE_TO_PURCHASE_ORDER,
    {
      update: (
        cache,
        { data: { createAndAddServiceToPurchaseOrder: serviceLineItem } }
      ) => {
        const {
          purchaseOrder: { purchaseOrderItem: poItems },
        } = cache.readQuery({
          query: GET_PURCHASE_ORDER,
          variables: {
            id: purchaseOrderId,
          },
        })

        cache.writeQuery({
          query: GET_PURCHASE_ORDER,
          variables: {
            id: purchaseOrderId,
          },
          data: {
            purchaseOrder: {
              purchaseOrderItem: [...poItems, serviceLineItem],
            },
          },
        })
      },
    }
  )
  const dispatch = useDispatch()
  const formMethods = useForm({ resolver: yupResolver(schema) })

  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  useEffect(() => {
    // reset on the closure, so you don't see the label animation on the next open
    if (!open) {
      formMethods.reset({
        ataCode: '',
        cost: null,
        description: '',
      })
    }
  }, [open, formMethods])
  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }

  const handleBail = () => {
    handleClose()
    onBail?.()
  }

  const handleSubmit = async (values) => {
    await createAndAddServiceToPurchaseOrder({
      variables: {
        input: {
          ...values,
          cost: dollarsToCents(values.cost),
        },
        purchaseOrderId,
      },
      onCompleted: ({ createAndAddServiceToPurchaseOrder }) =>
        onComplete?.(createAndAddServiceToPurchaseOrder),
    })
    handleClose()
    toast.success('Service added')
    onRequest?.(values)
  }

  return (
    <Drawer anchor="right" open={open} onClose={handleBail}>
      <DrawerHeader title="Add Service" onClose={handleBail} />
      <div className="h-full w-[800px] p-3">
        <Form
          id="addServiceLineItemForm"
          className="h-full"
          formMethods={formMethods}
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-2">
            <Typography variant="caption" className="text-blueGrey-600">
              DETAILS
            </Typography>
            <div className="flex gap-2">
              <TextField label="Ata Code" name="ataCode" size="small" />
              <DollarCentsTextField
                name="cost"
                control={formMethods.control}
                label="Cost"
              />
            </div>
            <TextField
              label="Description"
              name="description"
              multiline
              fullWidth
              minRows={4}
            />
          </div>
        </Form>
      </div>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="text" color="black" onClick={handleBail}>
            CANCEL
          </Button>
        }
        rightActions={
          <Button
            type="submit"
            form="addServiceLineItemForm"
            variant="contained"
            color="primary"
          >
            ADD SERVICE
          </Button>
        }
      />
    </Drawer>
  )
}

export default AddServiceLineItemModal
