import coreAircraftUsageLogFragment from 'src/fragments/AircraftUsageLog'

export const DueStatusChipAircraftFieldsFragment = gql`
  ${coreAircraftUsageLogFragment}
  fragment DueStatusChipAircraftFields on Aircraft {
    id
    AircraftUsageLog(limit: 1) {
      ...CoreAircraftUsageLogFields
    }
  }
`

export const DueStatusChipFieldsFragment = gql`
  fragment DueStatusChipFields on MaintenanceItem {
    id
    trackedByComponentId
    cadenceValue
    nextDueStatus
    adSbStatus
    maintenanceNextDue {
      id
      nextDueValue
      nextDueOverride
    }
  }
`
