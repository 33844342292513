import { Divider, Tab, Tabs, Skeleton } from '@mui/material'

import Badge from 'src/components/Badge'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { setStatusTab } from 'src/slices/compliance'
import { RootState } from 'src/store'
import Searchbar from 'src/components/Searchbar/Searchbar'

interface TabbedTableLayoutProps {
  sections: string[]
  badgeCounts?: Record<string, number>
  actions?: React.ReactNode
  children: React.ReactNode
  tabSelectorFn: (state: RootState) => string
  onSearchChange?: (value: string) => void
  searchValue?: string
  showHeader?: boolean
  loading?: boolean
}

export const TabbedTableLayout: React.FC<TabbedTableLayoutProps> = ({
  sections,
  badgeCounts = {},
  tabSelectorFn,
  actions,
  children,
  loading = false,
  onSearchChange,
  searchValue = '',
  showHeader = true,
}) => {
  const dispatch = useDispatch()
  const currentSection = useSelector(tabSelectorFn)
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    dispatch(setStatusTab(sections[newValue]))
  }

  return (
    <>
      <div>
        <Divider />
        <Tabs
          value={sections.indexOf(currentSection)}
          onChange={handleTabChange}
        >
          {sections.map((section, index) => (
            <Tab
              key={index}
              disableRipple
              label={
                <div className="flex items-center gap-1">
                  <span>{section}</span>
                  {typeof badgeCounts[section] === 'number' &&
                    (loading ? (
                      <Skeleton variant="circular" height={24} width={24} />
                    ) : (
                      <Badge active={section === currentSection}>
                        {badgeCounts[section]}
                      </Badge>
                    ))}
                </div>
              }
            />
          ))}
        </Tabs>
        <Divider />

        <div className="m-2 mb-0 flex flex-col gap-2">
          {showHeader && (
            <div className="flex items-center">
              <Searchbar
                placeholder="Search"
                onChange={(e) => onSearchChange(e.target.value)}
                value={searchValue}
                iconPosition="left"
                className="py-1"
              />
              <div className="grow" />
              <div className="flex items-center gap-2">{actions}</div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-grow flex-col overflow-hidden p-2">
        {children}
      </div>
    </>
  )
}

export default TabbedTableLayout
