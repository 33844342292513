import { OperationVariables } from '@apollo/client/core'
import { InternalWorkItem, UpdateInternalWorkItemInput } from 'types/graphql'

import useMutationUpdate from './useMutationUpdate'

const UPDATE_WORK_ITEM_MUTATION = gql`
  mutation UpdateWorkItemV1($id: String!, $input: UpdateInternalWorkItemInput!) {
    updateInternalWorkItem(id: $id, input: $input) {
      id
      workOrderId
      maintenanceItemId
      description
      notes
      workMinutes
      workedById
      correctiveAction
      nextDueId
      workedById
      status
    }
  }
`

const useUpdateWorkItem = (options?: OperationVariables) => {
  return useMutationUpdate<InternalWorkItem, UpdateInternalWorkItemInput>(
    UPDATE_WORK_ITEM_MUTATION,
    options
  )
}

export default useUpdateWorkItem
