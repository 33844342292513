import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import TextField from 'src/components/MUI/TextField'
import { useOrgName } from 'src/hooks/useOrgName'
import useQuery from 'src/hooks/useQuery'
import { closeModal } from 'src/slices/modal'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import DrawerHeader from 'src/components/MUI/Drawer/DrawerHeader'
import DrawerActions from 'src/components/MUI/Drawer/DrawerActions'
import Button from 'src/components/MUI/Button'

import {
  CREATE_SHIPPING_METHOD,
  GET_ALL_SHIPPING_METHODS,
  GET_SHIPPING_METHOD_BY_ID,
  UPDATE_SHIPPING_METHOD,
} from '../../queries'

const modalName = 'shippingMethodEditorModal'

const schema = yup.object().shape({ name: yup.string().required() })

type ShippingMethodEditorProps = {
  onComplete?: (value: any) => void
}

const ShippingMethodEditor: React.FC<ShippingMethodEditorProps> = ({
  onComplete,
}) => {
  const orgSlug = useOrgName()
  const dispatch = useDispatch()

  const isOpen =
    useSelector((state: any) => state.modal.modals[modalName]?.isOpen) ?? false

  const id = useSelector(
    (state: any) => state.modal.modals[modalName]?.data?.shippingMethodId
  )

  const formMethods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })
  const {
    formState: { errors },
  } = formMethods

  const [createShippingMethod] = useMutation(CREATE_SHIPPING_METHOD, {
    update: (cache, { data: { createShippingMethod: method } }) => {
      // Add the new address to the cache for the GET_ALL_ADDRESSES query
      // This will cause the UI to update and add the new address to the list
      const { shippingMethods } = cache.readQuery({
        query: GET_ALL_SHIPPING_METHODS,
      })

      cache.writeQuery({
        query: GET_ALL_SHIPPING_METHODS,
        data: { shippingMethods: [...shippingMethods, method] },
      })

      // automatically set the shipping method in the PO form if created from there
      onComplete?.({
        id: method.id,
        name: method.name,
        accountNumber: method.accountNumber,
      })
    },
  })
  const [updateShippingMethod] = useMutation(UPDATE_SHIPPING_METHOD)

  const { data, hasLoaded } = useQuery(GET_SHIPPING_METHOD_BY_ID, {
    variables: { id },
    skip: !id,
  })

  useEffect(() => {
    // if we load data because we're in update mode, reset the form with the new data
    if (data) {
      const { __typename, id: _id, ...shippingMethod } = data.shippingMethod

      formMethods.reset(shippingMethod)
    }
  }, [data, formMethods])

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
    formMethods.reset({ name: '', accountNumber: '' })
  }

  const handleSubmit = async (values) => {
    if (id) {
      const updatePayload = {
        ...values,
        orgSlug,
        id: undefined,
      }

      await updateShippingMethod({
        variables: {
          id,
          input: updatePayload,
        },
      })
    } else {
      await createShippingMethod({
        variables: {
          input: { ...values, orgSlug },
        },
      })
    }
    // return to the list view and unset id
    handleClose()
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <DrawerHeader
        title={id ? 'Edit Shipping Method' : 'Add Shipping Method'}
        onClose={handleClose}
      />
      <div className="h-full w-[800px] p-3">
        <Form
          id="shippingMethodForm"
          onSubmit={handleSubmit}
          formMethods={formMethods}
          className="flex flex-col gap-2"
        >
          <TextField
            name="name"
            required
            label="Name"
            error={!!errors.name}
            helperText={errors.name ? 'Name is required' : ''}
            fullWidth
          />
          <TextField name="accountNumber" label="Account Number" fullWidth />
        </Form>
      </div>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="text" color="black" onClick={handleClose}>
            CANCEL
          </Button>
        }
        rightActions={
          <Button
            type="submit"
            form="shippingMethodForm"
            variant="contained"
            color="primary"
          >
            {id ? 'SAVE' : 'CREATE'}
          </Button>
        }
      />
    </Drawer>
  )
}

export default ShippingMethodEditor
