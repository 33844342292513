import { useMemo } from 'react'

import { ConstructionRounded, ShoppingCartRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Checkbox,
  Modal,
  Paper,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { t } from 'i18next'
import { Product, PurchaseOrderItem } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import Table, {
  CheckboxHeader,
  useCheckboxSelection,
} from 'src/components/Table'
import { useDispatch } from 'src/hooks/useDispatch'
import useQuery from 'src/hooks/useQuery'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal } from 'src/slices/modal'
import { abbreviateFullName } from 'src/utils/stringFormatters'

import {
  ADD_ITEMS_TO_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_ITEMS,
} from '../PurchaseOrdersPageV2Page/queries'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import DrawerHeader from 'src/components/MUI/Drawer/DrawerHeader'
import DrawerActions from 'src/components/MUI/Drawer/DrawerActions'
import {
  MaterialReactTable,
  MRT_PaginationState,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table'
import { getDefaultMRTOptions } from 'src/utils/getDefaultMRTOptions'
import { GET_PRODUCTS_LIST } from '../ProductsListPage/queries'
import {
  GET_PURCHASE_ORDER,
  CREATE_PART_ITEMS_AND_ADD_TO_PURCHASE_ORDER,
} from './queries'

interface LowStockPickerDrawerProps {
  purchaseOrderId: string
  onComplete?: (items: PurchaseOrderItem[]) => void
}

const modalName = 'lowStockPickerDrawer'
const LowStockPickerDrawer: React.FC<LowStockPickerDrawerProps> = ({
  purchaseOrderId,
  onComplete,
}) => {
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false)
  const handleClose = () => {
    setLoading(false)
    table.resetRowSelection()
    dispatch(closeModal({ name: modalName }))
  }
  // const { data } = useQuery(GET_PURCHASE_ORDER_ITEMS)

  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })
  const { data, loading: productsLoading } = useQuery(GET_PRODUCTS_LIST, {
    variables: {
      filters: {
        lowStock: true,
      },
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
    },
  })
  const { purchaseOrderItems: items = [] } = data ?? {}

  const requestedItems = useMemo(
    () => items.filter((item) => !item.rejectedAt && !item.deletedAt),
    [items]
  )

  const [addItemsToPurchaseOrder] = useMutation(ADD_ITEMS_TO_PURCHASE_ORDER, {
    onCompleted: ({ addItemsToPurchaseOrder: items }) => {
      toast.success(
        `${items.length} Item${
          items.length !== 1 ? 's' : ''
        } added to Purchase Order`
      )
      onComplete?.(items)
    },
  })

  const [createPartItemsAndAddToPurchaseOrder] = useMutation(
    CREATE_PART_ITEMS_AND_ADD_TO_PURCHASE_ORDER,
    {
      onCompleted: ({ createPartItemsAndAddToPurchaseOrder: items }) => {
        toast.success(
          `${items.length} Item${
            items.length !== 1 ? 's' : ''
          } added to Purchase Order`
        )
      },
    }
  )

  const columns = useMemo<MRT_ColumnDef<Product>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Item',
        size: 300,
      },
      {
        accessorKey: 'partNumber',
        header: 'Part Number',
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        size: 50,
        Cell: ({ renderedCellValue, row }) => {
          return row.original?.lowStockThreshold >= row?.original?.quantity ? (
            <span className="font-bold text-red-500">{renderedCellValue}</span>
          ) : (
            renderedCellValue
          )
        },
      },
      {
        header: 'Min. Stock',
        accessorKey: 'lowStockThreshold',
        size: 50,
      },
    ],
    []
  )

  const table = useMaterialReactTable({
    ...getDefaultMRTOptions(),
    columns,
    enableStickyHeader: true,
    onPaginationChange: setPagination,
    data: data?.productsPage?.edges ?? [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    rowCount: data?.productsPage?.pageInfo?.totalCount ?? 0,

    enableTopToolbar: false,
    manualPagination: true,

    enableRowSelection: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: true,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => {
      return {
        onClick: (event) => {
          // Don't toggle if user is dragging to select text
          if (window.getSelection()?.toString()) {
            return
          }
          row.toggleSelected(!row.getIsSelected())
        },
      }
    },

    state: {
      isLoading: productsLoading,
      pagination,
      showSkeletons: false,
    },
  })

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <DrawerHeader title="Add Low Stock" onClose={handleClose} />
      <div className="h-full w-[800px] p-3">
        <MaterialReactTable table={table} />
      </div>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="text" color="black" onClick={handleClose}>
            CANCEL
          </Button>
        }
        rightActions={
          <LoadingButton
            onClick={async () => {
              setLoading(true)
              try {
                await createPartItemsAndAddToPurchaseOrder({
                  variables: {
                    purchaseOrderId,
                    parts: table.getSelectedRowModel().rows.map((row) => ({
                      partNumber: row.original.partNumber,
                      quantity:
                        row.original.lowStockThreshold - row.original.quantity,
                      description: row.original.name,
                    })),
                  },
                  update: (
                    cache,
                    { data: { createPartItemsAndAddToPurchaseOrder: items } }
                  ) => {
                    const existingData = cache.readQuery({
                      query: GET_PURCHASE_ORDER,
                      variables: { id: purchaseOrderId },
                    })

                    cache.writeQuery({
                      query: GET_PURCHASE_ORDER,
                      variables: { id: purchaseOrderId },
                      data: {
                        purchaseOrder: {
                          ...existingData.purchaseOrder,
                          __typename: 'PurchaseOrder',
                          purchaseOrderItem: [
                            ...existingData.purchaseOrder.purchaseOrderItem,
                            ...items.map((item) => ({
                              ...item,
                              __typename: 'PurchaseOrderItem',
                            })),
                          ],
                        },
                      },
                    })
                  },
                })
              } catch (error) {
                toast.error('Failed to add items to purchase order')
              } finally {
                handleClose()
              }
            }}
            variant="contained"
            loading={loading}
            disabled={table.getSelectedRowModel().rows.length === 0}
          >
            ADD SELECTED
          </LoadingButton>
        }
      />
    </Drawer>
  )
}

export default LowStockPickerDrawer
