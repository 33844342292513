import { useEffect } from 'react'

import { DocumentNode, MutationHookOptions } from '@apollo/client'
import { OperationVariables } from '@apollo/client/core'

import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import Sentry from 'src/lib/sentry'

const useMutationUpdate = <MutationResult, MutationVariables>(
  mutation: DocumentNode,
  options?: OperationVariables
) => {
  const [mutationFunction, { data, loading, error, ...resultRest }] =
    useMutation<MutationResult, MutationVariables>(mutation, options)

  const mutate = async (
    args: { id: string } & MutationVariables,
    options?: MutationHookOptions
  ) => {
    const { id, ...input } = args
    return mutationFunction({
      ...options,
      variables: { id, input },
    })
  }

  useEffect(() => {
    if (error) {
      console.error(error)
      toast.error('Something went wrong.')
      Sentry.captureException(error)
    }
  }, [error])

  return {
    mutate,
    returnedData: data || null,
    loading,
    error,
    ...resultRest,
  }
}

export default useMutationUpdate
