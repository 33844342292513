import React from 'react'
import { useSelector, useDispatch } from 'react-redux'


import { closeModal } from 'src/slices/modal'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import DrawerHeader from 'src/components/MUI/Drawer/DrawerHeader'
import DrawerActions from 'src/components/MUI/Drawer/DrawerActions'
import Button from 'src/components/MUI/Button'

import AddressForm from './AddressForm' // Adjust import path as necessary

interface AddressEditorModalProps {
  onComplete?: (data: any) => void
}

const AddressEditorModal: React.FC<AddressEditorModalProps> = ({
  onComplete,
}) => {
  const dispatch = useDispatch()
  const isOpen =
    useSelector(
      (state: any) => state.modal.modals.addressEditorModal?.isOpen
    ) ?? false

  const addressId = useSelector(
    (state: any) => state.modal.modals.addressEditorModal?.data?.addressId
  )

  const discriminator: 'shippingAddress' | 'billingAddress' = useSelector(
    (state: any) => state.modal.modals.addressEditorModal?.data?.discriminator
  )

  const handleClose = () => {
    dispatch(closeModal({ name: 'addressEditorModal' }))
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <DrawerHeader
        title={addressId ? 'Edit Address' : 'Create Address'}
        onClose={handleClose}
      />
      <div className="h-full w-[800px] p-3">
        <AddressForm
          id={addressId}
          onClose={handleClose}
          onComplete={onComplete}
          discriminator={discriminator}
        />
      </div>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="text" color="black" onClick={handleClose}>
            CANCEL
          </Button>
        }
        rightActions={
          <Button
            type="submit"
            form="addressForm"
            variant="contained"
            color="primary"
          >
            {addressId ? 'SAVE' : 'CREATE'}
          </Button>
        }
      />
    </Drawer>
  )
}

export default AddressEditorModal
