import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Modal, Paper, Typography } from '@mui/material'
import { PurchaseOrderItem } from 'types/graphql'
import * as yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import {
  CREATE_AND_ADD_PART_TO_PURCHASE_ORDER,
  GET_PURCHASE_ORDER,
} from 'src/pages/PurchaseOrderPage/queries'
import { closeModal } from 'src/slices/modal'
import {
  dollarsToCents,
  isDecimalWithTwoOrLessPlacesNullable,
} from 'src/utils/helpers'

import DollarCentsTextField from './DollarCentsTextField'
import Button from './MUI/Button'
import Drawer from './MUI/Drawer/Drawer'
import DrawerHeader from './MUI/Drawer/DrawerHeader'
import DrawerActions from './MUI/Drawer/DrawerActions'
import SelectDropdown from './MUI/SelectDropdown'
import TextField from './MUI/TextField'

type AddPartLineItemModalProps = {
  purchaseOrderId?: string
  onComplete?: (item: PurchaseOrderItem) => void
  onRequest?: ({ description, quantity, partNumber }) => void
  /** Optionally define what happens when the user clicks backdrop, the close icon, or hits "escape" */
  onBail?: () => void
}

const modalName = 'addPartLineItemModal'

const schema = yup.object().shape({
  partNumber: yup.string().required('Part Number is required'),
  quantity: yup
    .number()
    .typeError('Quantity is required')
    .positive('Must be positive')
    .integer('Must be a whole number')
    .required('Quantity Required'),
  condition: yup.string().required('Condition is required'),
  cost: yup
    .number()
    .typeError('Cost is a number')
    .min(0, 'Must be positive')
    .test(
      'valid-decimal',
      'No more than 2 decimal points',
      isDecimalWithTwoOrLessPlacesNullable
    )
    .nullable(),
  description: yup.string(),
})

const AddPartLineItemModal = ({
  purchaseOrderId,
  onComplete,
  onRequest,
  onBail,
}: AddPartLineItemModalProps) => {
  const [createAndAddPartToPurchaseOrder] = useMutation(
    CREATE_AND_ADD_PART_TO_PURCHASE_ORDER,
    {
      update: (
        cache,
        { data: { createAndAddPartToPurchaseOrder: partLineItem } }
      ) => {
        const {
          purchaseOrder: { purchaseOrderItem: poItems },
        } = cache.readQuery({
          query: GET_PURCHASE_ORDER,
          variables: {
            id: purchaseOrderId,
          },
        })
        cache.writeQuery({
          query: GET_PURCHASE_ORDER,
          variables: {
            id: purchaseOrderId,
          },
          data: {
            purchaseOrder: {
              purchaseOrderItem: [...poItems, partLineItem],
            },
          },
        })
      },
      onCompleted: ({ createAndAddPartToPurchaseOrder }) =>
        onComplete?.(createAndAddPartToPurchaseOrder),
    }
  )
  const dispatch = useDispatch()
  const formMethods = useForm({ resolver: yupResolver(schema) })

  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  useEffect(() => {
    if (!open) {
      formMethods.reset({
        partNumber: '',
        condition: '',
        cost: null,
        description: '',
        quantity: '',
      })
    }
  }, [open, formMethods])

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }

  const handleBail = () => {
    handleClose()
    onBail?.()
  }

  const handleSubmit = async (values) => {
    await createAndAddPartToPurchaseOrder({
      variables: {
        input: {
          ...values,
          cost: dollarsToCents(parseFloat(values.cost)),
        },
        purchaseOrderId,
      },
    })
    handleClose()
    toast.success('Part added')
    onRequest?.(values)
  }

  return (
    <Drawer anchor="right" open={open} onClose={handleBail}>
      <DrawerHeader title="Add Part" onClose={handleBail} />
      <div className="h-full w-[800px] p-3">
        <Form
          id="addPartLineItemForm"
          className="h-full"
          formMethods={formMethods}
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-2">
            <Typography variant="caption" className="text-blueGrey-600">
              DETAILS
            </Typography>
            <div className="flex gap-2">
              <TextField
                label="Part Number"
                required
                name="partNumber"
                size="small"
              />
              <TextField
                label="Quantity"
                required
                name="quantity"
                type="number"
                size="small"
                inputProps={{ step: '1', min: 0 }}
              />
            </div>
            <div className="flex gap-2">
              <SelectDropdown
                name="condition"
                required
                label="Condition"
                fullWidth
                options={[
                  { value: 'NEW', label: 'New' },
                  { value: 'OVERHAULED', label: 'Overhauled' },
                  { value: 'REPAIRED', label: 'Repaired' },
                  { value: 'SERVICEABLE', label: 'Serviceable' },
                  { value: 'INSPECTED', label: 'Inspected' },
                ]}
              />
              <DollarCentsTextField
                name="cost"
                control={formMethods.control}
                label="Cost"
              />
            </div>
            <TextField
              label="Description"
              name="description"
              multiline
              fullWidth
              minRows={4}
            />
          </div>
        </Form>
      </div>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="text" color="black" onClick={handleBail}>
            CANCEL
          </Button>
        }
        rightActions={
          <Button
            type="submit"
            form="addPartLineItemForm"
            variant="contained"
            color="primary"
          >
            ADD PART
          </Button>
        }
      />
    </Drawer>
  )
}

export default AddPartLineItemModal
