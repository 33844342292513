import { PartsTransactionFieldsFragment } from 'src/pages/BulkCompliancePageV2/queries'

export const DELETE_PARTS_TRANSACTION = gql`
  mutation DeletePartsTransaction($id: String!) {
    deletePartsTransaction(id: $id) {
      id
    }
  }
`

export const UPSERT_PARTS_TRANSACTION = gql`
  ${PartsTransactionFieldsFragment}
  mutation UpsertPartsTransactionForWorkflowPartsTransactionSection(
    $id: String
    $input: UpsertPartsTransactionInput!
  ) {
    upsertPartsTransaction(id: $id, input: $input) {
      ...PartsTransactionFields
    }
  }
`
