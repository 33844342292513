import { SelectOption } from './components/MUI/SelectDropdown'

export const CADENCE_TYPE_TO_LABEL = {
  FLYING_HOURS: 'H',
  CYCLES: 'C',
  LANDINGS: 'L',
  CALENDAR_MONTHS: 'M',
  CALENDAR_DAYS: 'D',
}

export const CADENCE_TYPE_TO_FULL_LABEL = {
  FLYING_HOURS: 'Hours',
  CYCLES: 'Cycles',
  LANDINGS: 'Landings',
  CALENDAR_MONTHS: 'Months',
  CALENDAR_DAYS: 'Days',
  DATE: 'Date',
}

export const CADENCE_TYPE_TO_INTERVAL_KEY = {
  FLYING_HOURS: 'flying_hours',
  CYCLES: 'cycles',
  LANDINGS: 'landings',
  CALENDAR_MONTHS: 'months',
  CALENDAR_DAYS: 'days',
}

export const ATA_CODE_TYPES: string[] = [
  '00 - GENERAL',
  '01 - Reserved for Airline Use',
  '02 - Reserved For Airline Use',
  '03 - Reserved For Airline Use',
  '04 - Reserved For Airline Use',
  '05 - PERIODIC INSPECTIONS',
  '06 - DIMENSIONS AND AREAS',
  '07 - LIFTING AND SHORING',
  '08 - LEVELING AND WEIGHING',
  '09 - TOWING AND TAXIING',
  '10 - PARKING, MOORING, STORAGE, AND RETURN TO SERVICE',
  '11 - PLACARDS AND MARKINGS',
  '12 - SERVICING - ROUTINE MAINTENANCE',
  '18 - VIBRATION AND NOISE ANALYSIS (HELICOPTER ONLY)',
  '20 - STANDARD PRACTICES - AIRFRAME',
  '21 - AIR CONDITIONING',
  '22 - AUTO FLIGHT',
  '23 - COMMUNICATIONS',
  '24 - ELECTRICAL POWER',
  '25 - EQUIPMENT / FURNISHINGS',
  '26 - FIRE PROTECTION',
  '27 - FLIGHT CONTROLS',
  '28 - FUEL',
  '29 - HYDRAULIC POWER',
  '30 - ICE AND RAIN PROTECTION',
  '31 - INDICATING / RECORDING SYSTEMS',
  '32 - LANDING GEAR',
  '33 - LIGHTS',
  '34 - NAVIGATION',
  '35 - OXYGEN',
  '36 - PNEUMATIC',
  '37 - VACUUM',
  '38 - WATER / WASTE',
  '39 - ELECTRONIC PANELS AND MULTIPURPOSE COMPONENTS',
  '40 - MULTI-SYSTEM',
  '41 - WATER BALLAST',
  '42 - INTEGRATED MODULAR AVIONICS',
  '43 - TACTICAL COMMUNICATIONS',
  '44 - CABIN SYSTEMS',
  '45 - CENTRAL MAINTENANCE SYSTEM (CMS)',
  '46 - INFORMATION SYSTEMS',
  '47 - NITROGEN GENERATION SYSTEM',
  '49 - AIRBORNE AUXILIARY POWER',
  '50 - CARGO AND ACCESSORY COMPARTMENTS',
  '51 - STANDARD PRACTICES AND STRUCTURES - GENERAL',
  '52 - DOORS',
  '53 - FUSELAGE',
  '54 - NACELLES / PYLONS',
  '55 - STABILIZER',
  '56 - WINDOWS',
  '57 - WINGS',
  '60 - STANDARD PRACTICES - PROPELLER / ROTOR',
  '61 - PROPELLERS / PROPULSORS',
  '62 - MAIN ROTOR(S)',
  '63 - MAIN ROTOR DRIVE(S)',
  '64 - TAIL ROTOR65 - TAIL ROTOR DRIVE',
  '66 - ROTOR BLADE AND TAIL PYLON FOLDING',
  '67 - ROTORS FLIGHT CONTROL',
  '70 - STANDARD PRACTICES - ENGINE',
  '71 - POWER PLANT - GENERAL',
  '72 - ENGINE',
  '73 - ENGINE - FUEL AND CONTROL',
  '74 - IGNITION',
  '75 - BLEED AIR',
  '76 - ENGINE CONTROLS',
  '77 - ENGINE INDICATING',
  '78 - EXHAUST',
  '79 - OIL',
  '80 - STARTING',
  '81 - TURBINES (RECIPROCATING ENGINES)',
  '82 - WATER INJECTION',
  '83 - ACCESSORY GEAR BOXES (ENGINE DRIVEN)',
  '84 - PROPULSION AUGMENTATION',
  '85 - FUEL CELL SYSTEMS',
  '91 - CHARTS',
  '92 - ELECTRICAL SYSTEM INSTALLATION',
  '95 - Reserved For Airline Use',
  '96 - Reserved For Airline Use',
  '97 - ENGINE SERVICE BULLETINS',
  '98 - AIRFRAME SERVICE BULLETINS',
  '99 - AIRWORTHINESS DIRECTIVES',
]

export const ataCodeOptions: SelectOption[] = ATA_CODE_TYPES.map((code) => ({
  label: code,
  value: code.substring(0, 2),
}))

export const adSbTaskTypeOptions: SelectOption[] = [
  {
    label: 'DISCREPANCY',
    value: 'DISCREPANCY',
  },
  {
    label: 'MEL',
    value: 'MEL',
  },
  {
    label: 'NEF',
    value: 'NEF',
  },
  {
    label: 'AIRWORTHINESS DIRECTIVE',
    value: 'AIRWORTHINESS_DIRECTIVE',
  },
  {
    label: 'SERVICE BULLETIN',
    value: 'SERVICE_BULLETIN',
  },
  {
    label: 'SERVICE LETTER',
    value: 'SERVICE_LETTER',
  },
  {
    label: 'OTHER',
    value: 'OTHER',
  },
]

export const adSbStatusOptions: SelectOption[] = [
  {
    label: 'OPEN',
    value: 'OPEN',
  },
  {
    label: 'RECURRING',
    value: 'RECURRING',
  },
  {
    label: 'SUPERSEDED',
    value: 'SUPERSEDED',
  },
  {
    label: 'COMPLIED WITH',
    value: 'COMPLIED_WITH',
  },
  {
    label: 'NOT APPLICABLE',
    value: 'NOT_APPLICABLE',
  },
]

export const mtxItemStatusOptions: SelectOption[] = [
  {
    label: 'Enabled',
    value: 'ENABLED',
  },
  {
    label: 'Disabled',
    value: 'DISABLED',
  },
]

export const maintenanceTypeOptions: SelectOption[] = [
  {
    label: 'Inspection',
    value: 'INSPECTION',
  },
  {
    label: 'Part',
    value: 'PART',
  },
  {
    label: 'Unscheduled',
    value: 'UNSCHEDULED',
  },
]
