import { useCallback, useMemo } from 'react'
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { IconButton, Typography, Tooltip, Chip } from '@mui/material'
import { PurchaseOrderItem } from 'types/graphql'
import { useFormContext } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'
import { useDispatch } from 'src/hooks/useDispatch'
import { openModal } from 'src/slices/modal'
import { abbreviateFullName } from 'src/utils/stringFormatters'
import { getDefaultMRTOptions } from 'src/utils/getDefaultMRTOptions'
import Button from 'src/components/MUI/Button'
import TextField from 'src/components/MUI/TextField'
import DollarCentsTextField from 'src/components/DollarCentsTextField'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants'
import { REMOVE_ITEM_FROM_PURCHASE_ORDER } from './queries'

interface OtherLineItemsTableProps {
  otherLineItems: PurchaseOrderItem[]
}

const OtherLineItemsTable: React.FC<OtherLineItemsTableProps> = ({
  otherLineItems,
}) => {
  const dispatch = useDispatch()
  const formMethods = useFormContext()
  const { control } = formMethods
  const canAddItems = useHasPermission(Permissions.purchaseOrder.addItems)

  const [removeItemFromPurchaseOrder] = useMutation(
    REMOVE_ITEM_FROM_PURCHASE_ORDER,
    {
      onCompleted: () => {
        toast.success('Line Item removed from Purchase Order')
      },
      update: (cache, { data: { deletePurchaseOrderItem: task } }) => {
        cache.evict({
          id: cache.identify(task),
        })
      },
    }
  )

  const removeItem = useCallback(
    (id) => {
      removeItemFromPurchaseOrder({ variables: { id } })
      formMethods.setValue('other', (prev) => {
        const other = { ...prev }
        delete other[id]
        return other
      })
    },
    [formMethods, removeItemFromPurchaseOrder]
  )

  const columns = useMemo<MRT_ColumnDef<PurchaseOrderItem>[]>(
    () => [
      {
        accessorKey: 'description',
        header: 'Description',
        size: 600,
        Cell: ({ row }) => (
          <TextField
            name={`other.${row.original.id}.description`}
            label=""
            fullWidth
            multiline
            size="small"
            maxRows={2}
          />
        ),
      },
      {
        accessorKey: 'cost',
        header: 'Cost',
        size: 200,
        Cell: ({ row }) => (
          <DollarCentsTextField
            name={`other.${row.original.id}.cost`}
            control={control}
            label=""
          />
        ),
      },
      {
        accessorKey: 'requestedBy',
        header: 'Requested By',
        size: 200,
        Cell: ({ row }) => abbreviateFullName(row.original.requestedBy),
      },
      {
        id: 'actions',
        header: '',
        size: 75,
        Cell: ({ row }) => (
          <Tooltip title="Delete">
            <IconButton onClick={() => removeItem(row.original.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ),
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
    ],
    [control, removeItem]
  )

  const table = useMaterialReactTable({
    ...getDefaultMRTOptions(),
    columns,
    data: otherLineItems,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    layoutMode: 'grid-no-grow',
    state: {
      columnPinning: {
        right: ['actions'],
      },
    },
  })

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Typography variant="h6">Other</Typography>
        <Button
          variant="outlined"
          color="base"
          startIcon={<AddIcon />}
          onClick={() => dispatch(openModal({ name: 'addLineItemModal' }))}
          locked={!canAddItems}
          lockedTooltip="You do not have permission"
        >
          NEW LINE ITEM
        </Button>
      </div>
      <MaterialReactTable table={table} />
    </div>
  )
}

export default OtherLineItemsTable
