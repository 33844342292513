import React, { useState, useEffect } from 'react'

import { TextField, InputAdornment } from '@mui/material'

import { useFormContext } from '@redwoodjs/forms'

import { roundToPrecision } from 'src/utils/helpers'

const NumericAccruedTextField = ({
  name,
  label,
  initialValue,
  disabled,
  error,
  isAccruedMode,
  resetToggle,
  parentHandleChange,
  valueStepper,
  ...otherProps
}) => {
  const [startAdornment, setStartAdornment] = useState('')
  const [endAdornment, setEndAdornment] = useState('')
  const { setValue, watch } = useFormContext()
  const formValue = watch(name)
  const [formPreviouslyRendered, setFormPreviouslyRendered] = useState(false)

  useEffect(() => {
    // Set initial value or reset based on mode
    if (isAccruedMode) {
      setValue(name, 0)
    } else {
      setValue(name, initialValue)
    }
    updateAdornments(initialValue, isAccruedMode ? 0 : initialValue)
  }, [resetToggle]) // Empty array for initial render, resetToggle for resets

  useEffect(() => {
    if (formPreviouslyRendered) {
      if (isAccruedMode) {
        //Going from false to true
        const delta = roundToPrecision(parseFloat(formValue) - initialValue)
        setValue(name, delta)
      } else {
        //Going from true to false
        const total = roundToPrecision(parseFloat(formValue) + initialValue)
        setValue(name, total)
      }
    }
    setFormPreviouslyRendered(true)
    // When toggling mode, ensure we update adornments with the right value (current form value)
    updateAdornments(initialValue, formValue)

  }, [isAccruedMode])

  useEffect(() => {
    updateAdornments(initialValue, formValue)

  }, [initialValue, formValue])

  const updateAdornments = (baseValue, currentValue) => {
    if (isAccruedMode) {
      setStartAdornment(`${baseValue} + `)
      const accruedValue = parseFloat(currentValue || 0) || 0
      setEndAdornment(`= ${roundToPrecision(baseValue + accruedValue)}`)
    } else {
      setStartAdornment('')
      const delta = roundToPrecision(parseFloat(currentValue || 0) - baseValue)
      setEndAdornment(delta >= 0 ? `(+ ${delta})` : `(${delta})`)
    }
  }

  const handleChange = (event) => {
    const newValue = event.target.value
    setValue(name, newValue)
    otherProps?.onChange?.(event)
  }

  const handleKeyStroke = (event) => {
    const { key } = event
    const isDigit = key != ' ' && !isNaN(Number(key))
    const valueKey = key === '.' || isDigit
    const operationKey =
      event.keyCode === 8 || // backspace
      event.keyCode === 9 || // tab
      event.keyCode === 37 || // left arrow
      event.keyCode === 39 || // right arrow
      (event.keyCode === 9 && event.shiftKey) || // shift + tab, aka reverse tab
      (['r', 'x', 'c', 'v', 'a'].includes(key) &&
        (event.metaKey || event.ctrlKey)) // support refresh, cut, copy, paste

    const isAllowedKey = valueKey || operationKey

    // Only operation key is allowed after 2 decimal points
    if (
      event.target.value.indexOf('.') !== -1 &&
      event.target.value.split('.')[1].length > 1 &&
      !operationKey
    ) {
      event.preventDefault()
    }
    if (!isAllowedKey) {
      event.preventDefault()
    }
  }

  const handleBlur = (event) => {
    const finalValue = parseFloat(event.target.value) || 0
    // Calculate the processedValue (total value) based on mode
    const processedValue = isAccruedMode
      ? initialValue + finalValue
      : finalValue

    // Ensure the displayed value is correctly set
    setValue(name, finalValue)

    if (parentHandleChange) {
      parentHandleChange({
        target: {
          name,
          value: event.target.value,
          absoluteValue: processedValue,
          incrementValue: processedValue - initialValue,
          inputType: isAccruedMode ? 'increment' : 'absolute',
        },
      })
    }

    // Safely call onBlur from otherProps if it exists
    if (otherProps?.onBlur) {
      otherProps.onBlur(event)
    }
  }

  return (
    <TextField
      className="col-span-3"
      name={name}
      label={label}
      value={formValue}
      onKeyDown={handleKeyStroke}
      onChange={handleChange}
      onBlur={handleBlur}
      size="small"
      type="number"
      autoComplete="off"
      disabled={disabled}
      error={!!error}
      helperText={error || ''}
      slotProps={{
        input: {
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        },

        htmlInput: {
          step: valueStepper,
        },

        inputLabel: { shrink: true },
        formHelperText: { className: 'whitespace-pre-wrap' },
      }}
    />
  )
}

export default NumericAccruedTextField
