import { gql } from '@apollo/client'
import coreAircraftUsageLogFragment from 'src/fragments/AircraftUsageLog'
import {
  DueStatusChipFieldsFragment,
  DueStatusChipAircraftFieldsFragment,
} from 'src/components/DueStatusChip/queries'

const LaborTransactionFragment = gql`
  fragment LaborTransaction on LaborTransaction {
    id
    punchedInAt
    punchedOutAt
    notes
    description
    correctiveAction
    customInternalWorkItemId
    internalWorkItemId
    workedBy {
      id
      clerkId
      firstName
      lastName
    }
    lastUpdatedBy {
      id
      clerkId
      firstName
      lastName
    }
  }
`

const WorkOrderTaskTableDataFragment = gql`
  ${DueStatusChipFieldsFragment}
  fragment WorkOrderTaskTableData on InternalWorkItem {
    id
    status
    description
    correctiveAction
    estimatedWorkMinutes
    estimatedRate
    notes
    workedById
    estimatedWorkMinutes
    estimatedRate
    estimatedRateType
    workMinutes
    laborTransactionMinutes
    overrideLaborTransactionHours
    rate
    rateType
    partCost
    workedBy {
      id
      firstName
      lastName
    }
    nextDue {
      id
      nextDueType
      nextDueValue
      nextDueOverride
    }
    maintenanceItem {
      id
      ataCode
      manufactureCode
      title
      ataManufactureCode
      relatedMaintenanceItemIds
      componentID
      parentId
      isEndOfMonthAdjustment
      ...DueStatusChipFields
      trackedByComponent {
        id
        name
      }
      aircraftComponent {
        id
        name
      }
      aircraft {
        id
      }
    }
    discrepancyItems {
      id
      discrepancyStatus
      adSbType
      status
      title
      description
      notes
      discrepancyCompActivityId
      discrepancyWorkItemId
      tags {
        id
        name
      }
      trackedByComponent {
        id
        name
      }
      maintenanceNextDue {
        id
        nextDueValue
      }
      aircraftComponent {
        id
      }
    }
    laborTransactions {
      id
      punchedInAt
      punchedOutAt
      notes
      description
      correctiveAction
    }
    partsTransaction {
      id
      installedStatus
      otherInstalledStatus
      removalReason
      otherRemovalReason

      addedPartNumber
      addedSerialNumber
      removedPartNumber
      removedSerialNumber
    }
  }
`

const WorkOrderCustomTaskTableDataFragment = gql`
  ${LaborTransactionFragment}
  fragment WorkOrderCustomTaskTableData on CustomInternalWorkItem {
    id
    status
    title
    description
    correctiveAction
    notes
    isCustomWorkItem
    estimatedWorkMinutes
    estimatedRate
    estimatedRateType
    workMinutes
    rate
    rateType
    overrideLaborTransactionHours
    laborTransactionMinutes
    workedById
    workedBy {
      id
      firstName
      lastName
    }
    laborTransactions {
      ...LaborTransaction
    }
  }
`

const FileEntryFragment = gql`
  fragment FileEntry on FileEntry {
    id
    url
    originalFilename
    uploadedBy {
      id
      firstName
      lastName
    }
    createdAt
  }
`

const AttachedFileFragment = gql`
  ${FileEntryFragment}
  fragment AttachedFile on AttachedFile {
    id
    file {
      ...FileEntry
    }
  }
`

export const WorkOrderBaseFieldsFragment = gql`
  ${coreAircraftUsageLogFragment}
  fragment WorkOrderBaseFields on InternalWorkOrder {
    id
    workOrderNumber
    status
    createdAt
    updatedAt
    aircraftUsageLogId
    startedAt
    endedAt
    vendor
    shop
    estimatedLaborCost
    estimatedWorkMinutes
    name
    createdAt
    laborCost
    partCost
    aircraft {
      id
      tailNumber
    }
    aircraftUsageLog {
      ...CoreAircraftUsageLogFields
    }
    workItems {
      id
      status
    }
    customWorkItems {
      id
      status
    }
    complianceLedger {
      id
      workedById
      inspectedById
      requestedWorkerId
      requestedInspectorId
    }
    invoice {
      id
    }
  }
`

export const GET_WORK_ORDER_BASE_FIELDS = gql`
  ${WorkOrderBaseFieldsFragment}
  query GetWorkOrderBaseFields($id: String!) {
    internalWorkOrder(id: $id) {
      ...WorkOrderBaseFields
    }
  }
`

export const GET_WORK_ORDER_ATTACHMENTS = gql`
  ${AttachedFileFragment}
  query GetWorkOrderAttachments($id: String!) {
    internalWorkOrder(id: $id) {
      id
      attachedFiles {
        ...AttachedFile
      }
    }
  }
`

export const WORK_ORDER_AIRCRAFT = gql`
  ${DueStatusChipAircraftFieldsFragment}
  query WorkOrderAircraft($id: String!) {
    internalWorkOrder(id: $id) {
      id
      aircraft {
        ...DueStatusChipAircraftFields
      }
    }
  }
`

export const GET_WORK_ORDER_TASK_LIST = gql`
  ${WorkOrderTaskTableDataFragment}
  query GetWorkOrderTaskList($id: String!) {
    internalWorkItems(filters: { workOrderId: $id, omitChildren: true }) {
      ...WorkOrderTaskTableData
      childWorkItems {
        ...WorkOrderTaskTableData
      }
    }
  }
`

export const GET_WORK_ORDER_TASK_LIST_PAGINATED = gql`
  ${WorkOrderTaskTableDataFragment}
  query GetWorkOrderTaskListPaginated(
    $id: String!
    $limit: Int!
    $offset: Int!
  ) {
    internalWorkItemsPaginated(
      filters: { workOrderId: $id, omitChildren: true }
      limit: $limit
      offset: $offset
    ) {
      ...WorkOrderTaskTableData
      childWorkItems {
        ...WorkOrderTaskTableData
      }
    }
  }
`

export const GET_WORK_ORDER_CUSTOM_TASK_LIST = gql`
  ${WorkOrderCustomTaskTableDataFragment}
  query GetWorkOrderCustomTaskList($id: String!) {
    customInternalWorkItems(filters: { workOrderId: $id }) {
      ...WorkOrderCustomTaskTableData
    }
  }
`

export const GET_WORK_ORDER_TASK_DETAILS = gql`
  ${WorkOrderTaskTableDataFragment}
  ${LaborTransactionFragment}
  query GetWorkOrderTaskDetails($id: String!) {
    internalWorkItem(id: $id) {
      ...WorkOrderTaskTableData
      laborTransactions {
        ...LaborTransaction
      }
    }
  }
`
export const GET_WORK_ORDER_TASK_DETAILS_BY_MTX_ID = gql`
  ${WorkOrderTaskTableDataFragment}
  query GetWorkOrderTaskDetailsByMtxId($maintenanceItemIds: [String!]!) {
    internalWorkItems(filters: { maintenanceItemIds: $maintenanceItemIds }) {
      ...WorkOrderTaskTableData
      childWorkItems {
        ...WorkOrderTaskTableData
      }
    }
  }
`

export const GET_WORK_ORDER_CUSTOM_TASK_DETAILS = gql`
  ${WorkOrderCustomTaskTableDataFragment}
  ${LaborTransactionFragment}
  query GetWorkOrderCustomTaskDetails($id: String!) {
    customInternalWorkItem(id: $id) {
      ...WorkOrderCustomTaskTableData
      laborTransactions {
        ...LaborTransaction
      }
    }
  }
`

export const GET_INTERNAL_WORK_ITEM_ATTACHMENTS = gql`
  ${AttachedFileFragment}
  ${FileEntryFragment}
  query GetInternalWorkItemAttachments($id: String!) {
    internalWorkItem(id: $id) {
      id
      attachedFiles {
        id
        ...AttachedFile
      }
      maintenanceItem {
        id
        attachedFiles {
          id
          ...AttachedFile
        }
      }
      partsTransaction {
        id
        addedPartFiles {
          id
          ...FileEntry
        }
        removedPartFiles {
          id
          ...FileEntry
        }
      }
    }
  }
`

export const GET_CUSTOM_INTERNAL_WORK_ITEM_ATTACHMENTS = gql`
  query GetCustomInternalWorkItemAttachments($id: String!) {
    customInternalWorkItem(id: $id) {
      id
      attachedFiles {
        id
        file {
          id
          url
          originalFilename
          uploadedBy {
            id
            firstName
            lastName
          }
          createdAt
        }
      }
    }
  }
`

export const CREATE_CUSTOM_WORK_ITEM_MUTATION = gql`
  ${WorkOrderCustomTaskTableDataFragment}
  mutation CreateCustomWorkItemMutation(
    $input: CreateCustomInternalWorkItemInput!
  ) {
    createCustomInternalWorkItem(input: $input) {
      ...WorkOrderCustomTaskTableData
    }
  }
`

export const REMOVE_TASKS_FROM_WORK_ORDER = gql`
  mutation RemoveTasksFromWorkOrderV2($ids: [String!]!) {
    bulkDeleteInternalWorkItem(ids: $ids) {
      id
    }
  }
`

export const REMOVE_CUSTOM_TASKS_FROM_WORK_ORDER = gql`
  mutation RemoveCustomTasksFromWorkOrderV2($ids: [String!]!) {
    bulkDeleteCustomInternalWorkItem(ids: $ids) {
      id
    }
  }
`

export const BULK_UPDATE_CUSTOM_WORK_ITEM = gql`
  ${WorkOrderCustomTaskTableDataFragment}
  mutation BulkUpdateCustomInternalWorkItemV2(
    $ids: [String!]!
    $input: BulkUpdateCustomInternalWorkItemInput!
  ) {
    bulkUpdateCustomInternalWorkItem(ids: $ids, input: $input) {
      ...WorkOrderCustomTaskTableData
    }
  }
`

export const BULK_UPDATE_WORK_ITEM = gql`
  ${WorkOrderTaskTableDataFragment}
  mutation BulkUpdateInternalWorkItemV2(
    $ids: [String!]!
    $workOrderId: String
    $input: BulkUpdateWorkItemInput!
    $overrideOrAppend: OverrideOrAppendInput
    $updateFields: BulkEditTasksUpdateFieldsInput
  ) {
    bulkUpdateInternalWorkItem(
      ids: $ids
      input: $input
      workOrderId: $workOrderId
      overrideOrAppend: $overrideOrAppend
      updateFields: $updateFields
    ) {
      ...WorkOrderTaskTableData
      childWorkItems {
        ...WorkOrderTaskTableData
      }
    }
  }
`

export const UPDATE_WORK_ITEM = gql`
  ${WorkOrderTaskTableDataFragment}
  ${LaborTransactionFragment}
  mutation UpdateWorkItem($id: String!, $input: UpdateInternalWorkItemInput!) {
    updateInternalWorkItem(id: $id, input: $input) {
      ...WorkOrderTaskTableData
      laborTransactions {
        ...LaborTransaction
      }
    }
  }
`

export const UPDATE_CUSTOM_WORK_ITEM = gql`
  ${WorkOrderCustomTaskTableDataFragment}
  ${LaborTransactionFragment}
  mutation UpdateCustomWorkItem(
    $id: String!
    $input: UpdateCustomInternalWorkItemInput!
  ) {
    updateCustomInternalWorkItem(id: $id, input: $input) {
      ...WorkOrderCustomTaskTableData
      laborTransactions {
        ...LaborTransaction
      }
    }
  }
`

export const ADD_TASKS_TO_WORK_ORDER = gql`
  ${WorkOrderTaskTableDataFragment}
  mutation AddTasksToWorkOrder($workOrderId: String!, $ids: [String]) {
    addMaintenanceItemsToInternalWorkOrder(
      input: { internalWorkOrderId: $workOrderId, ids: $ids }
    ) {
      id
      workItems(filters: { omitChildren: true }) {
        ...WorkOrderTaskTableData
        childWorkItems {
          ...WorkOrderTaskTableData
        }
      }
    }
  }
`

export const UPDATE_WORK_ORDER = gql`
  ${WorkOrderBaseFieldsFragment}
  mutation UpdateWorkOrderV2(
    $id: String!
    $input: UpdateInternalWorkOrderInput!
  ) {
    updateInternalWorkOrder(id: $id, input: $input) {
      ...WorkOrderBaseFields
    }
  }
`

export const UPDATE_WORK_ORDER_STATUS = gql`
  mutation UpdateWorkOrderStatus($id: String!, $status: WorkOrderStatus!) {
    updateInternalWorkOrder(id: $id, input: { status: $status }) {
      id
      status
    }
  }
`
export const UPDATE_WORK_ITEM_STATUS = gql`
  ${WorkOrderTaskTableDataFragment}
  mutation UpdateWorkItemStatus($id: String!, $status: WorkItemStatus!) {
    updateInternalWorkItem(id: $id, input: { status: $status }) {
      ...WorkOrderTaskTableData
      childWorkItems {
        ...WorkOrderTaskTableData
      }
    }
  }
`

export const UPDATE_CUSTOM_WORK_ITEM_STATUS = gql`
  ${WorkOrderCustomTaskTableDataFragment}
  mutation UpdateCustomWorkItemStatus($id: String!, $status: WorkItemStatus!) {
    updateCustomInternalWorkItem(id: $id, input: { status: $status }) {
      ...WorkOrderCustomTaskTableData
    }
  }
`

export const CREATE_LABOR_TRANSACTION = gql`
  ${LaborTransactionFragment}
  mutation UpsertLaborTransaction(
    $id: String!
    $input: UpsertLaborTransactionInput!
  ) {
    upsertLaborTransaction(id: $id, input: $input) {
      ...LaborTransaction
    }
  }
`

export const PartsTransactionFieldsFragment = gql`
  fragment PartsTransactionFields on PartsTransaction {
    id
    removedPartNumber
    removedSerialNumber
    removedPartFiles {
      id
      url
      originalFilename
    }

    addedPartNumber
    addedSerialNumber
    addedPartFiles {
      id
      url
      originalFilename
    }

    removalReason
    otherRemovalReason
    installedStatus
    otherInstalledStatus

    monthsSinceNew
    monthsSinceOverhaul
    monthsSinceRepair
    hoursSinceNew
    hoursSinceOverhaul
    hoursSinceRepair
    cyclesSinceNew
    cyclesSinceOverhaul
    cyclesSinceRepair
  }
`

export const GET_WORK_ITEM_PARTS_TRANSACTION = gql`
  ${PartsTransactionFieldsFragment}
  query GetWorkItemPartsTransaction($id: String!) {
    internalWorkItem(id: $id) {
      id
      purchaseOrderItem {
        id
      }
      maintenanceItem {
        id
        aircraftComponent {
          id
          partNumber
          serialNumber
          monthsSinceNew
          monthsSinceOverhaul
          monthsSinceRepair
          hoursSinceNew
          hoursSinceOverhaul
          hoursSinceRepair
          cyclesSinceNew
          cyclesSinceOverhaul
          cyclesSinceRepair
        }
      }
      partsTransaction {
        ...PartsTransactionFields
      }
    }
  }
`
export const CREATE_WORK_ORDER_PDF = gql`
  mutation CreateWorkOrderPDF(
    $aircraftId: String!
    $workOrderId: String!
    $reportDateString: String
  ) {
    createWorkOrderPDF(
      aircraftId: $aircraftId
      workOrderId: $workOrderId
      reportDateString: $reportDateString
    )
  }
`
export const DELETE_WORK_ORDER = gql`
  mutation DeleteWorkOrder($id: String!) {
    deleteInternalWorkOrder(id: $id) {
      id
    }
  }
`
