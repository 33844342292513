import React, { useEffect, useMemo, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { useLazyQuery } from '@apollo/client'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import { IconButton, Paper, Typography } from '@mui/material'
import { nextDueValueSchema } from '@wingwork/common/src/jsonObjects'
import { getNextDueCadenceType } from '@wingwork/common/src/maintenanceItem'
import {
  InternalWorkItem,
  InternalWorkOrder,
  UpsertPartsTransactionInput,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import { useAuth } from 'src/auth'
import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import {
  CORE_INTERNAL_WORK_ITEM_FRAGMENT,
  INTERNAL_WORK_ORDER_TASKS,
} from 'src/components/Compliance/WorkOrderSelectedTasksManager/consts'
import Loading from 'src/components/Loading'
import Button from 'src/components/MUI/Button'
import ReusableTabs, { TabComponent } from 'src/components/MUI/Tabs'
import useCreateAircraftComponent from 'src/hooks/requests/useCreateAircraftComponent'
import useGetUsers from 'src/hooks/requests/useGetUsers'
import useUpdateAircraftComponent from 'src/hooks/requests/useUpdateAircraftComponent'
import useUpdateWorkItem from 'src/hooks/requests/useUpdateWorkItem'
import useUploadFile from 'src/hooks/requests/useUploadFile'
import { useDispatch } from 'src/hooks/useDispatch'
import { useOrgName } from 'src/hooks/useOrgName'
import { openModal } from 'src/slices/modal'
import { FormSubmitRef } from 'src/types'
import {
  capitalizeFirstLetter,
  centsToDollars,
  dollarsToCents,
  findChanges,
  hoursToMinutes,
  minutesToHours,
  minutesToHoursStr,
  titleCase,
} from 'src/utils/helpers'

import AdditionalTab from './AdditionalTab'
import DiscrepanciesTab from './DiscrepanciesTab'
import ItemInformationForm from './ItemInformationForm'
import PartsForm, { GET_PARTS_FOR_WORK_ITEM } from './PartsForm'
import useModal from 'src/hooks/useModal'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useSelector } from 'src/hooks/useSelector'

export const UPSERT_MTX_NEXT_DUE = gql`
  mutation UpsertMtxNextDueInWorkOrderComplianceEditor(
    $id: String!
    $input: UpsertMaintenanceNextDueInput!
  ) {
    upsertMaintenanceNextDue(id: $id, input: $input) {
      id
      isCompleted
      nextDueValue
      nextDueType
      nextDueOverride
      nextDueOverrideType
      nextDueOverrideBy
    }
  }
`

export const UPSERT_LABOR_TRANSACTION = gql`
  mutation UpsertLaborTransactionV1(
    $id: String!
    $input: UpsertLaborTransactionInput!
  ) {
    upsertLaborTransaction(id: $id, input: $input) {
      id
      punchedInAt
      punchedOutAt
      notes
      description
      correctiveAction
      customInternalWorkItemId
      internalWorkItemId
      workedBy {
        id
        clerkId
        firstName
        lastName
      }
    }
  }
`

export const UPSERT_PARTS_TRANSACTION = gql`
  mutation UpsertPartsTransactionForEditorRoot(
    $id: String
    $input: UpsertPartsTransactionInput!
  ) {
    upsertPartsTransaction(id: $id, input: $input) {
      id
    }
  }
`

const FETCH_SELECTED_WORK_ITEM = gql`
  ${CORE_INTERNAL_WORK_ITEM_FRAGMENT}
  query GetSelectedWorkItem($id: String!) {
    internalWorkItem(id: $id) {
      ...CoreWorkItemFields
    }
  }
`

const TaskForm = ({
  selectedWorkItemId,
  workOrder,
  hasFoundErrors,
  refetch,
  setClickedTask,
  taskInQueue,
  resetTaskInQueue,
  hasAppliedTimes,
}: {
  selectedWorkItemId: string
  workOrder: InternalWorkOrder
  hasFoundErrors: boolean
  refetch: () => void
  setClickedTask: (task: InternalWorkItem) => void
  taskInQueue: InternalWorkItem
  resetTaskInQueue: () => void
  hasAppliedTimes?: boolean
}) => {
  const { handleOpen: openWorkCardModal } = useModal('printWorkCardModal')
  const [hasOpenDiscrepancies, setHasOpenDiscrepancies] = React.useState(false)
  const [hasIncompleteLaborTransaction, setHasIncompleteLaborTransaction] =
    React.useState(false)
  const client = useApolloClient()
  const dispatch = useDispatch()
  const orgSlug = useOrgName()
  const [workItem, setWorkItem] = useState<InternalWorkItem>()
  const [
    currentUserInProgressLaborTransaction,
    setCurrentUserInProgressLaborTransaction,
  ] = useState(null)
  const isComplianceInProgressByCurrentUser =
    currentUserInProgressLaborTransaction !== null

  const [workInformationErrors, setWorkInformationErrors] = React.useState(null)
  const [partAndLaborErrors, setPartAndLaborErrors] = React.useState(null)
  const [additionalErrors, setAdditionalErrors] = React.useState(null)

  const { data: users } = useGetUsers()
  const { currentUser } = useAuth()
  const currentUserFromUser = useMemo(
    () => users?.users.find((user) => user.clerkId === currentUser?.providerId),
    [users, currentUser]
  )

  const [fetchSelectedWorkItem, { refetch: refetchSelectedWorkItem }] =
    useLazyQuery(FETCH_SELECTED_WORK_ITEM, {
      fetchPolicy: 'cache-first',
      onCompleted(data) {
        setWorkItem(data?.internalWorkItem)
      },
    })
  const [upsertMaintenanceNextDue] = useMutation(UPSERT_MTX_NEXT_DUE)
  const { mutate: updateAircraftComponent } = useUpdateAircraftComponent()
  const [createAircraftComponent] = useCreateAircraftComponent()
  const [upsertLaborTransaction] = useMutation(UPSERT_LABOR_TRANSACTION)

  useEffect(() => {
    fetchSelectedWorkItem({
      variables: { id: selectedWorkItemId },
    })
    setWorkInformationErrors(null)
    setPartAndLaborErrors(null)
    setAdditionalErrors(null)
  }, [selectedWorkItemId])

  const workInformationFormRef = React.useRef<FormSubmitRef>(null)
  const partAndLaborFormRef = React.useRef<FormSubmitRef>(null)
  const additionalFormRef = React.useRef<FormSubmitRef>(null)

  const workInformationDataRef = React.useRef(null)
  const partAndLaborDataRef = React.useRef(null)
  const additionalDataRef = React.useRef(null)

  const { mutate: updateWorkItem } = useUpdateWorkItem({
    refetchQueries: [
      { query: INTERNAL_WORK_ORDER_TASKS, variables: { id: workOrder.id } },
    ],
    awaitRefetchQueries: true,
  })
  const uploadFile = useUploadFile()
  const [upsertPartsTransaction] = useMutation(UPSERT_PARTS_TRANSACTION)
  useEffect(() => {
    // This effect should not be called before workItem is set.
    if (workItem === undefined) return
    if (hasAppliedTimes) {
      if (
        //Only for internal because, external discrepancies are always CLOSED
        workItem.discrepancyItems?.some(
          (discrepancy) => discrepancy.discrepancyStatus === 'OPEN'
        )
      ) {
        setHasOpenDiscrepancies(true)
        if (workItem.status === 'REVIEW') {
          updateWorkItem({ id: workItem.id, status: 'IN_PROGRESS' })
        }
      } else {
        setHasOpenDiscrepancies(false)
      }

      if (
        workItem.laborTransactions.some((item) => item.punchedOutAt === null)
      ) {
        setHasIncompleteLaborTransaction(true)
        if (workItem.status === 'REVIEW') {
          updateWorkItem({ id: workItem.id, status: 'IN_PROGRESS' })
        }
      } else {
        setHasIncompleteLaborTransaction(false)
      }
    }
    const currentUserInProgressLaborTransaction =
      workItem.laborTransactions.find((item) => {
        return (
          item.punchedOutAt === null &&
          item.workedBy?.clerkId === currentUser?.providerId
        )
      }) ?? null
    setCurrentUserInProgressLaborTransaction(
      currentUserInProgressLaborTransaction
    )
  }, [workItem])

  const handlePartSubmit = async (values) => {
    if (!values.parts.removalReason && !values.parts.installedStatus) {
      return
    }

    if (
      (values.parts.removed.partNumber || values.parts.removed.serialNumber) &&
      !values.parts.removalReason
    ) {
      return
    }

    const input: UpsertPartsTransactionInput = {
      // Time since values are always included
      monthsSinceNew: parseFloat(values.parts.monthsSinceNew) || -1,
      monthsSinceOverhaul: parseFloat(values.parts.monthsSinceOverhaul) || -1,
      monthsSinceRepair: parseFloat(values.parts.monthsSinceRepair) || -1,
      hoursSinceNew: parseFloat(values.parts.hoursSinceNew) || -1,
      hoursSinceOverhaul: parseFloat(values.parts.hoursSinceOverhaul) || -1,
      hoursSinceRepair: parseFloat(values.parts.hoursSinceRepair) || -1,
      cyclesSinceNew: parseFloat(values.parts.cyclesSinceNew) || -1,
      cyclesSinceOverhaul: parseFloat(values.parts.cyclesSinceOverhaul) || -1,
      cyclesSinceRepair: parseFloat(values.parts.cyclesSinceRepair) || -1,
      workItemId: workItem.id,
    }

    // Only include removed part info if removal reason is set
    if (values.parts.removalReason) {
      input.removedPartNumber = values.parts.removed.partNumber
      input.removedSerialNumber = values.parts.removed.serialNumber
      input.removalReason = values.parts.removalReason
      input.removedId = workItem.maintenanceItem?.aircraftComponent?.id
    }

    // Only include added part info if installed status is set
    if (values.parts.installedStatus) {
      input.addedPartNumber = values.parts.added.partNumber
      input.addedSerialNumber = values.parts.added.serialNumber
      input.installedStatus = values.parts.installedStatus
    }

    try {
      const result = await upsertPartsTransaction({
        variables: {
          id: workItem.partsTransaction?.id ?? null,
          input,
        },
      })

      const transactionId = result?.data?.upsertPartsTransaction?.id

      // Handle file uploads after transaction is created/updated
      if (
        transactionId &&
        values.parts.removedPartFile?.length &&
        values.parts.removalReason
      ) {
        await Promise.all(
          values.parts.removedPartFile.map((file) =>
            uploadFile(file, [
              {
                id: transactionId,
                type: 'RemovedPartTransaction',
              },
            ])
          )
        )
      }

      if (
        transactionId &&
        values.parts.addedPartFile?.length &&
        values.parts.installedStatus
      ) {
        await Promise.all(
          values.parts.addedPartFile.map((file) =>
            uploadFile(file, [
              {
                id: transactionId,
                type: 'AddedPartTransaction',
              },
            ])
          )
        )
      }

      return transactionId
    } catch (error) {
      toast.error('Failed to save parts transaction')
      return null
    }
  }

  const handleWorkInformationSubmit = async (values) => {
    workInformationDataRef.current = values
    setWorkInformationErrors(null)
  }

  const handlePartAndLaborSubmit = async (values) => {
    partAndLaborDataRef.current = {
      ...values,
      workItem: {
        ...values.workItem,
        rate: values.workItem.rate
          ? dollarsToCents(parseFloat(values.workItem.rate))
          : 0,
        workMinutes: hoursToMinutes(parseFloat(values.workItem.workHours)),
        partCost: values.workItem.partCost
          ? dollarsToCents(parseFloat(values.workItem.partCost))
          : 0, // Because this is a required field
      },
    }
    setPartAndLaborErrors(null)
  }

  const handleAdditionalSubmit = async (values) => {
    additionalDataRef.current = {
      workItem: {
        ...values.workItem,
        additionalNotes: values.workItem.additionalNotes ?? null,
      },
    }
    setAdditionalErrors(null)
  }

  const triggerAllValidationsAndUpdateErrors = async () => {
    await workInformationFormRef.current?.handleSubmit()
    await partAndLaborFormRef.current?.handleSubmit()
    await additionalFormRef.current?.handleSubmit()
  }

  const handleAllSubmit = async (isReadyForReview = false) => {
    let changesMade = false
    const workInformationSuccess =
      await workInformationFormRef.current?.handleSubmit()
    const partAndLaborSuccess =
      await partAndLaborFormRef.current?.handleSubmit()
    const additionalSuccess = await additionalFormRef.current?.handleSubmit()
    if (!workInformationSuccess || !partAndLaborSuccess || !additionalSuccess) {
      toast.error('Please fix errors before submitting')
      return false
    }
    const partsTransactionId = await handlePartSubmit(
      partAndLaborDataRef.current
    )

    const savedData = {
      partsTransactionId: workItem.partsTransaction?.id,
    }

    Object.keys(workInformationDataRef.current?.workItem).forEach((key) => {
      savedData[key] = workItem[key]
    })
    Object.keys(partAndLaborDataRef.current?.workItem).forEach((key) => {
      savedData[key] = workItem[key]
    })

    Object.keys(additionalDataRef.current?.workItem).forEach((key) => {
      savedData[key] = workItem[key]
    })

    const newData = {
      ...workInformationDataRef.current?.workItem,
      ...partAndLaborDataRef.current?.workItem,
      ...additionalDataRef.current?.workItem,
      partsTransactionId,
    }

    const activityChanges = findChanges(savedData, newData)
    const statusData: any = isReadyForReview
      ? { status: 'REVIEW', workedById: currentUserFromUser?.id }
      : {}
    const dataToSave = {
      ...activityChanges,
      ...statusData,
    }
    if (Object.keys(dataToSave).length > 0) {
      changesMade = true
    }
    await updateWorkItem(
      {
        ...dataToSave,
        id: workItem.id,
      },
      {
        onError(_) {
          toast.error('Error updating task')
          return false
        },
      }
    )
    const nextDueChanges = findChanges(
      workItem.nextDue?.nextDueOverride ?? {},
      workInformationDataRef.current?.nextDue?.nextDueOverride ?? {}
    )
    const cleanedNextDueChanges = nextDueValueSchema.cast(nextDueChanges)

    if (Object.keys(cleanedNextDueChanges).length > 0) {
      changesMade = true
      const nextDue = workItem?.nextDue
      const nextDueId = nextDue?.id
      const nextDueOverride = {
        ...((workItem.nextDue?.nextDueOverride ?? {}) as any),
        ...cleanedNextDueChanges,
      }
      upsertMaintenanceNextDue({
        variables: {
          input: {
            nextDueOverride,
            isCompleted: false, // isCompleted is always false for new/active next due
            nextDueType: getNextDueCadenceType(nextDueOverride),
            verifyPin: false,
            nextDueValue: nextDue?.nextDueValue,
            complianceActivityId: workItem.id,
          },
          id: nextDueId,
        },
        onError: (_) => {
          toast.error('Error updating next due, please try again')
          return false
        },
      })
    }

    const workOrderCache = client.readQuery({
      query: INTERNAL_WORK_ORDER_TASKS,
      variables: { id: workOrder.id },
    })
    const updatedWorkItem = workOrderCache.internalWorkOrder.workItems.find(
      (item) => item.id === selectedWorkItemId
    )
    setWorkItem(updatedWorkItem)
    if (changesMade) {
      toast.success('Task Updated')
    } else {
      toast.success('Task already up to date')
    }
    refetch() // refetch work Order data to refresh the grandTotalCost in WorkOrderOverviewCard and to rerun logic to conditionally display Review Tasks button
    return true
  }

  const onError = (formName: string, errors: any) => {
    switch (formName) {
      case 'workInformation':
        setWorkInformationErrors(errors)
        break
      case 'partAndLabor':
        setPartAndLaborErrors(errors)
        break
      case 'additional':
        setAdditionalErrors(errors)
        break
      default:
        break
    }
    if (workItem.status === 'REVIEW') {
      updateWorkItem({ id: workItem.id, status: 'IN_PROGRESS' })
    }
  }

  useEffect(() => {
    if (workItem && hasFoundErrors) {
      triggerAllValidationsAndUpdateErrors()
    }
  }, [workItem, hasFoundErrors])

  const getEstimatedCostStr = () => {
    const estWorkHours = workItem.estimatedWorkMinutes
      ? parseFloat(minutesToHours(workItem.estimatedWorkMinutes))
      : 0
    const rate = workItem.estimatedRate
      ? parseFloat(centsToDollars(workItem.estimatedRate))
      : 0
    const rateType = workItem.estimatedRateType

    if (!rate || !rateType) return '----'

    switch (rateType) {
      case 'FLAT_RATE':
        return `$${rate}`
      case 'HOURLY_RATE':
        return `$${(estWorkHours * rate).toFixed(2)}`
      default:
        return '----'
    }
  }

  const getActualCostStr = () => {
    const actualWorkHours = workItem.workMinutes
      ? parseFloat(minutesToHours(workItem.workMinutes))
      : 0
    const rate = workItem.rate ? parseFloat(centsToDollars(workItem.rate)) : 0
    const rateType = workItem.rateType
    const partCost = workItem.partCost
      ? parseFloat(centsToDollars(workItem.partCost))
      : 0

    if (!rate || !rateType) return '----'

    switch (rateType) {
      case 'FLAT_RATE':
        return `$${(rate + partCost).toFixed(2)}`
      case 'HOURLY_RATE':
        return `$${(actualWorkHours * rate + partCost).toFixed(2)}`
      default:
        return '----'
    }
  }

  const toggleLaborTransaction = async () => {
    if (isComplianceInProgressByCurrentUser) {
      dispatch(
        openModal({
          name: 'saveLaborTransactionModal',
          data: {
            laborTransaction: currentUserInProgressLaborTransaction,
            variant: 'punchOut',
          },
        })
      )
    } else {
      // If compliance is not in progress, start it
      await upsertLaborTransaction({
        variables: {
          id: '',
          input: {
            punchedInAt: new Date().toISOString(),
            internalWorkItemId: selectedWorkItemId,
          },
        },
      })
      if (workItem.status === 'REVIEW') {
        await updateWorkItem({ id: selectedWorkItemId, status: 'IN_PROGRESS' })
      }
      await refetchSelectedWorkItem()
    }
  }

  const getDisabledReadyForReviewTooltip = () => {
    if (!hasAppliedTimes) {
      return 'Apply times first'
    }
    if (hasIncompleteLaborTransaction) {
      return 'End compliance timer before marking for review'
    }
    return 'Task has open discrepancies'
  }

  useEffect(() => {
    // when switching from another task, check if there are unsaved changes to save then switch
    if (!taskInQueue) return
    if (
      partAndLaborFormRef.current?.isDirty ||
      workInformationFormRef.current?.isDirty ||
      additionalFormRef.current?.isDirty
    ) {
      handleAllSubmit(false).then((noErrors) => {
        if (noErrors) {
          setClickedTask(taskInQueue)
        } else {
          resetTaskInQueue()
        }
      })
    } else {
      setClickedTask(taskInQueue)
    }
  }, [taskInQueue])

  const aircraftId = useSelector((state) => state.auditLog.aircraftId)
  const canUpdateWorkOrder = useHasPermission(
    Permissions.workOrder.update,
    aircraftId
  )
  const canReadMtxItem = useHasPermission(
    Permissions.maintenanceItem.read,
    aircraftId
  )

  if (!workItem || !selectedWorkItemId) return <Loading />

  return (
    <Paper className="rounded-2xl">
      <div className="p-2">
        <div className="mb-1 flex items-center justify-between">
          <Typography variant="h6">Maintenance Task</Typography>
          <div className="flex gap-1">
            <Button
              size="small"
              startIcon={
                isComplianceInProgressByCurrentUser ? (
                  <PendingActionsOutlinedIcon />
                ) : (
                  <TimerOutlinedIcon />
                )
              }
              onClick={toggleLaborTransaction}
              locked={
                !useHasPermission(
                  Permissions.workOrder.performWorkItem,
                  aircraftId
                )
              }
              lockedTooltip="You do not have permission"
            >
              {isComplianceInProgressByCurrentUser ? 'Clock-Out' : 'Clock-In'}
            </Button>
            <Button
              variant="outlined"
              color="base"
              size="small"
              onClick={() => {
                dispatch(
                  openModal({
                    name: 'complianceEventLogsModal',
                    data: {
                      targetId: selectedWorkItemId,
                      discriminator: 'InternalWorkItem',
                    },
                  })
                )
              }}
            >
              Activity Log
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-2">
          <PrimSecTextCombo
            primaryText="----"
            secondaryText="Registration Number"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={titleCase(workItem.maintenanceItem.maintenanceType)}
            secondaryText="Item Type"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={workItem.maintenanceItem.ataCode}
            secondaryText="ATA Code"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText="----"
            secondaryText="Reference"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={capitalizeFirstLetter(
              titleCase(workItem.maintenanceItem.trackedByComponent?.name)
            )}
            secondaryText="Tracked By"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={
              titleCase(workItem.maintenanceItem.disposition) || '----'
            }
            secondaryText="Disposition"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={workItem.maintenanceItem.manufactureCode}
            secondaryText="MFG Code"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText="----"
            secondaryText="Requirement Type"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={getEstimatedCostStr()}
            secondaryText="Estimated Cost"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={getActualCostStr()}
            secondaryText="Actual Cost (Incl Part & Additional)"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={
              workItem.workedById
                ? `${workItem.workedBy?.firstName} ${workItem.workedBy?.lastName}`
                : '----'
            }
            secondaryText="Mechanic"
            variant="inverted"
          />
          <div className="flex  justify-between">
            <PrimSecTextCombo
              primaryText={minutesToHoursStr(workItem.workMinutes)}
              secondaryText="Total Work Hours"
              variant="inverted"
            />
            <div>
              <IconButton
                onClick={() => {
                  dispatch(
                    openModal({
                      name: 'laborTransactionsModal',
                      data: {
                        targetId: selectedWorkItemId,
                        discriminator: 'InternalWorkItem',
                      },
                    })
                  )
                }}
                size="small"
                className="rounded border border-solid"
              >
                <PendingActionsOutlinedIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <ReusableTabs variant="fullWidth" className="mt-3">
        <TabComponent
          label="Work Information"
          iconPosition="end"
          icon={workInformationErrors && <ErrorOutlinedIcon color="error" />}
        >
          <ItemInformationForm
            ref={workInformationFormRef}
            workItem={workItem}
            handleSubmit={handleWorkInformationSubmit}
            onError={onError}
          />
        </TabComponent>
        <TabComponent
          label="Parts & Labor"
          iconPosition="end"
          icon={partAndLaborErrors && <ErrorOutlinedIcon color="error" />}
        >
          <PartsForm
            ref={partAndLaborFormRef}
            workItem={workItem}
            handleSubmit={handlePartAndLaborSubmit}
            onError={onError}
          />
        </TabComponent>
        <TabComponent
          label="Additional"
          iconPosition="end"
          icon={additionalErrors && <ErrorOutlinedIcon color="error" />}
        >
          <AdditionalTab
            ref={additionalFormRef}
            workItem={workItem}
            handleSubmit={handleAdditionalSubmit}
            onError={onError}
          />
        </TabComponent>
        <TabComponent
          label="Discrepancies"
          iconPosition="end"
          icon={hasOpenDiscrepancies && <ErrorOutlinedIcon color="error" />}
        >
          <DiscrepanciesTab
            internalWorkItem={workItem}
            aircraftUsageLogId={workOrder?.aircraftUsageLogId}
            refreshWorkOrder={refetch}
          />
        </TabComponent>
      </ReusableTabs>
      <div className="flex w-full justify-end gap-2 rounded-b-2xl bg-[#E4E4E4] px-3 py-2">
        <Button
          onClick={() => {
            if (canUpdateWorkOrder) {
              handleAllSubmit().then(() => {
                openWorkCardModal({ ids: workItem.id })
              })
            } else {
              openWorkCardModal({ ids: workItem.id })
            }
          }}
          variant="outlined"
          color="base"
          locked={!canReadMtxItem}
          lockedTooltip="You do not have permission"
        >
          Print Work Card
        </Button>
        <Button
          variant="outlined"
          color="base"
          onClick={() => {
            handleAllSubmit()
          }}
          locked={!canUpdateWorkOrder}
          lockedTooltip="You do not have permission to update this work order"
        >
          Save
        </Button>
        <Button
          disabled={
            !hasAppliedTimes ||
            hasOpenDiscrepancies ||
            hasIncompleteLaborTransaction
          }
          disabledTooltip={getDisabledReadyForReviewTooltip()}
          onClick={() => {
            handleAllSubmit(true)
          }}
          locked={!canUpdateWorkOrder}
          lockedTooltip="You do not have permission to update this work order"
        >
          Ready for review
        </Button>
      </div>
    </Paper>
  )
}

export default TaskForm
