import { CircularProgress, Typography } from '@mui/material'
const Loading = ({
  extraMessage,
  className,
}: {
  extraMessage?: string
  className?: string
}) => (
  <div
    className={`flex h-full w-full flex-col items-center justify-center gap-2 ${
      className ?? ''
    }`}
  >
    <CircularProgress />
    <Typography>Loading...</Typography>
    {extraMessage && <Typography>{extraMessage}</Typography>}
  </div>
)

export default Loading
