import Button from 'src/components/MUI/Button'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { Typography, IconButton, Divider } from '@mui/material'
import useModal from 'src/hooks/useModal'
import { useCallback, useState } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { abbreviateFullName } from 'src/utils/stringFormatters'
import { CustomInternalWorkItem, InternalWorkItem } from 'types/graphql'
import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import { minutesToHoursStr } from 'src/utils/helpers'
import WorkPerformedDrawer, {
  WorkPerformedFormData,
} from 'src/pages/WorkOrderCompliancePageV2/components/WorkPerformedDrawer'
import { useMutation } from '@redwoodjs/web'
import { UPDATE_WORK_ITEM, UPDATE_CUSTOM_WORK_ITEM } from '../../queries'

interface WorkPerformedSectionProps {
  id: string
  workItem: InternalWorkItem | CustomInternalWorkItem
  isCustomWorkItem?: boolean
  flashSaved?: () => void
  flashError?: () => void
}

interface WorkRowProps {
  workData: InternalWorkItem
  taskId: string
  onEdit: () => void
}

const WorkRow = ({ workData, taskId, onEdit }: WorkRowProps) => {
  const [expanded, setExpanded] = useState(false)
  const { workedBy, workMinutes, notes, description, correctiveAction } =
    workData

  return (
    <div
      className="cursor-pointer rounded-md border border-solid border-gray-200"
      onClick={() => setExpanded(!expanded)}
    >
      <div className="grid grid-cols-5 items-center gap-4 p-2">
        <PrimSecTextCombo
          primaryText={description}
          secondaryText="Description"
          variant="inverted"
          primaryTextClassName="text-ellipsis overflow-hidden whitespace-nowrap"
        />

        <PrimSecTextCombo
          primaryText={correctiveAction || 'N/A'}
          secondaryText="Corrective Action"
          variant="inverted"
          primaryTextClassName="text-ellipsis overflow-hidden whitespace-nowrap"
        />

        <PrimSecTextCombo
          primaryText={minutesToHoursStr(workMinutes)}
          secondaryText="Time Worked"
          variant="inverted"
        />

        <PrimSecTextCombo
          primaryText={abbreviateFullName(workedBy)}
          secondaryText="Worked By"
          variant="inverted"
        />

        <div className="flex items-center gap-1">
          <Button
            variant="text"
            color="primary"
            onClick={(e) => {
              e.stopPropagation()
              onEdit()
            }}
          >
            Edit
          </Button>

          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setExpanded(!expanded)
            }}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
      </div>

      {expanded && (
        <>
          <Divider variant="middle" />
          <div className="p-2">
            <div className="flex flex-col gap-1">
              <div className="grid grid-cols-12 gap-2 break-words">
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="col-span-3"
                >
                  Description
                </Typography>
                <Typography variant="body2" className="col-span-9">
                  {description}
                </Typography>
              </div>
              <div className="grid grid-cols-12 gap-2 break-words">
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="col-span-3"
                >
                  Corrective Action
                </Typography>
                <Typography variant="body2" className="col-span-9">
                  {correctiveAction}
                </Typography>
              </div>
              <div className="grid grid-cols-12 gap-2 break-words">
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="col-span-3"
                >
                  Notes
                </Typography>
                <Typography variant="body2" className="col-span-9">
                  {notes}
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const WorkPerformedSection = ({
  id: workItemId,
  workItem,
  isCustomWorkItem = false,
  flashSaved,
  flashError,
}: WorkPerformedSectionProps) => {
  const { handleOpen: handleOpenWorkPerformed } = useModal(
    'workPerformedDrawer'
  )

  const [updateWorkItem] = useMutation(UPDATE_WORK_ITEM)
  const [updateCustomWorkItem] = useMutation(UPDATE_CUSTOM_WORK_ITEM)
  const handleWorkPerformed = useCallback(
    (data: WorkPerformedFormData) => {
      const mutationData = {
        workedById: data.workedById,
        notes: data.notes,
        description: data.description,
        correctiveAction: data.correctiveAction,
        ...(data.overrideLaborTransactionHours && {
          overrideLaborTransactionHours: data.overrideLaborTransactionHours,
          workMinutes: data.workMinutes,
        }),
      }
      if (isCustomWorkItem) {
        updateCustomWorkItem({
          variables: {
            id: workItemId,
            input: mutationData,
          },
          onCompleted: () => {
            flashSaved?.()
          },
          onError: () => {
            flashError?.()
          },
        })
      } else {
        updateWorkItem({
          variables: {
            id: workItemId,
            input: mutationData,
          },
          onCompleted: () => {
            flashSaved()
          },
          onError: () => {
            flashError()
          },
        })
      }
    },
    [workItemId, isCustomWorkItem]
  )

  const hasWorkDetails =
    workItem?.description ||
    workItem?.correctiveAction ||
    workItem?.notes ||
    workItem?.workedBy?.id

  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex items-center justify-between">
        <Typography variant="h6">Work Performed</Typography>
        {!hasWorkDetails ? (
          <Button
            startIcon={<AddCircleOutlineRoundedIcon />}
            variant="text"
            color="primary"
            onClick={handleOpenWorkPerformed}
          >
            Add Work
          </Button>
        ) : null}
      </div>
      {hasWorkDetails ? (
        <WorkRow
          workData={workItem as any}
          taskId={workItemId}
          onEdit={handleOpenWorkPerformed}
        />
      ) : (
        <Typography variant="body2">No work details yet</Typography>
      )}

      <WorkPerformedDrawer
        onSubmit={handleWorkPerformed}
        initialValues={{
          workedById: workItem?.workedBy?.id,
          workMinutes: workItem?.workMinutes,
          notes: workItem?.notes,
          description: workItem?.description,
          correctiveAction: workItem?.correctiveAction,
          overrideLaborTransactionHours:
            workItem?.overrideLaborTransactionHours,
        }}
      />
      <Divider className="mb-2 border-b-2" />
    </div>
  )
}

export default WorkPerformedSection
