import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'

import { PARTS_TRANSACTION_FRAGMENT } from './ComplianceEditor/PartsForm'

export const BULK_COMPLIANCE_PAGE_LEDGER_FRAGMENT = gql`
  ${PARTS_TRANSACTION_FRAGMENT}
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  fragment BulkCompliancePageLedgerFragment on ComplianceLedger {
    id
    status
    aircraftUsageLogId
    requestedInspectorId
    requestedWorkerId
    inspectedById
    workedById
    isRevision
    description
    aircraftUsageLog {
      ...CoreAircraftUsageLogFields
    }
    stats {
      cActivitiesInProgress
      cActivitiesInReview
      totalCActivities
    }
    complianceActivity(filters: { omitChildren: false }) {
      id
      status
      description
      notes
      workHours
      workedById
      nextDue {
        id
        isCompleted
        nextDueValue
        nextDueType
        nextDueOverride
        nextDueOverrideType
        nextDueOverrideBy
        createdAt
        updatedAt
      }
      discrepancyItems {
        discrepancyStatus
      }
      partsTransaction {
        ...PartsTransactionFragment
      }
      maintenanceItem {
        id
        aircraft {
          id
        }
        ataCode
        manufactureCode
        title
        trackedByComponentId
        trackedByComponent {
          id
          name
        }
        maintenanceType
        disposition
        notes
        cadenceType
        cadenceValue
        isEndOfMonthAdjustment
        aircraftComponent {
          id
          isPrimary
          partNumber
          serialNumber
          monthsSinceNew
          monthsSinceOverhaul
          monthsSinceRepair
          hoursSinceNew
          hoursSinceOverhaul
          hoursSinceRepair
          cyclesSinceNew
          cyclesSinceOverhaul
          cyclesSinceRepair
        }
        childItems {
          id
          title
        }
      }
    }
    aircraft {
      id
      name
      model
      tailNumber
      serialNumber
      orgSlug
      onboardStatus
      createdAt
      updatedAt
      AircraftUsageLog {
        ...CoreAircraftUsageLogFields
      }
      AircraftComponent(isPrimary: true) {
        id
        name
        isPrimary
        cyclesSinceNew
        hoursSinceNew
        serialNumber
        partNumber
      }
    }
    revisionBy {
      id
      firstName
      lastName
    }
  }
`

export const updateMaintenanceItemEndOfMonthAdjustmentQuery = gql`
  mutation UpdateMaintenanceItemEndOfMonthAdjustment($id: String!, $endOfMonthAdjustment: Boolean!) {
    updateMaintenanceItem(id: $id, input: { isEndOfMonthAdjustment: $endOfMonthAdjustment }) {
      id
      isEndOfMonthAdjustment
    }
  }
`

export const updateMaintenanceItemNotesQuery = gql`
  mutation UpdateMaintenanceItemNotes($id: String!, $notes: String!) {
    updateMaintenanceItem(id: $id, input: { notes: $notes }) {
      id
      notes
    }
  }
`

export const GET_COMPLIANCE_LEDGER_BY_ID_FOR_BULK_COMPLIANCE = gql`
  ${BULK_COMPLIANCE_PAGE_LEDGER_FRAGMENT}
  query GetComplianceLedgerById($id: String!) {
    complianceLedger(id: $id) {
      ...BulkCompliancePageLedgerFragment
    }
  }
`

export const REMOVE_TASKS_FROM_COMPLIANCE_LEDGER_MUTATION = gql`
  mutation RemoveTasksFromComplianceLedger($ids: [String!]!) {
    bulkDeleteComplianceActivity(ids: $ids) {
      id
    }
  }
`

export const RESET_COMPLIANCE_REVISION = gql`
  mutation ResetComplianceRevision($id: String!) {
    resetComplianceRevision(id: $id) {
      id
    }
  }
`

export const CANCEL_COMPLIANCE_REVISION = gql`
  mutation CancelComplianceRevision($id: String!) {
    cancelComplianceRevision(id: $id)
  }
`
