import { Modal, Paper, Typography } from '@mui/material'

import Button from 'src/components/MUI/Button'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, openModal } from 'src/slices/modal'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useMutation } from '@redwoodjs/web'
import { CREATE_INVOICE } from '../queries'
import { routes } from '@redwoodjs/router'
import { toast } from 'react-hot-toast'
import { navigate } from '@redwoodjs/router'
import { useOrgName } from 'src/hooks/useOrgName'

const modalName = 'workOrderCompletedModal'

type WorkOrderCompletedModalProps = {
  workOrderId: string
  ledgerId: string
}

const WorkOrderCompletedModal: React.FC<WorkOrderCompletedModalProps> = ({
  workOrderId,
  ledgerId,
}) => {
  const dispatch = useDispatch()
  const orgName = useOrgName()

  const [createInvoice] = useMutation(CREATE_INVOICE)

  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className="absolute left-1/2 top-1/2 w-60 -translate-x-1/2 -translate-y-1/2 transform rounded-xl">
        <div className="flex flex-col items-center gap-2 p-3">
          <CheckCircleIcon color="success" className="h-10 w-10" />
          <Typography variant="h6">Work order completed!</Typography>
          <Typography variant="body1" className="px-5">
            Your work order was placed successfully.
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="px-5 text-center"
          >
            You can now publish a logbook entry or an invoice. Both options are
            also available at any time on task details.
          </Typography>
          <Button
            onClick={async () => {
              handleClose()
              dispatch(
                openModal({
                  name: 'createLogbookEntryModal',
                  data: {
                    ledgerId,
                  },
                })
              )
            }}
            fullWidth
            color="base"
            size="large"
            variant="outlined"
          >
            Publish Logbook Entry
          </Button>{' '}
          <Button
            onClick={async () => {
              handleClose()

              createInvoice({
                variables: {
                  input: {
                    orgSlug: orgName,
                    internalWorkOrderId: workOrderId,
                  },
                },
                onCompleted: (data) => {
                  toast.success('Invoice created')
                  navigate(
                    routes.invoice({
                      orgName,
                      id: data.createInvoice.id,
                    })
                  )
                },
              })
            }}
            fullWidth
            color="base"
            size="large"
            variant="outlined"
          >
            Generate Invoice
          </Button>
          <Button
            onClick={() => {
              handleClose()
            }}
            fullWidth
            size="large"
            LinkComponent={'a'}
          >
            Close
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}

export default WorkOrderCompletedModal
