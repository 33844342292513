import { get } from 'lodash'
import { ComponentUsageLog, AircraftComponent } from '../graphql'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const someHelperFunction = () => {
  console.log('someHelperFunction is called')
}

export const roundToPrecision = (num: number, places = 2): number => {
  if (Number.isInteger(num)) {
    return num
  }
  const multiplier = Math.pow(10, places)
  return Math.round(num * multiplier) / multiplier
}

export const capitalizeFirstLetter = (str: string): string => {
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
}

export const sortComponentLikeObjects =
  <T>(nameString: string) =>
  (items: T[]): T[] => {
    if (!items) return []
    const arr = [...items] // create a copy of the array

    arr.sort((a, b) => {
      const nameA = get(a, nameString).toUpperCase() // ignore upper and lowercase
      const nameB = get(b, nameString).toUpperCase() // ignore upper and lowercase

      if (nameA === 'AIRFRAME') return -1
      if (nameB === 'AIRFRAME') return 1

      const isEngineA = nameA.includes('ENGINE')
      const isEngineB = nameB.includes('ENGINE')

      if (isEngineA && isEngineB) return nameA < nameB ? -1 : 1
      if (isEngineA) return -1
      if (isEngineB) return 1

      return nameA < nameB ? -1 : 1
    })

    return arr
  }

export const sortComponentUsageLogs =
  sortComponentLikeObjects<ComponentUsageLog>('component.name')
export const sortAircraftComponents =
  sortComponentLikeObjects<AircraftComponent>('name')

export const formatDateForDisplayInUtc = (
  date: string | Date | dayjs.Dayjs | undefined,
  format = 'MMM DD, YYYY'
) => {
  return formatDateForDisplay(date, true, format)
}

export const formatDateForDisplayInLocal = (
  date: string | Date | dayjs.Dayjs | undefined,
  format = 'MMM DD, YYYY'
) => {
  return formatDateForDisplay(date, false, format)
}

export const formatDateForDisplay = (
  date: string | Date | dayjs.Dayjs | undefined,
  showInUtc?: boolean,
  format = 'MMM DD, YYYY'
): string => {
  if (!date) {
    return ''
  }

  const inputDate = showInUtc ? dayjs.utc(date) : dayjs(date)
  if (inputDate.isBefore('1970-01-01')) {
    return ''
  }
  // if it's not a valid date
  if (!inputDate.isValid()) {
    return ''
  }
  return inputDate.format(format)
}
