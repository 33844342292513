import UploadIcon from '@mui/icons-material/Upload'
import { IconButton, Modal, Paper, Typography } from '@mui/material'
import ReactPDF from '@react-pdf/renderer'
import clsx from 'clsx'
import { PurchaseOrder } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { DELETE_ATTACHED_FILES } from 'src/components/FileRemoveButton'
import FileUploadButton from 'src/components/FileUploadButton'
import Loading from 'src/components/Loading'
import Button from 'src/components/MUI/Button'
import { DELETE_INVALID_FILE } from 'src/hooks/requests/useUploadFile'
import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal } from 'src/slices/modal'
import useQuery from 'src/hooks/useQuery'
import { downloadPdfFromUrl } from 'src/utils/helpers'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { GET_OPEN_PURCHASE_ORDER_INVOICE_BY_ID } from './queries'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from 'react-hot-toast'

type InvoiceViewModalProps = {
  id: string
}

const modalName = 'invoiceViewModal'

const InvoiceViewModal: React.FC<InvoiceViewModalProps> = ({ id }) => {
  const { data, hasLoaded, refetch } = useQuery(
    GET_OPEN_PURCHASE_ORDER_INVOICE_BY_ID,
    {
      variables: { id },
    }
  )
  const po = data?.purchaseOrder ?? {}

  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  const [deleteInvoiceFile] = useMutation(DELETE_INVALID_FILE, {
    update: (cache, { data: { deleteInvalidFile } }) => {
      // Read the current data from the cache for the GET_OPEN_PURCHASE_ORDER_BY_ID query.
      const existingPOData = cache.readQuery({
        query: GET_OPEN_PURCHASE_ORDER_INVOICE_BY_ID,
        variables: { id },
      })

      // Filter out the deleted file from the po.invoiceFile array.
      const updatedInvoiceFiles =
        existingPOData.purchaseOrder.invoiceFile.filter(
          (file) => file.id !== deleteInvalidFile.id
        )

      // Write the updated data back to the cache.
      cache.writeQuery({
        query: GET_OPEN_PURCHASE_ORDER_INVOICE_BY_ID,
        variables: { id },
        data: {
          ...existingPOData,
          purchaseOrder: {
            ...existingPOData.purchaseOrder,
            invoiceFile: updatedInvoiceFiles,
          },
        },
      })
    },
  })

  const invoiceFile = po?.invoiceFile?.reduce((acc, file) => {
    if (!acc) return file
    return file.createdAt > acc.createdAt ? file : acc
  }, null)

  const isPdf = invoiceFile?.url.endsWith('.pdf')

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className="absolute left-1/2 top-1/2 w-140 -translate-x-1/2 -translate-y-1/2 transform rounded-xl bg-white p-3">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <Typography variant="h6" component="h2">
              Invoice
            </Typography>
            <div className="flex items-center gap-2">
              <Button
                color="error"
                variant="text"
                onClick={() =>
                  deleteInvoiceFile({
                    variables: { id: invoiceFile.id },
                    onCompleted: () => {
                      handleClose()
                    },
                    onError: (error) => {
                      toast.error(
                        'Failed to delete invoice file: ' + error.message
                      )
                    },
                  })
                }
              >
                DELETE
              </Button>
              <Button
                color="base"
                variant="outlined"
                onClick={() =>
                  downloadPdfFromUrl(
                    po.invoiceFile[0].urlForPdfPreview,
                    po.invoiceFile[0].originalFilename
                  )
                }
                startIcon={<CloudDownloadOutlinedIcon fontSize="small" />}
              >
                DOWNLOAD
              </Button>

              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div
            className={clsx(
              isPdf && 'h-[8.5in]',
              !invoiceFile && 'h-50',
              'flex items-center justify-center'
            )}
          >
            {!hasLoaded && <Loading />}
            {hasLoaded && invoiceFile && isPdf && (
              <object
                data={po.invoiceFile[0].urlForPdfPreview}
                width="100%"
                height="100%"
              >
                <p>Your browser cannot display this PDF invoice directly.</p>
                <a
                  href={po.invoiceFile[0].urlForPdfPreview}
                  type="application/pdf"
                >
                  Click here to view or download the invoice PDF
                </a>
              </object>
            )}
            {hasLoaded && invoiceFile && !isPdf && (
              <img
                src={invoiceFile.url}
                alt="invoice"
                className="h-auto max-w-full"
              />
            )}
          </div>
        </div>
      </Paper>
    </Modal>
  )
}

export default InvoiceViewModal
