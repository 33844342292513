  // Memoize component description to prevent unnecessary re-renders
  export const UnscheduledTypeMapConsts = {
    DISCREPANCY: 'Discrepancy',
    SQUAK: 'Squawk',
    MEL: 'MEL',
    NEF: 'NEF',
    WATCH_LIST: 'Watch List',
    AIRWORTHINESS_DIRECTIVE: 'Airworthiness Directive',
    SERVICE_BULLETIN: 'Service Bulletin',
    SERVICE_LETTER: 'Service Letter',
    AD_SB: 'AD/SB',
    SPECIAL_AD: 'Special AD',
    EMERGENCY_AD: 'Emergency AD',
    RECURRING_AD: 'Recurring AD',
    ALERT_SB: 'Alert SB',
    MANDATORY_SB: 'Mandatory SB',
    OTHER: 'Other'
  }
