import { useState, useCallback, useEffect } from 'react'

type FetchMoreFn<TOptions = unknown, TResult = unknown> = (options: TOptions) => Promise<TResult>

interface OffsetParams {
  data: any
  limit: number
  offset?: number
  path: string // Path to the paginated data in the response
  loading?: boolean // Add loading state from query
}

interface FetchAllState {
  isLoading: boolean
  hasAllData: boolean
}

const offsetStrategy = async (
  fetchMore: FetchMoreFn,
  params: OffsetParams,
  setState: (state: Partial<FetchAllState>) => void
): Promise<void> => {
  const { data, limit, offset = 0, path } = params

  // Guard against undefined data
  if (!data?.[path]) return

  setState({ isLoading: true })

  try {
    let currentOffset = offset + (data[path]?.length || 0)
    let keepFetching = true

    while (keepFetching) {
      const response = await fetchMore({
        variables: { limit, offset: currentOffset },
      })

      const pageData = response.data[path]
      if (!pageData?.length || pageData.length < limit) {
        keepFetching = false
      } else {
        currentOffset += pageData.length
      }
    }
  } catch (error) {
    console.error('Error fetching pages:', error)
    setState({ isLoading: false, hasAllData: false })
    return
  } finally {
    setState({ isLoading: false, hasAllData: true })
  }
}

interface UseFetchAllProps {
  fetchMore: FetchMoreFn
  strategy: 'offset' // We'll add more strategies later
  params: OffsetParams
  autoFetch?: boolean // Add option to auto-fetch all pages
}

export const useFetchAll = ({
  fetchMore,
  strategy,
  params,
  autoFetch = false,
}: UseFetchAllProps) => {
  const [state, setState] = useState<FetchAllState>({
    isLoading: false,
    hasAllData: false,
  })
  const [hasStartedFetching, setHasStartedFetching] = useState(false)

  const updateState = (newState: Partial<FetchAllState>) => {
    setState((prev) => ({ ...prev, ...newState }))
  }

  const fetchAll = useCallback(async () => {
    if (state.hasAllData || !fetchMore || params.loading || state.isLoading) return

    switch (strategy) {
      case 'offset':
        await offsetStrategy(fetchMore, params, updateState)
        break
    }
  }, [fetchMore, strategy, params, state.hasAllData, state.isLoading])

  // Handle auto-fetching for useQuery
  useEffect(() => {
    if (
      autoFetch &&
      !params.loading &&
      params.data?.[params.path] &&
      !state.hasAllData &&
      !state.isLoading &&
      !hasStartedFetching
    ) {
      setHasStartedFetching(true)
      fetchAll()
    }
  }, [
    autoFetch,
    params.loading,
    params.data,
    fetchAll,
    state.hasAllData,
    state.isLoading,
    hasStartedFetching,
  ])

  return {
    fetchAll,
    isLoading: state.isLoading,
    hasAllData: state.hasAllData,
  }
}

export default useFetchAll
