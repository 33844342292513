import {
  createTheme,
  alpha,
  getContrastRatio,
  colors as muiColors,
} from '@mui/material'
import { PaletteColor, PaletteColorOptions } from '@mui/material/styles'

export const tabletMediaQuery = '@media (max-width:1200px)'

const pastelRedBase = '#e53935'
const pastelRedMain = alpha(pastelRedBase, 0.7)

declare module '@mui/material/styles' {
  interface Palette {
    base?: PaletteColor
    pastelRed?: PaletteColor
    white?: PaletteColor
    black?: PaletteColor
    // Add other custom colors here
  }
  interface PaletteOptions {
    base?: PaletteColorOptions
    pastelRed?: PaletteColorOptions
    white?: PaletteColorOptions
    black?: PaletteColorOptions
    // Add other custom colors here
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    base: true
    pastelRed: true
    white: true
    black: true
    // Add other custom colors here
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    soft: true
    softOutlined: true
  }
}

const primaryColors = {
  main: '#2B83FD',
  light: '#3EA2FF',
  dark: '#2B83FD',
  contrastText: '#FFFFFF',
}

export const wwAppColors = {
  // ! MUI default colors are included here for convenience.
  // ! These colors will be synced with tailwind.
  // ! You can now use tailwind classes like `bg-sys-black`, `bg-lightgrey-700`, etc.
  ...muiColors,
  darkRed: {
    50: '#fbe9e9',
    100: '#f5c8c8',
    200: '#efa4a4',
    300: '#e88080',
    400: '#e36565',
    500: '#de4a4a',
    600: '#cd4343',
    700: '#b83939',
    800: '#a33030',
    900: '#9b1c1c',
  },
  coolBlue: {
    50: '#F0F5FF',
    100: '#E1EFFE',
    200: '#C3DDFD',
    300: '#A5CBFC',
    400: '#87B9FB',
    500: '#69A7FA',
    600: '#4B85E8',
    700: '#3563D6',
    800: '#2752C4',
    900: '#1E429F',
  },
  sys: {
    black: '#000000',
    white: '#FFFFFF',
    outline: '#C4C6D0',
    lightgrey: '#F5F5F5',
    textLowEmpathy: '#9E9E9E',
    // ! These are not used in the palette, but are used in styled-components as custom colors.
    // ! Add more as needed.
    primary: primaryColors,
    border: '#E0E0E0',
  },
}

const customMuiPaletteConfig = {
  // ! Palette only works with main, light, dark, contrastText values.
  // ! You can't really customize the palette much more than this.
  // ! So we have defined our own colors above in `wwAppColors`
  // ! which will be source of truth & in sync with tailwind colors.
  // ! There are not a lot of places where we use styled-components,
  // ! so this should be mostly fine.
  // ! If you do need to use styled-components, you can use the wwAppColors object.
  primary: primaryColors,
  secondary: {
    main: muiColors.purple[500],
    light: muiColors.purple[300],
    dark: muiColors.purple[700],
    contrastText: '#FFFFFF',
  },
  warning: {
    main: muiColors.orange[900],
    light: muiColors.orange[100],
    dark: muiColors.orange[900],
    contrastText: '#FFFFFF',
  },
  error: {
    main: wwAppColors.red[700],
    light: wwAppColors.red[100],
    dark: wwAppColors.red[800],
    contrastText: '#FFFFFF',
  },
  info: {
    main: muiColors.lightBlue[700],
    light: muiColors.lightBlue[100],
    dark: muiColors.lightBlue[900],
    contrastText: '#FFFFFF',
  },
  success: {
    main: muiColors.green[800],
    light: muiColors.green[100],
    dark: muiColors.green[900],
    contrastText: '#FFFFFF',
  },
  white: {
    main: '#FFFFFF',
    light: '#FFFFFF',
    dark: '#F0F0F0',
    contrastText: '#000000',
  },
  base: {
    main: '#C4C6D0',
    contrastText: '#5E5E62',
  },
  pastelRed: {
    main: pastelRedMain,
    light: alpha(pastelRedBase, 0.5),
    dark: alpha(pastelRedBase, 0.9),
    contrastText:
      getContrastRatio(pastelRedMain, '#FFFFFF') > 4 ? '#FFFFFF' : '#00000042',
  },
  darkRed: {
    main: wwAppColors.darkRed[700],
    light: wwAppColors.darkRed[100],
    dark: wwAppColors.darkRed[900],
    contrastText: '#FFFFFF',
  },
  coolBlue: {
    main: wwAppColors.coolBlue[700],
    light: wwAppColors.coolBlue[100],
    dark: wwAppColors.coolBlue[900],
    contrastText: '#FFFFFF',
  },
}

const getTypographyVariant = (desktopSpecs, tabletSpecs) => ({
  fontWeight: desktopSpecs.fontWeight,
  fontSize: desktopSpecs.fontSize,
  lineHeight: desktopSpecs.lineHeight,
  [tabletMediaQuery]: {
    // Tablet adjustments
    fontSize: tabletSpecs.fontSize,
    lineHeight: tabletSpecs.lineHeight,
    fontWeight: tabletSpecs.fontWeight,
  },
})

const theme = createTheme({
  palette: customMuiPaletteConfig,
  breakpoints: {
    values: {
      xs: 0, // phones
      sm: 600, // tablets
      md: 900, // small laptop screens
      lg: 1200, // desktops
      xl: 1536, // large screens
    },
  },
  typography: {
    h1: getTypographyVariant(
      { fontSize: '5.875rem', fontWeight: 300, lineHeight: '7.625rem' },
      { fontSize: '5.625rem', fontWeight: 300, lineHeight: '6.5rem' }
    ),
    h2: getTypographyVariant(
      { fontSize: '3.6875rem', fontWeight: 300, lineHeight: '4.875rem' },
      { fontSize: '3rem', fontWeight: 300, lineHeight: '4rem' }
    ),
    h3: getTypographyVariant(
      { fontSize: '2.9375rem', fontWeight: 400, lineHeight: '3.75rem' },
      { fontSize: '2.25rem', fontWeight: 400, lineHeight: '3rem' }
    ),
    h4: getTypographyVariant(
      { fontSize: '2.5rem', fontWeight: 500, lineHeight: '3rem' },
      { fontSize: '2.25rem', fontWeight: 500, lineHeight: '2.5rem' }
    ),
    h5: getTypographyVariant(
      { fontSize: '2rem', fontWeight: 500, lineHeight: '2.5rem' },
      { fontSize: '1.75rem', fontWeight: 500, lineHeight: '2rem' }
    ),
    h6: getTypographyVariant(
      { fontSize: '1.25rem', fontWeight: 500, lineHeight: '1.5rem' },
      { fontSize: '1.125rem', fontWeight: 500, lineHeight: '1.5rem' }
    ),
    subtitle1: getTypographyVariant(
      { fontSize: '1rem', fontWeight: 500, lineHeight: '1.75rem' },
      { fontSize: '1rem', fontWeight: 500, lineHeight: '1.5rem' }
    ),
    subtitle2: getTypographyVariant(
      { fontSize: '0.875rem', fontWeight: 500, lineHeight: '1.375rem' },
      { fontSize: '0.875rem', fontWeight: 500, lineHeight: '1.125rem' }
    ),
    body1: getTypographyVariant(
      { fontSize: '1rem', fontWeight: 400, lineHeight: '1.5rem' },
      { fontSize: '0.875rem', fontWeight: 400, lineHeight: '1.25rem' }
    ),
    body2: getTypographyVariant(
      { fontSize: '0.875rem', fontWeight: 400, lineHeight: '1.25rem' },
      { fontSize: '0.75rem', fontWeight: 400, lineHeight: '1rem' }
    ),
    caption: getTypographyVariant(
      { fontSize: '0.75rem', fontWeight: 400, lineHeight: '1rem' },
      { fontSize: '0.625rem', fontWeight: 400, lineHeight: '0.875rem' }
    ),
    overline: getTypographyVariant(
      { fontSize: '0.75rem', fontWeight: 400, lineHeight: '1rem' },
      { fontSize: '0.625rem', fontWeight: 400, lineHeight: '0.875rem' }
    ),
  },
  zIndex: {
    drawer: 1201, // Set a higher zIndex value for the drawer
    appBar: 1200, // Set a lower zIndex value for the appBar/navbar
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top',
      },
    },
    MuiButton: {
      defaultProps: { variant: 'contained' },
      styleOverrides: {
        root: { textTransform: 'none' },
        outlinedBase: {
          // required to override the default text color
          color: customMuiPaletteConfig.base.contrastText,
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: { variant: 'contained' },
      styleOverrides: {
        root: { textTransform: 'none' },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          color: wwAppColors.sys.textLowEmpathy,
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'soft' },
          style: ({ theme, color }) => ({
            height: '22px',
            padding: '2px 10px',
            borderRadius: '6px',
            backgroundColor:
              theme.palette[color]?.light || theme.palette.grey[200],
            color: theme.palette[color]?.dark || theme.palette.text.primary,
            '& .MuiChip-label': {
              padding: '0',
              fontSize: '12px',
              lineHeight: '150%',
              fontWeight: 500,
            },
          }),
        },
        {
          props: { variant: 'softOutlined' },
          style: ({ theme, color }) => ({
            height: '22px',
            padding: '2px 10px',
            borderRadius: '6px',
            backgroundColor: 'transparent',
            border: `1px solid ${
              theme.palette[color]?.main || theme.palette.grey[500]
            }`,
            color: theme.palette[color]?.main || theme.palette.text.primary,
            '& .MuiChip-label': {
              padding: '0',
              fontSize: '12px',
              lineHeight: '150%',
              fontWeight: 500,
            },
          }),
        },
      ],
    },
  },
})

export default theme
