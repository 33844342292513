import React, { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import DrawerActions from 'src/components/MUI/Drawer/DrawerActions'
import DrawerHeader from 'src/components/MUI/Drawer/DrawerHeader'
import useModal from 'src/hooks/useModal'
import TextField from 'src/components/MUI/TextField'

import { Typography, TextField as MuiTextField } from '@mui/material'
import { CustomInternalWorkItem, InternalWorkItem } from 'types/graphql'
import * as Yup from 'yup'

import DollarCentsTextField from 'src/components/DollarCentsTextField'
import RadioGroup from 'src/components/MUI/RadioGroup'
import Switch from 'src/components/MUI/Switch'
import {
  centsToDollars,
  minutesToHours,
  isDecimalWithTwoOrLessPlaces,
  dollarsToCents,
  hoursToMinutes,
} from 'src/utils/helpers'
import {
  UPDATE_CUSTOM_WORK_ITEM,
  UPDATE_WORK_ITEM,
} from 'src/pages/WorkOrderCompliancePageV2/queries'

interface EditLaborDetailsDrawerProps {
  task: InternalWorkItem | CustomInternalWorkItem
  refetch: () => void
}

import { toast } from 'react-hot-toast'
export const laborDetailsFormSchema = Yup.object().shape({
  task: Yup.object().shape({
    rateType: Yup.string().required('Choose rate type'),
    rate: Yup.number()
      .typeError('Rate is required')
      .min(0, 'Must be positive')
      .test(
        'valid-decimal',
        'No more than 2 decimal points',
        isDecimalWithTwoOrLessPlaces
      )
      .required('Rate is required'),
    workHours: Yup.number()
      .typeError('Work hours is required')
      .min(0, 'Must be positive')
      .required('Work hours is required'),
  }),
})

const EditLaborDetailsDrawer: React.FC<EditLaborDetailsDrawerProps> = ({
  task,
  refetch,
}) => {
  const [updateCustomWorkItem, { loading }] = useMutation(
    UPDATE_CUSTOM_WORK_ITEM
  )

  const [updateInternalWorkItem, { loading: internalWorkItemLoading }] =
    useMutation(UPDATE_WORK_ITEM)

  const { handleClose, isOpen } = useModal('editLaborDetailsDrawer')

  const formMethods = useForm({
    resolver: yupResolver(laborDetailsFormSchema),
    defaultValues: {
      task: {
        rateType: 'HOURLY_RATE',
        rate: centsToDollars(0),
        estimatedWorkMinutes: 0,
        workHours: 0,
        overrideLaborTransactionHours: false,
      },
    },
  })

  const { reset, watch, setValue } = formMethods
  const workHoursInput = watch('task.workHours')
  const rateInput = watch('task.rate')
  const rateTypeInput = watch('task.rateType')
  const overrideLaborTransactionHours = watch(
    'task.overrideLaborTransactionHours'
  )

  useEffect(() => {
    if (overrideLaborTransactionHours) {
      setValue('task.workHours', parseFloat(minutesToHours(task?.workMinutes)))
    } else {
      setValue(
        'task.workHours',
        parseFloat(minutesToHours(task?.laborTransactionMinutes))
      )
    }
  }, [overrideLaborTransactionHours])

  useEffect(() => {
    if (task) {
      const getWorkHoursVal = () => {
        if (!task?.overrideLaborTransactionHours) {
          return task?.laborTransactionMinutes
            ? parseFloat(minutesToHours(task?.laborTransactionMinutes))
            : 0
        } else {
          return task?.workMinutes
            ? parseFloat(minutesToHours(task?.workMinutes))
            : 0
        }
      }
      reset({
        task: {
          rateType: task?.rateType ?? 'HOURLY_RATE',
          rate: task?.rate ? centsToDollars(task?.rate) : centsToDollars(0),
          workHours: getWorkHoursVal(),
          overrideLaborTransactionHours:
            task?.overrideLaborTransactionHours ?? false,
        },
      })
    }
  }, [task, isOpen])

  const getEstimatedSubtotal = () => {
    const estWorkHours = task?.estimatedWorkMinutes
      ? parseFloat(minutesToHours(task?.estimatedWorkMinutes))
      : 0
    const rate = task?.estimatedRate
      ? parseFloat(centsToDollars(task?.estimatedRate))
      : 0
    const rateType = task?.estimatedRateType

    if (!rate || !rateType) return '----'

    switch (rateType) {
      case 'FLAT_RATE':
        return `$${rate}`
      case 'HOURLY_RATE':
        return `$${(estWorkHours * rate).toFixed(2)}`
      default:
        return '----'
    }
  }

  const getActualSubtotal = () => {
    const actualWorkHours = workHoursInput ? parseFloat(`${workHoursInput}`) : 0
    const rate = rateInput ? parseFloat(rateInput) : 0

    if (!rate || !rateTypeInput) return '----'

    switch (rateTypeInput) {
      case 'FLAT_RATE':
        return `$${rate}`
      case 'HOURLY_RATE':
        return `$${(actualWorkHours * rate).toFixed(2)}`
      default:
        return '----'
    }
  }

  const handleSubmit = async (values) => {
    const payload = {
      ...values.task,
      rate: values.task.rate ? dollarsToCents(parseFloat(values.task.rate)) : 0,
      workMinutes: hoursToMinutes(parseFloat(values.task.workHours)),
    }
    if (task?.isCustomWorkItem) {
      await updateCustomWorkItem({
        variables: {
          id: task.id,
          input: payload,
        },
        onError(_) {
          toast.error('Error updating task')
          return false
        },
      })
    } else {
      await updateInternalWorkItem({
        variables: {
          id: task.id,
          input: payload,
        },
      })
    }

    refetch()
    handleClose()
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <DrawerHeader title="Edit Labor Rate Details" onClose={handleClose} />
      <div className="h-full w-[800px] p-3">
        <Form
          id="editLaborDetailsForm"
          formMethods={formMethods}
          onSubmit={handleSubmit}
        >
          <div className="mt-2 grid grid-cols-2 gap-2">
            <RadioGroup
              name={'task.rateType'}
              required
              row
              options={[
                {
                  label: 'Hourly Rate',
                  value: 'HOURLY_RATE',
                },
                {
                  label: 'Flat Rate',
                  value: 'FLAT_RATE',
                },
              ]}
            />
            <DollarCentsTextField
              name="task.rate"
              required
              label="Rate"
              control={formMethods.control}
            />
          </div>

          <div className="mt-2 grid grid-cols-2 gap-2">
            <div className="flex flex-col gap-2 ">
              <div className="mb-[4px]">
                <Typography variant="subtitle2" fontWeight={'bold'}>
                  Estimate
                </Typography>
              </div>
              <MuiTextField
                size="small"
                label="Work Hours"
                required
                variant="outlined"
                value={
                  task?.estimatedWorkMinutes
                    ? parseFloat(minutesToHours(task?.estimatedWorkMinutes))
                    : 0
                }
                disabled
              />
              <MuiTextField
                size="small"
                label="Subtotal"
                variant="outlined"
                value={getEstimatedSubtotal()}
                disabled
              />
            </div>
            <div className="flex flex-col gap-2 ">
              <div className="flex items-center justify-between">
                <Typography variant="subtitle2" fontWeight={'bold'}>
                  Actual
                </Typography>
                <div className="flex items-center gap-2">
                  <Typography variant="caption">
                    Override Labor Transaction Hours
                  </Typography>
                  <Switch
                    name="task.overrideLaborTransactionHours"
                    switchProps={{ size: 'small' }}
                  />
                </div>
              </div>
              <TextField
                name="task.workHours"
                size="small"
                label="Work Hours"
                variant="outlined"
                type="number"
                disabled={!overrideLaborTransactionHours}
                inputProps={{ step: '0.1', min: 0 }}
              />
              <MuiTextField
                size="small"
                label="Subtotal"
                variant="outlined"
                value={getActualSubtotal()}
                disabled
              />
            </div>
          </div>
        </Form>
      </div>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="text" color="black" onClick={handleClose} fullWidth>
            Cancel
          </Button>
        }
        rightActions={
          <Button
            type="submit"
            form="editLaborDetailsForm"
            variant="contained"
            color="primary"
            fullWidth
          >
            Save
          </Button>
        }
      />
    </Drawer>
  )
}

export default EditLaborDetailsDrawer
