import { RadioGroupOption } from '../MUI/RadioGroup'
import { SelectOption } from '../MUI/SelectDropdown'

export const melCategoryOptions: SelectOption[] = [
  {
    label: 'Category A',
    value: 'catA',
  },
  {
    label: 'Category B',
    value: 'catB',
  },
  {
    label: 'Category C',
    value: 'catC',
  },
  {
    label: 'Category D',
    value: 'catD',
  },
]

export const deferredOptions: RadioGroupOption[] = [
  {
    label: 'Yes',
    value: 'yes',
    disabled: false,
  },
  {
    label: 'No',
    value: 'no',
    disabled: false,
  },
]

export const melCatToDaysMap = {
  catA: 0,
  catB: 3,
  catC: 10,
  catD: 120,
}

export const GET_TAGS = gql`
  query tags {
    tags {
      id
      name
    }
  }
`

export const CREATE_DISCREPANCY_ITEM_TRANSACTION = gql`
  mutation CreateDiscrepancyItemTransaction(
    $input: CreateMaintenanceItemTransactionInput!
  ) {
    createMaintenanceItemTransaction(input: $input) {
      id
      maintenanceNextDue {
        id
      }
      aircraftComponent {
        id
      }
    }
  }
`
