import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import { Form } from '@redwoodjs/forms'
import TextField from 'src/components/MUI/TextField'

const PromptForPinDialog = ({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean
  onClose: () => void
  onSubmit: (pin: string) => void
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Enter PIN</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={({ pin }) => {
            onSubmit(pin)
          }}
          className="flex flex-col gap-2"
        >
          <DialogContentText>
            Please enter your PIN to continue
          </DialogContentText>
          <TextField
            autoFocus
            required
            inputProps={{
              className: '[-webkit-text-security:disc]',
              autoComplete: 'off',
            }}
            margin="dense"
            name="pin"
            label="PIN"
            fullWidth
            variant="standard"
          />
          <DialogActions>
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default PromptForPinDialog
