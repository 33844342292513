import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'
import { customInternalWorkItemFragment } from 'src/pages/CreateWorkOrderPage/fragments'

// #region internal work order compliance page

const WORK_ITEM_FRAGMENT_FOR_BULK_UPDATE = gql`
  fragment WorkItemFieldsForBulkUpdate on InternalWorkItem {
    id
    status
    workOrderId
    maintenanceItem {
      id
      parentId
      title
      trackedByComponentId
      trackedByComponent {
        id
        name
      }
      maintenanceType
      ataCode
      manufactureCode
      ataManufactureCode
      relatedMaintenanceItemIds
      componentID
    }
  }
`
export const CORE_INTERNAL_WORK_ITEM_FRAGMENT = gql`
  fragment CoreWorkItemFields on InternalWorkItem {
    id
    status
    description
    notes
    workHours
    workMinutes
    overrideLaborTransactionHours
    laborTransactionMinutes
    workedById
    additionalLabor
    additionalNotes
    correctiveAction
    partCost
    laborTransactions {
      id
      description
      correctiveAction
      notes
      punchedInAt
      punchedOutAt
      workedBy {
        id
        firstName
        lastName
        clerkId
      }
    }
    nextDue {
      id
      nextDueType
      nextDueValue
      nextDueOverride
    }
    rate
    estimatedRate
    rateType
    estimatedRateType
    estimatedWorkMinutes
    discrepancyItems {
      title
      ataCode
      manufactureCode
      description
      adSbType
      discrepancyStatus
      tags {
        id
        name
      }
      maintenanceNextDue {
        nextDueValue
      }
      trackedByComponentId
      trackedByComponent {
        id
        name
      }
      maintenanceType
    }
    workedBy {
      id
      firstName
      lastName
    }
    maintenanceItem {
      id
      parentId
      aircraft {
        id
      }
      ataCode
      manufactureCode
      title
      maintenanceType
      disposition
      cadenceType
      cadenceValue
      componentID
      relatedMaintenanceItemIds
      aircraftComponent {
        id
        serialNumber
        partNumber
      }
      trackedByComponentId
      trackedByComponent {
        id
        name
      }
      childItems {
        id
        title
      }
    }
    partsTransaction {
      id
      removalReason
      installedStatus
      removedPartNumber
      removedSerialNumber
      addedPartNumber
      addedSerialNumber
      monthsSinceNew
      monthsSinceOverhaul
      monthsSinceRepair
      hoursSinceNew
      hoursSinceOverhaul
      hoursSinceRepair
      cyclesSinceNew
      cyclesSinceOverhaul
      cyclesSinceRepair
      added {
        id
        partNumber
        serialNumber
      }
      removed {
        id
        partNumber
        serialNumber
      }
      removedPartFiles {
        id
        url
        originalFilename
      }
      addedPartFiles {
        id
        url
        originalFilename
      }
    }
  }
`
export const INTERNAL_WORK_ORDER_TASKS = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  ${CORE_INTERNAL_WORK_ITEM_FRAGMENT}
  ${customInternalWorkItemFragment}
  query GetComplianceLedgerForInternalWorkOrder(
    $id: String!
    $workItemFilters: InternalWorkItemFilter
    $customWorkItemFilters: CustomInternalWorkItemFilter
  ) {
    internalWorkOrder(id: $id) {
      id
      status
      aircraftUsageLogId
      stats {
        customWorkItemsInProgress
        customWorkItemsInReview
        customWorkItemsUnassigned
        totalCustomWorkItems
        maintenanceWorkItemsInProgress
        maintenanceWorkItemsInReview
        maintenanceWorkItemsUnassigned
        totalMaintenanceWorkItems
      }
      assigneeStats {
        totalEstimatedWorkMinutes
        totalActualWorkHours
        readyForReviewCount
        totalAssignedCount
        totalAssignedCustomWorkItems
        totalAssignedMaintenanceWorkItems
      }
      aircraftUsageLog {
        ...CoreAircraftUsageLogFields
      }
      workItems(filters: $workItemFilters) {
        ...CoreWorkItemFields
        childWorkItems {
          ...CoreWorkItemFields
        }
      }
      customWorkItems(filters: $customWorkItemFilters) {
        id
        ...CustomInternalWorkItemFragment
      }
    }
  }
`

export const BULK_UPDATE_WORK_ITEM_STATUS = gql`
  ${WORK_ITEM_FRAGMENT_FOR_BULK_UPDATE}
  mutation BulkUpdateInternalWorkItemStatus(
    $ids: [String!]!
    $status: WorkItemStatus
    $workedById: String
    $workOrderId: String
  ) {
    bulkUpdateInternalWorkItem(
      ids: $ids
      input: { status: $status, workedById: $workedById }
      workOrderId: $workOrderId
    ) {
      ...WorkItemFieldsForBulkUpdate
      childWorkItems {
        ...WorkItemFieldsForBulkUpdate
      }
    }
  }
`

export const BULK_UPDATE_CUSTOM_WORK_ITEM_STATUS = gql`
  mutation BulkUpdateCustomInternalWorkItemStatus(
    $ids: [String!]!
    $status: WorkItemStatus
    $workedById: String
  ) {
    bulkUpdateCustomInternalWorkItem(
      ids: $ids
      input: { status: $status, workedById: $workedById }
    ) {
      id
    }
  }
`

export const REMOVE_TASKS_FROM_WORK_ORDER = gql`
  mutation RemoveTasksFromWorkOrder($ids: [String!]!) {
    bulkDeleteInternalWorkItem(ids: $ids) {
      id
    }
  }
`

export const REMOVE_CUSTOM_TASKS_FROM_WORK_ORDER = gql`
  mutation RemoveCustomTasksFromWorkOrder($ids: [String!]!) {
    bulkDeleteCustomInternalWorkItem(ids: $ids) {
      id
    }
  }
`

// #endregion
