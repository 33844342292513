import { useState } from 'react'

import { useLazyQuery, ApolloError, gql } from '@apollo/client'
import type { PaginationInput, SortingInput } from 'types/graphql'

import Sentry from 'src/lib/sentry'

import { useOrgName } from '../useOrgName'

const PAGE_SIZE = 25

// sortField: String : Supported values are column names of MaintenanceItem table
// sortOrder: String : Supported values 'asc' or 'desc'
const GET_WORK_ORDER_MAINTENANCE_DUE_LIST = gql`
  query getWorkOrderMaintenancePage(
    $after: String
    $pageSize: Int
    $sortField: String
    $sortOrder: String
    $filters: MaintenanceItemFilters
  ) {
    maintenanceItemsPage(
      maintenanceItemFilters: $filters
      pagination: { after: $after, pageSize: $pageSize }
      sorting: { sortField: $sortField, sortOrder: $sortOrder }
    ) {
      edges {
        adSbStatus
        adSbType
        aircraftId
        ataCode
        cadenceType
        cadenceValue
        calculatedNextDueAt
        description
        disposition
        groupKey
        id
        tags {
          id
          name
        }
        discrepancyStatus
        lastComplianceDate
        importedDataCompliance
        isAdSb
        isOptional
        isParent
        isRecurring
        maintenanceType
        manufactureCode
        metadata
        calculatedNextDueAt
        nextDueStatus
        notes
        otherAdSbType
        otherComponent
        parentId
        remainingValue
        status
        title
      }
      pageInfo {
        cursor
        hasNextPage
        totalCount
      }
    }
  }
`

export type WorkOrderMaintenanceItemPageFetchParams =
  WorkOrderMaintenanceItemFilters & PaginationInput & SortingInput

interface PaginatedMaintenanceItemListResponse {
  workOrderMaintenanceItems: WorkOrderMaintenanceItem[]
  hasNextPage: boolean
  totalCount: number
  initialLoading: boolean
  error: ApolloError

  loadWorkOrderMaintenanceItems: (
    params: WorkOrderMaintenanceItemPageFetchParams,
    resetData?: boolean
  ) => void
  fetchNextWorkOrderMaintenancePage: () => void
}

const usePaginatedWorkOrderMaintenanceItemList =
  (): PaginatedMaintenanceItemListResponse => {
    const [workOrderMaintenanceItems, setMaintenanceItems] = useState<
      WorkOrderMaintenanceItem[]
    >([])
    const orgName = useOrgName()
    const [error, setError] = useState<ApolloError>(undefined)
    const [hasNextPage, setHasNextPage] = useState<boolean>(false)
    const [nextPageCursor, setNextPageCursor] = useState<string>(undefined)
    const [totalCount, setTotalCount] = useState<number>(undefined)
    const [initialLoading, setInitialLoading] = useState<boolean>(undefined)
    const [queryVariables, setQueryVariables] =
      useState<WorkOrderMaintenanceItemPageFetchParams>()

    const postMaintenanceItemFetch = (
      maintenancePageData,
      resetData = false
    ) => {
      if (maintenancePageData) {
        setHasNextPage(
          maintenancePageData.workOrderMaintenanceItemsPage.pageInfo.hasNextPage
        )
        setNextPageCursor(
          maintenancePageData.workOrderMaintenanceItemsPage.pageInfo.cursor
        )
        setTotalCount(
          maintenancePageData.workOrderMaintenanceItemsPage.pageInfo.totalCount
        )
        if (resetData) {
          setMaintenanceItems([
            ...maintenancePageData.workOrderMaintenanceItemsPage.edges,
          ])
        } else {
          setMaintenanceItems([
            ...workOrderMaintenanceItems,
            ...maintenancePageData.workOrderMaintenanceItemsPage.edges,
          ])
        }
      }
    }

    const [fetchMaintenanceItems, { fetchMore }] = useLazyQuery(
      GET_WORK_ORDER_MAINTENANCE_DUE_LIST
    )

    const fetchNextWorkOrderMaintenancePage = async () => {
      if (hasNextPage) {
        const variables = { ...queryVariables }
        variables.after = nextPageCursor
        const { after, pageSize, sortField, sortOrder, ...filters } = variables
        fetchMore({
          variables: { after, pageSize, sortField, sortOrder, filters },
        })
          .then(({ data }) => {
            postMaintenanceItemFetch(data)
          })
          .catch((error) => {
            setError(error)
            Sentry.captureException(error, {
              extra: {
                message: "Couldn't fetch next maintenance page",
                variables,
              },
            })
          })
      }
    }
    const loadWorkOrderMaintenanceItems = (
      params: WorkOrderMaintenanceItemPageFetchParams,
      resetData = false
    ) => {
      if (params.pageSize === undefined) {
        params.pageSize = PAGE_SIZE
      }
      params.orgSlug = orgName
      const { after, pageSize, sortField, sortOrder, ...filters } = params
      setQueryVariables(params)
      setInitialLoading(true)
      fetchMaintenanceItems({
        variables: { after, pageSize, sortField, sortOrder, filters },
      })
        .then(({ data }) => {
          postMaintenanceItemFetch(data, resetData)
          setInitialLoading(false)
        })
        .catch((error) => {
          setError(error)
          setInitialLoading(false)
          Sentry.captureException(error, {
            extra: {
              message: "Couldn't fetch first work order maintenance page",
              params,
            },
          })
        })
    }

    return {
      workOrderMaintenanceItems,
      hasNextPage,
      totalCount,
      initialLoading,
      error,
      loadWorkOrderMaintenanceItems,
      fetchNextWorkOrderMaintenancePage,
    }
  }

export default usePaginatedWorkOrderMaintenanceItemList
