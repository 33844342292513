import React, { useMemo } from 'react'
import useFetchAll from 'src/hooks/useFetchAll'
import useQuery from 'src/hooks/useQuery'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants'
import ReviewForm from 'src/components/ReviewForm'
import {
  GET_WORK_ORDER_CUSTOM_TASK_LIST,
  GET_WORK_ORDER_TASK_LIST,
  GET_WORK_ORDER_TASK_LIST_PAGINATED,
} from 'src/pages/WorkOrderCompliancePageV2/queries'
import Loading from 'src/components/Loading'
import {
  massageTasks,
  MassagedComplianceActivity,
} from 'src/components/ReviewForm/helper'

interface WorkOrderReviewContentProps {
  id: string
  aircraftId: string
  onCancel?: () => void
}

const PAGE_SIZE = 10
const WorkOrderReviewContent: React.FC<WorkOrderReviewContentProps> = ({
  id,
  aircraftId,
  onCancel,
}) => {
  const canCompleteWorkOrder = useHasPermission(
    Permissions.workOrder.complete,
    aircraftId
  )

  const {
    data: taskData,
    loading: firstPageLoading,
    fetchMore,
    error: taskDataError,
  } = useQuery(GET_WORK_ORDER_TASK_LIST_PAGINATED, {
    variables: { id, limit: PAGE_SIZE, offset: 0 },
    fetchPolicy: 'cache-and-network',
  })
  const { hasAllData } = useFetchAll({
    fetchMore,
    strategy: 'offset',
    params: {
      data: taskData,
      loading: firstPageLoading,
      limit: PAGE_SIZE,
      path: 'internalWorkItemsPaginated',
    },
    autoFetch: true,
  })

  const {
    data: customTaskData,
    loading: customTaskDataLoading,
    error: customTaskDataError,
  } = useQuery(GET_WORK_ORDER_CUSTOM_TASK_LIST, {
    variables: { id },
    fetchPolicy: 'cache-first',
  })

  const { workItems, customWorkItems } = useMemo(() => {
    return {
      workItems: taskData?.internalWorkItemsPaginated ?? [],
      customWorkItems: customTaskData?.customInternalWorkItems ?? [],
    }
  }, [taskData, customTaskData])

  const tasks: MassagedComplianceActivity[] = useMemo(() => {
    if (!workItems) return []
    return [
      ...massageTasks(workItems, true, false),
      ...massageTasks(customWorkItems, true, true),
    ]
  }, [workItems, customWorkItems])

  if (!hasAllData || customTaskDataLoading) {
    return <Loading />
  }

  return (
    <div className="flex h-full flex-col">
      <ReviewForm
        title="Review Information"
        tasks={tasks}
        hasCorrectiveAction
        hasAccounting
        aircraftId={aircraftId}
      />
    </div>
  )
}

export default WorkOrderReviewContent
