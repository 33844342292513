import { useState } from 'react'

import { useLazyQuery, ApolloError } from '@apollo/client'
import { MaintenanceItem, MaintenanceItemFilters } from 'types/graphql'

import { useOrgName } from '../useOrgName'

const GET_ALL_MAINTENANCE_DUE_LIST = gql`
  query getMaintenanceDueList($filters: MaintenanceItemFilters) {
    maintenanceItems(maintenanceItemFilters: $filters) {
      adSbStatus
      adSbType
      aircraftId
      ataCode
      cadenceType
      cadenceValue
      calculatedNextDueAt
      description
      disposition
      groupKey
      id
      importedDataCompliance
      isAdSb
      isOptional
      isParent
      isRecurring
      maintenanceType
      manufactureCode
      metadata
      nextDueStatus
      notes
      otherAdSbType
      otherComponent
      parentId
      remainingValue
      status
      title
      trackedByComponentId
      trackedByComponent {
        id
        name
      }
      upstreamId
      maintenanceNextDue(isCompleted: false) {
        nextDueType
        nextDueValue
        nextDueOverrideType
        nextDueOverride
        nextDueOverrideUser {
          firstName
          lastName
          email
        }
      }
    }
  }
`

interface DuelistRecordsHook {
  maintenanceItems: MaintenanceItem[]
  aircrafts: any
  loading: boolean
  error: ApolloError
  loadMaintenanceItems: (params: MaintenanceItemFilters) => void
}

const useDuelistRecords = (): DuelistRecordsHook => {
  const [maintenanceItems, setMaintenanceItems] = useState<MaintenanceItem[]>(
    []
  )
  const [error, setError] = useState<ApolloError>(undefined)
  const orgName = useOrgName()
  const [fetchMaintenanceItems, { loading: isAllMaintenanceDuelistLoading }] =
    useLazyQuery(GET_ALL_MAINTENANCE_DUE_LIST, {
      onCompleted: (data) => {
        setMaintenanceItems(data.maintenanceItems)
      },
      onError: (error) => {
        setError(error)
      },
    })

  const loading = isAllMaintenanceDuelistLoading

  const loadMaintenanceItems = (params: MaintenanceItemFilters) => {
    params.orgSlug = orgName
    fetchMaintenanceItems({ variables: { filters: params } })
  }

  return {
    maintenanceItems,
    aircrafts: [],
    loading,
    error,
    loadMaintenanceItems,
  }
}

export default useDuelistRecords
