import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AuditLogState {
  aircraftId: string
}

const initialState: AuditLogState = {
  aircraftId: '',
}

const auditLogSlice = createSlice({
  name: 'auditLog',
  initialState,
  reducers: {
    // also used for BE permission checks
    setAircraftId: (state: AuditLogState, action: PayloadAction<string>) => {
      state.aircraftId = action.payload
    },
  },
})

export const { setAircraftId } = auditLogSlice.actions

export default auditLogSlice.reducer
