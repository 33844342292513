import { getNextDueCadenceType } from '@wingwork/common/src/maintenanceItem'
import dayjs from 'dayjs'
import { ComplianceActivity, InternalWorkItem } from 'types/graphql'
import * as Yup from 'yup'

import { RadioGroupOption } from 'src/components/MUI/RadioGroup'
import { SelectOption } from 'src/components/MUI/SelectDropdown'

export const taskTypeOptions: SelectOption[] = [
  {
    label: 'MEL',
    value: 'MEL',
  },
  {
    label: 'NEF',
    value: 'NEF',
  },
  {
    label: 'Watch List',
    value: 'WATCH_LIST',
  },
  {
    label: 'Discrepancy',
    value: 'DISCREPANCY',
  },
]

export const melCategoryOptions: SelectOption[] = [
  {
    label: 'Category A',
    value: 'catA',
  },
  {
    label: 'Category B',
    value: 'catB',
  },
  {
    label: 'Category C',
    value: 'catC',
  },
  {
    label: 'Category D',
    value: 'catD',
  },
]

export const deferredOptions: RadioGroupOption[] = [
  {
    label: 'Yes',
    value: 'yes',
    disabled: false,
  },
  {
    label: 'No',
    value: 'no',
    disabled: false,
  },
]

export const melCatToDaysMap = {
  catA: 0,
  catB: 3,
  catC: 10,
  catD: 120,
}

export const schema = Yup.object().shape({
  discrepancyDeferred: Yup.string()
    .oneOf(['yes', 'no'])
    .required("Select 'yes' or 'no'"),
  adSbType: Yup.string()
    .oneOf(['MEL', 'NEF', 'WATCH_LIST', 'DISCREPANCY'])
    .required('Select type'),
  nextDueCategory: Yup.string().when(['discrepancyDeferred', 'adSbType'], {
    is: (discrepancyDeferred, adSbType) => {
      return (
        discrepancyDeferred === 'yes' &&
        (adSbType === 'MEL' || adSbType === 'NEF')
      )
    },
    then: (fieldSchema) => fieldSchema.required('Select category'),
  }),
  nextDueDate: Yup.string().when(['discrepancyDeferred'], {
    is: (discrepancyDeferred) => {
      return discrepancyDeferred === 'yes'
    },
    then: (fieldSchema) => fieldSchema.required('Select date'),
  }),
  title: Yup.string().required('Enter title'),
  trackedByComponent: Yup.mixed().when('isStandaloneDiscrepancy', {
    is: true,
    then: (schema) =>
      Yup.object()
        .shape({
          id: Yup.string().required(),
          name: Yup.string().required(),
        })
        .nullable()
        .required('Select component'),
    otherwise: (schema) => schema.nullable(),
  }),
})

export const getMassagedMtxItemInput = (
  workPackageId: string,
  isUpdateMode: boolean,
  isWorkOrder: boolean,
  aircraftId: string,
  values: any,
  complianceOrWorkOrder: ComplianceActivity | InternalWorkItem,
  aircraftUsageLogId: string,
  orgName: string,
  userId: string,
  userPin: string
) => {
  const getDiscrepancyStatus = () => {
    if (values.discrepancyDeferred === 'yes') return 'DEFERRED'
    if (values.statusClosed) return 'CLOSED'
    return 'OPEN'
  }

  const getNextDueStatus = () => {
    if (values.discrepancyDeferred === 'yes') return 'GOOD'
    if (values.statusClosed) return 'NOT_DUE'
    return 'GOOD'
  }

  const getComplianceInformation = () => {
    if (values.discrepancyDeferred === 'yes')
      return {
        importedDataCompliance: {},
      }
    return {
      importedDataCompliance: {},
      lastComplianceDate: dayjs().toISOString(),
      lastComplianceStampId: aircraftUsageLogId,
    }
  }

  const getFieldsByMode = (isUpdateMode: boolean, userId: string) => {
    if (!isUpdateMode) {
      return {
        createdById: userId,
      }
    }
    return {}
  }

  const getFieldsByType = (isWorkOrder: boolean) => {
    const payload = {}
    if (values.isStandaloneDiscrepancy) {
      payload['isStandaloneDiscrepancy'] = true
    } else {
      if (isWorkOrder) {
        payload['discrepancyWorkItemId'] = complianceOrWorkOrder.id
      } else {
        payload['discrepancyCompActivityId'] = complianceOrWorkOrder.id
      }
    }
    return payload
  }

  const massagedMtxItem = {
    orgSlug: orgName,
    ataCode: '',
    cadenceType: 'CALENDAR_MONTHS',
    cadenceValue: {},
    disposition: '',
    isOptional: false,
    isRecurring: false,
    isParent: false,
    isAdSb: true,
    maintenanceType: 'UNSCHEDULED',
    manufactureCode: '',
    metadata: {},
    status: 'ENABLED',
    remainingValue: -1,
    trackedByComponentId: values.trackedByComponent?.id,

    title: values.title,
    adSbType: values.adSbType,
    aircraftId: aircraftId,
    description: values.description,
    nextDueStatus: getNextDueStatus(),
    notes: values.notes,
    discrepancyStatus: getDiscrepancyStatus(),
    verifyPin: true,
    verifyUserId: userId,
    verifyUserPin: userPin,
    ...getFieldsByMode(isUpdateMode, userId),
    ...getFieldsByType(isWorkOrder),
    ...getComplianceInformation(),
  }

  const massagedMtxNextDue = getMassagedMtxNextDueInput(
    values,
    orgName,
    userId,
    userPin
  )

  const getWorkPackageIdPayload = () => {
    if (isUpdateMode) {
      return {}
    }
    if (isWorkOrder) {
      return { workOrderId: workPackageId }
    }
    return { complianceLedgerId: workPackageId }
  }

  return {
    maintenanceItem: massagedMtxItem,
    maintenanceNextDue: massagedMtxNextDue,
    ...getWorkPackageIdPayload(),
  }
}

const getMassagedMtxNextDueInput = (
  values: any,
  orgName: string,
  userId: string,
  userPin: string
) => {
  const nextDueVal = {
    category: values.nextDueCategory || undefined,
    date: values.nextDueDate || undefined,
    flying_hours: values.nextDueHours || undefined,
    landings: values.nextDueLandings || undefined,
  }
  return {
    orgSlug: orgName,
    isCompleted: false,
    nextDueValue: nextDueVal,
    nextDueType: getNextDueCadenceType(nextDueVal),
    verifyPin: true,
    verifyUserId: userId,
    verifyUserPin: userPin,
  }
}
