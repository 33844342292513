import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define the type for a single search state
interface SearchStateItem {
  rawText?: string
  [key: string]: string
}

// Define the type for our state object
interface SearchState {
  [discriminator: string]: SearchStateItem
}

const initialState: SearchState = {}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // Update the search state for a specific discriminator
    updateSearchState: (
      state,
      action: PayloadAction<{
        discriminator: string
        searchState: SearchStateItem
      }>
    ) => {
      const { discriminator, searchState } = action.payload
      state[discriminator] = {
        ...state[discriminator],
        ...searchState,
      }
    },

    // Update a specific category within a search state
    updateSearchCategory: (
      state,
      action: PayloadAction<{
        discriminator: string
        category: string
        value: string
      }>
    ) => {
      const { discriminator, category, value } = action.payload
      if (!state[discriminator]) {
        state[discriminator] = {}
      }
      state[discriminator][category] = value
    },

    // Delete a specific category from a search state
    deleteSearchCategory: (
      state,
      action: PayloadAction<{
        discriminator: string
        category: string
      }>
    ) => {
      const { discriminator, category } = action.payload
      if (state[discriminator]) {
        const { [category]: _, ...rest } = state[discriminator]
        state[discriminator] = rest
      }
    },
  },
})

export const { updateSearchState, updateSearchCategory, deleteSearchCategory } =
  searchSlice.actions

// Selectors
export const selectSearchState = (
  state: { search: SearchState },
  discriminator: string
) => state.search[discriminator] ?? {}

export default searchSlice.reducer
