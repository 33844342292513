export const GET_TAGS = gql`
  query tags {
    tags {
      id
      name
    }
  }
`

export const CREATE_DISCREPANCY_ITEM_TRANSACTION = gql`
  mutation CreateDiscrepancyItem(
    $input: CreateMaintenanceItemTransactionInput!
  ) {
    createMaintenanceItemTransaction(input: $input) {
      id
      adSbType
      discrepancyStatus
      status
      title
      description
      notes
      discrepancyCompActivityId
      discrepancyWorkItemId
      tags {
        id
        name
      }
      trackedByComponent {
        id
        name
      }
      maintenanceNextDue {
        id
        nextDueValue
      }
      aircraftComponent {
        id
      }
    }
  }
`
export const UPDATE_DISCREPANCY_ITEM_TRANSACTION = gql`
  mutation UpdateDiscrepancyItem(
    $id: String!
    $input: UpdateMaintenanceItemTransactionInput!
    $isDeferred: Boolean!
  ) {
    updateMaintenanceItemTransaction(
      id: $id
      input: $input
      isDeferred: $isDeferred
    ) {
      id
      discrepancyStatus
      status
      title
      description
      notes
      discrepancyCompActivityId
      discrepancyWorkItemId
      tags {
        id
        name
      }
      trackedByComponent {
        id
        name
      }
      maintenanceNextDue {
        id
        nextDueValue
      }
      aircraftComponent {
        id
      }
    }
  }
`

export const DELETE_DISCREPANCY_ITEM = gql`
  mutation DeleteDiscrepancyItem(
    $id: String!
    $input: DeleteMaintenanceItemInput!
  ) {
    deleteMaintenanceItem(id: $id, input: $input) {
      id
    }
  }
`
