import {
  MonetizationOnOutlined,
  ShoppingBagOutlined,
  StoreOutlined,
  AttachFile,
} from '@mui/icons-material'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import {
  Chip,
  Divider,
  TextField as MUITextField,
  Skeleton,
} from '@mui/material'
import { StaticDatePicker } from '@mui/x-date-pickers'
import { useMutation } from '@redwoodjs/web'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { toast } from 'react-hot-toast'
import AircraftTimeModal from 'src/components/AircraftTimeModal/AircraftTimeModal'
import InlinePopoverField from 'src/components/InlinePopoverField'
import Button from 'src/components/MUI/Button'
import WorkflowAircraftDetailsDrawer from 'src/components/WorkflowAircraftDetailsDrawer'
import WorkflowHeader from 'src/components/WorkflowHeader'
import useModal from 'src/hooks/useModal'
import useQuery from 'src/hooks/useQuery'
import Sentry from 'src/lib/sentry'
import { centsToDollars } from 'src/utils/helpers'
import {
  GET_WORK_ORDER_BASE_FIELDS,
  GET_WORK_ORDER_ATTACHMENTS,
  UPDATE_WORK_ORDER,
  UPDATE_WORK_ORDER_STATUS,
} from '../queries'
import { formatDateForDisplay } from '@wingwork/common/src/helpers'
import useHasPermission from 'src/hooks/useHasPermission'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useDispatch } from 'src/hooks/useDispatch'
import { setAircraftId } from 'src/slices/auditLog'
import WorkflowAttachmentsSection from 'src/components/workflow/WorkflowAttachmentsSection/WorkflowAttachmentsSection'
import { WorkflowAttachmentType } from 'src/components/workflow/WorkflowAttachmentsSection'
interface WorkOrderHeaderProps {
  id: string
}

export const WorkOrderHeader: React.FC<WorkOrderHeaderProps> = ({ id }) => {
  const dispatch = useDispatch()

  const { data, loading } = useQuery(GET_WORK_ORDER_BASE_FIELDS, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      // make sure to set the aircraft id for BE permit checks
      dispatch(setAircraftId(data.internalWorkOrder.aircraft.id))
    },
  })
  const {
    data: attachmentsData,
    loading: attachmentsLoading,
    refetch: refetchAttachments,
  } = useQuery(GET_WORK_ORDER_ATTACHMENTS, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
  const [updateWorkOrder] = useMutation(UPDATE_WORK_ORDER)
  const [updateWorkOrderStatus] = useMutation(UPDATE_WORK_ORDER_STATUS)

  const { handleOpen: openAircraftDetailsDrawer } = useModal(
    'workflowAircraftDetailsDrawer'
  )

  const { handleOpen: openAircraftTimeModal } = useModal('aircraftTimeModal')

  const onApplyTimes = (usageLogId: string) => {
    updateWorkOrder({
      variables: {
        id: data.internalWorkOrder.id,
        input: {
          aircraftUsageLogId: usageLogId,
        },
      },
      onCompleted: (data) => {
        const dateDisplay = formatDateForDisplay(
          data.updateInternalWorkOrder.aircraftUsageLog.usageAsOf
        )
        toast.success(`Applied latest times from ${dateDisplay} to compliance`)
      },
      update: (cache, { data: { updateInternalWorkOrder } }) => {
        cache.modify({
          id: cache.identify(updateInternalWorkOrder),
          fields: {
            aircraftUsageLog: () => updateInternalWorkOrder.aircraftUsageLog,
            aircraftUsageLogId: () =>
              updateInternalWorkOrder.aircraftUsageLogId,
          },
        })
      },
    })
  }

  const noWorkItems =
    data?.internalWorkOrder?.workItems?.length === 0 &&
    data?.internalWorkOrder?.customWorkItems?.length === 0

  const allTasksReadyForReview =
    !noWorkItems &&
    data?.internalWorkOrder?.workItems?.length ===
      data?.internalWorkOrder?.workItems?.filter(
        (workItem) => workItem.status === 'REVIEW'
      ).length &&
    data?.internalWorkOrder?.customWorkItems?.length ===
      data?.internalWorkOrder?.customWorkItems?.filter(
        (customWorkItem) => customWorkItem.status === 'REVIEW'
      ).length

  const appliedUsageLog = useMemo(() => {
    return data?.internalWorkOrder?.aircraftUsageLog
  }, [data?.internalWorkOrder?.aircraftUsageLog])
  const aircraftUsageLogId = appliedUsageLog?.id

  const canUpdateWorkOrder = useHasPermission(
    Permissions.workOrder.update,
    data?.internalWorkOrder?.aircraft?.id
  )

  return (
    <>
      <WorkflowHeader
        loading={loading}
        showAppliedTimes
        appliedUsageLog={appliedUsageLog}
        onUsageLogClick={() => {
          openAircraftDetailsDrawer({
            highlightAppliedTimes: true,
          })
        }}
        title={data?.internalWorkOrder?.workOrderNumber}
        createdAt={data?.internalWorkOrder?.createdAt}
        tailNumber={data?.internalWorkOrder?.aircraft?.tailNumber}
        slots={{
          statusChip: loading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <Chip
              className="px-1"
              size="small"
              variant="filled"
              label={
                data?.internalWorkOrder?.status === 'IN_REVIEW'
                  ? 'In Review'
                  : 'In Progress'
              }
              color="warning"
            />
          ),
          topRightFields: (
            <>
              <Button
                variant="outlined"
                color="base"
                onClick={openAircraftDetailsDrawer}
              >
                AIRCRAFT DETAILS
              </Button>
              <Button
                variant={aircraftUsageLogId ? 'outlined' : 'contained'}
                color={aircraftUsageLogId ? 'base' : 'primary'}
                disabled={noWorkItems || loading}
                locked={!canUpdateWorkOrder}
                lockedTooltip="You do not have permission"
                onClick={() =>
                  openAircraftTimeModal({
                    aircraftId: data?.internalWorkOrder?.aircraft?.id,
                  })
                }
              >
                {aircraftUsageLogId ? 'CHANGE TIMES' : 'APPLY TIMES'}
              </Button>
              <Button
                onClick={async () => {
                  await updateWorkOrderStatus({
                    variables: {
                      id,
                      status: 'IN_REVIEW',
                    },
                    optimisticResponse: {
                      updateInternalWorkOrder: {
                        __typename: 'InternalWorkOrder',
                        id,
                        status: 'IN_REVIEW',
                      },
                    },
                    onError: (error) => {
                      toast.error('Failed to update work order status')
                      Sentry.captureException(error)
                    },
                  })
                }}
                disabled={!allTasksReadyForReview || loading}
                disabledTooltip="Please mark all tasks as complete first"
                locked={!canUpdateWorkOrder}
                lockedTooltip="You do not have permission"
              >
                REVIEW
              </Button>
            </>
          ),
          inlineFields: (
            <>
              <Divider orientation="vertical" flexItem variant="middle" />
              <InlinePopoverField
                loading={loading}
                label="Start Date"
                value={formatDateForDisplay(data?.internalWorkOrder?.startedAt)}
                icon={CalendarTodayOutlinedIcon}
                name="startedAt"
                triggerOn={{ change: false, onClose: false }}
                onSubmit={(val) => {
                  return updateWorkOrder({
                    variables: {
                      id: data.internalWorkOrder.id,
                      input: {
                        startedAt: val,
                      },
                    },
                  })
                }}
              >
                {({ submit, handleClose, value }) => (
                  <StaticDatePicker
                    value={dayjs(value)}
                    disableHighlightToday
                    onClose={handleClose}
                    onAccept={(date) => submit(date.toISOString())}
                  />
                )}
              </InlinePopoverField>

              <Divider orientation="vertical" flexItem variant="middle" />

              <InlinePopoverField
                loading={loading}
                label="End Date"
                value={formatDateForDisplay(data?.internalWorkOrder?.endedAt)}
                icon={CalendarTodayOutlinedIcon}
                name="endedAt"
                triggerOn={{ change: false, onClose: false }}
                onSubmit={(val) => {
                  return updateWorkOrder({
                    variables: {
                      id: data.internalWorkOrder.id,
                      input: {
                        endedAt: val,
                      },
                    },
                  })
                }}
              >
                {({ handleClose, submit, value }) => (
                  <>
                    <StaticDatePicker
                      value={dayjs(value)}
                      disableHighlightToday
                      onClose={handleClose}
                      onAccept={(date) => submit(date.toISOString())}
                    />
                  </>
                )}
              </InlinePopoverField>

              <Divider orientation="vertical" flexItem variant="middle" />

              <InlinePopoverField
                loading={loading}
                label="Vendor"
                value={data?.internalWorkOrder?.vendor}
                icon={StoreOutlined}
                name="vendor"
                triggerOn={{ enter: true, blur: true, onClose: true }}
                onSubmit={(val) => {
                  return updateWorkOrder({
                    variables: {
                      id: data.internalWorkOrder.id,
                      input: {
                        vendor: val,
                      },
                    },
                  })
                }}
              >
                {({
                  onChange,
                  value,
                  onKeyDown,
                  handleClose,
                  handleCancel,
                }) => (
                  <MUITextField
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    className="p-2"
                    size="small"
                    onKeyDown={(e) => {
                      onKeyDown?.(e)
                      if (e.key === 'Enter') {
                        handleClose()
                      }
                      if (e.key === 'Escape') {
                        handleCancel()
                      }
                    }}
                  />
                )}
              </InlinePopoverField>

              <Divider orientation="vertical" flexItem variant="middle" />

              <InlinePopoverField
                loading={loading}
                label="Shop"
                value={data?.internalWorkOrder?.shop}
                icon={ShoppingBagOutlined}
                name="shop"
                triggerOn={{ enter: true, blur: true, onClose: true }}
                onSubmit={(val) => {
                  return updateWorkOrder({
                    variables: {
                      id: data.internalWorkOrder.id,
                      input: {
                        shop: val,
                      },
                    },
                  })
                }}
              >
                {({
                  onChange,
                  value,
                  onKeyDown,
                  handleClose,
                  handleCancel,
                }) => (
                  <MUITextField
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    size="small"
                    className="p-2"
                    onKeyDown={(e) => {
                      onKeyDown?.(e)
                      if (e.key === 'Enter') {
                        handleClose()
                      }
                      if (e.key === 'Escape') {
                        handleCancel()
                      }
                    }}
                  />
                )}
              </InlinePopoverField>

              <Divider orientation="vertical" flexItem variant="middle" />

              <InlinePopoverField
                loading={loading}
                label="Est. Cost"
                value={`$${centsToDollars(
                  data?.internalWorkOrder?.estimatedLaborCost
                )}`}
                icon={MonetizationOnOutlined}
                name="estimatedLaborCost"
              />
              <Divider orientation="vertical" flexItem variant="middle" />
              <InlinePopoverField
                loading={attachmentsLoading}
                label="Attachments"
                value={`(${
                  attachmentsData?.internalWorkOrder?.attachedFiles?.length || 0
                })`}
                icon={AttachFile}
                name="attachments"
                triggerOn={{ enter: false, blur: false, onClose: false }}
              >
                {({ handleClose }) => {
                  const attachables = useMemo(
                    () => [
                      {
                        id: attachmentsData?.internalWorkOrder?.id,
                        type: 'InternalWorkOrder' as const,
                      },
                    ],
                    [attachmentsData?.internalWorkOrder?.id]
                  )

                  const attachments: WorkflowAttachmentType[] = useMemo(() => {
                    return [
                      ...(attachmentsData?.internalWorkOrder?.attachedFiles?.map(
                        (file) =>
                          file?.file
                            ? {
                                ...file.file,
                                category: 'InternalWorkOrder',
                              }
                            : null
                      ) ?? []),
                    ].filter(Boolean) as WorkflowAttachmentType[]
                  }, [attachmentsData?.internalWorkOrder?.attachedFiles])

                  return (
                    <div className="max-w-lg py-2">
                      <WorkflowAttachmentsSection
                        data={attachments}
                        attachables={attachables}
                        refetchAttachments={() => {
                          refetchAttachments({
                            id,
                          })
                          handleClose()
                        }}
                        variant={'default'}
                      />
                    </div>
                  )
                }}
              </InlinePopoverField>
            </>
          ),
        }}
      />

      <WorkflowAircraftDetailsDrawer
        aircraftId={data?.internalWorkOrder?.aircraft?.id}
        aircraftUsageLogId={aircraftUsageLogId}
      />

      <AircraftTimeModal
        onSuccess={(id) => {
          onApplyTimes(id)
        }}
      />
    </>
  )
}

export default WorkOrderHeader
