import React, { useMemo } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import _ from 'lodash'

import { Form, useForm } from '@redwoodjs/forms'
import { toast } from 'react-hot-toast'

import { useDispatch } from 'src/hooks/useDispatch'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal } from 'src/slices/modal'

import Button from '../MUI/Button'

import FormFields from './FormFields'
import DrawerActions from '../MUI/Drawer/DrawerActions'
import DrawerHeader from '../MUI/Drawer/DrawerHeader'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import LoadingButton from '../MUI/LoadingButton'

type BooleanFields = {
  description?: boolean
  correctiveAction?: boolean
  taskNotes?: boolean
  workedById?: boolean
}

type WorkflowTasksBulkEditDrawerProps = {
  isWorkOrder: boolean
  selectedTaskIds: readonly string[]
  selectedCustomTaskIds?: readonly string[]
  filteredTasks: any[]
  onSubmit: (args: {
    values: any
    showOverrideOrAppendToggle: BooleanFields
    overrideOrAppend: BooleanFields
    canApplyToOthers: BooleanFields
    dirtyFields: BooleanFields
  }) => void
}

const modalName = 'workflowTasksBulkEditDrawer'

const WorkflowTasksBulkEditDrawer: React.FC<
  WorkflowTasksBulkEditDrawerProps
> = ({
  isWorkOrder,
  selectedTaskIds,
  selectedCustomTaskIds = [],
  filteredTasks,
  onSubmit,
}) => {
  const [overrideOrAppend, setOverrideOrAppend] = React.useState({
    description: true,
    correctiveAction: true,
    taskNotes: true,
    workedById: true,
  })

  // keeps track of which fields have been modified to determine which fields to bulk update
  const [dirtyFields, setDirtyFields] = React.useState({
    description: false,
    correctiveAction: false,
    taskNotes: false,
    workedById: false,
  })

  const dispatch = useDispatch()
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false
  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }

  const selectedTasksData = useMemo(() => {
    const tasks = []
    filteredTasks?.forEach((task) => {
      if (selectedTaskIds.includes(task.id)) {
        tasks.push(task)
      }
      if (task?.childWorkItems) {
        task?.childWorkItems?.forEach((childTask) => {
          if (selectedTaskIds.includes(childTask.id)) {
            tasks.push(childTask)
          }
        })
      }
      if (task?.childActivities) {
        task?.childActivities?.forEach((childTask) => {
          if (selectedTaskIds.includes(childTask.id)) {
            tasks.push(childTask)
          }
        })
      }
      if (task?.isCustomWorkItem && selectedCustomTaskIds.includes(task.id)) {
        tasks.push(task)
      }
    })
    return tasks
  }, [filteredTasks, selectedTaskIds])

  const uniqueSelectedTasksDescription = [
    ...new Set(selectedTasksData.map((task) => task.description)),
  ]

  const uniqueTruthySelectedTasksDescription = uniqueSelectedTasksDescription
    .filter((description) => description !== '')
    .filter((description) => description)

  const uniqueSelectedTasksCorrectiveAction = [
    ...new Set(selectedTasksData.map((task) => task?.correctiveAction)),
  ]

  const uniqueTruthySelectedTasksCorrectiveAction =
    uniqueSelectedTasksCorrectiveAction
      .filter((correctiveAction) => correctiveAction !== '')
      .filter((correctiveAction) => correctiveAction)

  const uniqueSelectedTasksNotes = [
    ...new Set(selectedTasksData.map((task) => task?.notes)),
  ]

  const uniqueTruthySelectedTasksNotes = uniqueSelectedTasksNotes
    .filter((notes) => notes !== '')
    .filter((notes) => notes)

  const uniqueSelectedTasksMechanics = [
    ...new Set(selectedTasksData.map((task) => task.workedById)),
  ]

  const uniqueTruthySelectedTasksMechanics =
    uniqueSelectedTasksMechanics.filter((mechanic) => mechanic) // not null

  const conflict = {
    description: uniqueTruthySelectedTasksDescription.length > 1,
    workedById: uniqueTruthySelectedTasksMechanics.length > 1,
    correctiveAction: uniqueTruthySelectedTasksCorrectiveAction.length > 1,
    taskNotes: uniqueTruthySelectedTasksNotes.length > 1,
  }

  // allow user to apply unique truthy value to all other tasks with null/empty values

  const canApplyToOthers = {
    description:
      uniqueTruthySelectedTasksDescription.length === 1 &&
      uniqueSelectedTasksDescription.length > 1,
    workedById:
      uniqueTruthySelectedTasksMechanics.length === 1 &&
      uniqueSelectedTasksMechanics.length > 1,
    correctiveAction:
      uniqueTruthySelectedTasksCorrectiveAction.length === 1 &&
      uniqueSelectedTasksCorrectiveAction.length > 1,
    taskNotes:
      uniqueTruthySelectedTasksNotes.length === 1 &&
      uniqueSelectedTasksNotes.length > 1,
  }

  const showOverrideOrAppendToggle = {
    description: dirtyFields.description && conflict.description,
    correctiveAction: dirtyFields.correctiveAction && conflict.correctiveAction,
    taskNotes: dirtyFields.taskNotes && conflict.taskNotes,
  }

  // disable reset and save button if no fields have been modified and user cannot apply to all
  const bulkEditDisabled =
    !dirtyFields.description &&
    !dirtyFields.correctiveAction &&
    !dirtyFields.taskNotes &&
    !canApplyToOthers.description &&
    !canApplyToOthers.correctiveAction &&
    !canApplyToOthers.taskNotes

  const initialFormData = {
    task: {
      description:
        uniqueTruthySelectedTasksDescription.length === 1
          ? uniqueTruthySelectedTasksDescription[0]
          : '',
      correctiveAction:
        uniqueTruthySelectedTasksCorrectiveAction.length === 1
          ? uniqueTruthySelectedTasksCorrectiveAction[0]
          : '',
      notes:
        uniqueTruthySelectedTasksNotes.length === 1
          ? uniqueTruthySelectedTasksNotes[0]
          : '',
      workedById:
        uniqueTruthySelectedTasksMechanics.length === 1
          ? uniqueTruthySelectedTasksMechanics[0]
          : undefined, // if switch to null will override all mechanics to not selected
    },
  }

  const formMethods = useForm()

  React.useEffect(() => {
    if (open) {
      formMethods.reset(initialFormData)
    }
  }, [open])

  const updateOverrideOrAppend = (field: string, value: boolean) => {
    setOverrideOrAppend((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleSubmit = async (values) => {
    try {
      onSubmit({
        values: values.task,
        showOverrideOrAppendToggle,
        overrideOrAppend,
        canApplyToOthers,
        dirtyFields,
      })
      formMethods.reset(values)
    } catch (error) {
      toast.error('Error updating tasks')
    }
  }

  const handleReset = () => {
    formMethods.reset(initialFormData)
  }

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <DrawerHeader title="Bulk Edit" onClose={handleClose} />
      <div className="flex h-full flex-col">
        <Form
          id="workflowTasksBulkEditForm"
          formMethods={formMethods}
          onSubmit={() => formMethods.handleSubmit(handleSubmit)()}
          className="flex h-full flex-col"
        >
          <div className={`flex h-full px-3 py-3`}>
            <div className="flex h-full w-1/2 flex-col  border-0 border-r border-solid border-gray-300 pr-3">
              <Typography
                variant="subtitle2"
                id="tasks-selected-title"
                className="mb-3"
              >
                Tasks Selected
              </Typography>
              <TableContainer className="max-h-[calc(100vh-250px)] overflow-y-auto">
                <Table size={'medium'}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '22%' }}>ATA / CODE</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedTasksData.map((task, idx) => (
                      <TableRow key={idx}>
                        <TableCell style={{ width: '22%' }}>
                          {task?.isCustomWorkItem
                            ? 'Custom'
                            : `${task.maintenanceItem.ataCode} - ${task.maintenanceItem.manufactureCode}`}
                        </TableCell>
                        <TableCell>
                          {task?.isCustomWorkItem
                            ? task.title
                            : task.maintenanceItem.title}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <FormFields
              isWorkOrder={isWorkOrder}
              conflict={{
                description: conflict.description,
                correctiveAction: conflict.correctiveAction,
                taskNotes: conflict.taskNotes,
                workedById: conflict.workedById,
              }}
              canApplyToOthers={{
                description: canApplyToOthers.description,
                correctiveAction: canApplyToOthers.correctiveAction,
                taskNotes: canApplyToOthers.taskNotes,
                workedById: canApplyToOthers.workedById,
              }}
              overrideOrAppend={overrideOrAppend}
              updateOverrideOrAppend={updateOverrideOrAppend}
              dirtyFields={dirtyFields}
              setDirtyFields={setDirtyFields}
              selectedTaskIds={selectedTaskIds}
              selectedCustomTaskIds={selectedCustomTaskIds}
              formMethods={formMethods}
              open={open}
            />
          </div>
        </Form>
      </div>
      <DrawerActions
        className="gap-1"
        leftActions={
          <Button variant="text" color="black" onClick={handleClose}>
            Cancel
          </Button>
        }
        rightActions={
          <div className="flex w-full items-center justify-end gap-2">
            <Button
              variant="outlined"
              color="base"
              tabIndex={-1}
              onClick={handleReset}
              disabled={bulkEditDisabled}
            >
              Reset
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              form="workflowTasksBulkEditForm"
              disabled={bulkEditDisabled}
            >
              Save Bulk Changes
            </LoadingButton>
          </div>
        }
      />
    </Drawer>
  )
}

export default WorkflowTasksBulkEditDrawer
