import { gql } from '@apollo/client'
import coreAircraftUsageLogFragment from 'src/fragments/AircraftUsageLog'
import {
  DueStatusChipFieldsFragment,
  DueStatusChipAircraftFieldsFragment,
} from 'src/components/DueStatusChip/queries'

export const ATTACHMENTS_FRAGMENT = gql`
  fragment Attachments on AttachedFile {
    id
    file {
      id
      url
      originalFilename
      uploadedBy {
        id
        firstName
        lastName
      }
      createdAt
    }
  }
`

export const ComplianceLedgerBaseFieldsFragment = gql`
  ${coreAircraftUsageLogFragment}
  fragment ComplianceLedgerBaseFields on ComplianceLedger {
    id
    status
    createdAt
    aircraftUsageLogId
    name
    createdAt
    updatedAt
    aircraft {
      id
      tailNumber
    }
    aircraftUsageLog {
      id
      usageAsOf
    }
    aircraftUsageLog {
      ...CoreAircraftUsageLogFields
    }
    complianceActivity {
      id
      status
    }
    requestedInspectorId
    inspectedById
    requestedWorkerId
    workedById
  }
`

export const GET_COMPLIANCE_LEDGER_BASE_FIELDS = gql`
  ${ComplianceLedgerBaseFieldsFragment}
  query GetComplianceLedgerBaseFields($id: String!) {
    complianceLedger(id: $id) {
      ...ComplianceLedgerBaseFields
    }
  }
`

export const GET_COMPLIANCE_LEDGER_ATTACHMENTS = gql`
  ${ATTACHMENTS_FRAGMENT}
  query GetComplianceLedgerAttachments($id: String!) {
    complianceLedger(id: $id) {
      id
      attachedFiles {
        ...Attachments
      }
    }
  }
`

const ComplianceActivityTableDataFragment = gql`
  ${DueStatusChipFieldsFragment}
  fragment ComplianceActivityTableData on ComplianceActivity {
    id
    status
    description
    correctiveAction
    notes
    workedById
    workHours
    workedBy {
      id
      firstName
      lastName
    }
    nextDue {
      id
      nextDueType
      nextDueValue
      nextDueOverride
    }
    partsTransaction {
      id
      installedStatus
      otherInstalledStatus
      removalReason
      otherRemovalReason
      addedPartNumber
      addedSerialNumber
      removedPartNumber
      removedSerialNumber
    }
    maintenanceItem {
      id
      ataCode
      manufactureCode
      title
      ataManufactureCode
      relatedMaintenanceItemIds
      componentID
      parentId
      isEndOfMonthAdjustment
      ...DueStatusChipFields
      trackedByComponent {
        id
        name
      }
      aircraftComponent {
        id
        name
      }
      aircraft {
        id
      }
    }
    discrepancyItems {
      id
      discrepancyStatus
      adSbType
      status
      title
      description
      notes
      discrepancyCompActivityId
      discrepancyWorkItemId
      tags {
        id
        name
      }
      trackedByComponent {
        id
        name
      }
      maintenanceNextDue {
        id
        nextDueValue
      }
      aircraftComponent {
        id
      }
    }
  }
`
export const GET_COMPLIANCE_LEDGER_TASK_LIST = gql`
  ${ComplianceActivityTableDataFragment}
  query GetComplianceLedgerTaskList($id: String!) {
    complianceActivities(
      filters: { complianceLedgerId: $id, omitChildren: true }
    ) {
      ...ComplianceActivityTableData
      childActivities {
        ...ComplianceActivityTableData
      }
    }
  }
`

export const GET_COMPLIANCE_LEDGER_TASK_DETAILS_BY_MTX_ID = gql`
  ${ComplianceActivityTableDataFragment}
  query GetComplianceLedgerTaskDetailsByMtxId($maintenanceItemIds: [String!]!) {
    complianceActivities(filters: { maintenanceItemIds: $maintenanceItemIds }) {
      ...ComplianceActivityTableData
      childActivities {
        ...ComplianceActivityTableData
      }
    }
  }
`

export const COMPLIANCE_LEDGER_AIRCRAFT = gql`
  ${DueStatusChipAircraftFieldsFragment}
  query ComplianceLedgerAircraft($id: String!) {
    complianceLedger(id: $id) {
      id
      aircraft {
        ...DueStatusChipAircraftFields
      }
    }
  }
`

export const GET_COMPLIANCE_LEDGER_TASK_DETAILS = gql`
  ${ComplianceActivityTableDataFragment}
  query GetComplianceLedgerTaskDetails($id: String!) {
    complianceActivity(id: $id) {
      ...ComplianceActivityTableData
    }
  }
`

export const COMPLETE_COMPLIANCE_ACTIVITY = gql`
  mutation CompleteComplianceActivity($id: String!) {
    updateComplianceActivity(id: $id, input: { status: REVIEW }) {
      id
      status
    }
  }
`

export const GET_MECHANICS = gql`
  query GetMechanics {
    users {
      id
      firstName
      lastName
    }
  }
`

export const SET_WORK_INFORMATION = gql`
  mutation SetWorkInformation(
    $input: UpdateComplianceActivityInput!
    $id: String!
  ) {
    updateComplianceActivity(input: $input, id: $id) {
      id
      workedBy {
        id
        firstName
        lastName
      }
      workHours
      notes
      description
    }
  }
`

export const PartsTransactionFieldsFragment = gql`
  fragment PartsTransactionFields on PartsTransaction {
    id
    removedPartNumber
    removedSerialNumber
    removedPartFiles {
      id
      url
      originalFilename
    }

    addedPartNumber
    addedSerialNumber
    addedPartFiles {
      id
      url
      originalFilename
    }

    removalReason
    otherRemovalReason
    installedStatus
    otherInstalledStatus

    monthsSinceNew
    monthsSinceOverhaul
    monthsSinceRepair
    hoursSinceNew
    hoursSinceOverhaul
    hoursSinceRepair
    cyclesSinceNew
    cyclesSinceOverhaul
    cyclesSinceRepair
  }
`

export const GET_PARTS_TRANSACTION = gql`
  ${PartsTransactionFieldsFragment}
  query GetPartsTransaction($id: String!) {
    complianceActivity(id: $id) {
      id
      purchaseOrderItem {
        id
      }
      maintenanceItem {
        id
        aircraftComponent {
          id
          partNumber
          serialNumber
          monthsSinceNew
          monthsSinceOverhaul
          monthsSinceRepair
          hoursSinceNew
          hoursSinceOverhaul
          hoursSinceRepair
          cyclesSinceNew
          cyclesSinceOverhaul
          cyclesSinceRepair
        }
      }
      partsTransaction {
        ...PartsTransactionFields
      }
    }
  }
`
export const UPDATE_COMPLIANCE_LEDGER = gql`
  ${ComplianceLedgerBaseFieldsFragment}
  mutation UpdateComplianceLedger(
    $id: String!
    $input: UpdateComplianceLedgerInput!
  ) {
    updateComplianceLedger(id: $id, input: $input) {
      ...ComplianceLedgerBaseFields
    }
  }
`

export const GET_ALL_ATTACHMENTS = gql`
  ${ATTACHMENTS_FRAGMENT}
  query GetAllAttachments($id: String!) {
    complianceLedger(id: $id) {
      id
      complianceActivity {
        id
        attachedFiles {
          ...Attachments
        }
      }
    }
  }
`
export const GET_COMPLIANCE_ACTIVITY_ATTACHMENTS = gql`
  ${ATTACHMENTS_FRAGMENT}
  query GetComplianceActivityAttachments($id: String!) {
    complianceActivity(id: $id) {
      id
      attachedFiles {
        id
        ...Attachments
      }
    }
  }
`

export const ADD_TASKS_TO_COMPLIANCE_LEDGER = gql`
  ${ComplianceActivityTableDataFragment}
  mutation AddTasksToComplianceLedger($ledgerId: String!, $ids: [String!]!) {
    addMaintenanceItemsToComplianceLedger(
      id: $ledgerId
      input: { maintenanceItemIds: $ids }
    ) {
      id
      complianceActivity(filters: { omitChildren: true }) {
        ...ComplianceActivityTableData
        childActivities {
          ...ComplianceActivityTableData
        }
      }
    }
  }
`

export const ADD_TASKS_TO_COMPLIANCE_LEDGER_V2 = gql`
  ${ComplianceActivityTableDataFragment}
  mutation AddTasksToComplianceLedgerV2($ledgerId: String!, $ids: [String!]!) {
    addMaintenanceItemsToComplianceLedgerV2(
      input: { complianceLedgerId: $ledgerId, maintenanceItemIds: $ids }
    ) {
      ...ComplianceActivityTableData
      childActivities {
        ...ComplianceActivityTableData
      }
    }
  }
`
export const BULK_UPDATE_COMPLIANCE_ACTIVITY = gql`
  ${ComplianceActivityTableDataFragment}
  mutation BulkUpdateComplianceActivity(
    $ids: [String!]!
    $input: BulkUpdateComplianceActivityInput!
    $overrideOrAppend: OverrideOrAppendInput
    $updateFields: BulkEditTasksUpdateFieldsInput
  ) {
    bulkUpdateComplianceActivity(
      ids: $ids
      input: $input
      overrideOrAppend: $overrideOrAppend
      updateFields: $updateFields
    ) {
      ...ComplianceActivityTableData
      childActivities {
        ...ComplianceActivityTableData
      }
    }
  }
`

export const REMOVE_TASKS_FROM_COMPLIANCE_LEDGER = gql`
  mutation RemoveTasksFromComplianceLedger($ids: [String!]!) {
    bulkDeleteComplianceActivity(ids: $ids) {
      id
    }
  }
`

export const UPDATE_COMPLIANCE_LEDGER_STATUS = gql`
  mutation UpdateComplianceLedgerStatus(
    $id: String!
    $status: ComplianceStatus!
  ) {
    updateComplianceLedger(id: $id, input: { status: $status }) {
      id
      status
    }
  }
`

export const DELETE_COMPLIANCE_LEDGER = gql`
  mutation DeleteComplianceLedger($id: String!) {
    deleteComplianceLedger(id: $id) {
      id
    }
  }
`

export const GET_COMPLIANCE_LEDGER_TASK_LIST_PAGINATED = gql`
  ${ComplianceActivityTableDataFragment}
  query GetComplianceLedgerTaskListPaginated(
    $id: String!
    $pagination: PaginationInput!
  ) {
    complianceActivitiesPaginated(
      filters: { complianceLedgerId: $id }
      pagination: $pagination
    ) {
      edges {
        ...ComplianceActivityTableData
        childActivities {
          ...ComplianceActivityTableData
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        pageIndex
      }
    }
  }
`
