import { Autocomplete, Chip, TextField, Popper } from '@mui/material'
import { Tag } from 'types/graphql'

import { Controller } from '@redwoodjs/forms'

type TagModalFormProps = {
  control: any
  options: Tag[]
  name?: string
}

const TagModalForm: React.FC<TagModalFormProps> = ({
  control,
  options,
  name = 'tags',
}) => {
  const onChange = (field: any, value: Tag[]) => {
    const massaged = value.reduce((acc: Tag[], curr: Tag) => {
      const objectified = typeof curr === 'string' ? { name: curr } : curr
      const existing = acc.find((item) => item.name === objectified.name)
      if (!existing) {
        acc.push(objectified)
      }
      return acc
    }, [])

    field.onChange(massaged)
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          options={options}
          freeSolo
          onChange={(event, value) => onChange(field, value)}
          PopperComponent={(props) => (
            <Popper
              {...props}
              open={
                props.open &&
                document.activeElement?.getAttribute('aria-autocomplete') ===
                  'list'
              }
            />
          )}
          getOptionLabel={(option) => option.name}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Tags"
              size="small"
            />
          )}
        />
      )}
    />
  )
}

export default TagModalForm
