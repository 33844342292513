import React, { createContext, useEffect, useState, ReactNode } from 'react'
import { createMongoAbility, MongoAbility } from '@casl/ability'
import { useAuth } from '@clerk/clerk-react'
import { Permit } from 'permit-fe-sdk'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import Loading from 'src/components/Loading'
import useHasFeature from 'src/hooks/useHasFeature'
import Button from 'src/components/MUI/Button'
import { Alert } from '@mui/material'
// Create Context
export const AbilityContext = createContext<MongoAbility | undefined>(undefined)

interface AbilityLoaderProps {
  children: ReactNode
  orgSlug: string
  userId: string
}

const getAllPermissionsToCheck = (aircraftIds: string[]) => {
  const basePermissions = [
    ...Object.values(Permissions.user),
    ...Object.values(Permissions.purchaseOrder),
  ]

  const aircraftSpecificPermissions = aircraftIds.flatMap((aircraftId) =>
    [
      ...Object.values(Permissions.compliance),
      ...Object.values(Permissions.maintenanceItem),
      ...Object.values(Permissions.workOrder),
    ].map((permission) => ({
      action: permission.action,
      resource: `${permission.resource}:${aircraftId}`,
    }))
  )

  return [...basePermissions, ...aircraftSpecificPermissions]
}

export const AbilityLoader: React.FC<AbilityLoaderProps> = ({
  children,
  orgSlug,
  userId,
}) => {
  const {
    hasFeature: orgHasPermissions,
    loading: orgHasPermissionsLoading,
    hasLoaded: orgHasPermissionsLoaded,
  } = useHasFeature('Permissions', orgSlug)
  const isRbacEnabled = process.env.RBAC_ENABLED === 'true'
  const { getToken } = useAuth()
  const [ability, setAbility] = useState<MongoAbility | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)

  const [showErrorBanner, setShowErrorBanner] = useState(false)

  useEffect(() => {
    const getAbility = async () => {
      if (orgHasPermissionsLoading || !orgHasPermissionsLoaded) return
      if (!isRbacEnabled || !orgHasPermissions) {
        setIsLoading(false)
        return
      }
      if (!userId) {
        setIsLoading(false)
        return
      }

      setIsLoading(true)

      try {
        const token = await getToken()
        const permit = Permit({
          loggedInUser: userId,
          backendUrl: '/.netlify/functions/checkPermissions',
          customRequestHeaders: {
            orgSlug,
            Authorization: `Bearer ${token}`,
            'auth-provider': 'clerk',
          },
        })
        permit.reset()

        const response = await fetch(
          '/.netlify/functions/getAccessibleAircraftIds',
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'auth-provider': 'clerk',
            },
          }
        )
        const { data: aircraftIds } = await response.json()

        const permissions = getAllPermissionsToCheck(aircraftIds)
        await permit.loadLocalStateBulk(permissions)

        const caslConfig = permit.getCaslJson()

        const newAbility =
          caslConfig && caslConfig.length
            ? createMongoAbility(caslConfig)
            : undefined

        setAbility(newAbility)
      } catch (error) {
        setShowErrorBanner(true)
      } finally {
        setIsLoading(false)
      }
    }

    getAbility()
  }, [
    orgSlug,
    orgHasPermissions,
    orgHasPermissionsLoading,
    userId,
    orgHasPermissionsLoaded,
  ])

  if (isLoading || orgHasPermissionsLoading || !orgHasPermissionsLoaded) {
    return <Loading />
  }

  return (
    <AbilityContext.Provider value={ability}>
      {showErrorBanner && (
        <Alert
          severity="error"
          className="fixed right-4 top-5 z-50"
          action={
            <Button
              color="white"
              size="small"
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>
          }
          onClose={() => setShowErrorBanner(false)}
        >
          Failed to load permissions. Defaulting to read-only access. Please
          reload the page to try again.
        </Alert>
      )}
      {children}
    </AbilityContext.Provider>
  )
}
