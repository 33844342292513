import { Typography } from '@mui/material'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { AircraftUsageLog } from 'types/graphql'

import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'
import { useDispatch } from 'src/hooks/useDispatch'
import useQuery from 'src/hooks/useQuery'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, setModalData } from 'src/slices/modal'

import Loading from '../Loading'
import Button from '../MUI/Button'
import { DataProp } from '../Table'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table'
import { getDefaultMRTOptions } from 'src/utils/getDefaultMRTOptions'
import { useMemo } from 'react'
import PrimSecTextCombo from '../common/PrimSecTextCombo'

const GET_USAGE_LOGS = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query SelectAircraftUsageLog($aircraftId: String!) {
    aircraftUsageLogs(aircraftId: $aircraftId) {
      ...CoreAircraftUsageLogFields
    }
  }
`

type RowData = DataProp & AircraftUsageLog

type SelectTimeProps = {
  onSuccess?: (aircraftUsageLogId: string) => void
}

const CellData: React.FC<{
  columnKey: string
  data: RowData
  trackedByComponentName?: string
}> = ({ data, trackedByComponentName, columnKey }) => {
  const componentLog = data.ComponentUsageLog.find(
    (log) => log.component.name === (trackedByComponentName ?? 'Airframe')
  )
  if (!componentLog) return null
  switch (columnKey) {
    case 'date':
      return (
        <Typography variant="body2" className="min-w-[60px]">
          {dayjs(data.usageAsOf).format('MMM DD, YYYY')}
        </Typography>
      )
    case 'hours-landings':
      return (
        <div className="flex items-center gap-2">
          <PrimSecTextCombo
            primaryText={componentLog.totalTimeSinceNew}
            secondaryText="Hours"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={componentLog.cycleSinceNew}
            secondaryText="Landings"
            variant="inverted"
          />
        </div>
      )
    case 'hours-cycles':
      return (
        <div className="flex items-center gap-2">
          <PrimSecTextCombo
            primaryText={componentLog.totalTimeSinceNew}
            secondaryText="Hours"
            variant="inverted"
          />
          <PrimSecTextCombo
            primaryText={componentLog.cycleSinceNew}
            secondaryText="Cycles"
            variant="inverted"
          />
        </div>
      )
  }
}

const SelectTime: React.FC<SelectTimeProps> = ({ onSuccess }) => {
  const dispatch = useDispatch()
  const { aircraftId, loading = false } = useSelector(
    (state) => state.modal.modals?.aircraftTimeModal?.data
  )
  const { data, loading: aircraftUsageLogsLoading } = useQuery(GET_USAGE_LOGS, {
    variables: {
      aircraftId,
    },
    onCompleted: () => {
      dispatch(
        setModalData({
          name: 'aircraftTimeModal',
          data: { loading: false },
        })
      )
    },
  })

  const handleSelect = (aircraftUsageLogId: string) => {
    onSuccess(aircraftUsageLogId)
    dispatch(closeModal({ name: 'aircraftTimeModal' }))
  }

  const defaultTableOptions = getDefaultMRTOptions<any>()

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: 'date',
        header: 'Date',
        size: 150,
        Cell: ({ row }) => <CellData columnKey="date" data={row.original} />,
      },
      {
        id: 'airframe',
        header: `Airframe`,
        size: 150,
        Cell: ({ row }) => (
          <CellData
            columnKey="hours-landings"
            data={row.original}
            trackedByComponentName={'Airframe'}
          />
        ),
      },
      {
        id: 'engine1',
        header: `Engine 1`,
        size: 150,
        Cell: ({ row }) => (
          <CellData
            columnKey="hours-cycles"
            data={row.original}
            trackedByComponentName={'Engine 1'}
          />
        ),
      },
      {
        id: 'engine2',
        header: `Engine 2`,
        size: 150,
        Cell: ({ row }) => (
          <CellData
            columnKey="hours-cycles"
            data={row.original}
            trackedByComponentName={'Engine 2'}
          />
        ),
      },
      {
        id: 'actions',
        header: '',
        size: 100,
        Cell: ({ row }) => (
          <div className="flex justify-end gap-1">
            <Button
              disabledTooltip="Upcoming feature: Edit this time"
              tooltip="Edit this time"
              disabled
              variant="outlined"
              size="small"
            >
              EDIT
            </Button>

            <Button
              tooltip="Select this time"
              variant="outlined"
              size="small"
              onClick={() => {
                handleSelect(row.original.id)
              }}
            >
              APPLY
            </Button>
          </div>
        ),
      },
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    columns,
    data: data?.aircraftUsageLogs || [],
    enableTopToolbar: false,
    enableColumnFilters: false,
    enablePagination: true,
    muiTablePaperProps: {
      elevation: 0,
      className: ' border border-solid border-gray-200 w-full',
    },
    muiTableContainerProps: {
      className: 'overflow-auto',
    },
    // Add this to control column sizing
    defaultColumn: {
      size: 'auto', // default column width
    },
    state: {
      isLoading: aircraftUsageLogsLoading,
      columnPinning: {
        right: ['actions'],
        left: ['date'],
      },
    },
  })

  return (
    <>
      <div
        className={clsx({
          'flex h-52 items-center justify-center': true,
          hidden: !loading && !aircraftUsageLogsLoading,
        })}
      >
        <Loading />
      </div>
      <div
        className={clsx({
          'flex flex-col gap-2 px-2': true,
          hidden: loading || aircraftUsageLogsLoading,
        })}
      >
        <Typography variant="h6">Historical Usage Log</Typography>
        <MaterialReactTable table={table} />
      </div>
    </>
  )
}

export default SelectTime
