/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider } from '@mui/material'
import { Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import { AircraftUsageLog, MaintenanceItem } from 'types/graphql'
import { useApolloClient } from '@apollo/client'
import coreAircraftUsageLogFragment from 'src/fragments/AircraftUsageLog'
import dayjs from 'dayjs'
import RemainingStatusChip from 'src/components/RemainingStatusChip'
import {
  calculateNextDueValue,
  cadenceValueSchema,
  getRemainingValueFromNextDueValue,
} from '@wingwork/common/src/jsonObjects'
import useModal from 'src/hooks/useModal'
import EndOfMonthAdjustmentCheckbox from 'src/components/workflow/WorkPerformed/EndOfMonthAdjustmentCheckbox'

interface SingleComplianceDetailsSectionProps {
  aircraftId: string
  aircraftUsageLogId: string
  maintenanceItem?: MaintenanceItem
}

const SingleComplianceDetailsSection = ({
  aircraftId,
  aircraftUsageLogId,
  maintenanceItem,
}: SingleComplianceDetailsSectionProps) => {
  const { handleOpen: handleOpenAircraftTimeModal } = useModal(
    'aircraftTimeModal',
    'singleComplianceForm'
  )
  const client = useApolloClient()

  const [nextDueValue, setNextDueValue] = useState(maintenanceItem?.maintenanceNextDue?.[0]?.nextDueValue ?? {})

  const cadenceValue = maintenanceItem?.cadenceValue
  const trackedByComponent = maintenanceItem?.trackedByComponent
  const usageLog: AircraftUsageLog = client.readFragment({
    id: client.cache.identify({
      __typename: 'AircraftUsageLog',
      id: aircraftUsageLogId,
    }),
    fragment: coreAircraftUsageLogFragment,
  })

  //TODO: This will fail if selected aircraftUsageLogId has missing non-integral components
  const componentUsageLog = React.useMemo(
    () =>
      usageLog?.ComponentUsageLog?.find(
        (log) => log.component.id === trackedByComponent.id
      ),
    [usageLog, trackedByComponent]
  )

  const calculatedRemainingValue = useMemo(() => {
    if (
      !componentUsageLog?.id ||
      !aircraftUsageLogId
    )
    {
      setNextDueValue({})
      return '----'
    }

    const calculatedNextDueValue = calculateNextDueValue(
      maintenanceItem.cadenceType,
      cadenceValueSchema.cast(cadenceValue),
      componentUsageLog,
      componentUsageLog?.usageAsOf,
      maintenanceItem.isEndOfMonthAdjustment
    )

    const mtxNextDueObj = {
      nextDueValue: calculatedNextDueValue ?? {},
    }
    setNextDueValue(calculatedNextDueValue ?? {})

    const remVal = getRemainingValueFromNextDueValue(
      mtxNextDueObj,
      cadenceValue as any,
      componentUsageLog
    )
    delete remVal['status']
    delete remVal['note']
    return (
      <div className="flex items-center justify-end gap-1">
        {Object.keys(remVal).length > 0 ? (
          Object.keys(remVal).map((key, id) => {
            return <RemainingStatusChip key={id} remVal={remVal[key]} />
          })
        ) : (
          <RemainingStatusChip
            remVal={{ status: 'NOT_DUE', valString: 'Not Due' }}
          />
        )}
      </div>
    )
  }, [trackedByComponent, componentUsageLog, aircraftUsageLogId, cadenceValue, maintenanceItem?.isEndOfMonthAdjustment])

  return (
    <div className="mb-2 flex flex-col gap-1 px-3">
      <div className="mb-1 flex items-center justify-between">
        <Typography variant="h6" className="self-end">
          Compliance
        </Typography>
        <div className="flex items-center gap-2">
          {aircraftUsageLogId && (
            <div className="flex items-center gap-1">
              <Typography variant="caption" className="text-right">
                Updated remaining time
              </Typography>
              {calculatedRemainingValue}
            </div>
          )}
          <Button
            size="small"
            variant="outlined"
            className="self-end"
            color={aircraftUsageLogId ? 'warning' : 'primary'}
            onClick={() => handleOpenAircraftTimeModal({ aircraftId })}
          >
            {aircraftUsageLogId ? 'Change Times' : 'Apply Times'}
          </Button>
        </div>
      </div>

      {aircraftUsageLogId ? (
        <>
          <div>
            <Typography variant="overline">Times</Typography>
            <div className="grid grid-cols-3">
              <PrimSecTextCombo
                primaryText={
                  usageLog?.usageAsOf
                    ? dayjs(usageLog?.usageAsOf).format('MM/DD/YYYY')
                    : null
                }
                secondaryText="Date"
                variant="inverted"
              />

              <PrimSecTextCombo
                primaryText={componentUsageLog?.totalTimeSinceNew}
                secondaryText="Hours"
                variant="inverted"
              />

              <PrimSecTextCombo
                primaryText={componentUsageLog?.cycleSinceNew}
                secondaryText={
                  trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                    ? 'Landings'
                    : 'Cycles'
                }
                variant="inverted"
              />
            </div>
            <Divider className="my-2" />
          </div>
          <div>
            <div className="flex items-center justify-between">
              <Typography variant="overline">Next Due</Typography>
              {maintenanceItem && (
                <EndOfMonthAdjustmentCheckbox
                  maintenanceItemId={maintenanceItem.id}
                  maintenanceItem={maintenanceItem}
                  aircraftUsageLogId={aircraftUsageLogId}
                  onNextDueUpdated={(newNextDueValue) => {
                    setNextDueValue(newNextDueValue);
                  }}
                />
              )}
            </div>
            <div>
              <div className="grid grid-cols-3">
                <PrimSecTextCombo
                  secondaryText="Date"
                  variant="inverted"
                  primaryText={
                    aircraftUsageLogId && nextDueValue?.date
                      ? dayjs(nextDueValue?.date).format('MMM DD, YYYY')
                      : '--'
                  }
                />
                <PrimSecTextCombo
                  primaryText={
                    aircraftUsageLogId
                      ? nextDueValue?.flying_hours ?? '--'
                      : '--'
                  }
                  secondaryText="Hours"
                  variant="inverted"
                />
                {trackedByComponent?.name?.toUpperCase() === 'AIRFRAME' ? (
                  <PrimSecTextCombo
                    primaryText={
                      aircraftUsageLogId ? nextDueValue?.landings ?? '--' : '--'
                    }
                    secondaryText="Landings"
                    variant="inverted"
                  />
                ) : (
                  <PrimSecTextCombo
                    primaryText={
                      aircraftUsageLogId ? nextDueValue?.cycles ?? '--' : '--'
                    }
                    secondaryText="Cycles"
                    variant="inverted"
                  />
                )}
              </div>
            </div>
            <Divider className="my-2" />
          </div>
          <div>
            <Typography variant="overline">Intervals</Typography>
            <div className="grid grid-cols-3">
              <PrimSecTextCombo
                secondaryText="Months"
                variant="inverted"
                primaryText={cadenceValue?.months ?? 'N/A'}
              />
              <PrimSecTextCombo
                primaryText={cadenceValue?.flying_hours ?? 'N/A'}
                secondaryText="Hours"
                variant="inverted"
              />
              <PrimSecTextCombo
                primaryText={
                  trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                    ? cadenceValue.landings ?? 'N/A'
                    : cadenceValue.cycles ?? 'N/A'
                }
                secondaryText={
                  trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                    ? 'Landings'
                    : 'Cycles'
                }
                variant="inverted"
              />
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center">
          <Typography variant="body1">Apply times first</Typography>
        </div>
      )}
      <Divider flexItem className="mt-2 border-b-2" />
    </div>
  )
}

export default SingleComplianceDetailsSection
