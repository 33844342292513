import { Button, Modal, Paper, Typography } from '@mui/material'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import Loading from 'src/components/Loading'
import useCreatePurchaseOrderPdf from 'src/hooks/requests/useCreatePurchaseOrderPdf'
import useUploadFile from 'src/hooks/requests/useUploadFile'
import { useDispatch } from 'src/hooks/useDispatch'
import { useOrgName } from 'src/hooks/useOrgName'
import { useSelector } from 'src/hooks/useSelector'
import { closeModal, openModal } from 'src/slices/modal'
import { downloadBlobAsFile } from 'src/utils/helpers'

import { UPDATE_PURCHASE_ORDER_MUTATION } from './queries'

interface PurchaseOrderReviewModalProps {
  purchaseOrderId: string
}

const modalName = 'purchaseOrderReviewModal'
const PurchaseOrderReviewModal: React.FC<PurchaseOrderReviewModalProps> = ({
  purchaseOrderId,
}) => {
  const orgName = useOrgName()
  const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER_MUTATION)

  const [pdfLoaded, setPdfLoaded] = React.useState(false)
  const [pdfUrl, setPdfUrl] = React.useState('' as string)
  const [pdfFailed, setPdfFailed] = React.useState(false)

  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(closeModal({ name: modalName }))
  }
  const open =
    useSelector((state) => state.modal.modals?.[modalName]?.isOpen) ?? false

  const { createPurchaseOrderPdf } = useCreatePurchaseOrderPdf()

  const pdfData = useSelector(
    (state) => state.modal.modals?.[modalName]?.data?.pdfData
  )

  React.useEffect(() => {
    if (open) {
      setPdfLoaded(false)
      setPdfFailed(false)
      createPurchaseOrderPdf(pdfData, {
        onCompleted: (data) => {
          setPdfUrl(data.createPurchaseOrderPdf)
          setPdfLoaded(true)
        },
        onError: (error) => {
          console.error(error)
          toast.error(
            `Something went wrong while loading the document.\nPlease contact support.`,
            { duration: 3000 }
          )
          setPdfFailed(true)
        },
      })
    }
  }, [open])

  const uploadFile = useUploadFile()

  const handleConfirm = async () => {
    try {
      // Turn into blob
      const response = await fetch(pdfUrl)
      const pdfBlob = new Blob([await response.blob()], {
        type: 'application/pdf',
      })

      // Convert blob into File to be uploaded to supabase
      const pdfFile = new File([pdfBlob], `PO-${pdfData.number}.pdf`, {
        type: 'application/pdf',
      })
      await uploadFile(pdfFile, [
        { id: purchaseOrderId, type: 'PurchaseOrderPdf' },
      ])

      downloadBlobAsFile(pdfBlob, `PO-${pdfData.number}.pdf`)

      // update state from draft to open
      // since we render the open page based on status, upload the pdf before updating the status
      await updatePurchaseOrder({
        variables: {
          id: purchaseOrderId,
          input: {
            status: 'OPEN',
          },
        },
      })

      toast.success('Purchase Order Confirmed', { duration: 3000 })

      handleClose()
      dispatch(
        openModal({
          name: 'sendPurchaseOrderModal',
          data: { autoDownloaded: true },
        })
      )
    } catch (error) {
      console.error(error)
      toast.error(
        `Something went wrong while creating the purchase order.\nPlease contact support.`,
        { duration: 3000 }
      )
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className="absolute left-1/2 top-1/2 w-140 -translate-x-1/2 -translate-y-1/2 transform rounded-xl  bg-white p-3">
        <div className="flex flex-col gap-2">
          <Typography variant="h6" component="h2">
            Review Purchase Order
          </Typography>
          {pdfFailed && (
            <Typography variant="body2" color="error">
              Something went wrong while loading the document. Please try again
              later.
            </Typography>
          )}
          {!pdfFailed && !pdfLoaded && <Loading />}
          {!pdfFailed && pdfLoaded && (
            <iframe
              title="purchaseOrder"
              src={pdfUrl}
              className="aspect-[1.4142_/_1] w-full"
            />
          )}

          <div className="flex justify-between">
            <Button
              color="base"
              variant="outlined"
              onClick={handleClose}
              size="large"
            >
              KEEP EDITING
            </Button>
            <Button
              size="large"
              disabled={pdfFailed || !pdfLoaded}
              onClick={handleConfirm}
            >
              CREATE PURCHASE ORDER
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  )
}

export default PurchaseOrderReviewModal
