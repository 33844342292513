import { toast } from 'react-hot-toast'
import { FeatureName } from '@wingwork/common/src/constants/features'
import { useEffect } from 'react'
import useQuery from 'src/hooks/useQuery'

export const GET_FEATURE_SETTING = gql`
  query GetFeatureSetting($input: TenantFeatureQueryInput) {
    tenantFeatures(input: $input) {
      featureName
      enabled
      orgSlug
    }
  }
`

// Global cache of enabled features per org
const enabledFeaturesCache: Record<string, Set<FeatureName>> = {}
// Track if we've fetched for an org
const hasFetched: Record<string, boolean> = {}
// Track loading state per org
const loadingOrgs: Record<string, boolean> = {}

// Utility function to directly check if a feature is enabled for an org
export const hasFeature = (
  featureName: FeatureName,
  orgSlug: string
): boolean => {
  if (!orgSlug) return false
  return enabledFeaturesCache[orgSlug]?.has(featureName) ?? false
}

const useHasFeature = (featureName: FeatureName, orgSlug: string) => {
  const { loading, hasLoaded } = useQuery(GET_FEATURE_SETTING, {
    variables: { input: { orgSlug } },
    skip: !orgSlug || hasFetched[orgSlug] || loadingOrgs[orgSlug],
    onCompleted: (data) => {
      const enabledFeatures = new Set<FeatureName>(
        (data.tenantFeatures ?? [])
          .filter((feature) => feature.enabled)
          .map((feature) => feature.featureName)
      )
      enabledFeaturesCache[orgSlug] = enabledFeatures
      hasFetched[orgSlug] = true
      loadingOrgs[orgSlug] = false
    },
    onError: (error) => {
      console.error(error)
      toast.error(
        'Something went wrong determining your feature settings. Please contact support.'
      )
      loadingOrgs[orgSlug] = false
      hasFetched[orgSlug] = true
    },
  })

  // Set loading state when query starts
  // it prevents race conditions when the query is called multiple times for the same org
  useEffect(() => {
    if (loading && orgSlug) {
      loadingOrgs[orgSlug] = true
    }
    return () => {
      if (loading && orgSlug && loadingOrgs[orgSlug]) {
        loadingOrgs[orgSlug] = false
      }
    }
  }, [loading, orgSlug])

  return {
    hasFeature: enabledFeaturesCache[orgSlug]?.has(featureName) ?? false,
    loading: loading || loadingOrgs[orgSlug],
    hasLoaded: hasLoaded || hasFetched[orgSlug],
  }
}

export default useHasFeature
