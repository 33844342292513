import { Typography } from '@mui/material'
import React from 'react'
import clsx from 'clsx'

const Badge = ({
  children,
  active = true,
}: {
  children: React.ReactNode
  active?: boolean
}) => {
  return (
    <div
      className={clsx(
        'inline-flex items-center justify-center rounded-full px-1 py-0.25',
        active
          ? 'bg-sys-primary-main text-white'
          : 'bg-sys-lightgrey text-black'
      )}
    >
      <Typography variant="body2">{children}</Typography>
    </div>
  )
}

export default Badge
