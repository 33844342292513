import { Divider, Skeleton } from '@mui/material'
import { Typography } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import Button from 'src/components/MUI/Button'
import LoadingButton from 'src/components/MUI/LoadingButton'
import {
  InternalWorkItem,
  ComplianceActivity,
  AircraftUsageLog,
} from 'types/graphql'
import { useApolloClient } from '@apollo/client'
import coreAircraftUsageLogFragment from 'src/fragments/AircraftUsageLog'
import dayjs from 'dayjs'
import RemainingStatusChip from 'src/components/RemainingStatusChip'
import {
  getRemainingValueFromNextDueValue,
  nextDueValueSchema,
} from '@wingwork/common/src/jsonObjects'
import { getNextDueCadenceType } from '@wingwork/common/src/maintenanceItem'
import DatePicker from 'src/components/MUI/DatePicker'
import TextField from 'src/components/MUI/TextField'
import { Form, useForm, useWatch } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { findChanges } from 'src/utils/helpers'
import EndOfMonthAdjustmentCheckbox from 'src/components/workflow/WorkPerformed/EndOfMonthAdjustmentCheckbox'

const UPSERT_MTX_NEXT_DUE = gql`
  mutation UpsertMtxNextDueInWorkOrderComplianceWorkflow(
    $id: String
    $input: UpsertMaintenanceNextDueInput!
  ) {
    upsertMaintenanceNextDue(id: $id, input: $input) {
      id
      nextDueType
      nextDueValue
      nextDueOverride
    }
  }
`

interface ComplianceDetailsSectionProps {
  aircraftUsageLogId: string
  task: InternalWorkItem | ComplianceActivity
  flashSaved?: () => void
  flashError?: () => void
}

const WorkflowComplianceDetailsSection = ({
  aircraftUsageLogId,
  task,
  flashSaved,
  flashError,
}: ComplianceDetailsSectionProps) => {
  if (!aircraftUsageLogId)
    return (
      <Typography variant="body2" className="text-center">
        Apply times first
      </Typography>
    )
  if (!task || !task.maintenanceItem) return <Skeleton variant="rectangular" />
  const [overridingNextDue, setOverridingNextDue] = React.useState(false)
  const [
    upsertMaintenanceNextDue,
    { loading: upsertMaintenanceNextDueLoading },
  ] = useMutation(UPSERT_MTX_NEXT_DUE)
  const client = useApolloClient()
  const formMethods = useForm({
    defaultValues: {
      nextDueOverride:
        task.nextDue?.nextDueOverride ?? task.nextDue?.nextDueValue,
    },
  })

  const nextDueRootValue = useMemo(() => {
    return task.nextDue?.nextDueOverride ?? task.nextDue?.nextDueValue
  }, [task.nextDue?.nextDueOverride, task.nextDue?.nextDueValue])

  const nextDueOverride = useWatch({
    control: formMethods.control,
    name: 'nextDueOverride',
  })
  const cleanedNextDueOverride = nextDueValueSchema.cast(nextDueOverride ?? {})

  delete cleanedNextDueOverride['note']
  delete cleanedNextDueOverride['status']

  const nextDueOverrideCycles = useWatch({
    control: formMethods.control,
    name: 'nextDueOverride.cycles',
  })
  const nextDueOverrideLandings = useWatch({
    control: formMethods.control,
    name: 'nextDueOverride.landings',
  })
  const nextDueOverrideDate = useWatch({
    control: formMethods.control,
    name: 'nextDueOverride.date',
  })
  const nextDueOverrideFlyingHours = useWatch({
    control: formMethods.control,
    name: 'nextDueOverride.flying_hours',
  })

  const isRecurringTask = useMemo(() => {
    return task.nextDue !== null && task.nextDue !== undefined
  }, [task])

  const { cadenceValue, trackedByComponent } = task?.maintenanceItem || {}
  const usageLog: AircraftUsageLog = client.readFragment({
    id: client.cache.identify({
      __typename: 'AircraftUsageLog',
      id: aircraftUsageLogId,
    }),
    fragment: coreAircraftUsageLogFragment,
  })

  //TODO: This will fail if selected aircraftUsageLogId has missing non-integral components
  const componentUsageLog = React.useMemo(
    () =>
      usageLog?.ComponentUsageLog?.find(
        (log) => log.component.id === trackedByComponent.id
      ),
    [usageLog, trackedByComponent]
  )

  const calculatedRemainingValue = useMemo(() => {
    if (
      !trackedByComponent ||
      !trackedByComponent?.id ||
      !aircraftUsageLogId
    )
      return '----'
    const mtxNextDueObj = {
      nextDueValue: nextDueRootValue ?? {},
      nextDueOverride: nextDueValueSchema.cast(cleanedNextDueOverride),
    }

    const remVal = getRemainingValueFromNextDueValue(
      mtxNextDueObj,
      cadenceValue as any,
      componentUsageLog
    )
    delete remVal['status']
    delete remVal['note']
    return (
      <div className="flex items-center justify-end gap-1">
        {Object.keys(remVal).length > 0 ? (
          Object.keys(remVal).map((key, id) => {
            return <RemainingStatusChip key={id} remVal={remVal[key]} />
          })
        ) : (
          <RemainingStatusChip
            remVal={{ status: 'NOT_DUE', valString: 'Not Due' }}
          />
        )}
      </div>
    )
  }, [
    overridingNextDue,
    cleanedNextDueOverride,
    trackedByComponent,
    componentUsageLog,
    cadenceValue,
    nextDueOverrideCycles,
    nextDueOverrideLandings,
    nextDueOverrideDate,
    nextDueOverrideFlyingHours,
    task?.nextDue?.nextDueValue,
    task?.maintenanceItem?.isEndOfMonthAdjustment,
    nextDueRootValue,
  ])

  const handleSubmit = (values: any) => {
    const nextDueChanges = findChanges(
      task.nextDue?.nextDueOverride ?? {},
      values.nextDueOverride
    )
    const cleanedNextDueChanges = nextDueValueSchema.cast(nextDueChanges)
    if (Object.keys(cleanedNextDueChanges).length > 0) {
      const nextDue = task?.nextDue
      const nextDueId = nextDue?.id
      const nextDueOverride = {
        ...((task.nextDue?.nextDueOverride ?? {}) as any),
        ...cleanedNextDueChanges,
      }
      upsertMaintenanceNextDue({
        variables: {
          input: {
            nextDueOverride,
            isCompleted: false, // isCompleted is always false for new/active next due
            nextDueType: getNextDueCadenceType(nextDueOverride),
            verifyPin: false,
            nextDueValue: nextDue?.nextDueValue ?? {},
            ...(task?.__typename === 'ComplianceActivity'
              ? { complianceActivityId: task.id }
              : { internalWorkItemId: task.id }),
          },
          id: nextDueId,
        },
        update: (cache, { data: { upsertMaintenanceNextDue } }) => {
          cache.modify({
            id: cache.identify(task),
            fields: {
              nextDue: () => upsertMaintenanceNextDue,
            },
          })
        },
        onError: (_) => {
          flashError?.()
        },
        onCompleted: () => {
          setOverridingNextDue(false)
          flashSaved?.()
        },
      })
    }
  }

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <div className="flex flex-col gap-1 px-3">
        <div className="flex items-center justify-between">
          <div>
            <Typography variant="h6">Compliance</Typography>
            <Typography variant="caption">
              Apply new times to this task and adjust intervals
            </Typography>
          </div>
          <div className="flex flex-col gap-1">
            <Typography variant="caption" className="text-right">
              Updated remaining time
            </Typography>
            {calculatedRemainingValue}
          </div>
        </div>
        <div>
          <Typography variant="overline">Times</Typography>
          <div className="grid grid-cols-3">
            <PrimSecTextCombo
              primaryText={
                usageLog?.usageAsOf
                  ? dayjs(usageLog?.usageAsOf).format('MM/DD/YYYY')
                  : null
              }
              secondaryText="Date"
              variant="inverted"
            />

            <PrimSecTextCombo
              primaryText={componentUsageLog?.totalTimeSinceNew}
              secondaryText="Hours"
              variant="inverted"
            />

            <PrimSecTextCombo
              primaryText={componentUsageLog?.cycleSinceNew}
              secondaryText={
                trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                  ? 'Landings'
                  : 'Cycles'
              }
              variant="inverted"
            />
          </div>
          <Divider className="my-2" />
        </div>
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Typography variant="overline">Next Due</Typography>
              <Button
                variant="text"
                disabled={!aircraftUsageLogId}
                disabledTooltip="Apply times first"
                color={overridingNextDue ? 'error' : 'primary'}
                onClick={() => {
                  if (overridingNextDue) {
                    formMethods.reset({
                      nextDueOverride: nextDueRootValue,
                    })
                  }
                  setOverridingNextDue(!overridingNextDue)
                }}
              >
                {overridingNextDue ? 'Reset' : 'Edit'}
              </Button>
              {overridingNextDue && (
                <LoadingButton
                  type="submit"
                  variant="text"
                  loading={upsertMaintenanceNextDueLoading}
                >
                  Save
                </LoadingButton>
              )}
            </div>
            {task?.maintenanceItem && (
              <EndOfMonthAdjustmentCheckbox
                maintenanceItemId={task.maintenanceItem.id}
                maintenanceItem={task.maintenanceItem}
                aircraftUsageLogId={aircraftUsageLogId}
                activityId={task.__typename === 'ComplianceActivity' ? task.id : undefined}
                workItemId={task.__typename === 'InternalWorkItem' ? task.id : undefined}
                onNextDueUpdated={(newNextDueValue) => {
                  formMethods.setValue('nextDueOverride', newNextDueValue, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                  setOverridingNextDue(false)
                }}
              />
            )}
          </div>
          <div>
            {overridingNextDue ? (
              <div className="mt-1 grid grid-cols-3 gap-2">
                <DatePicker
                  label="Date"
                  name="nextDueOverride.date"
                  slotProps={{ textField: { size: 'small' } }}
                />
                <TextField
                  name="nextDueOverride.flying_hours"
                  size="small"
                  label="Hours"
                  type="number"
                  variant="outlined"
                />
                <TextField
                  name={`nextDueOverride.${
                    trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                      ? 'landings'
                      : 'cycles'
                  }`}
                  size="small"
                  type="number"
                  label={
                    trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                      ? 'Landings'
                      : 'Cycles'
                  }
                  variant="outlined"
                />
                {/* <Button
                variant="outlined"
                fullWidth
                color="pastelRed"
                onClick={handleResetOverride}
              >
                Reset
              </Button> */}
              </div>
            ) : (
              <div className="grid grid-cols-3">
                <PrimSecTextCombo
                  secondaryText="Date"
                  variant="inverted"
                  primaryText={
                    aircraftUsageLogId &&
                    isRecurringTask &&
                    nextDueRootValue?.date
                      ? dayjs(nextDueRootValue?.date).format('MMM DD, YYYY')
                      : '--'
                  }
                />
                <PrimSecTextCombo
                  primaryText={
                    aircraftUsageLogId && isRecurringTask
                      ? nextDueRootValue?.flying_hours ?? '--'
                      : '--'
                  }
                  secondaryText="Hours"
                  variant="inverted"
                />
                {trackedByComponent?.name?.toUpperCase() === 'AIRFRAME' ? (
                  <PrimSecTextCombo
                    primaryText={
                      aircraftUsageLogId && isRecurringTask
                        ? nextDueRootValue?.landings ?? '--'
                        : '--'
                    }
                    secondaryText="Landings"
                    variant="inverted"
                  />
                ) : (
                  <PrimSecTextCombo
                    primaryText={
                      aircraftUsageLogId && isRecurringTask
                        ? nextDueRootValue?.cycles ?? '--'
                        : '--'
                    }
                    secondaryText="Cycles"
                    variant="inverted"
                  />
                )}
              </div>
            )}
          </div>
          <Divider className="my-2" />
        </div>
        <div>
          <Typography variant="overline">Intervals</Typography>
          <div className="grid grid-cols-3">
            <PrimSecTextCombo
              secondaryText="Months"
              variant="inverted"
              primaryText={cadenceValue?.months ?? 'N/A'}
            />
            <PrimSecTextCombo
              primaryText={cadenceValue?.flying_hours ?? 'N/A'}
              secondaryText="Hours"
              variant="inverted"
            />
            <PrimSecTextCombo
              primaryText={
                trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                  ? cadenceValue.landings ?? 'N/A'
                  : cadenceValue.cycles ?? 'N/A'
              }
              secondaryText={
                trackedByComponent?.name?.toUpperCase() === 'AIRFRAME'
                  ? 'Landings'
                  : 'Cycles'
              }
              variant="inverted"
            />
          </div>
          <Divider className="my-2" />
        </div>
      </div>
    </Form>
  )
}

export default WorkflowComplianceDetailsSection
