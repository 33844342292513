import { OrgPermissions } from '@wingwork/common/src/constants/permissions'
import { useContext } from 'react'
import { AbilityContext } from 'src/utils/AbilityLoader'
import { hasFeature } from './useHasFeature'
import { useOrgName } from 'src/hooks/useOrgName'

const useHasPermission = (
  permission: OrgPermissions,
  aircraftId?: string | undefined
): boolean => {
  const orgSlug = useOrgName()
  const ability = useContext(AbilityContext)
  const orgHasPermissions = hasFeature('Permissions', orgSlug)

  if (process.env.RBAC_ENABLED !== 'true' || !orgHasPermissions) {
    return true
  }

  if (
    !aircraftId &&
    ['compliance', 'work-order', 'maintenance-item'].includes(
      permission.resource
    )
  ) {
    // If the resource is compliance, work-order, or maintenance-item, and the aircraftId is not provided, allow access by default because we can't check the
    return true
  }

  const resource =
    aircraftId &&
    ['compliance', 'work-order', 'maintenance-item'].includes(
      permission.resource
    )
      ? `${permission.resource}:${aircraftId}`
      : permission.resource

  return ability?.can(permission.action, resource) ?? false
}

export default useHasPermission
