export const defaultLogbookPrintOptions = {
  printSize: 'US_LETTER_8_5_X_11_PORTRAIT',
  header: 'EXPANDED',
  sorting: 'BY_ITEM_TYPE',
  componentLayout: 'TABLE',
  includeDescriptionOfWork: true,
  includeNotes: false,
  includeSubItems: false,
  includeATACodeOnly: false,
  includeTechnicianSignature: true,
  includeInspectorSignature: true,
  includeCorrectiveActionNotes: true,
  includeChildTasks: false,
  includePartRemovalReasonAndInstalledPartStatus: false,
  includeNextDueInformation: false,
  includePDFAttachments: false,
  includeDiscrepancies: false,
  includeCustomTasks: false,
}
