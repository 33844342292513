import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import { Button, FormHelperText, Typography } from '@mui/material'
import { Form, useForm } from '@redwoodjs/forms'
import { useEffect, useState } from 'react'
import FileUploadField, {
  RenderFileValue,
} from 'src/components/FileUploadField'
import Checkbox from 'src/components/MUI/Checkbox'
import Drawer from 'src/components/MUI/Drawer/Drawer'
import DrawerActions from 'src/components/MUI/Drawer/DrawerActions'
import DrawerHeader from 'src/components/MUI/Drawer/DrawerHeader'
import SelectDropdown from 'src/components/MUI/SelectDropdown'
import TextField from 'src/components/MUI/TextField'
import useUploadFile from 'src/hooks/requests/useUploadFile'
import useModal from 'src/hooks/useModal'
import {
  AircraftComponent,
  PartsTransaction,
  UpsertPartsTransactionInput,
} from 'types/graphql'
import * as yup from 'yup'
import { UPSERT_PARTS_TRANSACTION } from './queries'
import type { ApolloCache } from '@apollo/client'
import PartsTimeInputTextField from 'src/components/PartsTimeInputTextField'

interface PartTransactionDrawerProps {
  partsTransaction?: PartsTransaction
  currentPart: AircraftComponent
  complianceActivityId?: string
  workItemId?: string
  onSubmit?: (data: {
    input: UpsertPartsTransactionInput
    files: { removed: File[]; added: File[] }
  }) => void
  doMutation?: boolean
  upsertUpdateFn?: (cache: ApolloCache<any>, { data }: { data: any }) => void
  flashSaved?: () => void
  flashError?: () => void
}

// First, simplify the schema
const partTransactionSchema = yup
  .object()
  .shape({
    reapplyPart: yup.boolean(),
    removed: yup
      .object()
      .shape({
        partNumber: yup.string(),
        serialNumber: yup.string(),
        removalReason: yup.string().when(['partNumber', 'serialNumber'], {
          is: (partNumber, serialNumber) => partNumber || serialNumber,
          then: (schema) => schema.required('Removal reason is required'),
        }),
        otherRemovalReason: yup.string().when('removalReason', {
          is: (removalReason) => removalReason === 'Other',
          then: (schema) => schema.required('Other removal reason is required'),
        }),
        file: yup.mixed().nullable(),
      })
      .test(
        'part-serial-pair',
        'Part number and serial number must be provided together',
        function (value) {
          if (!value) return true
          const hasPartNumber = !!value.partNumber
          const hasSerialNumber = !!value.serialNumber

          if (hasPartNumber && !hasSerialNumber) {
            return this.createError({
              path: `${this.path}.serialNumber`,
              message: 'Serial number is required',
            })
          }

          if (!hasPartNumber && hasSerialNumber) {
            return this.createError({
              path: `${this.path}.partNumber`,
              message: 'Part number is required',
            })
          }

          return true
        }
      ),
    added: yup
      .object()
      .shape({
        partNumber: yup.string(),
        serialNumber: yup.string(),
        installedStatus: yup.string().when(['partNumber', 'serialNumber'], {
          is: (partNumber, serialNumber) => partNumber || serialNumber,
          then: (schema) => schema.required('Installed status is required'),
        }),
        otherInstalledStatus: yup.string().when('installedStatus', {
          is: (installedStatus) => installedStatus === 'Other',
          then: (schema) =>
            schema.required('Other installed status is required'),
        }),
        file: yup.mixed().nullable(),
      })
      .test(
        'part-serial-pair',
        'Part number and serial number must be provided together',
        function (value) {
          if (!value) return true
          const hasPartNumber = !!value.partNumber
          const hasSerialNumber = !!value.serialNumber

          if (hasPartNumber && !hasSerialNumber) {
            return this.createError({
              path: `${this.path}.serialNumber`,
              message: 'Serial number is required',
            })
          }

          if (!hasPartNumber && hasSerialNumber) {
            return this.createError({
              path: `${this.path}.partNumber`,
              message: 'Part number is required',
            })
          }

          return true
        }
      ),
  })
  .test(
    'unique-part',
    'Parts must be different when not reapplying',
    function (values) {
      const { reapplyPart, removed, added } = values
      if (
        !reapplyPart &&
        removed.partNumber === added.partNumber &&
        removed.serialNumber === added.serialNumber &&
        removed.partNumber &&
        added.partNumber
      ) {
        // Only validate if both parts have values
        return false
      }
      return true
    }
  )

const DEFAULT_FORM_VALUES = {
  reapplyPart: false,
  removed: {
    partNumber: '',
    serialNumber: '',
    removalReason: '',
    otherRemovalReason: '',
    file: null,
  },
  added: {
    partNumber: '',
    serialNumber: '',
    installedStatus: '',
    otherInstalledStatus: '',
    file: null,
  },
  monthsSinceNew: '',
  monthsSinceOverhaul: '',
  monthsSinceRepair: '',
  hoursSinceNew: '',
  hoursSinceOverhaul: '',
  hoursSinceRepair: '',
  cyclesSinceNew: '',
  cyclesSinceOverhaul: '',
  cyclesSinceRepair: '',
}

const PartTransactionDrawer = ({
  partsTransaction,
  currentPart,
  complianceActivityId,
  workItemId,
  onSubmit,
  doMutation = true,
  upsertUpdateFn,
  flashSaved,
  flashError,
}: PartTransactionDrawerProps) => {
  const { handleClose, isOpen } = useModal('partTransactionDrawer')

  if (!complianceActivityId && !workItemId && isOpen) {
    console.warn('No compliance activity ID or work item ID provided')
  }

  const uploadFile = useUploadFile()
  const [upsertPartsTransaction] = useMutation(UPSERT_PARTS_TRANSACTION, {
    // this creates or updates a parts transaction and updates the (compliance activity or work item) to point to it
    // this allows us to show the parts transaction in the task drawer without refetching
    update: upsertUpdateFn,
  })

  const formMethods = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(partTransactionSchema),
  })

  const removalReason = formMethods.watch('removed.removalReason')
  const installedStatus = formMethods.watch('added.installedStatus')

  useEffect(() => {
    if (!isOpen) {
      // Reset form when drawer closes
      formMethods.reset(DEFAULT_FORM_VALUES)
    }
  }, [isOpen, formMethods])

  // set form values from existing parts transaction
  // LOAD DATA VALUES
  useEffect(() => {
    if (!isOpen) {
      return
    }

    if (partsTransaction) {
      // If there's an existing parts transaction, use that data
      formMethods.reset({
        reapplyPart:
          partsTransaction.removedPartNumber ===
            partsTransaction.addedPartNumber &&
          partsTransaction.removedSerialNumber ===
            partsTransaction.addedSerialNumber,
        removed: {
          partNumber: partsTransaction.removedPartNumber || '',
          serialNumber: partsTransaction.removedSerialNumber || '',
          removalReason: partsTransaction.removalReason || '',
          otherRemovalReason: partsTransaction.otherRemovalReason || '',
          file: null,
        },
        added: {
          partNumber: partsTransaction.addedPartNumber || '',
          serialNumber: partsTransaction.addedSerialNumber || '',
          installedStatus: partsTransaction.installedStatus || '',
          otherInstalledStatus: partsTransaction.otherInstalledStatus || '',
          file: null,
        },
        monthsSinceNew: partsTransaction.monthsSinceNew || '',
        monthsSinceOverhaul: partsTransaction.monthsSinceOverhaul || '',
        monthsSinceRepair: partsTransaction.monthsSinceRepair || '',
        hoursSinceNew: partsTransaction.hoursSinceNew || '',
        hoursSinceOverhaul: partsTransaction.hoursSinceOverhaul || '',
        hoursSinceRepair: partsTransaction.hoursSinceRepair || '',
        cyclesSinceNew: partsTransaction.cyclesSinceNew || '',
        cyclesSinceOverhaul: partsTransaction.cyclesSinceOverhaul || '',
        cyclesSinceRepair: partsTransaction.cyclesSinceRepair || '',
      })
    } else if (currentPart) {
      // If no transaction exists but there is an aircraft component, use its data
      formMethods.reset({
        reapplyPart: false,
        removed: {
          partNumber: currentPart.partNumber || '',
          serialNumber: currentPart.serialNumber || '',
          removalReason: '',
          otherRemovalReason: '',
          file: null,
        },
        added: {
          partNumber: '',
          serialNumber: '',
          installedStatus: '',
          otherInstalledStatus: '',
          file: null,
        },
        monthsSinceNew: '',
        monthsSinceOverhaul: '',
        monthsSinceRepair: '',
        hoursSinceNew: '',
        hoursSinceOverhaul: '',
        hoursSinceRepair: '',
        cyclesSinceNew: '',
        cyclesSinceOverhaul: '',
        cyclesSinceRepair: '',
      })
    }
  }, [partsTransaction, currentPart, isOpen, formMethods])

  const handleSubmit = async (formData: any) => {
    try {
      const input: UpsertPartsTransactionInput = {
        // Time since values are always included
        monthsSinceNew: parseFloat(formData.monthsSinceNew) || null,
        monthsSinceOverhaul: parseFloat(formData.monthsSinceOverhaul) || null,
        monthsSinceRepair: parseFloat(formData.monthsSinceRepair) || null,
        hoursSinceNew: parseFloat(formData.hoursSinceNew) || null,
        hoursSinceOverhaul: parseFloat(formData.hoursSinceOverhaul) || null,
        hoursSinceRepair: parseFloat(formData.hoursSinceRepair) || null,
        cyclesSinceNew: parseFloat(formData.cyclesSinceNew) || null,
        cyclesSinceOverhaul: parseFloat(formData.cyclesSinceOverhaul) || null,
        cyclesSinceRepair: parseFloat(formData.cyclesSinceRepair) || null,
        ...(complianceActivityId && { complianceActivityId }),
        ...(workItemId && { workItemId }),
      }

      // Only include removed part info if removal reason is set
      if (formData.removed.removalReason) {
        input.removedPartNumber = formData.removed.partNumber
        input.removedSerialNumber = formData.removed.serialNumber
        input.removalReason = formData.removed.removalReason
        input.otherRemovalReason =
          formData.removed.removalReason === 'Other'
            ? formData.removed.otherRemovalReason
            : null
        input.removedId = currentPart?.id
      }

      // Only include added part info if installed status is set
      if (formData.added.installedStatus) {
        input.addedPartNumber = formData.added.partNumber
        input.addedSerialNumber = formData.added.serialNumber
        input.installedStatus = formData.added.installedStatus
        input.otherInstalledStatus =
          formData.added.installedStatus === 'Other'
            ? formData.added.otherInstalledStatus
            : null
      }

      if (doMutation) {
        const result = await upsertPartsTransaction({
          variables: {
            id: partsTransaction?.id ?? null,
            input,
          },
        })

        const transactionId = result.data.upsertPartsTransaction.id

        // Handle file uploads after transaction is created/updated
        if (formData.removed.file?.[0] && formData.removed.removalReason) {
          await uploadFile(formData.removed.file[0], [
            {
              id: transactionId,
              type: 'RemovedPartTransaction',
            },
          ])
        }

        if (formData.added.file?.[0] && formData.added.installedStatus) {
          await uploadFile(formData.added.file[0], [
            {
              id: transactionId,
              type: 'AddedPartTransaction',
            },
          ])
        }
      }

      onSubmit?.({
        input,
        files: { removed: formData.removed.file, added: formData.added.file },
      })
      handleClose()
      flashSaved?.()
    } catch (error) {
      console.error('Error updating parts transaction:', error)
      flashError?.()
    }
  }

  // Add a computed value to determine if removed fields should be disabled
  const shouldDisableRemovedFields =
    !partsTransaction?.removedPartNumber && !currentPart?.partNumber

  // Add watch to monitor the reapplyPart checkbox
  const { watch } = formMethods
  const reapplyPart = watch('reapplyPart')

  // Add effect to handle reapplyPart changes
  useEffect(() => {
    if (reapplyPart) {
      const removedPartNumber = formMethods.getValues('removed.partNumber')
      const removedSerialNumber = formMethods.getValues('removed.serialNumber')
      formMethods.setValue('added.partNumber', removedPartNumber)
      formMethods.setValue('added.serialNumber', removedSerialNumber)
    } else {
      formMethods.setValue('added.partNumber', '')
      formMethods.setValue('added.serialNumber', '')
    }
  }, [reapplyPart, formMethods])

  return (
    <Drawer
      anchor="right"
      onClose={handleClose}
      open={isOpen}
      title="Component Transaction"
      className="w-100"
    >
      <DrawerHeader title="Add Component" onClose={handleClose} />
      <Form
        formMethods={formMethods}
        onSubmit={handleSubmit}
        className="flex flex-grow flex-col gap-2 overflow-y-auto px-3 py-2"
        id="workflowPartTransactionForm"
      >
        {/* Removed Part */}
        <div className="flex flex-col items-start gap-2">
          <Typography variant="caption">Component Removed</Typography>
          <div className="grid grid-cols-3 gap-2">
            <TextField
              size="small"
              label="Number"
              name="removed.partNumber"
              disabled={true}
            />
            <TextField
              size="small"
              label="Serial"
              name="removed.serialNumber"
              disabled={true}
            />
            <SelectDropdown
              label="Removal Reason"
              fullWidth
              name="removed.removalReason"
              disabled={shouldDisableRemovedFields}
              options={[
                'Scheduled',
                'Premature Removal',
                'Maintenance Issue',
                'Life Limit Item',
                'Timed Item',
                'Failure',
                'Worn',
                'Other',
              ].map((status) => ({ label: status, value: status }))}
            />
            {removalReason === 'Other' && (
              <TextField
                size="small"
                required
                className="col-span-3"
                label="Other Removal Reason"
                name="removed.otherRemovalReason"
              />
            )}
          </div>
          <div className="max-w-lg">
            <FileUploadField
              renderValue={RenderFileValue}
              button={
                <Button
                  variant="text"
                  color="primary"
                  disabled={shouldDisableRemovedFields}
                  startIcon={<AttachFileOutlinedIcon />}
                >
                  Attach File
                </Button>
              }
              name="removed.file"
            />
          </div>
        </div>

        {/* Added Part */}
        <div className="flex flex-col items-start gap-2">
          <Typography variant="caption">Component Installed</Typography>
          <Checkbox
            label="Apply the same component"
            name="reapplyPart"
            disabled={shouldDisableRemovedFields}
            size="small"
          />
          <div className="grid grid-cols-3 gap-2">
            <TextField
              fullWidth
              size="small"
              label="Number"
              name="added.partNumber"
              disabled={reapplyPart}
            />
            <TextField
              fullWidth
              size="small"
              label="Serial"
              name="added.serialNumber"
              disabled={reapplyPart}
            />
            <SelectDropdown
              label="Installed Status"
              fullWidth
              name="added.installedStatus"
              options={[
                'Altered',
                'Inspected',
                'Modified',
                'New',
                'Not Specified',
                'Overhauled',
                'Repaired',
                'Serviceable',
                'Other',
              ].map((status) => ({ label: status, value: status }))}
            />
            {installedStatus === 'Other' && (
              <TextField
                size="small"
                required
                className="col-span-3"
                label="Other Installed Status"
                name="added.otherInstalledStatus"
              />
            )}
          </div>
          {!reapplyPart &&
            Object.values(formMethods.formState.errors).some(
              (error) => error?.type === 'unique-part'
            ) && (
              <FormHelperText error>
                Parts must be different when not reapplying
              </FormHelperText>
            )}
          <div className="grid grid-cols-2">
            <FileUploadField
              renderValue={RenderFileValue}
              button={
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<AttachFileOutlinedIcon />}
                >
                  Attach File
                </Button>
              }
              name="added.file"
            />
          </div>
        </div>

        {/* time since installed */}
        <div className="flex flex-col items-start gap-2">
          <Typography variant="caption">Time Since Installed</Typography>
          <div className="flex gap-2">
            <div className="flex flex-col gap-2">
              <Typography variant="caption">Months</Typography>
              <PartsTimeInputTextField name="monthsSinceNew" label="New" />
              <PartsTimeInputTextField
                name="monthsSinceOverhaul"
                label="Overhaul"
              />
              <PartsTimeInputTextField
                name="monthsSinceRepair"
                label="Repair"
              />
            </div>
            <div className="flex flex-col gap-2">
              <Typography variant="caption">Hours</Typography>
              <PartsTimeInputTextField name="hoursSinceNew" label="New" />
              <PartsTimeInputTextField
                name="hoursSinceOverhaul"
                label="Overhaul"
              />
              <PartsTimeInputTextField name="hoursSinceRepair" label="Repair" />
            </div>
            <div className="flex flex-col gap-2">
              <Typography variant="caption">Landings</Typography>
              <PartsTimeInputTextField name="cyclesSinceNew" label="New" />
              <PartsTimeInputTextField
                name="cyclesSinceOverhaul"
                label="Overhaul"
              />
              <PartsTimeInputTextField
                name="cyclesSinceRepair"
                label="Repair"
              />
            </div>
          </div>
        </div>
      </Form>
      <DrawerActions
        leftActions={
          <Button color="black" variant="text" onClick={handleClose}>
            Cancel
          </Button>
        }
        rightActions={
          <Button type="submit" form="workflowPartTransactionForm">
            Save
          </Button>
        }
      />
    </Drawer>
  )
}

export default PartTransactionDrawer
