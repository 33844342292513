export const ADMIN_ROLE = 'ww-user'

export const Permissions = {
  compliance: {
    create: { action: 'create', resource: 'compliance' } as const,
    read: { action: 'read', resource: 'compliance' } as const,
    update: { action: 'update', resource: 'compliance' } as const,
    complete: { action: 'complete', resource: 'compliance' } as const,
    actAsTechnician: {
      action: 'act-as-technician',
      resource: 'compliance',
    } as const,
    actAsInspector: {
      action: 'act-as-inspector',
      resource: 'compliance',
    } as const,
    delete: { action: 'delete', resource: 'compliance' } as const,
  },
  maintenanceItem: {
    create: { action: 'create', resource: 'maintenance-item' } as const,
    read: { action: 'read', resource: 'maintenance-item' } as const,
    update: { action: 'update', resource: 'maintenance-item' } as const,
    delete: { action: 'delete', resource: 'maintenance-item' } as const,
  },
  user: {
    add: { action: 'add', resource: 'user' } as const,
    remove: { action: 'remove', resource: 'user' } as const,
    changeRole: { action: 'change-role', resource: 'user' } as const,
    updatePermissions: {
      action: 'update-permissions',
      resource: 'user',
    } as const,
  },
  workOrder: {
    create: { action: 'create', resource: 'work-order' } as const,
    read: { action: 'read', resource: 'work-order' } as const,
    update: { action: 'update', resource: 'work-order' } as const,
    performWorkItem: {
      action: 'perform-work-item',
      resource: 'work-order',
    } as const,
    addTasks: { action: 'add-tasks', resource: 'work-order' } as const,
    removeTasks: { action: 'remove-tasks', resource: 'work-order' } as const,
    complete: { action: 'complete', resource: 'work-order' } as const,
    actAsInspector: {
      action: 'act-as-inspector',
      resource: 'work-order',
    } as const,
    actAsTechnician: {
      action: 'act-as-technician',
      resource: 'work-order',
    } as const,
    delete: { action: 'delete', resource: 'work-order' } as const,
    assignMechanic: {
      action: 'assign-mechanic',
      resource: 'work-order',
    } as const,
  },
  purchaseOrder: {
    create: { action: 'create', resource: 'purchase-order' } as const,
    addItems: { action: 'add-items', resource: 'purchase-order' } as const,
    deleteItems: {
      action: 'delete-items',
      resource: 'purchase-order',
    } as const,
    cancel: { action: 'cancel', resource: 'purchase-order' } as const,
    complete: { action: 'complete', resource: 'purchase-order' } as const,
    update: { action: 'update', resource: 'purchase-order' } as const,
    read: { action: 'read', resource: 'purchase-order' } as const,
    delete: { action: 'delete', resource: 'purchase-order' } as const,
    rejectItems: {
      action: 'reject-items',
      resource: 'purchase-order',
    } as const,
    requestItems: {
      action: 'request-items',
      resource: 'purchase-order',
    } as const,
  },
}

type CompliancePermission =
  (typeof Permissions.compliance)[keyof typeof Permissions.compliance]

type MaintenanceItemPermission =
  (typeof Permissions.maintenanceItem)[keyof typeof Permissions.maintenanceItem]

type UserPermission = (typeof Permissions.user)[keyof typeof Permissions.user]

type WorkOrderPermission =
  (typeof Permissions.workOrder)[keyof typeof Permissions.workOrder]

type PurchaseOrderPermission =
  (typeof Permissions.purchaseOrder)[keyof typeof Permissions.purchaseOrder]

export type OrgPermissions =
  | CompliancePermission
  | MaintenanceItemPermission
  | UserPermission
  | WorkOrderPermission
  | PurchaseOrderPermission

export const USER_ROLES = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Director', value: 'DIRECTOR_OF_MAINTENANCE' },
  { label: 'Analyst', value: 'ANALYST' },
  { label: 'Inspector', value: 'INSPECTOR' },
  { label: 'Technician', value: 'TECHNICIAN' },
  { label: 'Accountant', value: 'ACCOUNTANT' },
  { label: 'Mechanic', value: 'MECHANIC' },
  { label: 'Read Only', value: 'READONLY' },
]

export const GENERAL_ROLES = [
  { label: 'Admin', value: 'admin' },
  { label: 'All Access', value: 'all-access' },
  { label: 'Manager', value: 'manager' },
  { label: 'Mechanic', value: 'mechanic' },
  { label: 'Read Only', value: 'read-only' },
]

export const AIRCRAFT_ROLES = [
  ...GENERAL_ROLES,
  { label: 'Unauthorized', value: 'unauthorized' },
]
