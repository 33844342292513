import React, { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { ApolloError } from '@apollo/client'
import AddTaskIcon from '@mui/icons-material/AddTask'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { LoadingButton } from '@mui/lab'
import { Button, IconButton, Typography, Drawer } from '@mui/material'
import { getNextDueCadenceType } from '@wingwork/common/src/maintenanceItem'
import dayjs from 'dayjs'
import {
  ComplianceActivity,
  InternalWorkItem,
  MaintenanceItem,
} from 'types/graphql'
import * as Yup from 'yup'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import Checkbox from 'src/components/MUI/Checkbox'
import DatePicker from 'src/components/MUI/DatePicker'
import CustomRadioGroup, {
  RadioGroupOption,
} from 'src/components/MUI/RadioGroup'
import SelectDropdown, { SelectOption } from 'src/components/MUI/SelectDropdown'
import TextField from 'src/components/MUI/TextField'
import TagModalForm from 'src/components/TagModal/TagModalForm'
import useAddTagsToMtxItem from 'src/hooks/requests/useAddTagsToMtxItem'
import { useOrgName } from 'src/hooks/useOrgName'
import { capitalizeFirstLetter } from 'src/utils/helpers'
import {
  CREATE_DISCREPANCY_ITEM_TRANSACTION,
  DELETE_DISCREPANCY_ITEM,
  GET_TAGS,
  UPDATE_DISCREPANCY_ITEM_TRANSACTION,
} from 'src/components/Discrepanies/consts'
import PromptForPinDialog from 'src/components/common/PromptForPinDialog'
import useUserId from 'src/hooks/useUserId'
import Loading from 'src/components/Loading'

interface AddDiscrepancySliderProps {
  onClose: (refreshTable: boolean) => void
  isWorkOrder: boolean
  open: boolean
  complianceOrWorkOrder: ComplianceActivity | InternalWorkItem
  aircraftUsageLogId: string
  selectedTask: MaintenanceItem
}

const taskTypeOptions: SelectOption[] = [
  {
    label: 'MEL',
    value: 'MEL',
  },
  {
    label: 'NEF',
    value: 'NEF',
  },
  {
    label: 'Watch List',
    value: 'WATCH_LIST',
  },
  {
    label: 'Discrepancy',
    value: 'DISCREPANCY',
  },
]

const melCategoryOptions: SelectOption[] = [
  {
    label: 'Category A',
    value: 'catA',
  },
  {
    label: 'Category B',
    value: 'catB',
  },
  {
    label: 'Category C',
    value: 'catC',
  },
  {
    label: 'Category D',
    value: 'catD',
  },
]

const deferredOptions: RadioGroupOption[] = [
  {
    label: 'Yes',
    value: 'yes',
    disabled: false,
  },
  {
    label: 'No',
    value: 'no',
    disabled: false,
  },
]

const melCatToDaysMap = {
  catA: 0,
  catB: 3,
  catC: 10,
  catD: 120,
}

const schema = Yup.object().shape({
  discrepancyDeferred: Yup.string()
    .oneOf(['yes', 'no'])
    .required("Select 'yes' or 'no'"),
  adSbType: Yup.string()
    .oneOf(['MEL', 'NEF', 'WATCH_LIST', 'DISCREPANCY'])
    .required('Select type'),
  nextDueCategory: Yup.string().when(['discrepancyDeferred', 'adSbType'], {
    is: (discrepancyDeferred, adSbType) => {
      return (
        discrepancyDeferred === 'yes' &&
        (adSbType === 'MEL' || adSbType === 'NEF')
      )
    },
    then: (fieldSchema) => fieldSchema.required('Select category'),
  }),
  nextDueDate: Yup.string().when(['discrepancyDeferred'], {
    is: (discrepancyDeferred) => {
      return discrepancyDeferred === 'yes'
    },
    then: (fieldSchema) => fieldSchema.required('Select date'),
  }),
  title: Yup.string().required('Enter title'),
})

const getMassagedMtxItemInput = (
  isUpdateMode: boolean,
  isWorkOrder: boolean,
  values: any,
  complianceOrWorkOrder: ComplianceActivity | InternalWorkItem,
  aircraftUsageLogId: string,
  orgName: string,
  userId: string,
  userPin: string
) => {
  const getDiscrepancyStatus = () => {
    if (values.discrepancyDeferred === 'yes') return 'DEFERRED'
    if (values.statusClosed) return 'CLOSED'
    return 'OPEN'
  }

  const getNextDueStatus = () => {
    if (values.discrepancyDeferred === 'yes') return 'GOOD'
    if (values.statusClosed) return 'NOT_DUE'
    return 'GOOD'
  }

  const getComplianceInformation = () => {
    if (values.discrepancyDeferred === 'yes')
      return {
        importedDataCompliance: {},
      }
    return {
      importedDataCompliance: {},
      lastComplianceDate: dayjs().toISOString(),
      lastComplianceStampId: aircraftUsageLogId,
    }
  }

  const getFieldsByMode = (isUpdateMode: boolean, userId: string) => {
    if (!isUpdateMode) {
      return {
        createdById: userId,
      }
    }
    return {}
  }

  const getFieldsByType = (isWorkOrder: boolean) => {
    if (isWorkOrder) {
      return {
        discrepancyWorkItemId: complianceOrWorkOrder.id,
      }
    }
    return {
      discrepancyCompActivityId: complianceOrWorkOrder.id,
    }
  }

  const massagedMtxItem = {
    orgSlug: orgName,
    ataCode: '',
    cadenceType: 'CALENDAR_MONTHS',
    cadenceValue: {},
    disposition: '',
    isOptional: false,
    isRecurring: false,
    isParent: false,
    isAdSb: true,
    maintenanceType: 'UNSCHEDULED',
    manufactureCode: '',
    metadata: {},
    status: 'ENABLED',
    remainingValue: -1,
    trackedByComponentId:
      complianceOrWorkOrder?.maintenanceItem?.trackedByComponentId,

    title: values.title,
    adSbType: values.adSbType,
    aircraftId: complianceOrWorkOrder.maintenanceItem.aircraft.id,
    description: values.description,
    nextDueStatus: getNextDueStatus(),
    notes: values.notes,
    discrepancyStatus: getDiscrepancyStatus(),
    verifyPin: true,
    verifyUserId: userId,
    verifyUserPin: userPin,
    ...getFieldsByMode(isUpdateMode, userId),
    ...getFieldsByType(isWorkOrder),
    ...getComplianceInformation(),
  }

  const massagedMtxNextDue = getMassagedMtxNextDueInput(
    values,
    orgName,
    userId,
    userPin
  )

  return {
    maintenanceItem: massagedMtxItem,
    maintenanceNextDue: massagedMtxNextDue,
  }
}

const getMassagedMtxNextDueInput = (
  values: any,
  orgName: string,
  userId: string,
  userPin: string
) => {
  const nextDueVal = {
    category: values.nextDueCategory || undefined,
    date: values.nextDueDate || undefined,
    flying_hours: values.nextDueHours || undefined,
    landings: values.nextDueLandings || undefined,
  }
  return {
    orgSlug: orgName,
    isCompleted: false,
    nextDueValue: nextDueVal,
    nextDueType: getNextDueCadenceType(nextDueVal),
    verifyPin: true,
    verifyUserId: userId,
    verifyUserPin: userPin,
  }
}

const AddDiscrepancySlider: React.FC<AddDiscrepancySliderProps> = ({
  onClose,
  open,
  isWorkOrder,
  complianceOrWorkOrder,
  aircraftUsageLogId,
  selectedTask,
}) => {
  const componentRef = React.useRef<HTMLDivElement>(null)
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false)
  const [isCreateOrUpdateLoading, setIsCreateOrUpdateLoading] =
    React.useState(false)
  const [submissionType, setSubmissionType] = React.useState<
    'create' | 'update' | 'delete'
  >()
  const orgName = useOrgName()
  const { id: currentUserId, hasLoaded: currentUserIdHasLoaded } = useUserId()
  const { data: tags } = useQuery(GET_TAGS)
  const [createDiscrepancyItemTransaction] = useMutation(
    CREATE_DISCREPANCY_ITEM_TRANSACTION
  )
  const [updateDiscrepancyItemTransaction] = useMutation(
    UPDATE_DISCREPANCY_ITEM_TRANSACTION
  )
  const [deleteDiscrepancyItem] = useMutation(DELETE_DISCREPANCY_ITEM)
  const { addTagsToMtxItem } = useAddTagsToMtxItem()

  const defaultValues = {
    discrepancyDeferred: 'no',
    adSbType: 'MEL',
    nextDueCategory: '',
    nextDueDate: '',
    nextDueHours: '',
    nextDueLandings: '',
    statusClosed: !isWorkOrder, // Carry-over from when compliance and work order discrepancy components were
    tags: [],
    title: '',
    description: '',
    notes: '',
    enteredBy: '',
    pin: '',
  }

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const { watch, setValue, reset, handleSubmit } = formMethods
  const adSbType = watch('adSbType')
  const discrepancyDeferred = watch('discrepancyDeferred')
  const category = watch('nextDueCategory')

  const isUpdateMode = selectedTask !== undefined

  useEffect(() => {
    // set form values if selected task is present (update mode)
    if (isUpdateMode) {
      const notes: any = selectedTask?.notes
      let nextDueInfo = {}
      if (selectedTask.discrepancyStatus === 'DEFERRED') {
        const nextDueValue: any =
          selectedTask?.maintenanceNextDue[0]?.nextDueValue ?? {}
        nextDueInfo = {
          nextDueCategory: nextDueValue?.category ?? '',
          nextDueDate: nextDueValue?.date ?? '',
          nextDueHours: nextDueValue?.hours ?? '',
          nextDueLandings: nextDueValue?.landings ?? '',
        }
      }

      reset({
        ...selectedTask,
        statusClosed: selectedTask.discrepancyStatus === 'CLOSED',
        discrepancyDeferred:
          selectedTask.discrepancyStatus === 'DEFERRED' ? 'yes' : 'no',
        notes: notes ?? '',
        ...nextDueInfo,
      })
    }
  }, [selectedTask])

  useEffect(() => {
    // set next due date based on category
    if (category) {
      const daysToAdd = melCatToDaysMap[category]
      const nextDate = dayjs().add(daysToAdd, 'day')
      setValue('nextDueDate', nextDate.toISOString())
    }
  }, [category])

  useEffect(() => {
    // reset values based on adSbType
    if (adSbType === 'DISCREPANCY') {
      setValue('discrepancyDeferred', 'no')
      setValue('nextDueCategory', '')
      setValue('nextDueDate', '')
      setValue('nextDueHours', '')
      setValue('nextDueLandings', '')
    }
    if (adSbType === 'WATCH_LIST') {
      setValue('nextDueCategory', '')
    }
  }, [adSbType])

  useEffect(() => {
    // reset values based on discrepancyDeferred
    if (discrepancyDeferred === 'yes') {
      setValue('statusClosed', false)
    } else {
      setValue('nextDueDate', '')
      setValue('nextDueCategory', '')
      setValue('nextDueHours', '')
      setValue('nextDueLandings', '')
    }
  }, [discrepancyDeferred])

  const resetFormAndClose = (refreshTable: boolean) => {
    setIsDeleteLoading(false)
    setIsCreateOrUpdateLoading(false)
    reset(defaultValues)
    onClose(refreshTable)
  }

  const somethingWentWrong = (message?: string) => {
    setIsDeleteLoading(false)
    setIsCreateOrUpdateLoading(false)
    toast.error(message ?? `Something went wrong. Please try again later.`, {
      duration: 3000,
    })
  }

  const handleRequestError = (error: ApolloError) => {
    if (error.message === 'PIN_DOES_NOT_MATCH') {
      somethingWentWrong('PIN does not match.  Please try again.')
    } else {
      somethingWentWrong()
    }
  }

  const triggerSubmitChain = async (userPin: string) => {
    const values = formMethods.getValues()
    setIsCreateOrUpdateLoading(true)
    const mtxItemInput = getMassagedMtxItemInput(
      isUpdateMode,
      isWorkOrder,
      values,
      complianceOrWorkOrder,
      aircraftUsageLogId,
      orgName,
      currentUserId,
      userPin
    )

    // This is standard function to add tags to mtx item
    // this is executed at the end of all the mutations
    // because of the nature of it's low priority,
    // there is no rollback mechanism for it.
    const addTags = (mtxId) => {
      addTagsToMtxItem(values.tags, mtxId, true)
        .then(() => {
          toast.success(`Discrepancy added successfully`, {
            duration: 2000,
          })
          resetFormAndClose(true)
        })
        .catch(() => {
          somethingWentWrong(
            'Something went wrong while adding tags to discrepancy. However, discrepancy was added successfully.'
          )
          resetFormAndClose(true)
        })
    }

    // If it is update mode, then we need to update the mtx item and mtx next due
    if (isUpdateMode) {
      updateDiscrepancyItemTransaction({
        variables: {
          id: selectedTask.id,
          input: mtxItemInput,
          isDeferred: values.discrepancyDeferred === 'yes',
        },
        onCompleted: async (mtxData) => {
          addTags(mtxData.updateMaintenanceItemTransaction.id)
        },
        onError: handleRequestError,
      })
    } else {
      // If it is create mode, then we need to create the mtx item and mtx next due
      createDiscrepancyItemTransaction({
        variables: { input: mtxItemInput },
        onCompleted: async (mtxData) => {
          addTags(mtxData.createMaintenanceItemTransaction.id)
        },
        onError: handleRequestError,
      })
    }
  }

  const handleDelete = async (userPin: string) => {
    setIsDeleteLoading(true)
    deleteDiscrepancyItem({
      variables: {
        id: selectedTask.id,
        input: {
          verifyPin: true,
          verifyUserId: currentUserId,
          verifyUserPin: userPin,
        },
      },
      onCompleted: () => {
        resetFormAndClose(true)
      },
      onError: handleRequestError,
    })
  }

  const handlePinEntry = async (userPin: string) => {
    if (submissionType === 'delete') {
      handleDelete(userPin)
    } else {
      triggerSubmitChain(userPin)
    }
    setSubmissionType(null)
  }

  const drawerWidth = '450px'
  const areMutationsLoading = isCreateOrUpdateLoading || isDeleteLoading

  return (
    <>
      <Drawer
        anchor={'right'}
        open={open}
        sx={{ zIndex: 1202 }}
        ref={componentRef}
      >
        <Form formMethods={formMethods} className="relative h-full">
          {!currentUserIdHasLoaded ||
          isCreateOrUpdateLoading ||
          isDeleteLoading ? (
            <div style={{ width: drawerWidth, height: '100%' }}>
              <Loading />
            </div>
          ) : (
            <div
              className="h-full overflow-auto bg-[#EBECF3]"
              style={{ width: drawerWidth }}
            >
              <div className="bg-[#ffffff] p-2 pb-12">
                <div className="flex items-center justify-between">
                  <IconButton
                    onClick={() => resetFormAndClose(false)}
                    className="mb-1"
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </div>
                <div>
                  <Typography variant="h6" className="mb-2">
                    {isUpdateMode ? 'Update' : 'Add'} Discrepancy
                  </Typography>
                  <PrimSecTextCombo
                    secondaryText={'Task Tracked By'}
                    primaryText={capitalizeFirstLetter(
                      complianceOrWorkOrder?.maintenanceItem.trackedByComponent
                        ?.name
                    )}
                    variant="inverted"
                  />
                  <div className="mt-2 flex flex-col gap-2">
                    <SelectDropdown
                      required
                      name="adSbType"
                      label="Type"
                      options={taskTypeOptions}
                      fullWidth
                    />
                    <TextField
                      placeholder="Title"
                      required
                      size="small"
                      name="title"
                    />
                    <TextField
                      placeholder="Description"
                      name="description"
                      multiline
                      fullWidth
                      rows={4}
                    />
                    <TagModalForm
                      control={formMethods.control}
                      options={tags?.tags ?? []}
                    />
                    <div>
                      <Typography variant="body2">Is deferred?</Typography>
                      <CustomRadioGroup
                        name="discrepancyDeferred"
                        required
                        options={deferredOptions}
                        disabled={adSbType === 'DISCREPANCY'}
                        row
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <Typography variant="subtitle1" fontWeight={'bold'}>
                      Report Information
                    </Typography>

                    {discrepancyDeferred === 'yes' ? (
                      <div className="flex flex-col gap-2">
                        <Typography variant="subtitle2">Next Due</Typography>
                        {(adSbType === 'MEL' || adSbType === 'NEF') && (
                          <SelectDropdown
                            name="nextDueCategory"
                            label="Category"
                            options={melCategoryOptions}
                            fullWidth
                            value=""
                          />
                        )}
                        <DatePicker
                          className="col-span-2"
                          name={'nextDueDate'}
                          label="Date"
                          disabled={category && category !== 'catA'}
                          disablePast
                          closeOnSelect
                          slotProps={{
                            textField: { size: 'small', fullWidth: true },
                          }}
                        />

                        <TextField
                          placeholder="Hours"
                          size="small"
                          name="nextDueHours"
                          type="number"
                          fullWidth
                        />
                        <TextField
                          placeholder="Landings"
                          size="small"
                          name="nextDueLandings"
                          type="number"
                          fullWidth
                        />
                      </div>
                    ) : (
                      <>
                        <Checkbox
                          name="statusClosed"
                          label="Mark as closed"
                          checkboxProps={{
                            defaultChecked: true,
                            tabIndex: -1,
                          }}
                        />
                      </>
                    )}

                    <div className="flex flex-col gap-2">
                      <Typography variant="subtitle2">Additional</Typography>
                      <TextField
                        placeholder="Notes"
                        name="notes"
                        multiline
                        fullWidth
                        rows={4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="absolute bottom-0 left-0 right-0 z-[1] float-right flex justify-between gap-2 bg-[#FAFAFA] p-2">
            <Button
              variant="text"
              onClick={() => {
                if (isUpdateMode) {
                  resetFormAndClose(false)
                } else {
                  reset(defaultValues)
                }
              }}
            >
              {isUpdateMode ? 'Close' : 'Reset'}
            </Button>
            <div>
              {isUpdateMode && (
                <Button
                  variant="text"
                  disabled={areMutationsLoading}
                  color="error"
                  onClick={() => setSubmissionType('delete')}
                  style={{ marginRight: '8px' }}
                >
                  Delete
                </Button>
              )}
              <LoadingButton
                loading={isCreateOrUpdateLoading}
                startIcon={<AddTaskIcon />}
                loadingPosition="start"
                disabled={areMutationsLoading}
                variant="contained"
                onClick={() =>
                  handleSubmit(() => {
                    setSubmissionType(isUpdateMode ? 'update' : 'create')
                  })()
                }
              >
                {isUpdateMode ? 'Update Discrepancy' : 'Add Discrepancy'}
              </LoadingButton>
            </div>
          </div>
        </Form>
      </Drawer>
      <PromptForPinDialog
        open={!!submissionType}
        onClose={() => setSubmissionType(null)}
        onSubmit={handlePinEntry}
      />
    </>
  )
}

export default AddDiscrepancySlider
