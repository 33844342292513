import { SvgIconComponent } from '@mui/icons-material'
import {
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField as MUITextField,
  Typography,
  Skeleton,
} from '@mui/material'
import React, { useState } from 'react'
import {
  FieldRenderProps,
  InlineFieldWrapper,
  InlineFieldWrapperProps,
} from './InlineFieldWrapper'
import clsx from 'clsx'

interface InlinePopoverFieldProps {
  loading?: boolean
  label: string
  value: string | number
  icon: SvgIconComponent
  CTAText?: string
  name: InlineFieldWrapperProps['name']
  onSubmit?: InlineFieldWrapperProps['onSubmit']
  validation?: InlineFieldWrapperProps['validation']
  triggerOn: InlineFieldWrapperProps['triggerOn'] & {
    onClose?: boolean
  }
  children?: (
    props: FieldRenderProps & {
      handleClose: () => void
      handleCancel: () => void
    }
  ) => React.ReactNode
}

const InlinePopoverField = ({
  loading = false,
  label,
  value,
  icon: Icon,
  onSubmit,
  validation,
  triggerOn = { change: true, onClose: true },
  CTAText = 'Select',
  name,
  children,
}: InlinePopoverFieldProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (children) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (loading) {
    return (
      <div className="flex items-center gap-1">
        <Skeleton variant="circular" height={20} width={20} />
        <Skeleton variant="text" height={28} width={100} />
      </div>
    )
  }
  return (
    <InlineFieldWrapper
      name={name}
      initialValue={value}
      onSubmit={onSubmit}
      validation={validation}
      triggerOn={triggerOn}
    >
      {(fieldProps) => (
        <>
          <div
            className={clsx('flex items-center gap-1', {
              'cursor-pointer': !!children && !fieldProps.isSubmitting,
            })}
            onClick={(e) => {
              if (!!children && !fieldProps.isSubmitting) {
                handleOpen(e)
              }
            }}
          >
            <Icon color="primary" fontSize="small" />
            <Typography variant="body2">{label}</Typography>
            {value !== null ? (
              <Typography variant="subtitle1">
                {fieldProps.isSubmitting ? <Skeleton width={75} /> : value}
              </Typography>
            ) : (
              <Typography variant="subtitle2" color="primary">
                {CTAText}
              </Typography>
            )}
          </div>
          <Popover
            anchorEl={anchorEl}
            disableEscapeKeyDown
            open={!!anchorEl}
            onClose={() => {
              handleClose()
              if (triggerOn.onClose) {
                fieldProps.submit(fieldProps.value)
              }
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            {children?.({
              ...fieldProps,
              handleClose: () => {
                handleClose()
                if (triggerOn.onClose) {
                  fieldProps.submit(fieldProps.value)
                }
              },
              handleCancel: () => {
                handleClose()
                setTimeout(() => {
                  // delay change so the text doesnt change while closing popover
                  fieldProps.onChange(value)
                }, 100)
              },
            })}
          </Popover>
        </>
      )}
    </InlineFieldWrapper>
  )
}

const SearchMenuGuts = ({ options = [], handleClose, onAccept }) => {
  const [searchValue, setSearchValue] = useState('')

  const handleSearch = (event) => {
    setSearchValue(event.target.value.toLowerCase())
  }

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchValue)
  )

  return (
    <div style={{ padding: '10px', minWidth: '200px' }}>
      <MUITextField
        placeholder="Search"
        fullWidth
        variant="outlined"
        size="small"
        onChange={handleSearch}
      />
      <List>
        {options.map((option, index) => (
          <ListItem key={index} onClick={() => onAccept(option)}>
            <ListItemText primary={option} />
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default InlinePopoverField
