import * as React from 'react'

import { useLazyQuery } from '@apollo/client'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CheckIcon from '@mui/icons-material/Check'
import DifferenceRoundedIcon from '@mui/icons-material/DifferenceRounded'
import EditIcon from '@mui/icons-material/Edit'
import FindReplaceIcon from '@mui/icons-material/FindReplace'
import PolicyIcon from '@mui/icons-material/Policy'
import { Chip, Link as MUILink, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  BiDirectionalPageInfo,
  ComplianceLedger,
  ComplianceLedgerFilters,
} from 'types/graphql'

import { navigate, routes, Link } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import PrimSecTextCombo from 'src/components/common/PrimSecTextCombo'
import EditableDescriptionCell from 'src/components/EditableDescriptionCell'
import IconButton from 'src/components/MUI/IconButton'

import useUpdateComplianceLedger from 'src/hooks/requests/useUpdateComplianceLedger'
import { useOrgName } from 'src/hooks/useOrgName'
import useWorkCompletedFilters from 'src/hooks/useWorkCompletedFilters'

import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from 'material-react-table'
import { getDefaultMRTOptions } from 'src/utils/getDefaultMRTOptions'
import { useMemo, useState } from 'react'
import BookOutlinedIcon from '@mui/icons-material/BookOutlined'
import { getComplianceLedgerStatusText } from 'src/components/ViewLogbookEntryModal/helper'
import useUserId from 'src/hooks/useUserId'

export type SortOrder = 'asc' | 'desc'

const GET_ALL_LEDGER_ENTRIES_PAGE = gql`
  query GET_ALL_LEDGER_ENTRIES_PAGE(
    $sortField: String
    $sortOrder: String
    $after: String
    $before: String
    $pageSize: Int!
    $filters: ComplianceLedgerFilters
  ) {
    complianceLedgersPage(
      complianceLedgerFilters: $filters
      sorting: { sortField: $sortField, sortOrder: $sortOrder }
      pagination: { after: $after, before: $before, pageSize: $pageSize }
    ) {
      edges {
        id
        ledgerNumber
        description
        aircraft {
          id
          tailNumber
        }
        timestamp
        status
        aircraftUsageLog {
          usageAsOf
        }
        complianceActivity(filters: { omitChildren: false }) {
          id
          maintenanceItem {
            id
            ataCode
            manufactureCode
            title
            description
          }
        }
        workedBy {
          firstName
          lastName
        }
        inspectedBy {
          firstName
          lastName
        }
        MaintenanceLogbook {
          id
          status
        }
        # for isUserRequestedAsNextSigner
        workedById
        requestedWorkerId
        inspectedById
        requestedInspectorId
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
        totalCount
      }
    }
  }
`

const REVISE_COMPLIANCE_LEDGER = gql`
  mutation ReviseComplianceLedger($id: String!) {
    reviseComplianceLedger(id: $id) {
      id
      status
    }
  }
`

export const statusMap = {
  COMPLETED: {
    color: 'success',
    icon: <CheckIcon fontSize="small" />,
  },
  COMPLETED_LOGBOOK: {
    color: 'success',
    icon: <BookOutlinedIcon fontSize="small" />,
  },
  SIGN: {
    color: 'info',
    icon: <EditIcon fontSize="small" />,
  },
  REVISION_IN_PROGRESS: {
    color: 'error',
    icon: <FindReplaceIcon fontSize="small" />,
  },
  IN_REVIEW: {
    color: 'warning',
    icon: <PolicyIcon fontSize="small" />,
  },
  IN_PROGRESS: {
    color: 'warning',
    icon: <PolicyIcon fontSize="small" />,
  },
}

interface PastComplianceTableProps {
  editEnabled?: boolean
  queryVariables?: Omit<ComplianceLedgerFilters, 'orgSlug'>
}

const PastComplianceTable: React.FC<PastComplianceTableProps> = ({
  editEnabled = true,
  queryVariables = {
    aircraftUsageLogId: undefined,
  },
}) => {
  const { id: currentUserId, hasLoaded: currentUserIdHasLoaded } = useUserId()
  const [complianceLedgerPage, setComplianceLedgerPage] = React.useState<any>(
    []
  )
  const { t } = useTranslation()
  const [detailedLedgerView, setDetailedLedgerView] =
    React.useState<ComplianceLedger>(undefined)
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })
  const [previousPageIndex, setPreviousPageIndex] = useState<number>(0)

  const { workCompletedFiltersData } = useWorkCompletedFilters()

  const {
    searchTerm,
    aircrafts: aircraftFilter,
    timestamp,
    ledgerStatus,
  } = workCompletedFiltersData
  const { aircraftUsageLogId } = queryVariables

  const [reviseComplianceLedger] = useMutation(REVISE_COMPLIANCE_LEDGER)
  const { mutate: updateComplianceLedger } = useUpdateComplianceLedger()
  const [loadComplianceLedgersPage, { loading }] = useLazyQuery(
    GET_ALL_LEDGER_ENTRIES_PAGE,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        setComplianceLedgerPage(data?.complianceLedgersPage)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )
  const orgName = useOrgName()
  const complianceLedgersArr: ComplianceLedger[] = React.useMemo(() => {
    return get(complianceLedgerPage, 'edges', [])
  }, [complianceLedgerPage])
  const complianceLedgersPageInfo: BiDirectionalPageInfo = React.useMemo(() => {
    return get(complianceLedgerPage, 'pageInfo', {})
  }, [complianceLedgerPage])

  React.useEffect(() => {
    //Constructing Aircraft Filter
    const aircraftIds = Object.keys(aircraftFilter).filter(
      (aircraftId) => aircraftFilter[aircraftId]
    )
    const updatedQueryVariables = {
      fuzzySearch: searchTerm,
      aircraftIds,
      orgSlug: orgName,
      statuses: Object.keys(ledgerStatus).filter(
        (status) => ledgerStatus[status]
      ),
      aircraftUsageLogId: aircraftUsageLogId,
      timestamp: timestamp,
    }

    const isMovingForward = pagination.pageIndex > previousPageIndex
    const isMovingBackward = pagination.pageIndex < previousPageIndex
    loadComplianceLedgersPage({
      variables: {
        filters: updatedQueryVariables,
        sortOrder: sorting[0]?.desc ?? true ? 'desc' : 'asc',
        sortField: sorting[0]?.id ?? 'timestamp',
        after: isMovingForward
          ? complianceLedgersPageInfo.endCursor
          : undefined,
        before: isMovingBackward
          ? complianceLedgersPageInfo.startCursor
          : undefined,
        pageSize: pagination?.pageSize,
      },
    })

    setPreviousPageIndex(pagination.pageIndex)
  }, [
    searchTerm,
    aircraftFilter,
    sorting,
    pagination.pageIndex,
    pagination.pageSize,
    timestamp,
    ledgerStatus,
  ])

  const defaultTableOptions = getDefaultMRTOptions<any>()

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: 'ledgerNumber',
        header: 'Ledger #',
        // accessorFn: (row) => row.ledgerNumber,
        Cell: ({ row }) => (
          <PrimSecTextCombo
            primaryText={row?.original?.ledgerNumber}
            secondaryText={`Tail# ${row?.original?.aircraft?.tailNumber}`}
          />
        ),
      },
      {
        id: 'description',
        header: 'Description',
        size: 500,
        // accessorFn: (row) => row.description,
        Cell: ({ row }) => {
          return (
            <EditableDescriptionCell
              timestamp={row.original?.timestamp}
              description={row.original?.description}
              editEnabled={editEnabled}
              maintenanceItems={row.original?.complianceActivity?.map(
                (activity) => activity?.maintenanceItem
              )}
              onSave={(description) =>
                updateComplianceLedger({
                  id: row.original?.id,
                  description,
                })
              }
            />
          )
        },
      },
      {
        id: 'complianceCompletedDate',
        header: 'Completed On',

        accessorFn: (row) => row?.timestamp ?? '',
        Cell: ({ row }) => (
          <Typography variant="body2">
            {row.original?.timestamp
              ? dayjs(row.original.timestamp).format('MMM DD, YYYY')
              : ''}
          </Typography>
        ),
      },
      {
        id: 'aircraftLogDate',
        header: 'A/C Log Date',
        accessorFn: (row) => row?.aircraftUsageLog?.usageAsOf ?? '',
        Cell: ({ row }) => (
          <Typography variant="body2">
            {row.original?.aircraftUsageLog?.usageAsOf
              ? dayjs(row.original.aircraftUsageLog.usageAsOf).format(
                  'MMM DD, YYYY'
                )
              : ''}
          </Typography>
        ),
      },
      {
        id: 'signatures',
        header: 'Signatures',
        // accessorFn: (row) => row.endedAt,
        Cell: ({ row }) => (
          <>
            <Typography variant="body2">
              {row?.original?.inspectedBy?.firstName
                ? `I: ${row?.original?.inspectedBy?.firstName} ${row?.original?.inspectedBy?.lastName}`
                : '--'}
            </Typography>
            <Typography variant="body2">
              {row?.original?.workedBy?.firstName
                ? `T: ${row?.original?.workedBy?.firstName} ${row?.original?.workedBy?.lastName}`
                : row?.original?.inspectedBy?.firstName
                ? '--'
                : ''}
            </Typography>
          </>
        ),
      },
      {
        id: 'details',
        header: 'Details',
        Cell: ({ row }) => (
          <>
            <div className="flex items-center">
              <Typography variant="caption">Logbook:</Typography>
              <div className="flex items-center">
                {/* <MUILink
                component="button"
                variant="body1"
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(
                    routes.ledgerLogbookEntry({
                      orgName,
                      ledgerId: row.original.id,
                    })
                  )
                }}
                disabled
                className="flex items-center no-underline"
              > */}
                <AttachFileIcon fontSize="0.875rem" />
                <Typography variant="body2">
                  {row?.original?.MaintenanceLogbook?.length ?? 0}
                </Typography>
                {/* </MUILink> */}
              </div>
            </div>
            <Typography variant="caption">
              Tasks: {row?.original?.complianceActivity?.length ?? 0}
            </Typography>
          </>
        ),
      },
      {
        id: 'status',
        header: 'Status',
        Cell: ({ row }) => (
          <Chip
            label={getComplianceLedgerStatusText(
              row.original?.status,
              currentUserId,
              row.original
            )}
            variant="filled"
            size="small"
            className="px-1"
            color={statusMap[row.original?.status].color}
            icon={statusMap[row.original?.status].icon}
          />
        ),
      },
      {
        id: 'actions',
        header: 'Actions',
        size: 120,
        Cell: ({ row }) => {
          const showSignButton =
            row.original?.status === 'SIGN' &&
            row.original?.MaintenanceLogbook?.length > 0 &&
            row.original?.MaintenanceLogbook?.every(
              (log) => log?.status === 'PUBLISHED'
            )

          return (
            <div className="flex justify-end gap-1">
              {/* FIXME: remove revision button flag when ready for production */}
              {['COMPLETED', 'REVISION_IN_PROGRESS'].includes(
                row.original?.status
              ) &&
                process.env.REDWOOD_ENV_SHOW_REVISION_BUTTON == 'true' && (
                  <IconButton
                    disabled
                    tooltip={
                      row.original?.status === 'COMPLETED'
                        ? 'Start revision'
                        : 'Continue revision'
                    }
                    LinkComponent={Link}
                    onClick={async (e) => {
                      e.stopPropagation()
                      let ledgerId = row.original.id

                      if (row.original?.status === 'COMPLETED') {
                        await reviseComplianceLedger({
                          variables: {
                            id: row.original.id,
                          },
                          onCompleted: (data) => {
                            ledgerId = data.reviseComplianceLedger.id
                          },
                          context: {
                            headers: {
                              'aircraft-id': row.original?.aircraft?.id,
                            },
                          },
                        })
                      }

                      navigate(
                        routes.bulkCompliance({
                          orgName,
                          ledgerId,
                        })
                      )
                    }}
                  >
                    <DifferenceRoundedIcon />
                  </IconButton>
                )}
              {/* {showSignButton && (
                <IconButton
                  tooltip="esign documents"
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate(
                      routes.bulkComplianceSign({
                        orgName,
                        ledgerId: row.original.id,
                      })
                    )
                  }}
                >
                  <HistoryEduRoundedIcon />
                </IconButton>
              )} */}
              <IconButton
                tooltip="Open detail page"
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(
                    routes.bulkComplianceV2({
                      orgName,
                      id: row.original.id,
                    })
                  )
                }}
              >
                <ArrowForwardIcon />
              </IconButton>
            </div>
          )
        },
        align: 'right',
      },
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    columns,
    data: complianceLedgersArr || [],
    enableSorting: true,
    enableStickyHeader: true,
    enablePagination: true,
    manualPagination: true,
    manualSorting: true,
    muiTablePaperProps: {
      elevation: 0,
      className:
        'border border-solid border-[rgba(224,224,224,1)] rounded-lg w-full',
    },
    muiTableContainerProps: {
      className: 'overflow-auto h-[calc(100vh-320px)] w-full',
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        // Check if click is on a button, icon button, or their children
        const clickedElement = event.target as HTMLElement
        const isActionElement = clickedElement.closest(
          '.MuiButtonBase-root, button, a'
        )
        if (isActionElement) {
          return
        }

        event.stopPropagation()
        navigate(
          routes.bulkComplianceV2({
            orgName,
            id: row.original.id,
          })
        )
      },
      sx: { cursor: 'pointer' },
    }),
    muiPaginationProps: {
      showFirstButton: false,
      showLastButton: false,
    },
    rowCount: complianceLedgersPageInfo.totalCount,
    state: {
      sorting: sorting,
      pagination: pagination,
      isLoading: loading,
      columnPinning: {
        left: ['ledgerNumber'],
        right: ['actions'],
      },
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  })

  return (
    <div className="w-full">
      <MaterialReactTable table={table} />
    </div>
  )
}

export default PastComplianceTable
