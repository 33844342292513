import * as React from 'react'

import ViewColumnOutlined from '@mui/icons-material/ViewColumnOutlined'
import { Button, Checkbox, Chip, FormControlLabel } from '@mui/material'
import { isEmpty } from 'lodash'
import { useDebounce } from 'usehooks-ts'

import { useAuth } from 'src/auth'
import useMaintenanceItemTable, {
  TableVariant,
} from 'src/hooks/useMaintenanceItemTable'
import { useOrgName } from 'src/hooks/useOrgName'
import { isNotEmpty } from 'src/utils/helpers'
import useAircraftRecords from 'src/hooks/requests/useAircraftRecords'

import AircraftFilter from '../AircraftFilter/AircraftFilter'
import ProjectionsAndFilterModal from '../DueListTable/ProjectionsAndFilterModal'
import ManageTableColumnsModal from '../ManageTableColumnsModal'
import Searchbar from '../Searchbar/Searchbar'
import TimeFilter from '../TimeFilter/TimeFilter'
import FilterList from '@mui/icons-material/FilterList'
import DueListFilterPopover from '../FilterPopover/DueListFilterPopover'

interface DueListTableFiltersProps {
  tableVariant: TableVariant
  searchEnabled?: boolean
  aircraftFilterEnabled?: boolean
  projectionFilterEnabled?: boolean
  usageFilterEnabled?: boolean
  manageColumnsEnabled?: boolean
  disableActions?: boolean
  actions?: React.ReactNode[]
  openAircraftFilter?: boolean
  onToggleAircraftFilter?: (isOpen: boolean) => void
}

const duelistPowerSearchOptions = {
  ataCode: 'ATA Code',
  title: 'Title',
  manufactureCode: 'Manufacture Code',
  ataManufactureCode: 'ATA Manufacture Code',
  disposition: 'Disposition',
  partNumber: 'Part Number',
  serialNumber: 'Serial Number',
  maintenanceItemTags: 'Maintenance Item Tags',
  trackedByComponentName: 'Tracking Component Name',
}

const manageColumnOptions = {
  ataCode: 'ATA/Code',
  typeDescription: 'Type/Description',
  lastComplianceDate: 'Compliance',
  interval: 'Interval',
  nextDueStatus: 'Next Due',
  maxDue: 'Max Due',
  calculatedNextDueAt: 'Remaining',
  partSerial: 'Part/Serial Number',
}

const DueListTableFilters: React.FC<DueListTableFiltersProps> = ({
  tableVariant,
  searchEnabled = true,
  aircraftFilterEnabled = true,
  projectionFilterEnabled = true,
  usageFilterEnabled = true,
  manageColumnsEnabled = false,
  disableActions = true,
  actions = [],
  openAircraftFilter,
  onToggleAircraftFilter,
}) => {
  const [showProjectionsAndFilterModal, setShowProjectionsAndFilterModal] =
    React.useState(false)
  const [showManageColumnsModal, setShowManageColumnsModal] =
    React.useState(false)
  const {
    duelistFiltersData,
    setPowerSearchTerms,
    setFuzzySearchTerms,
    setProjectionFilters,
    resetAircraftFilter,
    setAircraftFilter,
    getSearchConfig,
    setEnabledColumns,
    setShowChildItems,
    showChildItems,
    areProjectionsApplied,
    removeProjectionFilters,
    areGeneralFiltersApplied,
    removeGeneralFilters,
    isAtaCodeFilterApplied,
    removeAtaCodeFilter,
    areTrackedByFiltersApplied,
    removeTrackedByFilters,
  } = useMaintenanceItemTable(tableVariant)
  const {
    aircrafts: aircraftsFilterData,
    powerSearchTerms,
    fuzzySearchTerms,
  } = duelistFiltersData
  const selectedAircrafts = Object.keys(aircraftsFilterData).filter(
    (key) => aircraftsFilterData[key]
  )
  const orgName = useOrgName()

  const [hasUserChangedLocalSearchValue, setHasUserChangedLocalSearchValue] =
    React.useState(false)
  const [localSearchValue, setLocalSearchValue] = React.useState<
    string | object
  >({})

  const debouncedSearchValue = useDebounce(localSearchValue, 500)

  const onSearchAircraftChange = (searchVal: string | object) => {
    setLocalSearchValue(searchVal)
  }
  React.useEffect(() => {
    if (!localSearchValue) {
      if (isEmpty(powerSearchTerms) && isNotEmpty(fuzzySearchTerms)) {
        setLocalSearchValue(fuzzySearchTerms)
      }
      if (isEmpty(fuzzySearchTerms) && isNotEmpty(powerSearchTerms)) {
        setLocalSearchValue(powerSearchTerms)
      }
      setHasUserChangedLocalSearchValue(false)
    }
  }, [powerSearchTerms, fuzzySearchTerms])

  React.useEffect(() => {
    if (hasUserChangedLocalSearchValue) {
      if (typeof debouncedSearchValue === 'string') {
        setFuzzySearchTerms(debouncedSearchValue)
      } else {
        setPowerSearchTerms(debouncedSearchValue)
      }
    }
  }, [debouncedSearchValue])

  const { aircrafts, loadAircraftList } = useAircraftRecords()
  React.useEffect(() => {
    loadAircraftList({ orgSlug: orgName })
  }, [])

  const { currentUser } = useAuth()

  const showChildItemsCheckbox = React.useMemo(
    () =>
      [
        'reaves.worrell@imail.org',
        'karthik.srinivasan@getwingwork.com',
        'matt.castellini@getwingwork.com',
        'matt.mcdermott@getwingwork.com',
        'meddy@stein.aero',
        'jason@stein.aero',
        'mkuntz@flightstar.com',
        'juan.trevilla@getwingwork.com',
        'tiger.ma@getwingwork.com',
      ].includes(currentUser?.email),
    [currentUser]
  )

  const projectionsApplied = areProjectionsApplied()
  const generalFiltersApplied = areGeneralFiltersApplied()
  const ataCodeFilterApplied = isAtaCodeFilterApplied()
  const trackedByFiltersApplied = areTrackedByFiltersApplied()
  const filtersApplied = [
    projectionsApplied,
    generalFiltersApplied,
    ataCodeFilterApplied,
    trackedByFiltersApplied,
  ]
  const filtersAppliedCount = filtersApplied.filter(Boolean).length

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-wrap items-center gap-1">
        <div className="min-w-[400px] flex-grow">
          {searchEnabled && (
            <Searchbar
              placeholder="Search"
              onChange={onSearchAircraftChange}
              iconPosition="right"
              value={localSearchValue}
              powerSearch
              powerSearchOptions={duelistPowerSearchOptions}
              setHasUserChangedLocalSearchValue={
                setHasUserChangedLocalSearchValue
              }
            />
          )}
        </div>
        <div className="flex w-auto flex-wrap items-center gap-1">
          {projectionFilterEnabled && (
            <React.Fragment>
              <DueListFilterPopover
                filtersAppliedCount={filtersAppliedCount}
                tableVariant={tableVariant}
              />
            </React.Fragment>
          )}
          {aircraftFilterEnabled && (
            <AircraftFilter
              aircrafts={Object.values(aircrafts)}
              setAircraftFilter={setAircraftFilter}
              resetAircraftFilter={resetAircraftFilter}
              aircraftsFilterData={aircraftsFilterData}
              isFilterActive={selectedAircrafts.length > 0}
              open={openAircraftFilter}
              onToggle={onToggleAircraftFilter}
            />
          )}

          {!disableActions && actions?.map((action) => action)}

          {/* {usageFilterEnabled && <TimeFilter tableVariant={tableVariant} />} */}

          {manageColumnsEnabled && (
            <React.Fragment>
              <Button
                className="aspect-square h-[32px]"
                variant="outlined"
                color="base"
                onClick={() => {
                  setShowManageColumnsModal(true)
                }}
                sx={{
                  minWidth: 'unset', // Override MUI's default minWidth
                  padding: 0, // Remove default padding
                }}
              >
                <ViewColumnOutlined className="h-[20px] w-[20px]" />
              </Button>
              <ManageTableColumnsModal
                tableName="MaintenanceItems" // TODO: this should change based on variant - duplicate all existing records with a talbe name DueList
                open={showManageColumnsModal}
                columnOptions={manageColumnOptions}
                onClose={() => {
                  setShowManageColumnsModal(false)
                }}
                onSaveDone={(columns) => {
                  setEnabledColumns(columns)
                }}
              />
            </React.Fragment>
          )}
        </div>
      </div>
      {searchEnabled && showChildItemsCheckbox && (
        <FormControlLabel
          control={<Checkbox />}
          label="Show Child Items"
          onChange={(e) => setShowChildItems(e.target.checked)}
          className="w-fit"
        />
      )}
      <div className="flex gap-1">
        {powerSearchTerms && !isEmpty(powerSearchTerms) && (
          <div className="flex items-center gap-1">
            {Object.keys(powerSearchTerms)
              .filter((col) => powerSearchTerms[col])
              .map((colKey, idx) => {
                return (
                  <Chip
                    key={idx}
                    size="small"
                    className="bg-[#E1EFFE]"
                    variant="filled"
                    label={`Search '${powerSearchTerms[colKey]}' in ${duelistPowerSearchOptions[colKey]}`}
                    onDelete={() => {
                      const newSearchTerms = { ...powerSearchTerms }
                      delete newSearchTerms[colKey]
                      setPowerSearchTerms(newSearchTerms)
                    }}
                  />
                )
              })}
          </div>
        )}
        {fuzzySearchTerms && (
          <div className="flex items-center gap-1">
            <Chip
              size="small"
              className="bg-[#E1EFFE]"
              variant="filled"
              label={`Fuzzy Search '${fuzzySearchTerms}'`}
              onDelete={() => {
                setFuzzySearchTerms('')
              }}
            />
          </div>
        )}
        {projectionsApplied && (
          <div className="flex items-center gap-1">
            <Chip
              label={'Projections'}
              className="bg-[#E1EFFE]"
              size="small"
              variant="filled"
              icon={<FilterList />}
              onDelete={() => removeProjectionFilters()}
            />
          </div>
        )}
        {generalFiltersApplied && (
          <div className="flex items-center gap-1">
            <Chip
              label={'General Filters'}
              className="bg-[#E1EFFE]"
              size="small"
              variant="filled"
              icon={<FilterList />}
              onDelete={() => removeGeneralFilters()}
            />
          </div>
        )}
        {ataCodeFilterApplied && (
          <div className="flex items-center gap-1">
            <Chip
              label={'ATA Code Filter'}
              className="bg-[#E1EFFE]"
              size="small"
              variant="filled"
              icon={<FilterList />}
              onDelete={() => removeAtaCodeFilter()}
            />
          </div>
        )}
        {trackedByFiltersApplied && (
          <div className="flex items-center gap-1">
            <Chip
              label={'Tracked By'}
              className="bg-[#E1EFFE]"
              size="small"
              variant="filled"
              icon={<FilterList />}
              onDelete={() => removeTrackedByFilters()}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default DueListTableFilters
