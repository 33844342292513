import { useMemo } from 'react'

import { LoadingButton } from '@mui/lab'
import { Permissions } from '@wingwork/common/src/constants/permissions'
import { useDispatch } from 'react-redux'

import { navigate, routes } from '@redwoodjs/router'

import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal'
import Button from 'src/components/MUI/Button'
import DropdownButton from 'src/components/MUI/DropdownButton'
import useHasPermission from 'src/hooks/useHasPermission'
import { useOrgName } from 'src/hooks/useOrgName'
import useQuery from 'src/hooks/useQuery'
import { openModal } from 'src/slices/modal'
import { SelectedInProgress } from 'src/types'

export const QUERY = gql`
  query aircraftsComplianceLedgers($orgSlug: String!) {
    aircrafts(orgSlug: $orgSlug) {
      id
      tailNumber
      ComplianceLedger(status: [IN_PROGRESS, IN_REVIEW]) {
        id
      }
    }
  }
`

type ComplianceButtonProps = {
  onAddCompliance: () => void
  selectedItemIds: string[]
  selectedInProgressItems: SelectedInProgress
  selectedAircraft: string
}

const ComplianceButton: React.FC<ComplianceButtonProps> = ({
  selectedAircraft,
  selectedItemIds,
  selectedInProgressItems,
  onAddCompliance,
}) => {
  const orgSlug = useOrgName()
  const canRead = useHasPermission(
    Permissions.compliance.read,
    selectedAircraft
  )
  const canUpdate = useHasPermission(
    Permissions.compliance.update,
    selectedAircraft
  )
  const { data, hasLoaded, loading } = useQuery(QUERY, {
    variables: { orgSlug },
  })

  const dispatch = useDispatch()

  const continuableAircraft = useMemo(() => {
    if (hasLoaded && data?.aircrafts) {
      return data.aircrafts.filter(
        (aircraft) => aircraft.ComplianceLedger.length
      )
    }
    return []
  }, [hasLoaded, data?.aircrafts])

  if (loading) return <LoadingButton />

  if (!selectedAircraft && continuableAircraft.length) {
    const disabledStatus = {
      disabled: false,
      disabledTooltip: '',
    }
    // if selectedItemIds.length > 1 and selectedAircraft is not set
    // then that means the item belongs to more than one aircraft
    if (selectedItemIds.length > 1) {
      disabledStatus.disabled = true
      disabledStatus.disabledTooltip =
        'All selected items must belong to a single aircraft'
    }
    if (continuableAircraft.length === 0) {
      disabledStatus.disabled = true
      disabledStatus.disabledTooltip = 'No active compliance in-progress'
    }
    if (!canRead) {
      disabledStatus.disabled = true
      disabledStatus.disabledTooltip =
        'You do not have permission to read compliance'
    }
    return (
      <DropdownButton
        {...disabledStatus}
        options={continuableAircraft?.map((aircraft) => ({
          label: aircraft.tailNumber,
          onClick: () =>
            navigate(
              routes.bulkCompliance({
                ledgerId: aircraft.ComplianceLedger[0].id,
                orgName: orgSlug,
              })
            ),
        }))}
        variant="outlined"
        color="base"
        size="large"
      >
        CONTINUE COMPLIANCE
      </DropdownButton>
    )
  }
  const aircraft = continuableAircraft.find((a) => selectedAircraft === a.id)
  const disableButton =
    (selectedItemIds.length === 0 && aircraft === undefined) || !canUpdate

  const disabledTooltip = !canUpdate
    ? 'You do not have permissions to update compliance'
    : 'Please select item(s) to add compliance'

  const confirmInProgressTasks = () => {
    if (Object.keys(selectedInProgressItems).length > 0) {
      dispatch(
        openModal({
          name: 'confirmationModal',
          data: { discriminator: 'inProgressTasksCompliance' },
        })
      )
    } else {
      onAddCompliance()
    }
  }

  const selectedItemsString = Object.values(selectedInProgressItems)
    .map((item) => `${item.ataCode} ${item.manufactureCode}: ${item.title}`)
    .join('\n')

  const isThereNewTasks =
    selectedItemIds.length !== Object.keys(selectedInProgressItems).length

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={confirmInProgressTasks}
        disabled={disableButton}
        disabledTooltip={disabledTooltip}
      >
        {aircraft
          ? selectedItemIds.length === 0
            ? `CONTINUE COMPLIANCE ${aircraft.tailNumber}`
            : `ADD AND CONTINUE COMPLIANCE ${aircraft.tailNumber}`
          : `CREATE COMPLIANCE`}
      </Button>
      <ConfirmationModal
        title="Continue to Compliance"
        discriminator="inProgressTasksCompliance"
        richMessage={
          isThereNewTasks
            ? `The following in-progress tasks will not be added to the compliance:\n\n\n${selectedItemsString}`
            : `All selected tasks are in progress, no new tasks will be added to the compliance.`
        }
        confirmText="Continue to Compliance"
        denyText="Cancel"
        confirmNote={
          isThereNewTasks ? 'Continue without the in-progress tasks.' : ''
        }
        onConfirm={onAddCompliance}
      />
    </>
  )
}

export default ComplianceButton
