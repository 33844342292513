export const GET_SINGLE_COMPLIANCE_DRAWER_DETAILS = gql`
  query GetSingleComplianceDrawerDetails($id: String!) {
    maintenanceItem(id: $id) {
      id
      title
      aircraftId
      ataCode
      maintenanceType
      cadenceType
      isEndOfMonthAdjustment
      cadenceValue
      trackedByComponent {
        id
        name
      }
      maintenanceNextDue {
        id
        nextDueValue
      }
      disposition
      manufactureCode
    }
  }
`

export const PERFORM_SINGLE_COMPLIANCE = gql`
  mutation PerformSingleComplianceDrawer(
    $input: PerformSingleComplianceInput!
    $doSign: Boolean!
  ) {
    performSingleCompliance(input: $input, doSign: $doSign) {
      id
      complianceActivity {
        id
        partsTransaction {
          id
        }
      }
    }
  }
`

export const GET_PARTS_INFORMATION = gql`
  query GetPartsInformationForSingleCompliance($id: String!) {
    maintenanceItem(id: $id) {
      id
      aircraftComponent {
        id
        partNumber
        serialNumber
        monthsSinceNew
        monthsSinceOverhaul
        monthsSinceRepair
        hoursSinceNew
        hoursSinceOverhaul
        hoursSinceRepair
        cyclesSinceNew
        cyclesSinceOverhaul
        cyclesSinceRepair
      }
    }
  }
`
