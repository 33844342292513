import { routes } from '@redwoodjs/router'
import { useBreadcrumbs } from 'src/layouts/components/BreadcrumbsContext'
import WorkOrderReviewHeader from './components/WorkOrderReviewHeader'
import WorkOrderReviewContent from './components/WorkOrderReviewContent'
import useQuery from 'src/hooks/useQuery'
// import { GET_WORK_ORDER_HEADER_DETAILS } from '../WorkOrderPageV2/queries'
import { Divider } from '@mui/material'
import { GET_WORK_ORDER_BASE_FIELDS } from '../WorkOrderCompliancePageV2/queries'

const WorkOrderReviewPage = ({ orgName, id }) => {
  const { data, loading } = useQuery(GET_WORK_ORDER_BASE_FIELDS, {
    variables: { id },
    fetchPolicy: 'cache-first',
  })

  useBreadcrumbs([
    { icon: 'dueList' },
    { label: 'Due List', to: routes.dueList({ orgName }) },
    { label: 'Work Order' },
    { label: 'Review' },
  ])

  return (
    <div className="flex h-full flex-col">
      <WorkOrderReviewHeader id={id} data={data} loading={loading} />
      <Divider />
      <WorkOrderReviewContent
        id={id}
        aircraftId={data?.internalWorkOrder?.aircraft?.id}
      />
    </div>
  )
}

export default WorkOrderReviewPage
