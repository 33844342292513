import { useState, useCallback, useRef } from 'react'

import { useLazyQuery, ApolloError, gql } from '@apollo/client'
import asyncRetry from 'async-retry'
import { MaintenanceItem } from 'types/graphql'
import type {
  MaintenanceItemFilters,
  PaginationInput,
  SortingInput,
} from 'types/graphql'

import CORE_AIRCRAFT_USAGE_LOG_FRAGMENT from 'src/fragments/AircraftUsageLog'
import Sentry from 'src/lib/sentry'

import { useOrgName } from '../useOrgName'
import useHasFeature from '../useHasFeature'

const PAGE_SIZE = 25

// sortField: String : Supported values are column names of MaintenanceItem table
// sortOrder: String : Supported values 'asc' or 'desc'
const GET_MAINTENANCE_DUE_LIST = gql`
  ${CORE_AIRCRAFT_USAGE_LOG_FRAGMENT}
  query getMaintenanceDueListPage(
    $after: String
    $pageSize: Int
    $sortField: String
    $sortOrder: String
    $filters: MaintenanceItemFilters
    $orgHasEmbeddedManuals: Boolean
  ) {
    maintenanceItemsPage(
      maintenanceItemFilters: $filters
      pagination: { after: $after, pageSize: $pageSize }
      sorting: { sortField: $sortField, sortOrder: $sortOrder }
    ) {
      edges {
        adSbStatus
        adSbType
        aircraftId
        ataCode
        cadenceType
        cadenceValue
        calculatedNextDueAt
        description
        disposition
        groupKey
        id
        tags {
          id
          name
        }
        complianceLedger {
          id
          status
          isRevision
          MaintenanceLogbook {
            id
            status
          }
        }
        workOrderIdInProgress
        discrepancyStatus
        lastComplianceDate
        lastComplianceStamp {
          ...CoreAircraftUsageLogFields
        }
        importedDataCompliance
        isAdSb
        isOptional
        isParent
        isRecurring
        maintenanceType
        manufactureCode
        metadata
        calculatedNextDueAt
        nextDueStatus
        notes
        otherAdSbType
        otherComponent
        childCount
        parentId
        remainingValue
        status
        title
        trackedByComponentId
        trackedByComponent {
          id
          name
        }
        upstreamId
        maintenanceNextDue(isCompleted: false) {
          id
          nextDueType
          nextDueValue
          nextDueOverrideType
          nextDueOverride
          nextDueOverrideUser {
            firstName
            lastName
            email
          }
        }
        aircraftComponent {
          id
          partNumber
          serialNumber
          name
          description
          location
        }
        references(orgHasEmbeddedManuals: $orgHasEmbeddedManuals) {
          id
          name
          description
          extractedText
          pageNumbers
          entityId
          referenceSource {
            id
            name
            fileEntry {
              url
            }
          }
        }
      }
      pageInfo {
        cursor
        hasNextPage
        totalCount
      }
    }
  }
`

export type MaintenanceItemPageFetchParams = MaintenanceItemFilters &
  PaginationInput &
  SortingInput

interface PaginatedMaintenanceItemListResponse {
  maintenanceItems: MaintenanceItem[]
  hasNextPage: boolean
  totalCount: number
  initialLoading: boolean
  paginationLoading: boolean
  error: ApolloError | undefined

  loadMaintenanceItems: (
    params: MaintenanceItemPageFetchParams,
    resetData?: boolean,
    signal?: AbortSignal,
    requestId?: number
  ) => void
  fetchNextMaintenancePage: () => void
}

const isRetriableError = (error: ApolloError) => {
  return (
    error.networkError ||
    (error.graphQLErrors &&
      error.graphQLErrors.some(
        (err) => err.extensions?.code === 'INTERNAL_SERVER_ERROR'
      ))
  )
}

const usePaginatedMaintenanceItemList =
  (): PaginatedMaintenanceItemListResponse => {
    const [maintenanceItems, setMaintenanceItems] = useState<MaintenanceItem[]>(
      []
    )
    const [error, setError] = useState<ApolloError | undefined>(undefined)
    const [hasNextPage, setHasNextPage] = useState<boolean>(false)
    const [nextPageCursor, setNextPageCursor] = useState<string | undefined>(
      undefined
    )
    const [totalCount, setTotalCount] = useState<number | undefined>(undefined)
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [paginationLoading, setPaginationLoading] = useState<boolean>(false)
    const [queryVariables, setQueryVariables] = useState<
      MaintenanceItemPageFetchParams | undefined
    >()
    const activeRequestRef = useRef<number | null>(null)

    const orgName = useOrgName()

    const { hasFeature: orgHasEmbeddedManuals } = useHasFeature(
      'EmbeddedManuals',
      orgName
    )

    const postMaintenanceItemFetch = useCallback(
      (maintenancePageData, resetData = false) => {
        if (maintenancePageData) {
          setHasNextPage(
            maintenancePageData.maintenanceItemsPage.pageInfo.hasNextPage
          )
          setNextPageCursor(
            maintenancePageData.maintenanceItemsPage.pageInfo.cursor
          )
          setTotalCount(
            maintenancePageData.maintenanceItemsPage.pageInfo.totalCount
          )
          if (resetData) {
            setMaintenanceItems([
              ...maintenancePageData.maintenanceItemsPage.edges,
            ])
          } else {
            setMaintenanceItems((prev) => [
              ...prev,
              ...maintenancePageData.maintenanceItemsPage.edges,
            ])
          }
        }
      },
      []
    )

    const [fetchMaintenanceItems, { fetchMore }] = useLazyQuery(
      GET_MAINTENANCE_DUE_LIST
    )

    const fetchWithRetry = useCallback(
      async (
        variables,
        resetData,
        initialLoading = false,
        signal?: AbortSignal
      ) => {
        return await asyncRetry(
          async (bail) => {
            try {
              let data
              if (initialLoading) {
                const result = await fetchMaintenanceItems({
                  variables: {
                    ...variables,
                    orgHasEmbeddedManuals,
                  },
                  context: {
                    fetchOptions: {
                      signal,
                    },
                  },
                })
                data = result.data
              } else {
                const result = await fetchMore({
                  variables: {
                    ...variables,
                    orgHasEmbeddedManuals,
                  },
                })
                data = result.data
              }
              postMaintenanceItemFetch(data, resetData)
              return data
            } catch (error) {
              if (!isRetriableError(error)) {
                bail(error)
                return
              }
              throw error // This will trigger a retry
            }
          },
          {
            retries: 3,
            factor: 2,
            minTimeout: 100,
            maxTimeout: 2000,
            onRetry: (error, attempt) => {
              console.log(
                `Retry attempt ${attempt} due to error: ${error.message}`
              )
            },
          }
        )
      },
      [fetchMaintenanceItems, fetchMore, postMaintenanceItemFetch]
    )

    const fetchNextMaintenancePage = useCallback(async () => {
      if (hasNextPage && queryVariables) {
        const variables = { ...queryVariables, after: nextPageCursor }
        const { after, pageSize, sortField, sortOrder, ...filters } = variables
        setPaginationLoading(true)
        setError(undefined)
        try {
          await fetchWithRetry(
            { after, pageSize, sortField, sortOrder, filters },
            false
          )
        } catch (error) {
          setError(error as ApolloError)
          Sentry.captureException(error, {
            extra: {
              message: "Couldn't fetch next maintenance page after retries",
              variables,
            },
          })
        } finally {
          setPaginationLoading(false)
        }
      }
    }, [hasNextPage, queryVariables, nextPageCursor, fetchWithRetry])

    const loadMaintenanceItems = useCallback(
      async (
        params: MaintenanceItemPageFetchParams,
        resetData = false,
        signal?: AbortSignal,
        requestId?: number
      ) => {
        if (params.pageSize === undefined) {
          params.pageSize = PAGE_SIZE
        }
        params.orgSlug = orgName
        const { after, pageSize, sortField, sortOrder, ...filters } = params
        setQueryVariables(params)
        setInitialLoading(true)
        // track new request
        if (requestId) {
          activeRequestRef.current = requestId
        }

        setError(undefined)
        try {
          await fetchWithRetry(
            { after, pageSize, sortField, sortOrder, filters },
            resetData,
            true,
            signal
          )
        } catch (error) {
          setError(error as ApolloError)
          Sentry.captureException(error, {
            extra: {
              message: "Couldn't fetch maintenance items after retries",
              params,
            },
          })
        } finally {
          // only set loading to false if this is the active request (not previous aborted request)
          if (requestId && requestId !== activeRequestRef.current) {
            return
          }
          //backward compatibility if requestId is not provided
          setInitialLoading(false)
        }
      },
      [orgName, fetchWithRetry]
    )

    return {
      maintenanceItems,
      hasNextPage,
      totalCount,
      initialLoading,
      paginationLoading,
      error,
      loadMaintenanceItems,
      fetchNextMaintenancePage,
    }
  }

export default usePaginatedMaintenanceItemList
