import { useEffect } from 'react'

import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { Divider } from '@mui/material'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from 'react-hot-toast'

import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal'
import Loading from 'src/components/Loading'
import { useDispatch } from 'src/hooks/useDispatch'
import useQuery from 'src/hooks/useQuery'
import { closeModal, openModal } from 'src/slices/modal'

import { UPDATE_PURCHASE_ORDER_MUTATION } from '../PurchaseOrderDraftPage/queries'

import InvoiceViewModal from './InvoiceViewModal'
import PurchaseOrderComplianceModal, {
  AircraftIdToLedgerMap,
  MtxItemsGroupedByAircraftId as MtxItemsGroupedByAircraftId,
} from './PurchaseOrderComplianceModal'
import PurchaseOrderPdfViewModal from './PurchaseOrderPdfViewModal'
import PurchaseOrderView from './PurchaseOrderView'
import {
  GET_OPEN_PURCHASE_ORDER_BY_ID,
  GET_OPEN_PURCHASE_ORDER_INVOICE_BY_ID,
} from './queries'
import SendPurchaseOrderModal from './SendPurchaseOrderModal'
import { useBreadcrumbs } from 'src/layouts/components/BreadcrumbsContext'
import PurchaseOrderOpenHeader from './PurchaseOrderOpenHeader'

type PurchaseOrderOpenPageProps = {
  id: string
  orgName: string
}

const PurchaseOrderOpenPage: React.FC<PurchaseOrderOpenPageProps> = ({
  id,
  orgName,
}) => {
  const dispatch = useDispatch()

  const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER_MUTATION, {
    onCompleted: () => {
      toast.success('Purchase Order has been completed')
    },
  })

  const { data, hasLoaded, refetch } = useQuery(GET_OPEN_PURCHASE_ORDER_BY_ID, {
    variables: {
      id,
    },
  })
  const po = data?.purchaseOrder ?? {}

  const {
    data: invoiceData,
    hasLoaded: invoiceHasLoaded,
    refetch: invoiceRefetch,
  } = useQuery(GET_OPEN_PURCHASE_ORDER_INVOICE_BY_ID, {
    variables: { id },
  })

  const invoiceFile = invoiceData?.purchaseOrder?.invoiceFile?.reduce(
    (acc, file) => {
      if (!acc) return file
      return file.createdAt > acc.createdAt ? file : acc
    },
    null
  )

  useBreadcrumbs([
    { icon: 'purchaseOrders' },
    { label: 'Purchase Orders', to: routes.purchaseOrders({ orgName }) },
    { label: `PO ${po.number}` },
  ])

  const serviceItemsWithMtxItem = po?.purchaseOrderItem?.filter(
    (item) => item.type === 'SERVICE' && item.maintenanceItemId
  )

  const aircraftIdToLedgerMap: AircraftIdToLedgerMap =
    po.complianceLedger?.reduce((acc, ledger) => {
      acc[ledger.aircraftId] = ledger
      return acc
    }, {})

  const mtxItemsGroupedByAircraftId: MtxItemsGroupedByAircraftId =
    serviceItemsWithMtxItem?.reduce((acc, item) => {
      const aircraftId = item.maintenanceItem.aircraftId
      const maintenanceItemDetails = {
        id: item.maintenanceItemId,
        ataCode: item.maintenanceItem.ataCode,
        manufactureCode: item.maintenanceItem.manufactureCode,
        title: item.maintenanceItem.title,
        tailNumber: item.maintenanceItem.aircraft.tailNumber,
      }

      if (!acc[aircraftId]) {
        acc[aircraftId] = []
      }

      acc[aircraftId].push(maintenanceItemDetails)

      return acc
    }, {})

  // number of bulk compliance ledgers that should be completed
  const ledgerQuantity = mtxItemsGroupedByAircraftId
    ? Object.keys(mtxItemsGroupedByAircraftId).length
    : 0

  const completedCompliance =
    po.complianceLedger &&
    po.complianceLedger.length === ledgerQuantity &&
    po.complianceLedger?.every((ledger) => ledger.status === 'COMPLETED')

  useEffect(() => {
    if (
      po.status === 'COMPLETED' &&
      serviceItemsWithMtxItem.length > 0 &&
      !completedCompliance
    ) {
      dispatch(
        openModal({
          name: 'purchaseOrderComplianceModal',
        })
      )
    } else {
      dispatch(closeModal({ name: 'purchaseOrderComplianceModal' }))
    }
  }, [po.status, dispatch, completedCompliance, serviceItemsWithMtxItem])

  if (!hasLoaded) {
    return <Loading />
  }

  const pdfFile = po?.pdfFile?.reduce((acc, file) => {
    if (!acc) return file
    return file.createdAt > acc.createdAt ? file : acc
  }, null)

  const fileName = `PO-${data.purchaseOrder.number}.pdf`

  return (
    <div className="flex h-full flex-col">
      <PurchaseOrderOpenHeader
        id={id}
        data={data}
        completedCompliance={completedCompliance}
        serviceItemsWithMtxItemLength={serviceItemsWithMtxItem.length}
        invoiceFile={invoiceFile}
        invoiceRefetch={invoiceRefetch}
      />
      <Divider />
      <PurchaseOrderView id={id} />
      <InvoiceViewModal id={id} />
      <SendPurchaseOrderModal
        urlForPdfDownload={pdfFile.url}
        vendorEmail={data.purchaseOrder.vendor.email}
        fileName={fileName}
      />
      <ConfirmationModal
        title="Complete Purchase Order"
        message="Are you sure you want to complete this purchase order?"
        confirmText="Complete PO"
        denyText="Cancel"
        discriminator="complete-po"
        onConfirm={async () => {
          // await updatePurchaseOrder({
          //   variables: {
          //     id,
          //     input: {
          //       status: 'COMPLETED',
          //     },
          //   },
          // })
          // if (serviceItemsWithMtxItem.length > 0) {
          //   dispatch(openModal({ name: 'purchaseOrderComplianceModal' }))
          // }
          navigate(routes.purchaseOrderIngest({ orgName, id }))
        }}
      />

      <PurchaseOrderPdfViewModal
        urlForPdfPreview={pdfFile.urlForPdfPreview}
        urlForPdfDownload={pdfFile.url}
        fileName={fileName}
      />
      <PurchaseOrderComplianceModal
        purchaseOrderId={id}
        aircraftIdToLedgerMap={aircraftIdToLedgerMap}
        mtxItemsGroupedByAircraftId={mtxItemsGroupedByAircraftId}
        onClose={() => {
          if (
            po.status === 'COMPLETED' &&
            serviceItemsWithMtxItem.length > 0 &&
            !completedCompliance
          ) {
            toast(
              'Complete compliance for service items in this purchase order',
              {
                icon: <WarningRoundedIcon color="warning" />,
                id: 'complete-compliance-warning', // id is used to prevent multiple fires
              }
            )
          }
        }}
      />
    </div>
  )
}

export default PurchaseOrderOpenPage
